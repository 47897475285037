import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LinkOverlay } from '@sorare/core/src/atoms/navigation/Box';
import { LabelL, LabelM, LabelS } from '@sorare/core/src/atoms/typography';
import { BLOG_PATH } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';

import { LobbyTile } from 'components/home/LobbyTile';

import bigBlogBg from './assets/bigBlogBg.jpg';
import blogSquare from './assets/blogSquare.png';

const Wrapper = styled.div`
  opacity: 1;
  position: relative;
  transition: opacity 0.05s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledLobbyTile = styled(LobbyTile)`
  height: 100%;
  display: flex;
  align-items: end;

  background: url(${bigBlogBg}) no-repeat center center;
  background-size: cover;
`;

const CTA = styled(Button)`
  margin-left: auto;
`;

const CompactVariant = () => {
  const { generateSportContextPath, sport } = useSportContext();

  return (
    <Wrapper>
      <LinkOverlay
        as={Link}
        to={generateSportContextPath(BLOG_PATH, {
          sport,
        })}
      >
        <Horizontal>
          <img width={48} height={48} src={blogSquare} alt="" />
          <Vertical gap={0.5}>
            <LabelM bold>
              <FormattedMessage
                id="ForYou.Blog.title"
                defaultMessage="Latest News"
              />
            </LabelM>
            <LabelM as="span" color="var(--c-nd-600)">
              <FormattedMessage
                id="ForYou.Blog.subtitle"
                defaultMessage="Blog"
              />
            </LabelM>
          </Vertical>
          <CTA size="compact" color="quaternary">
            <FormattedMessage id="ForYou.Blog.CTA" defaultMessage="Read now" />
          </CTA>
        </Horizontal>
      </LinkOverlay>
    </Wrapper>
  );
};

const DefaultVariant = () => {
  const { generateSportContextPath, sport } = useSportContext();

  return (
    <StyledLobbyTile
      outlineColor="#3498DB"
      label={
        <LabelS bold color="var(--c-white)">
          <FormattedMessage id="ForYou.Blog.label" defaultMessage="Blog" />
        </LabelS>
      }
    >
      <LinkOverlay
        as={Link}
        to={generateSportContextPath(BLOG_PATH, {
          sport,
        })}
      >
        <LabelL bold>
          <FormattedMessage
            id="ForYou.Blog.title"
            defaultMessage="Latest News"
          />
        </LabelL>
      </LinkOverlay>
    </StyledLobbyTile>
  );
};

type Props = {
  compact?: boolean;
};

export const Blog = ({ compact }: Props) => {
  if (compact) {
    return <CompactVariant />;
  }

  return <DefaultVariant />;
};
