import classNames from 'classnames';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { SorareLogo } from 'atoms/icons/SorareLogo';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineXL } from 'atoms/typography';
import { ScarcityBackground } from 'components/card/ScarcityBackground';
import { tabletAndAbove } from 'style/mediaQuery';

import { actionsAnimation, textAnimation } from '../ui';

const Root = styled(Vertical)`
  position: relative;
  height: 100%;
  text-align: center;
  justify-content: flex-start;
  color: var(--c-white);
  margin: 0 auto;
  @media ${tabletAndAbove} {
    justify-content: center;
  }
`;
const LogoCtn = styled(Horizontal)`
  ${textAnimation};
`;

const Title = styled(HeadlineXL)`
  ${textAnimation};
  width: 100%;
  text-shadow: 0px 0px 40px rgba(255, 255, 255, 0.58);
  .showAdditionalRewards & {
    animation: none;
    transition: transform 0.5s;
    transform: translateX(-100%);
    padding: 0 var(--quadruple-unit);
  }
`;

const Subtitle = styled(BodyM)`
  width: 100%;
  ${textAnimation}
  .showAdditionalRewards & {
    animation: none;
    transition: transform 0.6s;
    transform: translateX(-100%);
    padding: 0 var(--quadruple-unit);
  }
`;

const MainColumn = styled(Vertical)`
  width: 100%;
  .showAdditionalRewards.isBundle & {
    width: 0;
    opacity: 0;
    transition: all 0.3s;
    overflow: hidden;
  }
`;
const NoShrinkColumn = styled(Vertical)`
  width: 100%;
  flex-shrink: 0;
  transition: all 0.5s;
`;

const Actions = styled(Vertical)`
  flex-grow: 1;
  max-width: 340px;
  width: 100%;
  gap: var(--double-unit);
  justify-content: center;
  ${actionsAnimation};
`;
const ActionsCtn = styled(Vertical)`
  height: 88px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

type Props = {
  main: ReactNode;
  actions?: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  rarityBackground: Rarity;
  christmasBackground?: boolean;
  isBundle: boolean;
};

export const CongratsScreen = ({
  main,
  actions,
  title,
  subtitle,
  rarityBackground,
  christmasBackground,
  isBundle,
}: Props) => {
  return (
    <>
      <ScarcityBackground
        rarity={rarityBackground}
        christmas={christmasBackground}
      />
      <Root gap={1.5} center className={classNames({ isBundle })}>
        <LogoCtn center>
          <SorareLogo />
        </LogoCtn>

        <NoShrinkColumn center>
          <Title brand>{title}</Title>
          <Subtitle bold>{subtitle}</Subtitle>
        </NoShrinkColumn>

        <MainColumn center>{main}</MainColumn>

        <ActionsCtn>
          {actions && <Actions key="2">{actions}</Actions>}
        </ActionsCtn>
      </Root>
    </>
  );
};
