import { PropsWithChildren, useEffect, useState } from 'react';

import { useSentryContext } from 'contexts/sentry';

import { BrazeContext } from '.';
import { BrazeAPI, BrazeData } from './types';

const defaultData = {
  cards: [],
};

export const BrazeProvider = ({ children }: PropsWithChildren) => {
  const { sendSafeError } = useSentryContext();
  const [brazeData, setBrazeData] = useState<BrazeData | null>(null);
  const brazeAPI = 'braze' in window ? (window.braze as BrazeAPI) : null;

  useEffect(() => {
    try {
      if (brazeAPI) {
        brazeAPI.subscribeToContentCardsUpdates(({ cards }) => {
          setBrazeData({ cards: cards || [] });
        });
        if (!brazeData) {
          const cachedCards = brazeAPI.getCachedContentCards();
          if (cachedCards?.length) {
            setBrazeData({ cards: cachedCards });
          } else {
            brazeAPI.requestContentCardsRefresh();
          }
        }
      }
    } catch (e) {
      sendSafeError(e);
    }
  }, [brazeAPI, brazeData, sendSafeError]);

  return (
    <BrazeContext.Provider
      value={{
        data: brazeData || defaultData,
        api: brazeAPI,
      }}
    >
      {children}
    </BrazeContext.Provider>
  );
};
