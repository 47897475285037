import { Avatar } from 'components/user/Avatar';
import { UserName } from 'components/user/UserName';
import { UserHit, convertUserHit } from 'lib/algolia';

import { Suggestion } from '../Suggestion';

type Props = {
  hit: UserHit;
  isHighlighted: boolean;
};

export const UserSuggestion = ({ hit, isHighlighted }: Props) => {
  return (
    <Suggestion
      isHighlighted={isHighlighted}
      avatar={<Avatar user={convertUserHit(hit)} variant="medium" />}
      primary={<UserName user={convertUserHit(hit)} />}
    />
  );
};
