import { useEffect, useMemo, useState } from 'react';
import Autosuggest, { AutosuggestProps } from 'react-autosuggest';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import styled, { css } from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import { Button } from 'atoms/buttons/Button';
import { SearchInput } from 'atoms/inputs/SearchInput';
import { Vertical } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';
import {
  FOOTBALL_CARDS_SLUG,
  FOOTBALL_COUNTRIES_COUNTRYCODE,
  FOOTBALL_COUNTRIES_COUNTRYCODE_CARDS,
  FOOTBALL_MY_CLUB_SLUG,
  MLB_CARDS_SLUG,
  MLB_MY_CLUB_SLUG,
  MLB_PLAYERS_SLUG,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_TEAMS_SLUG,
  MLB_TEAMS_SLUG_CARDS,
  NBA_MY_CLUB_SLUG,
  NBA_PLAYERS_SLUG,
  NBA_PLAYERS_SLUG_CARDS,
  NBA_TEAMS_SLUG_CARDS,
} from 'constants/__generated__/routes';
import {
  FOOTBALL_CLUB_SHOW,
  FOOTBALL_CLUB_SHOW_CARDS,
  FOOTBALL_COUNTRY_SHOW,
  FOOTBALL_LEAGUE_SHOW,
  FOOTBALL_LEAGUE_SHOW_CARDS,
  FOOTBALL_PLAYER_SHOW,
  FOOTBALL_PLAYER_SHOW_CARDS,
  NBA_CARDS_SLUG,
  NBA_TEAM,
} from 'constants/routes';
import { useConfigContext } from 'contexts/config';
import { useCurrentUserContext } from 'contexts/currentUser';
import { LIFECYCLE, useLifecycle } from 'hooks/useLifecycle';
import { useSettlableWalletsSearch } from 'hooks/useSettlableWalletsSearch';
import { CardHit } from 'lib/algolia';
import { useEvents } from 'lib/events/useEvents';
import { glossary } from 'lib/glossary';
import { getFormattedSport } from 'lib/sports';
import { sportFromJSON } from 'protos/events/shared/events';
import { desktopAndAbove } from 'style/mediaQuery';
import { OverrideClasses } from 'style/utils';

import { CardSuggestion } from '../CardSuggestion';
import { ClubSuggestion } from '../ClubSuggestion';
import { CompetitionSuggestion } from '../CompetitionSuggestion';
import { CountrySuggestion } from '../CountrySuggestion';
import { EmptyResult } from '../EmptyResult';
import { SEARCH_PARAMS } from '../InstantSearch/types';
import { buildFilterQuery } from '../InstantSearch/utils';
import { NationalTeamSuggestion } from '../NationalTeamSuggestion';
import { PlayerSuggestion } from '../PlayerSuggestion';
import { UserSuggestion } from '../UserSuggestion';

export const MAX_RESULTS = 12;

const getSectionSuggestions = (section: any) => {
  section.hits.forEach((h: any) => {
    h.index = section.index;
  });
  return section.hits;
};

interface Props {
  onExit: boolean;
  onSelect?: () => void;
  selectedSport: Sport;
  sortedSports: Sport[];
  selectSport: (sportInput: Sport, searchTerm: string) => void;
  presetSearch?: string;
  setDisplayLatestSearchItem?: (value: boolean) => void;
}

const messages = defineMessages({
  tabsTitle: {
    id: 'MultiSportAutosuggestSearchInput.tabsTitle',
    defaultMessage: 'I’m looking for',
  },
  players: {
    id: 'SearchBar.players',
    defaultMessage: 'Players',
  },
  countries: {
    id: 'SearchBar.countries',
    defaultMessage: 'Countries',
  },
  nationalTeams: {
    id: 'SearchBar.nationalTeams',
    defaultMessage: 'National Teams',
  },
  clubs: {
    id: 'SearchBar.clubs',
    defaultMessage: 'Clubs',
  },
  teams: {
    id: 'SearchBar.teams',
    defaultMessage: 'Teams',
  },
  managers: {
    id: 'SearchBar.managers',
    defaultMessage: 'Managers',
  },
  leagues: {
    id: 'SearchBar.leagues',
    defaultMessage: 'Leagues',
  },
  cards: {
    id: 'SearchBar.cards',
    defaultMessage: 'My cards',
  },
});

const SectionTitle = styled(LabelS)`
  margin-bottom: var(--half-unit);
`;

// https://github.com/styled-components/styled-components/issues/4182
const AutosuggestHelper = <T,>({
  asTheme,
  ...props
}: Omit<AutosuggestProps<T>, 'theme'> & {
  asTheme?: AutosuggestProps<T>['theme'];
}) => {
  return <Autosuggest theme={asTheme} {...props} />;
};

const [StyledAutosuggest, classes] = OverrideClasses(AutosuggestHelper, null, {
  container: css`
    position: relative;
    line-height: 1;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  suggestionsContainerOpen: css`
    width: calc(100% + 50px);
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    margin: var(--double-unit) -50px 0;
    .forceSearchbarForDesktop & {
      margin: var(--double-unit) 0 0;
    }
    @media ${desktopAndAbove} {
      margin: var(--double-unit) 0 0;
      width: 100%;
    }
  `,
  suggestionsList: css`
    margin: 0;
    padding: 0;
    list-style-type: none;
  `,
  suggestion: css`
    display: block;
    &:last-child {
      margin-bottom: var(--unit);
    }
  `,
});
const AutosuggestContainer = styled(Vertical).attrs({ gap: 0 })`
  gap: 10px;
`;
const TabsContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-left: -50px;
  .forceSearchbarForDesktop & {
    margin-left: 0;
  }
  @media ${desktopAndAbove} {
    margin-left: 0;
  }
`;
const Tabs = styled.div`
  display: flex;
  gap: 10px;
`;

export const SearchBar = ({
  onExit,
  onSelect = () => {},
  selectSport: doSelectSport,
  selectedSport,
  sortedSports,
  presetSearch,
  setDisplayLatestSearchItem,
}: Props) => {
  const [suggestionValue, setSuggestionValue] = useState<string>();
  const { currentUser } = useCurrentUserContext();

  const { params } = useSettlableWalletsSearch();
  const { update, lifecycle } = useLifecycle();
  const { refine, query: currentSearch } = useSearchBox();
  const { scopedResults, results: searchResults } = useInstantSearch();
  const track = useEvents();
  const latestSearchedItems = lifecycle?.latestSearchedItems;

  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState(presetSearch || currentSearch);
  const { algoliaIndexes } = useConfigContext();
  const handleSuggestionsClearRequested = () => {};
  const shouldRenderSuggestions = () => true;
  const [hasNotClickedResult, setHasNotClickedResult] = useState<boolean>(true);

  const searchWithOnSale = buildFilterQuery({
    [SEARCH_PARAMS.ON_SALE]: true,
    ...(params ? { [SEARCH_PARAMS.PURCHASE_OPTIONS]: params } : {}),
  });
  // Select sport & ensure we do not deal with more than MAX_RESULTS items
  const truncatedLatestSearchedItems = latestSearchedItems
    ? (latestSearchedItems[selectedSport] || []).slice(0, MAX_RESULTS)
    : [];

  const getMaxResults = (index: string, defaultValue = 2) => {
    const formerSearchesInThisIndex = truncatedLatestSearchedItems.filter(
      latestSearchedItem => latestSearchedItem.index === index
    ).length;

    if (formerSearchesInThisIndex > defaultValue) {
      // cap it to max 4 results
      return Math.min(formerSearchesInThisIndex, 4);
    }
    return defaultValue;
  };

  const indexConfig = {
    [algoliaIndexes.Player]: {
      routes: {
        [Sport.FOOTBALL]: currentUser
          ? FOOTBALL_PLAYER_SHOW_CARDS
          : FOOTBALL_PLAYER_SHOW,
        [Sport.BASEBALL]: currentUser
          ? MLB_PLAYERS_SLUG_CARDS
          : MLB_PLAYERS_SLUG,
        [Sport.NBA]: currentUser ? NBA_PLAYERS_SLUG_CARDS : NBA_PLAYERS_SLUG,
      },
      search: currentUser ? searchWithOnSale : undefined,
      title: formatMessage(messages.players),
      suggestion: PlayerSuggestion,
      maxResults: getMaxResults(algoliaIndexes.Player, 4),
    },
    [algoliaIndexes.Country]: {
      routes: {
        [Sport.FOOTBALL]: FOOTBALL_COUNTRY_SHOW,
        [Sport.BASEBALL]: undefined,
        [Sport.NBA]: undefined,
      },
      title: formatMessage(messages.countries),
      suggestion: CountrySuggestion,
      maxResults: getMaxResults(algoliaIndexes.Country),
    },
    [algoliaIndexes['National Team']]: {
      routes: {
        [Sport.FOOTBALL]: currentUser
          ? FOOTBALL_COUNTRIES_COUNTRYCODE_CARDS
          : FOOTBALL_COUNTRIES_COUNTRYCODE,
        [Sport.BASEBALL]: undefined,
        [Sport.NBA]: undefined,
      },
      title: formatMessage(messages.nationalTeams),
      suggestion: NationalTeamSuggestion,
      maxResults: getMaxResults(algoliaIndexes['National Team']),
    },
    [algoliaIndexes.Club]: {
      routes: {
        [Sport.FOOTBALL]: currentUser
          ? FOOTBALL_CLUB_SHOW_CARDS
          : FOOTBALL_CLUB_SHOW,
        [Sport.BASEBALL]: currentUser ? MLB_TEAMS_SLUG_CARDS : MLB_TEAMS_SLUG,
        [Sport.NBA]: currentUser ? NBA_TEAMS_SLUG_CARDS : NBA_TEAM,
      },
      search: currentUser ? searchWithOnSale : undefined,
      titles: {
        [Sport.FOOTBALL]: formatMessage(messages.clubs),
        [Sport.BASEBALL]: formatMessage(messages.teams),
        [Sport.NBA]: formatMessage(messages.teams),
      },
      suggestion: ClubSuggestion,
      maxResults: getMaxResults(algoliaIndexes.Club),
    },
    [algoliaIndexes.User]: {
      routes: {
        [Sport.FOOTBALL]: FOOTBALL_MY_CLUB_SLUG,
        [Sport.BASEBALL]: MLB_MY_CLUB_SLUG,
        [Sport.NBA]: NBA_MY_CLUB_SLUG,
      },
      title: formatMessage(messages.managers),
      suggestion: UserSuggestion,
      maxResults: getMaxResults(algoliaIndexes.User),
      hideWithoutQuery:
        truncatedLatestSearchedItems.findIndex(
          truncatedLatestSearchedItem =>
            truncatedLatestSearchedItem.index === algoliaIndexes.User
        ) === -1,
    },
    [algoliaIndexes.Competition]: {
      routes: {
        [Sport.FOOTBALL]: currentUser
          ? FOOTBALL_LEAGUE_SHOW_CARDS
          : FOOTBALL_LEAGUE_SHOW,
        [Sport.BASEBALL]: undefined,
        [Sport.NBA]: undefined,
      },
      search: currentUser ? searchWithOnSale : undefined,
      title: formatMessage(messages.leagues),
      suggestion: CompetitionSuggestion,
      maxResults: getMaxResults(algoliaIndexes.Competition),
    },
    [algoliaIndexes.New]: {
      routes: {
        [Sport.FOOTBALL]: FOOTBALL_CARDS_SLUG,
        [Sport.BASEBALL]: MLB_CARDS_SLUG,
        [Sport.NBA]: NBA_CARDS_SLUG,
      },
      title: formatMessage(messages.cards),
      suggestion: CardSuggestion,
      maxResults: getMaxResults(algoliaIndexes.New),
      getSlug: {
        [Sport.FOOTBALL]: (s: CardHit) => s.objectID,
        [Sport.BASEBALL]: (s: CardHit) => s.slug,
        [Sport.NBA]: (s: CardHit) => s.slug,
      },
      hideWithoutQuery: false,
    },
  };

  const getConfig = (index: keyof typeof indexConfig) => {
    const result = indexConfig[index];
    if (result) {
      return result!;
    }
    throw new Error(`Missing config for ${index}`);
  };

  const getLink = (s: any) => {
    const config = getConfig(s.index);
    const slug = config.getSlug?.[selectedSport]?.(s) || s.objectID;

    const route = config.routes[selectedSport];
    if (!route) {
      throw new Error(`Missing route config for ${s.index}/${selectedSport}`);
    }
    let path;
    if (
      route === FOOTBALL_COUNTRIES_COUNTRYCODE ||
      route === FOOTBALL_COUNTRIES_COUNTRYCODE_CARDS
    ) {
      path = generatePath(route, { countryCode: s.country.code });
    } else if (route === FOOTBALL_COUNTRY_SHOW) {
      const countryCode = s.code;
      path = generatePath(route, { countryCode });
    } else {
      path = generatePath(route, { slug });
    }
    return `${path}${config.search ? `?${config.search}` : ''}`;
  };

  const renderSectionTitle = (section: any) => {
    const config = getConfig(section.index);
    const title = config.title || config.titles?.[selectedSport];

    return section.hits.length > 0 ? (
      <SectionTitle color="var(--c-nd-600)">{title}</SectionTitle>
    ) : null;
  };

  // First iteration, we stick to legacy search inputs
  const hits = scopedResults.map(result => {
    const config = getConfig(result.results.index);

    return {
      index: result.indexId,
      hits:
        config.hideWithoutQuery && !search
          ? []
          : result.results.hits.slice(0, config.maxResults).map(h => ({
              ...h,
              index: result.indexId,
            })),
    };
  });

  // since we are querying multiple indexes, hits has the following structure:
  // [
  //    { index: 'Player', hits: [{ display_name: 'Kylian Mbappé' }, { display_name: 'Antoine Griezmann' }]},
  //    { index: 'Club', hits: [{ name: 'PSG' }]}
  // ]
  const results: typeof hits = JSON.parse(JSON.stringify(hits));
  const nbIndex = results.length;
  let nbResults = results.reduce((acc, index) => acc + index.hits.length, 0);
  let iteration = 0;
  // remove the last hit from each index alternatively until the number of results is correct
  while (nbResults > MAX_RESULTS) {
    const indexHits = results[iteration % nbIndex].hits;
    if (indexHits.length > 0) {
      if (
        !truncatedLatestSearchedItems.find(
          latestSearchedItem =>
            latestSearchedItem.objectID ===
              indexHits[indexHits.length - 1].objectID &&
            indexHits[indexHits.length - 1].index === latestSearchedItem.index
        )
      ) {
        (indexHits as any).splice(-1);
        nbResults -= 1;
      }
    }
    iteration += 1;
  }

  const isLoadingAfterChangingTab = useMemo(
    () => presetSearch && !searchResults?.query,
    [presetSearch, searchResults?.query]
  );

  const emptyResult = useMemo(() => {
    if (isLoadingAfterChangingTab) return false;
    if (nbResults === 0) return true;
    return false;
  }, [isLoadingAfterChangingTab, nbResults]);

  const selectSport = (sport: Sport, searchTerm: string) => {
    track('Click Filter In Search', {
      searchTerm,
      context: sportFromJSON(selectedSport),
      sport,
    });
    doSelectSport(sport, searchTerm);
  };

  useEffect(() => {
    return () => {
      if (hasNotClickedResult && onExit) {
        track('Exit Search Without Clicking Result', {
          searchTerm: search,
          context: sportFromJSON(selectedSport),
        });
      }
    };
    // do not include `search` in the dependencies, we don't want this to be
    // triggered at each keystroke
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onExit, hasNotClickedResult, track]);

  useDebounce(
    () => {
      if (suggestionValue !== undefined) {
        setDisplayLatestSearchItem?.(
          suggestionValue === '' || suggestionValue === undefined
        );
        refine(suggestionValue);
      }
    },
    300,
    [suggestionValue, setDisplayLatestSearchItem]
  );

  const addItemToLatestSearch = (s: any) => {
    if (!currentUser) return;
    const alreadyExist = truncatedLatestSearchedItems.find(
      latestSearchedItem =>
        latestSearchedItem.objectID === s.objectID &&
        s.index === latestSearchedItem.index
    );
    if (alreadyExist) {
      return;
    }
    const newArray = [
      {
        objectID: s.objectID,
        sport: selectedSport,
        index: s.index,
      },
      ...truncatedLatestSearchedItems,
    ];
    const itemToUpdate = {
      ...latestSearchedItems,
      ...{ [selectedSport]: newArray.slice(0, MAX_RESULTS) },
    };
    update(LIFECYCLE.latestSearchedItems, itemToUpdate);
  };
  return (
    <StyledAutosuggest
      highlightFirstSuggestion
      multiSection
      alwaysRenderSuggestions
      asTheme={classes}
      suggestions={isLoadingAfterChangingTab ? [] : results}
      onSuggestionsFetchRequested={({ value }) => setSuggestionValue(value)}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      onSuggestionSelected={(event, { suggestion }) => {
        const destination = getLink(suggestion);
        setHasNotClickedResult(false);
        track('Click Search Result', {
          resultCategory: suggestion.index,
          resultDestination: destination,
          searchTerm: search,
          context: sportFromJSON(selectedSport),
        });
        addItemToLatestSearch(suggestion);
        navigate(destination);
        onSelect();
      }}
      getSuggestionValue={() => search}
      shouldRenderSuggestions={shouldRenderSuggestions}
      renderSuggestion={(hit: any, { isHighlighted }) => {
        const { index } = hit;
        const SuggestionComponent = getConfig(index).suggestion;

        return (
          <SuggestionComponent
            key={hit.objectID}
            hit={hit}
            isHighlighted={isHighlighted}
          />
        );
      }}
      inputProps={{
        value: search,
        placeholder: formatMessage(glossary.search),
        onChange: (event, { newValue }) => {
          setSearch(newValue);
        },
        autoFocus: true,
      }}
      renderInputComponent={({
        onChange,
        value,
        withIcon = false,
        ref,
        ...rest
      }) => (
        <AutosuggestContainer>
          <SearchInput
            withIcon={withIcon}
            fullWidth
            onChange={event =>
              onChange(event, {
                newValue: event.target.value,
                method: 'type',
              })
            }
            inputRef={ref}
            {...rest}
            value={value}
          />
          {sortedSports && sortedSports.length > 0 && (
            <TabsContainer>
              <LabelS color="var(--c-nd-600)">
                <FormattedMessage {...messages.tabsTitle} />
              </LabelS>
              <Tabs>
                {sortedSports.map((sport: Sport) => (
                  <Button
                    key={sport}
                    size="medium"
                    color={sport === selectedSport ? 'secondary' : 'tertiary'}
                    onClick={() => selectSport(sport, value)}
                  >
                    {getFormattedSport(sport)}
                  </Button>
                ))}
              </Tabs>

              {emptyResult && (
                <Tabs>
                  <EmptyResult sport={selectedSport} />
                </Tabs>
              )}
            </TabsContainer>
          )}
        </AutosuggestContainer>
      )}
      renderSuggestionsContainer={({ containerProps, children }) => (
        <Vertical {...containerProps}>{children}</Vertical>
      )}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      focusInputOnSuggestionClick={false}
    />
  );
};
