import { ReactNode, useCallback, useMemo, useRef } from 'react';

import { Operation, TMContext } from '.';

interface Props {
  children: ReactNode;
}

export const TMProvider = ({ children }: Props) => {
  const operations = useRef<Operation[]>([]);

  const logOperation = useCallback((operation: Operation) => {
    operations.current.push(operation);
  }, []);

  const flushOperations = useCallback(() => {
    const res = operations.current;
    operations.current = [];
    return res;
  }, []);

  const value = useMemo(
    () => ({
      logOperation,
      flushOperations,
    }),
    [flushOperations, logOperation]
  );

  return <TMContext.Provider value={value}>{children}</TMContext.Provider>;
};
