import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';

import { Button, Props as ButtonProps } from 'atoms/buttons/Button';
import { FontAwesomeIcon, IconDefinition } from 'atoms/icons';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { mobileApp } from 'lib/glossary';

type Props = {
  children?: ReactNode;
};

export const NotIOsAppFeature = ({ children }: Props) => {
  const { isIosApp } = useIsMobileApp();
  if (isIosApp) {
    return null;
  }
  return <>{children}</>;
};

export const IOsAppFeature = ({ children }: Props) => {
  const { isIosApp } = useIsMobileApp();
  if (isIosApp) {
    return <>{children}</>;
  }
  return null;
};

export const NotAndroidAppFeature = ({ children }: Props) => {
  const { isAndroidApp } = useIsMobileApp();

  if (isAndroidApp) {
    return null;
  }
  return <>{children}</>;
};

export const AndroidAppFeature = ({ children }: Props) => {
  const { isAndroidApp } = useIsMobileApp();

  if (isAndroidApp) {
    return <>{children}</>;
  }
  return null;
};

const StyledTooltip = styled(Tooltip)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  opacity: 0.5;
`;
type UnvailableButtonForAndroidProps = ButtonProps & {
  cardSlug?: string;
  message?: MessageDescriptor;
  icon?: IconDefinition;
};
export const UnvailableButtonForAndroid = (
  props: UnvailableButtonForAndroidProps
) => {
  const { message, icon, cardSlug, ...rest } = props;
  const {
    flags: { buyOnWebMarketplaceFromAndroid = false },
  } = useFeatureFlags();
  const { postOpenCurrentUrlMessage } = useIsMobileApp();

  if (buyOnWebMarketplaceFromAndroid && postOpenCurrentUrlMessage) {
    return (
      <Button {...rest} onClick={() => postOpenCurrentUrlMessage(cardSlug)}>
        <FontAwesomeIcon icon={faExternalLink} />
        <FormattedMessage {...mobileApp.openWebMarketplace} />
      </Button>
    );
  }

  return (
    <StyledTooltip
      title={
        <FormattedMessage
          id="UnvailableButtonForAndroid.tootltip"
          defaultMessage="Temporarily unavailable in-app. Please go to sorare.com"
        />
      }
    >
      <StyledButton {...rest}>
        {icon && <FontAwesomeIcon icon={icon} />}
        <FormattedMessage {...(message || mobileApp.unavailable)} />
      </StyledButton>
    </StyledTooltip>
  );
};

export const NotMobileAppFeature = ({ children }: Props) => {
  const { isAndroidApp, isIosApp } = useIsMobileApp();

  if (isIosApp || isAndroidApp) {
    return null;
  }
  return <>{children}</>;
};

export const MobileAppFeature = ({ children }: Props) => {
  const { isAndroidApp, isIosApp } = useIsMobileApp();
  if (isIosApp || isAndroidApp) {
    return <>{children}</>;
  }
  return null;
};
