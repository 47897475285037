import { createContext, useContext } from 'react';
import Helmet from 'react-helmet';

import { ImageVariations } from 'lib/share';

export interface Options {
  description?: string;
  img?: string | Record<ImageVariations, string | null> | null;
  twitter?: string;
  replaceFullTitle?: boolean;
  noindex?: boolean;
}

interface SeoContext {
  setPageMetadata: (seoTitle: string, options?: Options) => () => void;
}

export const SeoContext = createContext<SeoContext | null>(null);

export const useSeoContext = () => useContext(SeoContext)!;

export const NoIndex = () => (
  <Helmet>
    <meta name="robots" content="noindex" />
  </Helmet>
);
