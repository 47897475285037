import { useCallback, useState } from 'react';

export const useToggle = (
  defaultValue: boolean
): [boolean, () => void, (val: boolean) => void] => {
  const [value, setValue] = useState(defaultValue);
  const toggleValue = useCallback(() => setValue(val => !val), [setValue]);

  return [value, toggleValue, setValue];
};
