import { Destination } from 'types';

async function fetchDestinationForWriteKey(
  cdnHost: string,
  writeKey: string
): Promise<Destination[]> {
  const res = await fetch(
    `https://${cdnHost}/v1/projects/${writeKey}/integrations`
  );

  if (!res.ok) {
    throw new Error(
      `Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`
    );
  }

  const destinations = await res.json();

  // Rename creationName to id to abstract the weird data model
  for (const destination of destinations) {
    // Because of the legacy Fullstory integration the creationName for this integration is the `name`
    if (destination.name === 'Fullstory') {
      destination.id = destination.name;
    } else {
      destination.id = destination.creationName;
    }
    delete destination.creationName;
  }

  return destinations;
}

export const fetchDestinations = async (
  cdnHost: string,
  writeKeys: string[]
): Promise<Destination[]> => {
  const destinationsRequests = writeKeys.map(async writeKey =>
    fetchDestinationForWriteKey(cdnHost, writeKey)
  );

  let destinations = (await Promise.all(destinationsRequests)).flat();
  destinations = destinations.filter(d => d.id !== 'Repeater');
  destinations = destinations.sort((a, b) => a.id.localeCompare(b.id));
  destinations = [...new Map(destinations.map(d => [d.id, d])).values()];

  return destinations;
};
