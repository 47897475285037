import { faArrowDownLong, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { defineMessages, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled, { css } from 'styled-components';

import premierLeague from 'assets/logos/football/EPL.svg';
import mls from 'assets/logos/football/MLS.svg';
import bundesliga from 'assets/logos/football/bundesliga.svg';
import cinch from 'assets/logos/football/cinchPremiership-monochrome.svg';
import eredivisie from 'assets/logos/football/eredivisie-monochrome.svg';
import laLiga from 'assets/logos/football/laLiga.svg';
import serieA from 'assets/logos/football/serieA.svg';
import mlb from 'assets/logos/mlb/mlb.svg';
import nba from 'assets/logos/nba/nba.svg';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { BodyL, BodyS } from 'atoms/typography';
import { ContentContainer } from 'components/landing/LandingMultiSport/ui';
import { messages as globalMessages } from 'components/landing/LandingMultiSport/utils';
import { LICENSED_PARTNERS_SPORT_TAB } from 'constants/__generated__/routes';
import { useScreenSize } from 'hooks/device/useScreenSize';
import { tabletAndAbove } from 'style/mediaQuery';

const messages = defineMessages({
  moreLeagues: {
    id: 'Landing.LeaguesBlock.Moreleagues',
    defaultMessage: 'More leagues',
  },
});

const Wrapper = styled.div``;

const LeagueWrapper = styled(ContentContainer)<{ $maintenance?: boolean }>`
  position: relative;
  display: grid;
  gap: var(--unit);
  justify-content: space-between;
  grid-auto-flow: column;
  grid-template-columns: repeat(10, calc(var(--unit) * 14));
  ${({ $maintenance }) =>
    $maintenance
      ? css`
          grid-template-columns: repeat(9, calc(var(--unit) * 14));
        `
      : null}
  margin: calc(var(--unit) * 5) 0;
  z-index: 1;
  overflow: auto;

  @media ${tabletAndAbove} {
    margin: var(--double-unit) auto auto auto;
    grid-template-columns: repeat(10, calc(var(--unit) * 15));
    ${({ $maintenance }) =>
      $maintenance
        ? css`
            grid-template-columns: repeat(9, calc(var(--unit) * 15));
          `
        : null}
  }
`;

const LeagueLogo = styled.img`
  height: calc(var(--unit) * 7);
  max-width: calc(var(--unit) * 7);
`;

const LeagueBlock = styled.div`
  width: 100%;
  padding: var(--double-unit);
  border-radius: var(--unit);
  text-align: center;
`;

const MoreLeaguesLink = styled(LeagueBlock)`
  cursor: pointer;
  opacity: 1;
  background: rgba(255, 255, 255, 0.09);
  transition: opacity 0.2s ease-in-out;
  color: var(--c-white);

  &:hover {
    opacity: 0.8;
    color: var(--c-white);
  }

  &:visited {
    color: var(--c-white);
  }
`;

const LeagueName = styled(BodyS)`
  line-height: 1.15;
  margin-top: var(--intermediate-unit);
`;

const MoreLeaguesIcon = styled(Horizontal).attrs({ gap: 0, center: true })`
  background: black;
  border-radius: 50%;
  height: calc(var(--unit) * 7);
  width: calc(var(--unit) * 7);
  margin: auto;
`;
const CTAWrapper = styled(ContentContainer)`
  display: flex;
  gap: var(--double-and-a-half-unit);
  justify-content: space-between;
  padding-top: calc(var(--unit) * 7);
  padding-bottom: var(--quadruple-unit);
  position: relative;
  border-bottom: 1px solid var(--c-nd-700);
`;

const ScrollDown = styled(Horizontal).attrs({ gap: 2 })`
  padding-right: var(--quadruple-unit);
`;

export const LeaguesBlock = ({ maintenance }: { maintenance?: boolean }) => {
  const { formatMessage } = useIntl();
  const { up: isTabletOrDesktop } = useScreenSize('tablet');
  const leagues = [
    {
      name: 'Premier League',
      logo: premierLeague,
    },
    {
      name: 'NBA',
      logo: nba,
    },
    {
      name: 'MLB',
      logo: mlb,
    },
    {
      name: 'LALIGA',
      logo: laLiga,
    },
    {
      name: 'Bundesliga',
      logo: bundesliga,
    },
    {
      name: 'Serie A',
      logo: serieA,
    },
    {
      name: 'Major League Soccer',
      logo: mls,
    },
    {
      name: 'Eredivisie',
      logo: eredivisie,
    },
    {
      name: 'Cinch Premiership',
      logo: cinch,
    },
  ];

  return (
    <Wrapper>
      {!isTabletOrDesktop && (
        <CTAWrapper>
          <BodyL color="var(--c-nd-600-opaque)">
            {formatMessage(globalMessages.ScrollDownCTA)}
          </BodyL>
          <ScrollDown>
            <FontAwesomeIcon icon={faArrowDownLong} />
          </ScrollDown>
        </CTAWrapper>
      )}
      <LeagueWrapper $maintenance={maintenance}>
        {leagues.map(({ name, logo }) => (
          <LeagueBlock key={name}>
            <LeagueLogo src={logo} alt={name} />
            <LeagueName>{name}</LeagueName>
          </LeagueBlock>
        ))}
        {maintenance ? null : (
          <MoreLeaguesLink
            as="a"
            target="_blank"
            rel="noreferrer"
            href={generatePath(LICENSED_PARTNERS_SPORT_TAB, {
              sport: 'football',
              tab: 'competitions',
            })}
          >
            <MoreLeaguesIcon>
              <FontAwesomeIcon icon={faPlus} />
            </MoreLeaguesIcon>
            <LeagueName>{formatMessage(messages.moreLeagues)}</LeagueName>
          </MoreLeaguesLink>
        )}
      </LeagueWrapper>
    </Wrapper>
  );
};
