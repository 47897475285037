import { StartCase } from 'types';

export const toStartCase = <T extends string>(s: T): StartCase<T> => {
  return (
    s
      // convert camel case to start case
      .replace(/([^A-Z]+)([A-Z])/g, '$1 $2')
      // separate all-uppercased letters (etc Accept TCU Dialog)
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') as StartCase<T>
  );
};
