import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';

import { bonusMessages } from 'lib/bonus';

import type { BonusBreakdownTooltip_powerBreakdown } from './__generated__/index.graphql';

type Props = {
  powerBreakdown: BonusBreakdownTooltip_powerBreakdown;
  captainBonus?: number | null;
  transferMalus?: number | null;
  children: ReactNode;
};

export const BonusBreakdownTooltip = ({
  powerBreakdown,
  captainBonus,
  transferMalus,
  children,
}: Props) => {
  const normalizedBreakdownList = [
    {
      message: bonusMessages.captain,
      value: captainBonus || 0,
    },
    {
      message: bonusMessages.levelBonus,
      value: powerBreakdown.xpBasisPoints / 10000,
    },
    {
      message: bonusMessages.season,
      value: powerBreakdown.seasonBasisPoints / 10000,
    },
    {
      message: bonusMessages.scarcity,
      value: powerBreakdown.scarcityBasisPoints / 10000,
    },
    {
      message: bonusMessages.collection,
      value: transferMalus ? 0 : powerBreakdown.collectionBasisPoints / 10000,
    },
    {
      message: bonusMessages.club,
      value: powerBreakdown.activeClubsBasisPoints / 10000,
    },
    {
      message: bonusMessages.nationality,
      value: powerBreakdown.nationalityBasisPoints / 10000,
    },
    {
      message: bonusMessages.position,
      value: powerBreakdown.positionsBasisPoints / 10000,
    },
    {
      message: bonusMessages.specialEdition,
      value: powerBreakdown.specialEditionCardBasisPoints / 10000,
    },
    {
      message: bonusMessages.transferMalus,
      value: transferMalus || 0,
      malus: true,
    },
  ].filter(Boolean);

  return (
    <Tooltip
      title={
        <Vertical>
          {normalizedBreakdownList
            .filter(({ value }) => value)
            .map(({ message, value, malus }, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <SBHorizontal key={i}>
                <LabelS bold color="var(--c-nd-500)">
                  <FormattedMessage {...message} />
                </LabelS>
                <Chip color={malus ? 'red' : 'green'}>
                  <FormattedNumber
                    value={value}
                    signDisplay="always"
                    style="percent"
                    maximumFractionDigits={1}
                  />
                </Chip>
              </SBHorizontal>
            ))}
        </Vertical>
      }
    >
      {children}
    </Tooltip>
  );
};

BonusBreakdownTooltip.fragments = {
  powerBreakdown: gql`
    fragment BonusBreakdownTooltip_powerBreakdown on PowerBreakdown {
      activeClubsBasisPoints
      captainBasisPoints
      collectionBasisPoints
      nationalityBasisPoints
      positionsBasisPoints
      scarcityBasisPoints
      seasonBasisPoints
      specialEditionCardBasisPoints
      xpBasisPoints
    }
  ` as TypedDocumentNode<BonusBreakdownTooltip_powerBreakdown>,
};
