import classNames from 'classnames';
import { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const Root = styled.label`
  display: flex;
  gap: var(--unit);
  align-items: flex-start;
  cursor: pointer;
  vertical-align: middle;
  margin: 0;
  &.disabled {
    cursor: default;
  }
  label {
    color: var(--c-white);
  }
  &.start {
    flex-direction: row-reverse;
  }
  &.top {
    flex-direction: column-reverse;
  }
  &.bottom {
    flex-direction: column;
  }
`;

type Props = {
  control: ReactElement<any, string | JSXElementConstructor<any>>;
  label: ReactNode;
  labelPlacement?: 'start' | 'end' | 'top';
  className?: string;
};

export const FormControlLabel = ({
  control,
  label,
  labelPlacement = 'end',
  className,
}: Props) => {
  return (
    <Root
      className={classNames(
        className,
        labelPlacement !== 'end' && labelPlacement,
        { disabled: control?.props.disabled }
      )}
    >
      {control}
      {label}
    </Root>
  );
};
