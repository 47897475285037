import styled from 'styled-components';

import { FullWidthContainer } from '@sorare/core/src/atoms/layout/FullWidthContainer';

const IOSWrapper = styled.div`
  /* Page header is handled by the ios app */
  display: none;
`;
const OverflowHiddenWrapper = styled.div`
  overflow: hidden;
`;
const PageHeaderTitle = styled(OverflowHiddenWrapper)`
  width: 100%;
`;

export const IOSAppHeader = () => {
  return (
    <FullWidthContainer>
      <IOSWrapper>
        <PageHeaderTitle id="page-header-title" />
        <OverflowHiddenWrapper id="page-header-title-breadcrumb" />
      </IOSWrapper>
    </FullWidthContainer>
  );
};
