type RejectionReasonType<T> = Parameters<
  Parameters<ConstructorParameters<typeof Promise<T>>[0]>[1]
>[0];

export class Deferred<T> {
  public promise: Promise<T>;

  public pending: boolean;

  private res?: (value: T) => void;

  private rej?: (reason: RejectionReasonType<T>) => void;

  constructor() {
    this.pending = true;
    this.promise = new Promise<T>((resolve, reject) => {
      this.res = resolve;
      this.rej = reject;
    });
  }

  resolve(value: T) {
    this.pending = false;
    return this.res!(value);
  }

  reject(reason: RejectionReasonType<T>) {
    this.pending = false;
    return this.rej!(reason);
  }
}
