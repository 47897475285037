import classNames from 'classnames';
import { ChangeEvent, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

import { switchCss } from 'atoms/icons/SwitchIcon/ui';
import { Color } from 'style/types';

type Props = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  'name' | 'id' | 'readOnly'
> & {
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  $backgroundColor?: Color;
  name?: string;
  disabled?: boolean;
  small?: boolean;
};

const StyledInput = styled.input.attrs({ type: 'checkbox' })`
  width: 100%;
  height: 100%;
  display: block;
  appearance: none;
  position: absolute;
  inset: 0;
  cursor: pointer;
  background: transparent;
  margin: 0;
`;

const Wrapper = styled.span`
  position: relative;
  display: inline-block;
`;

const UI = styled.span<{
  $backgroundColor?: Color;
}>`
  ${switchCss}
  pointer-events: none;
`;

export const Switch = ({
  checked,
  onChange,
  $backgroundColor,
  name,
  small,
  ...rest
}: Props) => {
  return (
    <Wrapper>
      <StyledInput
        onChange={onChange}
        name={name}
        checked={checked}
        {...rest}
      />
      <UI
        aria-checked={checked}
        $backgroundColor={$backgroundColor}
        className={classNames({ small })}
      />
    </Wrapper>
  );
};
