import qs from 'qs';
import { ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import { API_ROOT } from 'config';
import { useOAuthParams } from 'hooks/useOAuthParams';

import { AuthenticityToken } from '../../form/AuthenticityToken';

interface Props<T extends ReactNode | undefined = ReactNode> {
  provider: 'discord' | 'twitter' | 'google_oauth2' | 'facebook' | 'apple';
  sport?: Sport;
  children?: T;
}

const Form = styled.form`
  margin-bottom: 0;
`;

export const OAuthForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { provider, children, sport } = props;

  const params = useOAuthParams({ sport });
  const querystring = qs.stringify(params, { skipNulls: true });

  return (
    <Form
      ref={ref}
      action={`${API_ROOT}/users/auth/${provider}?${querystring}`}
      method="post"
    >
      <AuthenticityToken />
      {children}
    </Form>
  );
});

OAuthForm.displayName = 'OAuthForm';
