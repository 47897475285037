import { So5FixtureEvent } from '__generated__/globalTypes';

import { STORAGE, useLocalStorage } from './useLocalStorage';

export const useLastVisitedEventType = () => {
  const [lastVisitedEventType, setLastVisitedEventType] = useLocalStorage(
    STORAGE.lastVisitedEventType,
    So5FixtureEvent.DAILY.toLowerCase()
  );

  return {
    lastVisitedEventType,
    setLastVisitedEventType,
  };
};
