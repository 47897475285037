import {
  QueryRef,
  TypedDocumentNode,
  gql,
  useMutation,
  useQueryRefHandlers,
} from '@apollo/client';
import gqlMacro from 'graphql-tag.macro';
import { ReactNode, useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useInterval, useTimeout } from 'react-use';

import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { RedirectAfterSignIn } from 'contexts/currentUser/RedirectAfterSignIn';
import { useGraphqlContext } from 'contexts/graphql';
import { coreCache } from 'contexts/graphql/Provider';
import { useTMContext } from 'contexts/tm';
import { useQuery } from 'hooks/graphql/useQuery';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useQueryLoaderResult } from 'hooks/useQueryLoaderResult';
import { CurrencyCode, currency } from 'lib/fiat';
import { queryLoader } from 'lib/navigation/queryLoader';

import { AlgoliaCardIndexes, AlgoliaIndexes, ConfigContext } from '.';
import { currentUser } from '../currentUser/queries';
import {
  ConfigProvider_TeamsPlayingNextGameWeek,
  ConfigQuery,
  ConfigQueryVariables,
  PingConfigQuery,
  PingConfigQueryVariables,
  ReportTelemetry,
  ReportTelemetryVariables,
  ScoutableSo5Competitions_query,
} from './__generated__/Provider.graphql';
import { ConfigQuery_currentUser } from './types';

const teamPlayingNextGameWeekFragment = gqlMacro`
  fragment ConfigProvider_TeamsPlayingNextGameWeek on TeamsPlayingNextGameWeek {
    slug
    gameWeek
    shortDisplayName
    longDisplayName
    endDate
    startDate
    teamSlugs
  }
` as TypedDocumentNode<ConfigProvider_TeamsPlayingNextGameWeek>;

const scoutableSo5CompetitionsFragment = gql`
  fragment ScoutableSo5Competitions_query on Query {
    so5 {
      id
      cardMaxGrade(sport: $sport)
      cardXpLevelUpable(sport: $sport)
      inSeasonName: seasonalityName(seasonality: IN_SEASON, sport: $sport)
      allSeasonsName: seasonalityName(seasonality: ALL_SEASONS, sport: $sport)
      so5Competitions(sport: $sport) {
        slug
        displayName
        logoUrl
        backgroundUrl
        algoliaFilters
        scoutable
        competitions {
          slug
          displayName
          logoUrl
        }
      }
    }
  }
` as TypedDocumentNode<ScoutableSo5Competitions_query>;

const CONFIG_QUERY = gqlMacro`
  query ConfigQuery($sport: Sport!) {
    config {
      id
      algoliaIndexSuffix
      algoliaApplicationId
      algoliaSearchApiKey
      bankAddress
      starkExchangeAddress
      ethereumNetworkId
      ethereumEndpoint
      sorareTokensAddress
      sorareEncryptionKey
      sponsorAccountAddress
      minimumReceiveWeiAmount
      marketFeeRateBasisPoints
      marketplacePromotionalEvents {
        id
        sport
        events {
          id
          name
          objectIds
          slugs
          rewardDetailsHref
        }
      }
      idealDepositFeesAmountMinor
      mangopayAmexFeesBasisPoints: creditCardFeesBasisPoints(
        creditCardType: AMEX
        provider: MANGOPAY
      )
      mangopayVisaFeesBasisPoints: creditCardFeesBasisPoints(
        creditCardType: CB_VISA_MASTERCARD
        provider: MANGOPAY
      )
      checkoutFeesBasisPoints: creditCardFeesBasisPoints(
        creditCardType: CB_VISA_MASTERCARD
        provider: CHECKOUT
      )
      paypalDepositFeesBasisPoints
      mangopayApplePayDepositFeesBasisPoints
      stripeCreditCardFeesBasisPoints: creditCardFeesBasisPoints(
        provider: STRIPE
      )
      exchangeRate {
        id
        ethRates {
          eurMinor
          usdMinor
          gbpMinor
        }
        time
      }
      defaultFiatCurrency
      ethQuantum
      so5 {
        id
        teamsPlayingNextDailyGameWeeks: teamsPlayingNextGameWeeks(
          eventType: DAILY
          sport: $sport
        ) {
          slug
          ...ConfigProvider_TeamsPlayingNextGameWeek
        }
        teamsPlayingNextClassicGameWeeks: teamsPlayingNextGameWeeks(
          eventType: CLASSIC
          sport: $sport
          first: 3
        ) {
          slug
          ...ConfigProvider_TeamsPlayingNextGameWeek
        }
      }
      currentLocation {
        countryCode
        regionCode
      }
      counts {
        usersCount
      }
    }
    currentUser {
      slug
      ...CurrentUserProvider_currentUser
    }
    tokens {
      promotionalEvents: promotionalEvents(sport: $sport) {
        slug
        name
        pictureUrl
        algoliaFilters
      }
    }
    ...ScoutableSo5Competitions_query
  }
  ${currentUser}
  ${teamPlayingNextGameWeekFragment}
  ${scoutableSo5CompetitionsFragment}
` as TypedDocumentNode<ConfigQuery, ConfigQueryVariables>;

const PING_QUERY = gqlMacro`
  query PingConfigQuery {
    config {
      id
      exchangeRate {
        id
        ethRates { eurMinor usdMinor gbpMinor }
        time
      }
      minimumReceiveWeiAmount
    }
    currentUser {
      slug
    }
  }
` as TypedDocumentNode<PingConfigQuery, PingConfigQueryVariables>;

const TM_MUTATION = gqlMacro`
  mutation ReportTelemetry($input: reportTelemetryInput!) {
    reportTelemetry(input: $input) {
      errors {
        message
      }
    }
  }
` as TypedDocumentNode<ReportTelemetry, ReportTelemetryVariables>;

interface Props {
  children: ReactNode;
}

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

const pingInterval = 115_000 + getRandomInt(10_000);
const tmInterval = 30_000 + getRandomInt(5_000);

export const loader = queryLoader(CONFIG_QUERY, ({ sport }) => ({ sport }));

/**
 * Provides environment dependent configuration keys.
 * Aggregates all startup loading states so that initialization can be done in parallel
 */
export const ConfigProvider = ({ children }: Props) => {
  const data = useQueryLoaderResult<ConfigQuery>();
  const { refetch } = useQueryRefHandlers<ConfigQuery>(
    useLoaderData() as QueryRef<ConfigQuery>
  );

  const [isPingQueryReady] = useTimeout(pingInterval);
  useQuery(PING_QUERY, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    pollInterval: pingInterval,
    skip: !isPingQueryReady(),
  });
  const {
    flags: { useReportTelemetry },
  } = useFeatureFlags();
  const { refreshWsCable } = useGraphqlContext();
  const flushOperations = useTMContext()?.flushOperations;
  const [tm] = useMutation(TM_MUTATION, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });

  const value = useMemo(() => {
    const { config, tokens, so5 } = data;
    const { algoliaIndexSuffix, exchangeRate, minimumReceiveWeiAmount } =
      config;
    const algoliaCardIndexes: AlgoliaCardIndexes = {
      // blockchain cards indices
      New: `BlockchainCard_New${algoliaIndexSuffix}`,
      'Ending Soon': `BlockchainCard_EndingSoon${algoliaIndexSuffix}`,
      'Newly Listed': `BlockchainCard_NewlyListed${algoliaIndexSuffix}`,
      'Highest Average Score': `BlockchainCard_HighestAverageScore${algoliaIndexSuffix}`,
      'Highest Price': `BlockchainCard${algoliaIndexSuffix}`,
      'Lowest Price': `BlockchainCard_LowestPrice${algoliaIndexSuffix}`,
      'Best Value': `BlockchainCard_BestValue${algoliaIndexSuffix}`,
      'Popular Player': `BlockchainCard_Popularity${algoliaIndexSuffix}`,

      'Cards On Sale Ending Soon': `CardsOnSale_EndingSoon${algoliaIndexSuffix}`,
      'Cards On Sale Newly Listed': `CardsOnSale_NewlyListed${algoliaIndexSuffix}`,
      'Cards On Sale Highest Average Score': `CardsOnSale_HighestAverageScore${algoliaIndexSuffix}`,
      'Cards On Sale Highest Price': `CardsOnSale${algoliaIndexSuffix}`,
      'Cards On Sale Lowest Price': `CardsOnSale_LowestPrice${algoliaIndexSuffix}`,
      'Cards On Sale Best Value': `CardsOnSale_BestValue${algoliaIndexSuffix}`,
      'Cards On Sale Popular Player': `CardsOnSale_Popularity${algoliaIndexSuffix}`,
    };

    const algoliaIndexes: AlgoliaIndexes = {
      ...algoliaCardIndexes,
      User: `User${algoliaIndexSuffix}`,
      Player: `Player${algoliaIndexSuffix}`,
      Club: `Club${algoliaIndexSuffix}`,
      League: `League${algoliaIndexSuffix}`,
      Country: `Country${algoliaIndexSuffix}`,
      'National Team': `NationalTeam${algoliaIndexSuffix}`,
      Competition: `Competition${algoliaIndexSuffix}`,
    };

    return {
      ...config,
      ...tokens,
      so5: {
        ...config.so5,
        ...so5,
      },
      marketFeeRateBasisPoints: config.marketFeeRateBasisPoints / 10000,
      marketFeePercentagePoints: BigInt(config.marketFeeRateBasisPoints) / 100n,
      minimumReceiveWeiAmount,
      algoliaIndexes,
      algoliaCardIndexes,
      currentUser: data.currentUser,
      exchangeRate: {
        ...exchangeRate,
        getEthRate: (to: CurrencyCode) =>
          (exchangeRate.ethRates[
            `${to.toLowerCase()}Minor` as keyof typeof exchangeRate.ethRates
          ] as number) / 100,
      },
      refetch,
      updateQuery: (newCurrentUser: ConfigQuery_currentUser) => {
        coreCache.updateQuery(
          {
            query: CONFIG_QUERY,
          },
          () => ({ ...data, currentUser: newCurrentUser })
        );
        refreshWsCable();
      },
      defaultFiatCurrency: currency(config.defaultFiatCurrency),
    };
  }, [data, refetch, refreshWsCable]);

  useInterval(
    () => {
      if (flushOperations) {
        const operations = flushOperations();
        if (!operations.length) {
          return;
        }
        tm({ variables: { input: { operations } } });
      }
    },
    useReportTelemetry ? tmInterval : null
  );

  if (!value) return <LoadingIndicator fullScreen />;

  return (
    <RedirectAfterSignIn currentUser={value.currentUser}>
      <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
    </RedirectAfterSignIn>
  );
};
