import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Badge } from '@sorare/core/src/atoms/ui/Badge';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useInviteLink } from '@sorare/core/src/hooks/useInviteLink';
import { navLabels } from '@sorare/core/src/lib/glossary';

export const InviteFriendsButton = () => {
  const inviteLink = useInviteLink();
  const { currentUser } = useCurrentUserContext();

  return (
    <Badge badgeContent={currentUser?.unclaimedReferralRewardsCount}>
      <Button to={inviteLink} color="tertiary" size="medium">
        <FontAwesomeIcon icon={faUser} size="sm" />
        <FormattedMessage {...navLabels.inviteFriends} />
      </Button>
    </Badge>
  );
};
