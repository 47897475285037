import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';

import { OAuthProvider } from '__generated__/globalTypes';
import { GOOGLE_OAUTH_CLIENT_ID } from 'config';
import { useConfigContext } from 'contexts/config';
import { useConnectionContext } from 'contexts/connection';
import { useCurrentUserContext } from 'contexts/currentUser';
import { currentUser as currentUserFragment } from 'contexts/currentUser/queries';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import { useSportContext } from 'contexts/sport';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { STORAGE, useLocalStorage } from 'hooks/useLocalStorage';
import { useOAuthParams } from 'hooks/useOAuthParams';
import { useEvents } from 'lib/events/useEvents';
import { formatGqlErrors } from 'lib/gql';

import {
  SignInWithOAuthMutation,
  SignInWithOAuthMutationVariables,
} from './__generated__/index.graphql';

const SRC = 'https://accounts.google.com/gsi/client';

declare global {
  interface Window {
    handleOneTapCredential?: (response: any) => void;
  }
}

const SIGN_IN_WITH_OAUTH_MUTATION = gql`
  mutation SignInWithOAuthMutation(
    $input: signInWithOAuthInput!
    $sport: Sport!
  ) {
    signInWithOauth(input: $input) {
      currentUser {
        slug
        ...CurrentUserProvider_currentUser
      }
      tcuToken
      errors {
        message
        code
      }
    }
  }
  ${currentUserFragment}
` as TypedDocumentNode<
  SignInWithOAuthMutation,
  SignInWithOAuthMutationVariables
>;

export const GoogleOneTap = () => {
  const { updateQuery } = useConfigContext();
  const { currentUser } = useCurrentUserContext();
  const { sport } = useSportContext();
  const {
    flags: { useGoogleOneTap = false },
  } = useFeatureFlags();
  const params = useOAuthParams({ sport });
  const [consent] = useLocalStorage<false | true>(
    STORAGE.trackingConsent,
    false
  );
  const { isMobileApp } = useIsMobileApp();
  const [signInWithOAuth] = useMutation(SIGN_IN_WITH_OAUTH_MUTATION);
  const { promptTerms } = useConnectionContext();
  const { showNotification } = useSnackNotificationContext();
  const track = useEvents();

  const disabled = !useGoogleOneTap || !!currentUser || !consent || isMobileApp;

  useEffect(() => {
    if (disabled || document.querySelector(`script[src="${SRC}"]`)) {
      return;
    }
    const script = document.createElement('script');
    script.src = SRC;
    document.body.appendChild(script);
  }, [disabled]);

  useEffect(() => {
    if (disabled) return () => {};

    window.handleOneTapCredential = async response => {
      track('Click Google One Tap', {
        sport,
      });
      const result = await signInWithOAuth({
        variables: {
          input: {
            idToken: response.credential,
            provider: OAuthProvider.GOOGLE_OAUTH2,
            referrer: params.referrer,
            impactClickId: params.impact_click_id,
            googleClickId: params.google_click_id,
            facebookClickId: params.facebook_click_id,
            twitterClickId: params.twitter_click_id,
            tikTokClickId: params.tik_tok_click_id,
            redditClickId: params.reddit_click_id,
            signupPlatform: params.signup_platform,
            signupSport: params.signup_sport,
          },
          sport,
        },
      });
      const tcuToken = result.data?.signInWithOauth?.tcuToken;
      if (tcuToken) {
        promptTerms({ closable: false, tcuToken });
        return;
      }

      const user = result.data?.signInWithOauth?.currentUser;
      if (user) {
        updateQuery(user);
        /** Redirection happens on RedirectAfterSignin */
      } else if (result.data?.signInWithOauth?.errors) {
        showNotification('errors', {
          errors: formatGqlErrors(result.data.signInWithOauth.errors),
        });
      }
    };

    return () => delete window.handleOneTapCredential;
  }, [
    disabled,
    params.facebook_click_id,
    params.google_click_id,
    params.impact_click_id,
    params.referrer,
    params.signup_platform,
    params.tik_tok_click_id,
    params.twitter_click_id,
    params.reddit_click_id,
    params.signup_sport,
    promptTerms,
    showNotification,
    signInWithOAuth,
    updateQuery,
    sport,
    track,
  ]);

  if (disabled) return null;

  return (
    <div
      id="g_id_onload"
      data-client_id={GOOGLE_OAUTH_CLIENT_ID}
      data-ux_mode="popup"
      data-callback="handleOneTapCredential"
    />
  );
};
