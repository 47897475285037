import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { ButtonBase } from 'atoms/buttons/ButtonBase';

export const StyledSpan = styled.span`
  font-weight: var(--t-bold);
`;
export const StyledLink = styled(Link)`
  text-decoration: underline;
  &,
  &:hover,
  &:focus {
    color: var(--c-white);
  }
`;
export const TermsLink = styled(ButtonBase)`
  color: var(--c-white);
  text-decoration: underline;
`;
