import { useCallback } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import { ExtendedUIState } from 'components/search/InstantSearch/types';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSearchCardsContext } from 'contexts/searchCards';
import { VIRTUAL_TOGGLE_FILTERS, VirtualToggleName } from 'lib/filters';

import { useVirtualToggle } from './useVirtualToggle';

type VirtualToggleActions = {
  toggle: (value?: any) => void;
  setState?: (value: any) => void;
  condition?: boolean;
};

export const useVirtualToggleManager = () => {
  const {
    setGameweekFilter,
    setDailyFilter,
    setProbablePitchersFilter,
    setFavoriteFilter,

    setCompetitionEligibilityFilter,
    setPromotion,
    setCustomDecksFilter,
    setNotInLineUpFilter,
    setPromotionalEvent,
    setStatsMode,
  } = useSearchCardsContext() ?? {};

  const { currentUser } = useCurrentUserContext();
  const { setIndexUiState } = useInstantSearch<ExtendedUIState>();

  const { toggle: toggleGameweekFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.gameweekFilter.name,
  });
  const { toggle: toggleDailyFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.dailyFilter.name,
  });
  const { toggle: toggleProbablePitchersFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.probablePitchersFilter.name,
  });
  const { toggle: toggleFavoriteFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.favoriteFilter.name,
  });
  const { toggle: toggleNotInLineupFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.notInLineupFilter.name,
  });
  const { toggle: togglePromotedCardsFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.promotedCardsFilter.name,
  });
  const { toggle: toggleCompetitionEligibilityFilter } =
    useVirtualToggle<boolean>({
      name: VIRTUAL_TOGGLE_FILTERS.competitionEligibilityFilter.name,
    });
  const { toggle: toggleCustomDecksFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.customDecksFilter.name,
  });
  const { toggle: togglePromotionalEventFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.promotionalEventFilter.name,
  });
  const { toggle: toggleStatsModeFilter } = useVirtualToggle<boolean>({
    name: VIRTUAL_TOGGLE_FILTERS.statsModeFilter.name,
  });

  return useCallback(
    (
      virtualToggle: {
        [name: string]: string | boolean | string[] | undefined;
      },
      shouldSetUiState = true
    ) => {
      if (!virtualToggle) return;

      const actionsPerVirtualToggle: Record<
        VirtualToggleName,
        VirtualToggleActions
      > = {
        [VIRTUAL_TOGGLE_FILTERS.gameweekFilter.name]: {
          toggle: toggleGameweekFilter,
          setState: setGameweekFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.dailyFilter.name]: {
          toggle: toggleDailyFilter,
          setState: setDailyFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.probablePitchersFilter.name]: {
          toggle: toggleProbablePitchersFilter,
          setState: setProbablePitchersFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.favoriteFilter.name]: {
          toggle: toggleFavoriteFilter,
          setState: setFavoriteFilter,
          condition: !!currentUser,
        },
        [VIRTUAL_TOGGLE_FILTERS.notInLineupFilter.name]: {
          toggle: toggleNotInLineupFilter,
          setState: setNotInLineUpFilter,
          condition: !!currentUser,
        },
        [VIRTUAL_TOGGLE_FILTERS.promotedCardsFilter.name]: {
          toggle: togglePromotedCardsFilter,
          setState: setPromotion,
        },
        [VIRTUAL_TOGGLE_FILTERS.competitionEligibilityFilter.name]: {
          toggle: toggleCompetitionEligibilityFilter,
          setState: setCompetitionEligibilityFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.customDecksFilter.name]: {
          toggle: toggleCustomDecksFilter,
          setState: setCustomDecksFilter,
        },
        [VIRTUAL_TOGGLE_FILTERS.promotionalEventFilter.name]: {
          toggle: togglePromotionalEventFilter,
          setState: setPromotionalEvent,
        },
        [VIRTUAL_TOGGLE_FILTERS.statsModeFilter.name]: {
          toggle: toggleStatsModeFilter,
          setState: setStatsMode,
        },
      };

      Object.entries(virtualToggle).forEach(([name, value]) => {
        if (name in actionsPerVirtualToggle) {
          const { toggle, setState, condition } =
            actionsPerVirtualToggle[name as VirtualToggleName];

          if (condition ?? true) {
            toggle(value);
            setState?.(value);
          }
        }
      });
      if (shouldSetUiState) {
        setIndexUiState(oldState => ({
          ...oldState,
          virtualToggle: { ...oldState.virtualToggle, ...virtualToggle },
        }));
      }
    },
    [
      toggleGameweekFilter,
      setGameweekFilter,
      toggleDailyFilter,
      setDailyFilter,
      toggleProbablePitchersFilter,
      setProbablePitchersFilter,
      toggleFavoriteFilter,
      setFavoriteFilter,
      currentUser,
      toggleNotInLineupFilter,
      setNotInLineUpFilter,
      togglePromotedCardsFilter,
      setPromotion,
      toggleCompetitionEligibilityFilter,
      setCompetitionEligibilityFilter,
      toggleCustomDecksFilter,
      setCustomDecksFilter,
      togglePromotionalEventFilter,
      setPromotionalEvent,
      setIndexUiState,
      toggleStatsModeFilter,
      setStatsMode,
    ]
  );
};
