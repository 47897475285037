import { AlgoliaSearchHelper } from 'algoliasearch-helper';
import qs from 'qs';

import { AlgoliaIndex, AlgoliaIndexes } from 'contexts/config';
import { AvailableLanguage } from 'i18n/useTranslations';
import {
  APPEARANCES_15_MAX,
  APPEARANCES_5_MAX,
  APPEARANCES_MIN,
  CARD_LEVEL_MIN,
} from 'lib/cards';
import {
  FILTERS,
  FLOOR_PRICES_FILTERS,
  MENU,
  TOGGLE_FILTERS,
} from 'lib/filters';

import {
  ExtendedIndexUIState,
  ExtendedUIState,
  RouteState,
  SEARCH_PARAMS,
} from './types';

export const buildFilterQuery = (filters: { [key in SEARCH_PARAMS]?: any }) =>
  qs.stringify(filters, { arrayFormat: 'comma' });

const decodeRefinements = (refinements: any): any => {
  if (!refinements) {
    return undefined;
  }
  if (Array.isArray(refinements)) {
    return refinements.length === 0 ? undefined : refinements;
  }
  if (typeof refinements === 'string') {
    return refinements.split(',');
  }
  // we don't know this kind of refinements
  return undefined;
};

const decodeMenuRefinements = (refinements: any): any => {
  if (!refinements) {
    return undefined;
  }
  if (Array.isArray(refinements)) {
    return refinements.length === 0 ? undefined : refinements;
  }
  if (typeof refinements === 'string') {
    return refinements;
  }
  // we don't know this kind of refinements
  return undefined;
};

const compactRefinements = (refinements: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(refinements).filter(e => {
      const v = e[1];
      if (v === undefined) {
        return false;
      }
      if (typeof v === 'object') {
        return Object.keys(v).length > 0;
      }
      return true;
    })
  );

const stringifyParams = (
  params: Record<
    SEARCH_PARAMS,
    string | string[] | boolean | number | undefined
  >
): RouteState => {
  return Object.entries(params).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value ? String(value) : undefined,
    }),
    {}
  ) as RouteState;
};

export const indexStateToRoute = (
  indexState: ExtendedIndexUIState,
  algoliaIndexes: AlgoliaIndexes,
  language: AvailableLanguage,
  cardMaxGrade: number
): RouteState => {
  const {
    query,
    sortBy,
    page,
    exclusions: { co } = {},
    refinementList: {
      [FILTERS.team.i18nAttribute(language)]: team,
      [FILTERS.rarity.attribute]: rarity,
      [FILTERS.position.attribute]: position,
      [FILTERS.saleType.attribute]: saleType,
      [FILTERS.player.attribute]: playerName,
      [FILTERS.activeNationalTeam.i18nAttribute(language)]: nationalTeam,
      [FILTERS.season.attribute]: season,
      [FILTERS.activeLeague.i18nAttribute(language)]: activeLeague,
      [FILTERS.activeClub.i18nAttribute(language)]: club,
      [FILTERS.activeTeam.attribute]: activeTeam,
      [FILTERS.cardEdition.attribute]: edition,
      [FILTERS.bundledSale.attribute]: bundled,
      [FILTERS.customDeck.attribute]: decks,
      [FILTERS.nationality.i18nAttribute(language)]: nationality,
      [FILTERS.settlementWallets.attribute]: purchaseOptions,
      [FILTERS.mlbEligibleLeaderboards.attribute]: eligibleLeaderboards,
      [FILTERS.playingStatus.attribute]: playing_status,
    } = {},
    range: {
      grade,
      [FILTERS.age.attribute]: age,
      [FILTERS.mlbLastFifteenAverageScore.attribute]: mlb15Avg,
      [FILTERS.mlbSeasonAverageScore.attribute]: mlbSAvg,
      [FILTERS.lastFiveAverageScore.attribute]: so55Avg,
      [FILTERS.lastFifteenAverageScore.attribute]: so515Avg,
      [FILTERS.lastFiveAppearances.attribute]: so5L5App,
      [FILTERS.lastFifteenAppearances.attribute]: so5L15App,
      [FILTERS.price.attribute]: pr,
      [FILTERS.serialNumber.attribute]: sn,
      [FILTERS.nbaTenGameAverageScore.attribute]: nba10Avg,

      [FLOOR_PRICES_FILTERS.allSeasonsLimited.attribute]:
        floorPricesAllSeasonsLimited,
      [FLOOR_PRICES_FILTERS.allSeasonsRare.attribute]:
        floorPricesAllSeasonsRare,
      [FLOOR_PRICES_FILTERS.allSeasonsSuperRare.attribute]:
        floorPricesAllSeasonsSuperRare,
      [FLOOR_PRICES_FILTERS.allSeasonsUnique.attribute]:
        floorPricesAllSeasonsUnique,
      [FLOOR_PRICES_FILTERS.inSeasonLimited.attribute]:
        floorPricesInSeasonLimited,
      [FLOOR_PRICES_FILTERS.inSeasonRare.attribute]: floorPricesInSeasonRare,
      [FLOOR_PRICES_FILTERS.inSeasonSuperRare.attribute]:
        floorPricesInSeasonSuperRare,
      [FLOOR_PRICES_FILTERS.inSeasonUnique.attribute]:
        floorPricesInSeasonUnique,
    } = {},
    toggle: {
      [TOGGLE_FILTERS.onSaleFilter.attribute]: onSale,
      [TOGGLE_FILTERS.inSeasonFilter.attribute]: inSeason,
      [TOGGLE_FILTERS.jerseySerialFilter.attribute]: jerseySrial,
      [TOGGLE_FILTERS.creditEligibleFilter.attribute]: creditEligible,
    } = {},
    virtualToggle: {
      legend,
      ff,
      lf: competitionEligibility,
      gw,
      dly,
      pp,
      promo,
      deck: custom_decks,
      nl,
      promotion,
      sm,
    } = {},
    menu: { [MENU.position.attribute]: menuPosition } = {},
  } = indexState;

  // get the sort name rather than the index name
  const sort =
    Object.entries(algoliaIndexes).find(v => v[1] === sortBy)?.[0] || sortBy;

  return stringifyParams({
    [SEARCH_PARAMS.QUERY]: query,
    [SEARCH_PARAMS.SORT]: sort,
    [SEARCH_PARAMS.PAGE]: page,
    [SEARCH_PARAMS.PROMOTION]: promo,
    [SEARCH_PARAMS.TEAM]: team,
    [SEARCH_PARAMS.RARITY]: rarity,
    [SEARCH_PARAMS.POSITION]: position,
    [SEARCH_PARAMS.MENU_POSITION]: menuPosition,
    [SEARCH_PARAMS.PLAYER_NAME]: playerName,
    [SEARCH_PARAMS.NATIONAL_TEAM]: nationalTeam,
    [SEARCH_PARAMS.NATIONALITY]: nationality,
    [SEARCH_PARAMS.SEASON]: season,
    [SEARCH_PARAMS.ACTIVE_LEAGUE]: activeLeague,
    [SEARCH_PARAMS.CLUB]: club,
    [SEARCH_PARAMS.ACTIVE_TEAM]: activeTeam,
    [SEARCH_PARAMS.EDITION]: edition,
    [SEARCH_PARAMS.BUNDLED]: bundled,
    [SEARCH_PARAMS.DECK]: decks || custom_decks,
    // Work-around range bug: forcing the min/max of the connectRange triggers a refinement
    [SEARCH_PARAMS.GRADE]:
      grade === `${CARD_LEVEL_MIN}:${cardMaxGrade}` ? undefined : grade,
    [SEARCH_PARAMS.AGE]: age,
    [SEARCH_PARAMS.ON_SALE]: onSale,
    [SEARCH_PARAMS.SALE_TYPE]: saleType,
    [SEARCH_PARAMS.IN_SEASON]: inSeason,
    [SEARCH_PARAMS.FAVORITE_FILTER]: ff,
    [SEARCH_PARAMS.NON_PLAYABLE_CARDS]: co,
    [SEARCH_PARAMS.LEGEND]: legend,
    [SEARCH_PARAMS.COMPETITION_ELIGIBILITY]: competitionEligibility,
    [SEARCH_PARAMS.GAMEWEEK_FILTER]: gw,
    [SEARCH_PARAMS.DAILY_FILTER]: dly,
    [SEARCH_PARAMS.PROBABLE_PITCHERS_FILTERS]: pp,
    [SEARCH_PARAMS.PRICE]: pr,
    [SEARCH_PARAMS.SERIAL_NUMBER]: sn,
    [SEARCH_PARAMS.MLB_15_AVERAGE]: mlb15Avg,
    [SEARCH_PARAMS.MLB_SEASON_AVG]: mlbSAvg,
    [SEARCH_PARAMS.SO5_15_AVERAGE]: so515Avg,
    [SEARCH_PARAMS.SO5_5_AVERAGE]: so55Avg,
    [SEARCH_PARAMS.NBA_10_AVERAGE]: nba10Avg,
    // Work-around range bug: forcing the min/max of the connectRange triggers a refinement
    [SEARCH_PARAMS.SO5_5_APPEARANCES]:
      so5L5App === `${APPEARANCES_MIN}:${APPEARANCES_5_MAX}`
        ? undefined
        : so5L5App,
    [SEARCH_PARAMS.SO5_15_APPEARANCES]:
      so5L15App === `${APPEARANCES_MIN}:${APPEARANCES_15_MAX}`
        ? undefined
        : so5L15App,
    [SEARCH_PARAMS.NOT_IN_LINEUP]: nl,
    [SEARCH_PARAMS.JERSEY_SERIAL]: jerseySrial,
    [SEARCH_PARAMS.PURCHASE_OPTIONS]: purchaseOptions,
    [SEARCH_PARAMS.ELIGIBLE_LEADERBOARDS]: eligibleLeaderboards,
    [SEARCH_PARAMS.PROMOTIONAL_EVENT]: promotion,
    [SEARCH_PARAMS.PLAYING_STATUS]: playing_status,
    [SEARCH_PARAMS.CREDIT_ELIGIBLE]: creditEligible,
    [SEARCH_PARAMS.FLOOR_PRICES_ALL_SEASONS_LIMITED]:
      floorPricesAllSeasonsLimited,
    [SEARCH_PARAMS.FLOOR_PRICES_ALL_SEASONS_RARE]: floorPricesAllSeasonsRare,
    [SEARCH_PARAMS.FLOOR_PRICES_ALL_SEASONS_SUPER_RARE]:
      floorPricesAllSeasonsSuperRare,
    [SEARCH_PARAMS.FLOOR_PRICES_ALL_SEASONS_UNIQUE]:
      floorPricesAllSeasonsUnique,
    [SEARCH_PARAMS.FLOOR_PRICES_IN_SEASON_LIMITED]: floorPricesInSeasonLimited,
    [SEARCH_PARAMS.FLOOR_PRICES_IN_SEASON_RARE]: floorPricesInSeasonRare,
    [SEARCH_PARAMS.FLOOR_PRICES_IN_SEASON_SUPER_RARE]:
      floorPricesInSeasonSuperRare,
    [SEARCH_PARAMS.FLOOR_PRICES_IN_SEASON_UNIQUE]: floorPricesInSeasonUnique,
    [SEARCH_PARAMS.STATS_MODE]: sm,
  });
};

export const stateToRoute = (
  uiState: ExtendedUIState,
  mainIndex: string,
  algoliaIndexes: AlgoliaIndexes,
  language: AvailableLanguage,
  cardMaxGrade: number
): RouteState =>
  indexStateToRoute(
    uiState[mainIndex] || {},
    algoliaIndexes,
    language,
    cardMaxGrade
  );

export const routeToState = (
  routeState: RouteState,
  mainIndex: string,
  algoliaIndexes: AlgoliaIndexes,
  language: AvailableLanguage
): ExtendedUIState => {
  // backward compatibility with former routes built by react-instantsearch
  if (routeState.refinementList || routeState.range) {
    const {
      refinementList,
      range: legacyRange = {},
      page,
      sortBy,
    } = routeState;

    const range = Object.entries(legacyRange).reduce(
      (
        res: Record<string, string>,
        entry: [string, { min?: string; max?: string }]
      ) => {
        const [attribute, { min, max }] = entry;

        if (min || max) {
          res[attribute] = `${min}:${max}`;
        }
        return res;
      },
      {}
    );

    return {
      [mainIndex]: {
        sortBy,
        page,
        range,
        refinementList,
      },
    };
  }

  const {
    [SEARCH_PARAMS.QUERY]: query,
    [SEARCH_PARAMS.SORT]: sort,
    [SEARCH_PARAMS.PAGE]: page,
    [SEARCH_PARAMS.PROMOTION]: promo,
    [SEARCH_PARAMS.TEAM]: team,
    [SEARCH_PARAMS.RARITY]: rarity,
    [SEARCH_PARAMS.POSITION]: position,
    [SEARCH_PARAMS.MENU_POSITION]: menuPosition,
    [SEARCH_PARAMS.PLAYER_NAME]: playerName,
    [SEARCH_PARAMS.NATIONAL_TEAM]: nationalTeam,
    [SEARCH_PARAMS.NATIONALITY]: nationality,
    [SEARCH_PARAMS.SEASON]: season,
    [SEARCH_PARAMS.ACTIVE_LEAGUE]: activeLeague,
    [SEARCH_PARAMS.CLUB]: club,
    [SEARCH_PARAMS.ACTIVE_TEAM]: activeTeam,
    [SEARCH_PARAMS.EDITION]: edition,
    [SEARCH_PARAMS.BUNDLED]: bundled,
    [SEARCH_PARAMS.DECK]: deck,
    [SEARCH_PARAMS.GRADE]: grade,
    [SEARCH_PARAMS.AGE]: age,
    [SEARCH_PARAMS.ON_SALE]: onSale,
    [SEARCH_PARAMS.SALE_TYPE]: saleType,
    [SEARCH_PARAMS.IN_SEASON]: inSeason,
    [SEARCH_PARAMS.FAVORITE_FILTER]: favoriteFilter,
    [SEARCH_PARAMS.NON_PLAYABLE_CARDS]: nonPlayableCards,
    [SEARCH_PARAMS.LEGEND]: legend,
    [SEARCH_PARAMS.COMPETITION_ELIGIBILITY]: competitionEligibilityFilter,
    [SEARCH_PARAMS.GAMEWEEK_FILTER]: gameweekFilter,
    [SEARCH_PARAMS.DAILY_FILTER]: dailyFilter,
    [SEARCH_PARAMS.PROBABLE_PITCHERS_FILTERS]: probablePitchersFilter,
    [SEARCH_PARAMS.PRICE]: price,
    [SEARCH_PARAMS.SERIAL_NUMBER]: serialNumber,
    [SEARCH_PARAMS.MLB_15_AVERAGE]: mlb15Avg,
    [SEARCH_PARAMS.MLB_SEASON_AVG]: mlbSAvg,
    [SEARCH_PARAMS.SO5_15_AVERAGE]: so515Avg,
    [SEARCH_PARAMS.SO5_5_AVERAGE]: so55Avg,
    [SEARCH_PARAMS.SO5_15_APPEARANCES]: so5L15App,
    [SEARCH_PARAMS.SO5_5_APPEARANCES]: so5L5App,
    [SEARCH_PARAMS.NBA_10_AVERAGE]: nba10Avg,
    [SEARCH_PARAMS.NOT_IN_LINEUP]: notInLineupFilter,
    [SEARCH_PARAMS.JERSEY_SERIAL]: jerseySerial,
    [SEARCH_PARAMS.PURCHASE_OPTIONS]: purchaseOptions,
    [SEARCH_PARAMS.ELIGIBLE_LEADERBOARDS]: eligibleLeaderboards,
    [SEARCH_PARAMS.PROMOTIONAL_EVENT]: promotion,
    [SEARCH_PARAMS.PLAYING_STATUS]: playingStatus,
    [SEARCH_PARAMS.CREDIT_ELIGIBLE]: creditEligible,
    [SEARCH_PARAMS.FLOOR_PRICES_ALL_SEASONS_LIMITED]:
      floorPricesAllSeasonsLimited,
    [SEARCH_PARAMS.FLOOR_PRICES_ALL_SEASONS_RARE]: floorPricesAllSeasonsRare,
    [SEARCH_PARAMS.FLOOR_PRICES_ALL_SEASONS_SUPER_RARE]:
      floorPricesAllSeasonsSuperRare,
    [SEARCH_PARAMS.FLOOR_PRICES_ALL_SEASONS_UNIQUE]:
      floorPricesAllSeasonsUnique,
    [SEARCH_PARAMS.FLOOR_PRICES_IN_SEASON_LIMITED]: floorPricesInSeasonLimited,
    [SEARCH_PARAMS.FLOOR_PRICES_IN_SEASON_RARE]: floorPricesInSeasonRare,
    [SEARCH_PARAMS.FLOOR_PRICES_IN_SEASON_SUPER_RARE]:
      floorPricesInSeasonSuperRare,
    [SEARCH_PARAMS.FLOOR_PRICES_IN_SEASON_UNIQUE]: floorPricesInSeasonUnique,
    [SEARCH_PARAMS.STATS_MODE]: sm,
  } = routeState;

  const sortBy =
    (sort in algoliaIndexes && algoliaIndexes[sort as AlgoliaIndex]) || sort;

  return {
    [mainIndex]: compactRefinements({
      exclusions: { co: nonPlayableCards === 'true' },
      query,
      sortBy,
      page,
      refinementList: compactRefinements({
        [FILTERS.team.i18nAttribute(language)]: decodeRefinements(team),
        [FILTERS.rarity.attribute]: decodeRefinements(rarity),
        [FILTERS.position.attribute]: decodeRefinements(position),
        [FILTERS.saleType.attribute]: decodeRefinements(saleType),
        [FILTERS.player.attribute]: decodeRefinements(playerName),
        [FILTERS.activeNationalTeam.i18nAttribute(language)]:
          decodeRefinements(nationalTeam),
        [FILTERS.season.attribute]: decodeRefinements(season),
        [FILTERS.activeLeague.i18nAttribute(language)]:
          decodeRefinements(activeLeague),
        [FILTERS.activeClub.i18nAttribute(language)]: decodeRefinements(club),
        [FILTERS.activeTeam.attribute]: decodeRefinements(activeTeam),
        [FILTERS.cardEdition.attribute]: decodeRefinements(edition),
        [FILTERS.bundledSale.attribute]: decodeRefinements(bundled),
        [FILTERS.customDeck.attribute]: decodeRefinements(deck),
        [FILTERS.nationality.i18nAttribute(language)]:
          decodeRefinements(nationality),
        [FILTERS.settlementWallets.attribute]:
          decodeRefinements(purchaseOptions),
        [FILTERS.mlbEligibleLeaderboards.attribute]:
          decodeRefinements(eligibleLeaderboards),
        [FILTERS.playingStatus.attribute]: decodeRefinements(playingStatus),
      }),
      range: compactRefinements({
        grade,
        [FILTERS.age.attribute]: age,
        [FILTERS.mlbLastFifteenAverageScore.attribute]: mlb15Avg,
        [FILTERS.mlbSeasonAverageScore.attribute]: mlbSAvg,
        [FILTERS.lastFiveAverageScore.attribute]: so55Avg,
        [FILTERS.lastFifteenAverageScore.attribute]: so515Avg,
        [FILTERS.lastFiveAppearances.attribute]: so5L5App,
        [FILTERS.lastFifteenAppearances.attribute]: so5L15App,
        [FILTERS.price.attribute]: price,
        [FILTERS.serialNumber.attribute]: serialNumber,
        [FILTERS.nbaTenGameAverageScore.attribute]: nba10Avg,

        [FLOOR_PRICES_FILTERS.allSeasonsLimited.attribute]:
          floorPricesAllSeasonsLimited,
        [FLOOR_PRICES_FILTERS.allSeasonsRare.attribute]:
          floorPricesAllSeasonsRare,
        [FLOOR_PRICES_FILTERS.allSeasonsSuperRare.attribute]:
          floorPricesAllSeasonsSuperRare,
        [FLOOR_PRICES_FILTERS.allSeasonsUnique.attribute]:
          floorPricesAllSeasonsUnique,
        [FLOOR_PRICES_FILTERS.inSeasonLimited.attribute]:
          floorPricesInSeasonLimited,
        [FLOOR_PRICES_FILTERS.inSeasonRare.attribute]: floorPricesInSeasonRare,
        [FLOOR_PRICES_FILTERS.inSeasonSuperRare.attribute]:
          floorPricesInSeasonSuperRare,
        [FLOOR_PRICES_FILTERS.inSeasonUnique.attribute]:
          floorPricesInSeasonUnique,
      }),
      toggle: compactRefinements({
        [TOGGLE_FILTERS.onSaleFilter.attribute]: onSale === 'true',
        [TOGGLE_FILTERS.inSeasonFilter.attribute]: inSeason === 'true',
        [TOGGLE_FILTERS.jerseySerialFilter.attribute]: jerseySerial === 'true',
        [TOGGLE_FILTERS.creditEligibleFilter.attribute]:
          creditEligible === 'true',
      }),
      virtualToggle: compactRefinements({
        ff: favoriteFilter === 'true',
        nl: notInLineupFilter === 'true',
        legend: legend === 'true',
        lf: competitionEligibilityFilter || false,
        gw: gameweekFilter || false,
        dly: dailyFilter || false,
        pp: probablePitchersFilter || false,
        promo: promo || false,
        deck: deck || false,
        promotion,
        sm,
      }),
      menu: compactRefinements({
        [MENU.position.attribute]: decodeMenuRefinements(menuPosition),
      }),
    }),
  };
};

export const cleanInitialIndexUiState = (
  initialIndexUIState: ExtendedIndexUIState | undefined,
  queryParams: Record<SEARCH_PARAMS, any>
): ExtendedIndexUIState => {
  const queryParamsKeys = Object.keys(queryParams);
  const deleteQueryParamsKeys = (obj?: Record<string, any>) =>
    obj
      ? Object.fromEntries(
          Object.entries(obj).filter(([filterKey]) => {
            return !queryParamsKeys.includes(filterKey);
          })
        )
      : {};

  return {
    ...(initialIndexUIState?.query
      ? { query: initialIndexUIState?.query }
      : {}),
    ...(!queryParamsKeys.includes(SEARCH_PARAMS.SORT) &&
    initialIndexUIState?.sortBy
      ? { sortBy: initialIndexUIState?.sortBy }
      : {}),
    refinementList: deleteQueryParamsKeys(initialIndexUIState?.refinementList),
    range: deleteQueryParamsKeys(initialIndexUIState?.range),
    toggle: {
      ...(!queryParamsKeys.includes(SEARCH_PARAMS.IN_SEASON)
        ? {
            in_season_eligible:
              !!initialIndexUIState?.toggle?.in_season_eligible,
          }
        : {}),
      ...(!queryParamsKeys.includes(SEARCH_PARAMS.ON_SALE)
        ? { on_sale: !!initialIndexUIState?.toggle?.on_sale }
        : {}),
    },
    virtualToggle: deleteQueryParamsKeys(initialIndexUIState?.virtualToggle),
    menu: deleteQueryParamsKeys(initialIndexUIState?.menu),
  };
};

// Work-around range bug: forcing the min/max of the connectRange triggers a refinement
export const cleanFixedRangeRefinements = (
  helper: AlgoliaSearchHelper,
  cardLevelMax: number
) => {
  [
    {
      attribute: FILTERS.cardLevel.attribute,
      min: CARD_LEVEL_MIN,
      max: cardLevelMax,
    },
    {
      attribute: FILTERS.lastFiveAppearances.attribute,
      min: APPEARANCES_MIN,
      max: APPEARANCES_5_MAX,
    },
    {
      attribute: FILTERS.lastFifteenAppearances.attribute,
      min: APPEARANCES_MIN,
      max: APPEARANCES_15_MAX,
    },
  ].forEach(c => {
    const refinements = helper.getRefinements(c.attribute);

    if (
      refinements.length === 2 &&
      refinements[0].operator === '>=' &&
      refinements[0].value?.[0] === c.min &&
      refinements[1].operator === '<=' &&
      refinements[1].value?.[0] === c.max
    ) {
      const { numericRefinements } = helper.state;
      delete numericRefinements?.[c.attribute];
      helper.setState({
        ...helper.state,
        numericRefinements,
      });
    }
  });
};
