import styled, { css } from 'styled-components';

import { Link } from '@sorare/routing';

import { LinkBox, LinkOverlay } from '@sorare/core/src/atoms/navigation/Box';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

const Wrapper = styled(LinkBox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--quadruple-unit) var(--unit);
  @media ${tabletAndAbove} {
    padding: var(--quadruple-unit);
  }
  border-radius: var(--unit);
  border: 1px solid var(--c-nd-100);
  gap: var(--unit);
  max-height: 200px;
`;

const nameStyle = css`
  flex: 1;
  font: var(--t-body-m);
  @media ${tabletAndAbove} {
    font: var(--t-body-l);
  }
  color: var(--c-white);
  text-align: center;
`;

const TextName = styled.div`
  ${nameStyle}
`;

const LinkName = styled(LinkOverlay)`
  ${nameStyle}
  &,
  &:hover,
  &:focus {
    color: var(--c-white);
  }
`;

const Logo = styled.div`
  & > img {
    min-height: 50px;
    height: 50px;
  }
  @media ${tabletAndAbove} {
    & > img {
      min-height: 80px;
      height: 80px;
    }
  }
`;

type Props = {
  logoUrl: string;
  to?: string;
  name: string;
};

export const Item = ({ logoUrl, to, name }: Props) => {
  return (
    <Wrapper>
      <Logo>
        <img src={logoUrl || ''} alt="" height={50} />
      </Logo>
      {to ? (
        <LinkName as={Link} to={to}>
          {name}
        </LinkName>
      ) : (
        <TextName>{name}</TextName>
      )}
    </Wrapper>
  );
};
