/* eslint-disable sorare/use-horizontal-vertical-layout */
import styled from 'styled-components';

import { unitMapping } from 'lib/style';

type Props = {
  gap?: keyof typeof unitMapping;
  center?: boolean;
  grow?: boolean; // used when using inside another flex element.
};
export const Horizontal = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  gap: ${({ gap = 1 }) => unitMapping[gap]};
  flex: ${({ grow }) => (grow ? '1' : 'initial')};
`;

export const SBHorizontal = styled.div<Exclude<Props, 'center'>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ gap = 1 }) => unitMapping[gap]};
  flex: ${({ grow }) => (grow ? '1' : 'initial')};
`;

export const Vertical = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? 'center' : 'stretch')};
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  gap: ${({ gap = 1 }) => unitMapping[gap]};
  flex: ${({ grow }) => (grow ? '1' : 'initial')};
`;
