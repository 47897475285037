import { TypePolicies } from '@apollo/client';
import { mergeDeep } from '@apollo/client/utilities';

import { defaultTypePolicies } from '__generated__/defaultTypePolicies';
import { scalarTypePolicies } from '__generated__/typePolicies';
import { cardTypes } from 'lib/cardTypes';
import { mergeArrayOfUnnormalizedObjects, replaceByIncoming } from 'lib/gql';

import { mySo5LineupsPaginated } from './mySo5LineupsPaginated';

/* eslint sort-keys: "error" */
export const typePolicies: TypePolicies = mergeDeep(
  defaultTypePolicies,
  {
    Age: {
      merge: true,
    },
    AppearanceRule: {
      keyFields: ['name'],
    },
    BaseballSubmitCommonDraftResponse: {
      keyFields: false,
    },
    BoostLevel: {
      keyFields: false,
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
    },
    Card: {
      fields: {
        availableCardBoosts: {
          merge: false,
        },
      },
      keyFields: ['slug'],
    },
    CardBoost: {
      keyFields: ['shopItem', ['id']],
    },
    CardCount: {
      merge: true,
    },
    CurrentUser: {
      fields: {
        baseballCurrentUserData: { merge: true },
        baseballUnclaimedBoxRewards: { merge: false },
        baseballUnclaimedCashRewards: { merge: false },
        baseballUnclaimedLineupRewards: { merge: false },
        cardShardsChests: {
          keyArgs: ['sport'],
          merge: replaceByIncoming,
        },
        connectedOauths: {
          merge: replaceByIncoming,
        },
        footballCards: {
          keyArgs: args =>
            Object.keys(args || {}).filter(
              arg => arg !== 'ownedSinceAfter' && arg !== 'first'
            ),
          merge: true,
        },
        managerProgressionTasksCount: {
          keyArgs: ['sport', 'type', 'state'],
        },
        nbaUnclaimedCardRewards: { merge: false },
        nbaUnclaimedCashRewards: { merge: false },
        nbaUnclaimedConversionCreditRewards: { merge: false },
        nbaUnclaimedLineupRewards: { merge: false },
        refereeRewards: {
          merge: replaceByIncoming,
        },
        referrals: {
          merge: replaceByIncoming,
        },
        unclaimedActionRewards: {
          merge: replaceByIncoming,
        },
        unclaimedSo5Rewards: {
          keyArgs: ['sport'],
          merge: replaceByIncoming,
        },
        unopenedProbabilisticBundles: {
          keyArgs: ['sport', 'rarity', 'design'],
          merge: true,
        },
        userSettings: {
          merge: true,
        },
      },
      keyFields: ['slug'],
    },
    FootballRivalsCurrentManager: {
      fields: { unreadStories: { merge: replaceByIncoming } },
    },
    FootballRivalsDivisionLeaderboard: {
      fields: { leaderboardConfig: { merge: true } },
    },
    FootballRivalsGame: {
      fields: {
        myFriendsPlaying: { merge: replaceByIncoming },
        userGroupLineups: { merge: true },
      },
      keyFields: ['slug'],
    },
    FootballRoot: {
      keyFields: false,
      merge: true,
    },
    ForYouRoot: {
      keyFields: false,
      merge: true,
    },
    InGameCurrencyPack: {
      keyFields: ['id'], // TODO(t.des): To remove once slug is remved from API
    },
    LeaderboardRewardsConfig: {
      fields: {
        conditional: {
          merge: mergeArrayOfUnnormalizedObjects,
        },
        ranking: {
          merge: mergeArrayOfUnnormalizedObjects,
        },
      },
      keyFields: false,
    },
    League: {
      fields: {
        members: {
          keyArgs: false,
          merge: (existing: any[], incoming: any[], _a) => {
            const args = { limit: 100, offset: 0, ...(_a.args || {}) };
            const merged = [...(existing || [])];
            const { offset } = args;
            incoming.forEach((member, index) => {
              merged[offset + index] = member;
            });
            return merged;
          },
        },
      },
      keyFields: ['slug'],
    },
    LeagueLeaderboard: {
      fields: {
        lineups: {
          keyArgs: false,
          merge: (existing: any[], incoming: any[], _a) => {
            const args = { limit: 100, offset: 0, ...(_a.args || {}) };
            const merged = [...(existing || [])];
            const { offset } = args;
            incoming.forEach((member, index) => {
              merged[offset + index] = member;
            });
            return merged;
          },
        },
      },
      keyFields: ['leaderboard', ['slug'], 'league', ['slug']],
    },
    MangopayRoot: {
      fields: {
        bankAccountType: {
          keyArgs: ['countryCode'],
        },
        ownerRegionRequired: {
          keyArgs: ['countryCode'],
        },
      },
    },
    NBARivals: {
      merge: true,
    },
    Player: {
      fields: {
        cardSupply: { merge: mergeArrayOfUnnormalizedObjects },
      },
      keyFields: ['slug'],
    },
    PlayerInjury: {
      merge: true,
    },
    Query: {
      fields: {
        anyCard: {
          read(existing, { args, toReference, canRead }) {
            if (existing) return existing;
            if (!args?.slug) return undefined;

            const footballCardRef = toReference({
              __typename: cardTypes.FOOTBALL,
              slug: args.slug,
            });
            const baseballCardRef = toReference({
              __typename: cardTypes.BASEBALL,
              slug: args.slug,
            });
            const nbaCardRef = toReference({
              __typename: cardTypes.NBA,
              slug: args.slug,
            });

            return [footballCardRef, baseballCardRef, nbaCardRef].find(ref =>
              canRead(ref)
            );
          },
        },
      },
    },
    Referral: {
      fields: {
        referrerRewards: {
          merge: replaceByIncoming,
        },
      },
    },
    ReferralPaginated: {
      keyFields: false,
    },
    RewardBoost: {
      keyFields: false,
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
    },
    RewardsOverview: {
      fields: {
        experiencesDetails: {
          read(value) {
            return value || [];
          },
        },
      },
      keyFields: false,
    },
    SealingConfig: {
      keyFields: false,
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
    },
    Season: {
      keyFields: ['startYear'],
    },
    So5CaptainScarcityRule: {
      keyFields: ['key'],
    },
    So5Fixture: {
      fields: { mySo5LineupsPaginated },
      keyFields: ['slug'],
    },
    So5Leaderboard: {
      fields: {
        canCompose: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        mySo5Lineups: {
          merge: mergeArrayOfUnnormalizedObjects,
        },
        rewardsConfig: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        totalRewards: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
      keyFields: ['slug'],
    },
    So5League: {
      fields: {
        mySo5LeaderboardContenders: { merge: replaceByIncoming },
      },
    },
    So5Ranking: {
      fields: {
        eligibleRewards: {
          merge: mergeArrayOfUnnormalizedObjects,
        },
      },
    },
    So5Root: {
      keyFields: false,
      merge: true,
    },
    So5RuleFeedback: {
      keyFields: ['ruleName'],
    },
    So5RuleInterface: {
      keyFields: ['key'],
    },
    So5Rules: {
      fields: {
        rarityLimits: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
    SocialPictureDerivatives: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
    },
    SportSeason: {
      keyFields: ['name'],
    },
    TokenRoot: {
      fields: {
        promotionalEvents: {
          keyFields: false,
          merge(
            existing: any[] | undefined,
            incoming: any[],
            { mergeObjects }
          ) {
            return incoming.map(currentValue => {
              const formerValue = existing?.find(
                e => e.slug === currentValue.slug
              );
              if (formerValue) {
                return mergeObjects(formerValue, currentValue);
              }
              return currentValue;
            });
          },
        },
      },
      merge: true,
    },
  } as TypePolicies,
  scalarTypePolicies
);
