import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Vertical } from 'atoms/layout/flex';
import { BodyS, LabelM } from 'atoms/typography';
import { Bold } from 'atoms/typography/Bold';
import { Dialog } from 'components/dialog';

const messages = defineMessages({
  title: {
    id: 'NewDeviceDialog.title',
    defaultMessage: 'Authorize a New Device',
  },
  description: {
    id: 'NewDeviceDialog.description',
    defaultMessage:
      "It looks like you're signing in to Sorare from a computer or device we haven't seen before.",
  },
  link: {
    id: 'NewDeviceDialog.link',
    defaultMessage:
      'Please <b>click the confirmation link in the email or text</b> we just sent you. This process is only required once per device and protects the security of your account.',
  },
});

const Root = styled(Vertical).attrs({ gap: 3 })`
  text-align: center;
  padding: var(--triple-unit);
`;
const Icon = styled(FontAwesomeIcon)`
  font-size: 80px;
  color: var(--c-brand-600);
  display: inline;
  margin: var(--double-unit) 0;
`;

type Props = {
  onClose: () => void;
};
export const NewDeviceDialog = ({ onClose }: Props) => {
  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      title={
        <Vertical center>
          <LabelM as="h2" bold>
            <FormattedMessage {...messages.title} />
          </LabelM>
        </Vertical>
      }
      onClose={onClose}
    >
      <Root>
        <Icon icon={faEnvelopeOpenText} />
        <BodyS as="p">
          <FormattedMessage {...messages.description} />
        </BodyS>
        <BodyS as="p">
          <FormattedMessage {...messages.link} values={{ b: Bold }} />
        </BodyS>
      </Root>
    </Dialog>
  );
};
