import {
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useMutation as useApolloMutation,
} from '@apollo/client';
import { FieldNode, OperationDefinitionNode } from 'graphql';
import { useCallback, useMemo } from 'react';

import { useRestrictedAccessContext } from 'contexts/restrictedAccess';
import { useSentryContext } from 'contexts/sentry';
import { Level } from 'contexts/snackNotification';
import { notificationsQueue } from 'hooks/useNotificationsQueue';

import { MutationError } from '../../errors/mutation';
import { NetworkError } from '../../errors/network';

export interface Error {
  message: string;
  code?: number;
}

type Options<T, V> = {
  showErrorsWithSnackNotification?: boolean;
  showErrorsInForm?: boolean;
  sendErrors?: boolean;
  warnIfNoErrorHandling?: boolean;
} & MutationHookOptions<T, V>;

export const errorCodes = {
  unverifiedEmail: 20000,
  unverifiedPhoneNumber: 20010,
};

export const useMutation = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  mutation: TypedDocumentNode<TData, TVariables>,
  opts: Options<TData, TVariables> = {}
) => {
  const {
    showErrorsWithSnackNotification,
    showErrorsInForm,
    sendErrors,
    warnIfNoErrorHandling = true,
    ...mutationOptions
  } = opts;

  const [mutate, { loading, called }] = useApolloMutation(
    mutation,
    mutationOptions
  );
  const { setShowRestrictedAccess } = useRestrictedAccessContext() ?? {};
  const { sendSafeError } = useSentryContext();

  const mutationName = useMemo(
    () =>
      (
        (
          mutation.definitions.find(
            d => d.kind === 'OperationDefinition' && d.operation === 'mutation'
          ) as OperationDefinitionNode
        ).selectionSet.selections[0] as FieldNode
      ).name.value,
    [mutation.definitions]
  );

  const handleErrors = useCallback(
    (errors: readonly Error[], networkError?: boolean) => {
      if (errors.length === 0) return;
      if (errors.some(({ code }) => code === errorCodes.unverifiedEmail)) {
        setShowRestrictedAccess?.('email');
      } else if (
        errors.some(({ code }) => code === errorCodes.unverifiedPhoneNumber)
      ) {
        setShowRestrictedAccess?.('phone');
      }
      const message = errors.map(e => e.message).join(', ');
      if (showErrorsWithSnackNotification) {
        notificationsQueue.addNotification(
          'errors',
          { errors: message },
          { level: Level.ERROR }
        );
        return;
      }
      if (sendErrors) {
        sendSafeError(new Error(`Errors in graphql mutation: ${message}`));
        return;
      }
      if (showErrorsInForm) {
        return;
      }

      if (networkError) {
        throw new NetworkError(message);
      } else {
        throw new MutationError(message);
      }
    },
    [
      showErrorsWithSnackNotification,
      showErrorsInForm,
      sendErrors,
      sendSafeError,
      setShowRestrictedAccess,
    ]
  );

  return [
    useCallback(
      async (options?: MutationFunctionOptions<TData, TVariables>) => {
        const errors: { message: string; code?: number }[] = [];
        let data: FetchResult<TData>['data'];
        try {
          ({ data } = await mutate(options));
          const mutationData = (data as unknown as any)[mutationName];
          if (Array.isArray(mutationData?.errors)) {
            errors.push(...mutationData.errors);
            handleErrors(mutationData.errors);
          }
        } catch (e: any) {
          const { graphQLErrors, networkError } = e;
          // networkError is NOT an array
          if (networkError) {
            errors.push(networkError);
            handleErrors([networkError], true);
          }
          if (graphQLErrors) {
            errors.push(...graphQLErrors);
            handleErrors(graphQLErrors);
          }
          if (!graphQLErrors && !networkError) {
            if (
              warnIfNoErrorHandling &&
              import.meta.env.MODE !== 'production'
            ) {
              // this is to fail the tests intentionally
              // eslint-disable-next-line no-console
              console.warn(`${mutationName} doesn't request errors`, e);
            }
            // these are unexpected errors that aren't shown to the user
            // let's send them to sentry & fix one by one
            sendSafeError(e);
          }
        }
        return { data, errors, success: errors.length === 0 };
      },
      [handleErrors, mutate, mutationName, warnIfNoErrorHandling, sendSafeError]
    ),
    { loading, called },
  ] as const;
};
