import { Outlet, useMatch } from 'react-router-dom';
import styled from 'styled-components';

import { catchAll } from '@sorare/routing';

import { LP } from '@sorare/core/src/constants/__generated__/routes';

import { AppLayout } from '@sorare/gameplay/src/components/navigation/AppLayout';

const PreviewRibbon = styled.div`
  position: fixed;
  top: 20px;
  left: -20px;
  white-space: nowrap;
  transform: rotate(-35deg);
  z-index: 2;
  background-color: var(--c-red-600);
  color: var(--c-white);
  font-weight: bold;
  text-align: center;
  padding: 3px 40px;
`;

const Layout = () => {
  const isPreview = useMatch(catchAll(`${LP}/preview`));
  return (
    <>
      {isPreview ? <PreviewRibbon>PREVIEW</PreviewRibbon> : null}
      <AppLayout>
        <Outlet />
      </AppLayout>
    </>
  );
};

export default Layout;
