import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { CategoryPreferences, Destination } from 'types';

import { fetchDestinations } from './fetch-destinations';
import { conditionallyLoadAnalytics } from './lib';
import { loadPreferences, savePreferences } from './preferences';

interface Props {
  writeKey: string;
  cookieExpires?: number;
  children: (props: RenderProps) => ReactNode;
}

interface RenderProps {
  isConsentRequired: boolean;
  destinations: Destination[];
  newDestinations: Destination[];
  preferences: CategoryPreferences;
  setPreferences: (newPreferences: CategoryPreferences) => void;
  saveConsent: (newPreferences?: CategoryPreferences) => void;
}

const getNewDestinations = (
  destinations: Destination[],
  preferences?: CategoryPreferences
) => {
  if (!preferences) {
    return destinations;
  }
  return destinations.filter(
    destination => preferences[destination.id] === undefined
  );
};

export const ConsentManagerBuilder = ({
  writeKey,
  cookieExpires,
  children,
}: Props) => {
  const [state, setState] = useState<{
    destinations: Destination[];
    newDestinations: Destination[];
    preferences?: CategoryPreferences;
  }>({
    destinations: [],
    newDestinations: [],
  });

  useEffect(() => {
    fetchDestinations('cdn.segment.com', [writeKey]).then(destinations => {
      const { destinationPreferences } = loadPreferences();
      const preferences = destinationPreferences;
      conditionallyLoadAnalytics({
        writeKey,
        destinations,
        destinationPreferences: preferences,
        isConsentRequired: true,
      });
      setState({
        destinations,
        newDestinations: getNewDestinations(destinations, preferences),
        preferences,
      });
    });
  }, [writeKey]);

  return children({
    isConsentRequired: true,
    destinations: state.destinations,
    newDestinations: state.newDestinations,
    preferences: state.preferences || {},
    setPreferences: newPreferences => {
      setState(prev => ({
        ...prev,
        preferences: { ...(prev.preferences || {}), ...newPreferences },
      }));
    },
    saveConsent: newPreferences => {
      savePreferences({
        destinationPreferences: newPreferences,
        cookieExpires,
      });

      setState(prev => ({
        ...prev,
        newDestinations: getNewDestinations(prev.destinations, newPreferences),
      }));

      conditionallyLoadAnalytics({
        writeKey,
        destinations: state.destinations,
        destinationPreferences: newPreferences,
        isConsentRequired: true,
      });
    },
  });
};
