import qs from 'qs';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useWalletContext } from '@sorare/core/src/contexts/wallet';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import starknet from './assets/starknet.png';

const REDIRECT_URL = 'https://provisions.starknet.io';
const RECIPIENT_PARAM_NAME = 'recipient';
const CONTRACT_ID_PARAM_NAME = 'contract_id';
const REDIRECT_PARAMS = {
  source: 'StarkEx',
  app: 'sorare',
};
const messages = defineMessages({
  title: {
    id: 'claimStrk.title',
    defaultMessage: 'Starknet Provisions',
  },
  description: {
    id: 'claimStrk.description',
    defaultMessage:
      'Sorare NFTs are minted and traded on an Ethereum Layer 2 called StarkEx and powered by Starkware. As a Sorare user you might be eligible to receive STRK tokens issued by Starkware. STRK tokens are issued to decentralize Starkware public ZK Rollup named Starknet.',
  },
  eligibilityCta: {
    id: 'claimStrk.eligibilityCta',
    defaultMessage: 'Check eligibility',
  },
  claimCta: {
    id: 'claimStrk.claimCta',
    defaultMessage: 'Claim your STRK',
  },
});

interface RedirectParams {
  source: string;
  app: string;
  identity: string;
  stark_key: string;
  recipient?: string;
  contract_id?: string;
  sig?: string;
}

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  border-radius: var(--unit);
  padding: var(--triple-unit);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletAndAbove} {
    width: 500px;
    border: 1px solid var(--c-nd-200);
    margin-top: var(--triple-unit);
  }
`;

const Visual = styled.img`
  width: 100px;
  margin: var(--double-unit) 0;
`;

const Description = styled.div`
  margin: var(--double-unit) 0;
`;

export const ClaimStrk = () => {
  const { signMany } = useWalletContext();
  const [searchParams] = useSearchParams();
  const currentUser = useCurrentUserContext();
  const { nickname, starkKey } = currentUser?.currentUser || {};

  if (!nickname || !starkKey) return null;

  const recipient = searchParams.get(RECIPIENT_PARAM_NAME);
  const contract_id = searchParams.get(CONTRACT_ID_PARAM_NAME);

  const buildRedirectParams = async () => {
    let signature: { r: string; s: string } | null = null;
    if (contract_id && recipient) {
      signature = await signMany([contract_id, recipient]);
      if (!signature) return null;
    }

    const params: RedirectParams = {
      ...REDIRECT_PARAMS,
      identity: nickname,
      stark_key: starkKey,
    };

    if (recipient) params.recipient = recipient;
    if (contract_id) params.contract_id = contract_id;
    if (signature) params.sig = `${signature.r}_${signature.s}`;

    return params;
  };

  const claimOrCheckEligibility = async () => {
    const params = await buildRedirectParams();
    if (!params) {
      return null;
    }
    const url = `${REDIRECT_URL}${qs.stringify(params, {
      addQueryPrefix: true,
    })}`;

    // eslint-disable-next-line no-restricted-properties
    return window.location.replace(url);
  };

  return (
    <Container>
      <FormattedMessage {...messages.title} tagName="h1" />
      <Visual alt="Starknet" src={starknet} />
      <Description>
        <FormattedMessage {...messages.description} />
      </Description>
      <Button
        color="primary"
        size="small"
        onClick={claimOrCheckEligibility}
        fullWidth
      >
        <FormattedMessage
          {...(recipient ? messages.claimCta : messages.eligibilityCta)}
        />
      </Button>
    </Container>
  );
};
