import { TypedDocumentNode, gql } from '@apollo/client';

import { loadTypekitFonts } from 'lib/fonts';
import { withFragments } from 'lib/gql';
import { Font, getTextSize } from 'lib/text';

import { drawObtainedInfos } from '../../drawObtainedInfos';
import { baseballStatsOverlay2025_AnyCardInterface } from './__generated__/index.graphql';

export const baseballStatsOverlay2025 = withFragments(
  (card: baseballStatsOverlay2025_AnyCardInterface) => {
    if (!card.cardStats) {
      return undefined;
    }
    return async (image: ImageBitmap) => {
      const canvas = document.createElement('canvas');
      const WIDTH = image.width;
      const HEIGHT = image.height;

      // not all card backs are the same size
      const scale = WIDTH / 771;

      canvas.width = WIDTH;
      canvas.height = HEIGHT;

      const so5RewardsCount = card.cardStats?.so5RewardsCount ?? 0;
      const bestScore = Math.floor(
        card.cardStats?.bestSo5Appearance?.score ?? 0
      );

      const ctx = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      // draw texture
      ctx.drawImage(image, 0, 0);

      const promises: Promise<void>[] = [];
      // load shuttleblock fonts
      await loadTypekitFonts('ntt2mii');

      ctx.fillStyle = '#fff';
      ctx.textAlign = 'center';
      ctx.shadowColor = 'rgba(0,0,0,1)';
      ctx.shadowBlur = 32;

      promises.push(
        drawObtainedInfos({
          ctx,
          card,
          y: 246,
          font: `${44 * scale}px shuttleblock-condensed` as Font,
        })
      );

      // OWNER NAME
      if (card.tokenOwner?.user) {
        const fontUsername: Font = `italic ${33 * scale}px shuttleblock-wide`;
        ctx.font = fontUsername;
        const { width: nicknameWidth } = getTextSize(
          card.tokenOwner.user.nickname,
          fontUsername
        );
        const textScale = Math.min((771 * 0.8) / nicknameWidth, 1);
        ctx.save();
        ctx.translate(385 * scale, 277 * scale);
        ctx.scale(textScale, textScale);
        ctx.fillText(card.tokenOwner.user.nickname, 0, 0);
        ctx.restore();
      }

      // PLAYER NAME
      ctx.font = `400 ${84 * scale}px shuttleblock-condensed`;

      ctx.fillText(
        card.anyPlayer.displayName.toLocaleUpperCase(),
        385 * scale,
        376 * scale
      );

      // values
      ctx.font = `400 ${102 * scale}px shuttleblock-condensed`;

      ctx.fillText(
        (card.cardStats?.so5LineupsCount ?? 0).toString(),
        157 * scale,
        800 * scale
      );
      ctx.fillText(so5RewardsCount.toString(), 385 * scale, 800 * scale);
      ctx.fillText(bestScore.toString(), 600 * scale, 800 * scale);

      ctx.font = `400 ${32 * scale}px shuttleblock-wide`;
      ctx.fillText('TIMES', 157 * scale, 835 * scale);
      ctx.fillText('PLAYED', 157 * scale, 870 * scale);

      ctx.fillText('REWARDS', 385 * scale, 835 * scale);

      ctx.fillText('HIGHEST', 600 * scale, 835 * scale);
      ctx.fillText('SCORE', 600 * scale, 870 * scale);

      await Promise.all(promises);

      return createImageBitmap(canvas);
    };
  },
  {
    AnyCardInterface: gql`
      fragment baseballStatsOverlay2025_AnyCardInterface on AnyCardInterface {
        slug

        cardStats {
          id
          so5LineupsCount
          so5RewardsCount
          bestSo5Appearance {
            id
            score
          }
        }
        anyPlayer {
          slug
          displayName
        }
        tokenOwner {
          id
          user {
            slug
            nickname
          }
        }
        ...drawObtainedInfos_AnyCardInterface
      }
      ${drawObtainedInfos.fragments.AnyCardInterface}
    ` as TypedDocumentNode<baseballStatsOverlay2025_AnyCardInterface>,
  }
);
