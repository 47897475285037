import { unitMapping } from 'lib/style';

type Props = {
  size?: keyof typeof unitMapping;
};
export const HomeIcon = ({ size = 2 }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: unitMapping[size], height: unitMapping[size] }}
    >
      <path
        d="M1.5 9V22.5H9V16.5C9 14.8431 10.3431 13.5 12 13.5C13.6569 13.5 15 14.8431 15 16.5V22.5H22.5V9L12 0L1.5 9Z"
        fill="currentColor"
      />
    </svg>
  );
};
