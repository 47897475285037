import classnames from 'classnames';
import {
  ButtonHTMLAttributes,
  ComponentType,
  ReactNode,
  forwardRef,
} from 'react';
import { To } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Link } from '@sorare/routing';

import { useClickHandler } from 'hooks/useClickHandler';

import { colors } from './variants';

export type Color = (typeof colors)[number];

export interface Props
  extends Omit<
    ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>,
    'type'
  > {
  size: 'compact' | 'small' | 'medium' | 'large';
  color?: Color;
  to?: To | number;
  href?: string;
  replace?: boolean;
  fullWidth?: boolean;
  disableDebounce?: boolean;
  externalLink?: boolean;
  state?: { [key: string]: any };
  className?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  type?: 'submit';
  asSpan?: boolean;
  blur?: boolean;
}

const Label = styled.span``;

const hoverAfterStyle = css`
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
`;

const style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--unit);
  height: 48px;
  padding: 0px 20px;
  box-shadow: none;
  border: none;
  border-radius: 2em !important;
  position: relative;
  & span,
  & {
    letter-spacing: 0;
    white-space: nowrap;
    font: var(--t-label-l);
    font-weight: var(--t-bold);
  }
  transition: background-color 0.2s ease-in-out;

  &.fullWidth {
    width: 100%;
  }
  &.compact {
    padding: 0 var(--unit);
    height: var(--triple-unit);
    font: var(--t-label-m);
    font-weight: var(--t-bold);
  }
  &.small {
    padding: 0 var(--double-unit);
    height: var(--quadruple-unit);
  }
  &.medium {
    height: 40px;
    /* ensures a nice rounding button when there is a small text */
    min-width: 40px;
    /* max padding before going higher than 40 if there is only 1char */
    padding: 0px 12px;
  }
  &.primary {
    background-color: var(--c-brand-600);
    color: var(--c-white);

    &.active,
    &:hover,
    &:focus {
      &:after {
        ${hoverAfterStyle}
        background: var(--c-nd-50);
      }
    }
  }
  &.secondary {
    background-color: var(--c-white);
    color: var(--c-black);

    &.active,
    &:hover,
    &:focus {
      &:after {
        ${hoverAfterStyle}
        background: var(--c-nl-100);
      }
    }
  }
  &.tertiary {
    background-color: var(--c-black);
    border: 1px solid var(--c-nd-150);
    color: var(--c-white);

    &.active,
    &:hover,
    &:focus {
      &:after {
        ${hoverAfterStyle}
        background: var(--c-nd-50);
      }
    }
  }
  &.quaternary {
    background-color: var(--c-nd-50);
    color: var(--c-white);

    &.active,
    &:hover,
    &:focus {
      background: var(--c-nd-100);
    }
  }
  &.transparent {
    color: var(--c-white);
    &:hover {
      background: var(--c-nd-50);
    }
  }
  &.red {
    background-color: rgba(var(--c-rgb-red-800), 0.16);
    color: var(--c-red-600);

    &.active,
    &:hover,
    &:focus {
      &:after {
        ${hoverAfterStyle}
        background: var(--c-nd-50);
      }
    }
  }
  &.green {
    background-color: rgba(var(--c-rgb-green-300), 0.16);
    color: var(--c-rivals-win);

    &.active,
    &:hover,
    &:focus {
      &:after {
        ${hoverAfterStyle}
        background: var(--c-nd-50);
      }
    }
  }
  &.yellow {
    background-color: rgba(var(--c-rgb-yellow-300), 0.16);
    color: var(--c-yellow-300);

    &.active,
    &:hover,
    &:focus {
      &:after {
        ${hoverAfterStyle}
        background: var(--c-nd-50);
      }
    }
  }
  &.disabled,
  &:disabled {
    &,
    &:active,
    &:hover,
    &:focus {
      color: var(--c-nd-500);
      background: var(--c-nd-150);
      border-color: transparent;

      &:after {
        content: none;
      }
    }
  }

  &.blur {
    backdrop-filter: blur(64px);
  }
`;

type Wrapper = ComponentType<
  Omit<ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, 'type'>
>;

const StyledButton: Wrapper = styled.button`
  ${style}
`;

const StyledLink = styled(Link)`
  ${style}
`;

const StyledA: Wrapper = styled.a`
  ${style}
`;

const getWrapper = ({
  disabled,
  to,
  href,
}: {
  to?: To | number;
  href?: string;
  disabled?: boolean;
}) => {
  if (to && !disabled) {
    return StyledLink;
  }
  if (href) {
    return StyledA;
  }
  return StyledButton;
};

const wrappedChildren = (
  children: ReactNode,
  startIcon?: ReactNode,
  endIcon?: ReactNode
) => {
  if (typeof children === 'string' && (startIcon || endIcon)) {
    return <Label>{children}</Label>;
  }
  return children;
};

export const Button = forwardRef<HTMLElement, Props>(
  (
    {
      children,
      size,
      color = 'primary',
      onClick,
      to,
      href,
      disableDebounce = false,
      externalLink = false,
      className,
      startIcon,
      endIcon,
      type = 'button',
      fullWidth,
      asSpan,
      role = href ? 'link' : 'button',
      blur,
      disabled,
      ...rest
    },
    ref
  ) => {
    const clickHandler = useClickHandler(onClick, disableDebounce);

    const Wrapper = getWrapper({ to, href, disabled });

    return (
      <Wrapper
        ref={ref}
        onClick={clickHandler}
        {...(externalLink
          ? ({ target: '_blank', rel: 'noopener noreferrer' } as any)
          : {})}
        to={to}
        href={href}
        type={type}
        role={role}
        disabled={disabled}
        {...{ as: asSpan ? 'span' : undefined }}
        {...rest}
        className={classnames(color, className, size, {
          fullWidth,
          blur,
          disabled,
        })}
      >
        {startIcon}
        {wrappedChildren(children, startIcon, endIcon)}
        {endIcon}
      </Wrapper>
    );
  }
);

Button.displayName = 'Button';
