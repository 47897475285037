export const CURRENT_IN_SEASON_MLB = '2025';
export const CURRENT_IN_SEASON_NBA = '2024';
export const CURRENT_IN_SEASON_FOOTBALL = '24/25';

export const format = (
  seasonYear: number,
  {
    separator = '-',
    singleCivilYear = false,
  }: {
    separator?: string;
    singleCivilYear?: boolean;
  } = {}
) => {
  if (singleCivilYear) return seasonYear.toString();

  const next = (seasonYear + 1).toString().slice(-2);
  return `${seasonYear}${separator}${next}`;
};

export const formatFilter = (season: string) => format(parseInt(season, 10));
