import styled, { keyframes } from 'styled-components';

export const unmountingDuration = 200;
const cardFadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(-30%);
  }
`;

export const UnmountingAnimation = styled.div`
  animation: ${unmountingDuration}ms ${cardFadeOut} ease-in-out forwards;
`;
