import { Navigate, Outlet } from 'react-router-dom';

import { LANDING } from '@sorare/core/src/constants/__generated__/routes';
import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';
import { EnsureTopVisibleOnMount } from '@sorare/core/src/routing/EnsureTopVisibleOnMount';
import { RequireAuth } from '@sorare/core/src/routing/RequireAuth';

const ContentCreatorsLayout = () => {
  const {
    flags: { useContentCreatorTool = false },
  } = useFeatureFlags();

  if (!useContentCreatorTool) {
    return <Navigate to={LANDING} />;
  }

  return (
    <RequireAuth>
      <EnsureTopVisibleOnMount>
        <Outlet />
      </EnsureTopVisibleOnMount>
    </RequireAuth>
  );
};

export default ContentCreatorsLayout;
