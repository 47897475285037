import { unitMapping } from 'lib/style';

type Props = { size?: keyof typeof unitMapping };

export const Search = ({ size = 2 }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: unitMapping[size], height: unitMapping[size] }}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6001 14C9.17243 14 10.6237 13.4816 11.7923 12.6064L14.893 15.7071L16.3072 14.2929L13.2065 11.1922C14.0817 10.0236 14.6001 8.57234 14.6001 7C14.6001 3.13401 11.4661 0 7.6001 0C3.7341 0 0.600098 3.13401 0.600098 7C0.600098 10.866 3.7341 14 7.6001 14ZM7.6001 11C9.80924 11 11.6001 9.20914 11.6001 7C11.6001 4.79086 9.80924 3 7.6001 3C5.39096 3 3.6001 4.79086 3.6001 7C3.6001 9.20914 5.39096 11 7.6001 11Z"
      fill="currentColor"
    />
  </svg>
);
