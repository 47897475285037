import classNames from 'classnames';
import { ChangeEvent, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  checked: boolean;
  label?: string | ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  noPadding?: boolean;
  disabled?: boolean;
  required?: boolean;
  indeterminate?: boolean;
  name?: string;
  id?: string;
};

const CheckboxRoot = styled.span`
  display: inline-grid;
  min-width: 24px;
  min-height: 24px;
  overflow: hidden;
  cursor: pointer;
  &.noPadding {
    padding: 0;
  }
  &.disabled {
    cursor: not-allowed;
  }
`;
const Svg = styled.svg`
  pointer-events: none;
  grid-area: 1 / 2;
`;
const Input = styled.input`
  grid-area: 1 / 2;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
  appearance: none;
`;
const Root = styled.label`
  display: flex;
  align-items: center;
  gap: var(--unit);
  cursor: pointer;
  font: var(--t-body-m);
  color: var(--c-nd-600);
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const Checkbox = ({
  checked,
  label,
  onChange,
  noPadding,
  disabled,
  required,
  indeterminate,
  name,
  id,
}: Props) => {
  const checkbox = (
    <CheckboxRoot className={classNames({ noPadding, disabled, checked })}>
      <Input
        type="checkbox"
        onChange={onChange}
        checked={!!checked}
        disabled={disabled}
        required={required}
        name={name}
        id={id}
      />
      <Svg width="24" height="24">
        {checked ? (
          <>
            {indeterminate ? (
              <path
                d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z"
                fill="currentColor"
              />
            ) : (
              <>
                <path
                  d="M18 3H6a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3z"
                  fill="var(--c-brand-600)"
                />
                <path
                  d="M18.179 9.012l-7.846 7.845-4.511-4.512 2.357-2.357 2.154 2.155 5.489-5.488 2.357 2.357z"
                  fill="var(--c-white)"
                />
              </>
            )}
          </>
        ) : (
          <path
            d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
            fill="currentColor"
          />
        )}
      </Svg>
    </CheckboxRoot>
  );

  if (!label) return checkbox;

  return (
    <Root className={classNames({ checked, disabled })}>
      {checkbox}
      <span>{label}</span>
    </Root>
  );
};
