import styled from 'styled-components';

import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { FootballLogo } from '@sorare/core/src/atoms/icons/FootballLogo';
import { MLBLogo } from '@sorare/core/src/atoms/icons/MLBLogo';
import { NBALogo } from '@sorare/core/src/atoms/icons/NBALogo';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineL, LabelL } from '@sorare/core/src/atoms/typography';
import {
  FOOTBALL,
  MLB,
  NBA,
} from '@sorare/core/src/constants/__generated__/routes';

import { SportButtonContent } from './SportButtonContent';
import { SportLink } from './SportLink';

const DropdownContent = styled(Vertical)`
  padding: var(--double-unit);
  background-color: var(--c-black);
`;

type Props = {
  displaySelectedSportName?: boolean;
  withoutChevronIcon?: boolean;
};
export const SportSelector = ({
  displaySelectedSportName,
  withoutChevronIcon,
}: Props) => {
  return (
    <Dropdown
      forceDesktopBehavior
      label={({ onClick, open }) => (
        <button type="button" onClick={onClick}>
          <SportButtonContent
            displaySelectedSportName={displaySelectedSportName}
            withoutChevronIcon={withoutChevronIcon}
            active={open}
          />
        </button>
      )}
    >
      {({ closeDropdown }) => (
        <DropdownContent>
          <SportLink
            to={FOOTBALL}
            icon={<FootballLogo width={45} height={45} />}
            title={
              <HeadlineL brand25 bold>
                Football
              </HeadlineL>
            }
            subtitle={<LabelL color="var(--c-nd-600)">X cards</LabelL>}
            onClick={closeDropdown}
          />
          <SportLink
            to={NBA}
            icon={<NBALogo width={45} height={45} />}
            title={
              <HeadlineL brand25 bold>
                NBA
              </HeadlineL>
            }
            subtitle={<LabelL color="var(--c-nd-600)">X cards</LabelL>}
            onClick={closeDropdown}
          />
          <SportLink
            to={MLB}
            icon={<MLBLogo width={45} height={45} />}
            title={
              <HeadlineL brand25 bold>
                MLB
              </HeadlineL>
            }
            subtitle={<LabelL color="var(--c-nd-600)">X cards</LabelL>}
            onClick={closeDropdown}
          />
        </DropdownContent>
      )}
    </Dropdown>
  );
};
