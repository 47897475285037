import { CategoryPreferences, Destination, WindowWithAJS } from 'types';

export const conditionallyLoadAnalytics = ({
  writeKey,
  destinations,
  destinationPreferences,
  isConsentRequired,
}: {
  writeKey: string;
  destinations: Destination[];
  destinationPreferences: CategoryPreferences | null | undefined;
  isConsentRequired: boolean;
}) => {
  const wd = window as WindowWithAJS;
  const integrations: Record<string, boolean> = {
    All: false,
    'Segment.io': true,
  };
  let isAnythingEnabled = false;

  if (!destinationPreferences) {
    if (isConsentRequired) {
      return;
    }

    if (!wd.analytics.initialized) {
      wd.analytics.load(writeKey);
    }
    return;
  }

  for (const destination of destinations) {
    const isEnabled = Boolean(destinationPreferences[destination.id]);
    if (isEnabled) {
      isAnythingEnabled = true;
    }
    integrations[destination.id] = isEnabled;
  }

  if (wd.analytics?.initialized) {
    // eslint-disable-next-line no-restricted-properties
    window.location.reload();
    return;
  }

  if (isAnythingEnabled) {
    wd.analytics.load(writeKey, { integrations });
  }
};
