import qs from 'qs';
import { ComponentProps, useState } from 'react';
import styled from 'styled-components';

const validWidths = [40, 80, 160, 320, 480, 640] as const;
const validXlWidths = [320, 640, 1280, 1920, 2560] as const;
export type ValidWidths = (typeof validWidths)[number];
type ValidXlWidths = (typeof validXlWidths)[number];
/**
 * Defines all the properties which may actually be injected and targetted towards Cloudflare resizing features.
 */
type CloudflareResizeProps =
  | {
      cropWidth?: ValidWidths;
      xl?: false;
      dpis?: 1 | 2 | 3;
    }
  | {
      cropWidth?: ValidXlWidths;
      xl: true;
      dpis?: 1 | 2 | 3;
    };

type Props = Omit<ComponentProps<'img'>, 'src' | 'alt'> &
  CloudflareResizeProps & {
    src: Nullable<string>;
    alt: string;
    fallback?: string;
  };

type CloudFlareOptions = {
  width?: number;
  xl?: boolean;
};

const resizableImgRegex = new RegExp(
  // https://gitlab.com/sorare/infra/-/blob/master/terraform/modules/edge/main.tf?ref_type=heads#L45-63
  /(?<origin>https:\/\/(frontend-)?assets\.sorare.(com|dev))(?<path>.*)/
);
const cachedDpis = [1, 2, 3];

const cachedDpi =
  (window.devicePixelRatio &&
    cachedDpis.find(dpi => dpi >= window.devicePixelRatio)) ||
  1;

const getClosestWidth = (
  width: number,
  widths: readonly number[] = validWidths
) => widths.find(c => c >= width) || widths[widths.length - 1];

export const getClosestStandardWidth = (width: number) => {
  return getClosestWidth(width, validWidths) as ValidWidths;
};

const getValidWidth = (width: number, useXlWidths = false) => {
  const widths = useXlWidths ? validXlWidths : validWidths;
  if (
    ['test', 'development'].includes(import.meta.env.MODE) &&
    !(widths as readonly number[]).includes(width)
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      `CropWidth value should be one of ${widths.join(
        ', '
      )}, you passed ${width}`
    );
  }
  return getClosestWidth(width, widths);
};

const options = (
  props: Omit<CloudflareResizeProps, 'cropWidth'> & { cropWidth?: number }
) => {
  const cdnOptions: CloudFlareOptions = {};
  if (props.cropWidth) {
    const expectedWidth = props.cropWidth * (props.dpis || cachedDpi);
    cdnOptions.width = getValidWidth(expectedWidth, props.xl);
  }
  if (props.xl) {
    cdnOptions.xl = true;
  }
  return cdnOptions;
};

export const proxyUrl = (
  url: string,
  cdnQueryParams: CloudflareResizeProps
) => {
  return url.replace(
    resizableImgRegex,
    `$<origin>/image-resize$<path>${qs.stringify(options(cdnQueryParams), {
      addQueryPrefix: true,
    })}`
  );
};

/**
 * For sorare.com assets only (relative paths in staging/production or absolute sorare.com paths)
 * If a dimension is specified, it returns a resized image of that dimension
 * Otherwise it returns a simple optimized image (image max width is the screen width)
 * Cloudflare converts automatically to webP for browsers that support it
 */
const imageProps = (url: Nullable<string>, props: CloudflareResizeProps) => {
  if (!url?.match(resizableImgRegex)) {
    if (props.cropWidth) {
      // ensure validation is also done in tests & storybook
      getValidWidth(props.cropWidth, props.xl);
    }
    return { src: url || undefined };
  }
  if (props.cropWidth) return { src: proxyUrl(url, props) };

  return {
    src: url,
    srcSet: `${proxyUrl(url, {
      ...props,
      cropWidth: 320,
    })} 300w,
    ${proxyUrl(url, { ...props, cropWidth: 640 })} 600w,
    ${proxyUrl(url, { ...props, cropWidth: 640 })} 900w`,
  };
};

const ImgHidingAltText = styled.img`
  color: transparent;
`;

const Img = ({ src, alt, fallback, onError, ...rest }: Props) => {
  const [hasError, setHasError] = useState(false);
  const { cropWidth, xl, ...nonCloudflareProperties } = rest;

  return (
    <ImgHidingAltText
      {...(hasError
        ? { src: fallback }
        : imageProps(src, {
            cropWidth,
            xl,
          } as CloudflareResizeProps))}
      {...nonCloudflareProperties}
      onError={e => {
        setHasError(true);
        if (onError) {
          onError(e);
        }
      }}
      alt={alt}
    />
  );
};

export const ResponsiveImg = ({ src, ...props }: Props) => {
  return <Img key={src} {...props} src={src} />;
};
