import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { CollectionArrow } from 'atoms/icons/CollectionArrow';
import { Vertical } from 'atoms/layout/flex';
import { LabelM, LabelS } from 'atoms/typography';

const Root = styled(Vertical).attrs({ center: true })`
  color: var(--c-nd-600);

  &.shiny {
    color: var(--c-yellow-200);
    text-shadow: 0px 1px 7px var(--c-yellow-400);
  }

  &.faded {
    opacity: 0.5;
  }
`;
const Icon = styled.img`
  width: var(--double-unit);
  height: var(--double-unit);
  object-fit: contain;
`;

type Props = {
  shiny?: boolean;
  faded?: boolean;
  bonus?: Nullable<number>;
  badge?: { pictureUrl?: Nullable<string>; name?: string };
};
export const ThresholdLabel = ({ shiny, faded, bonus, badge }: Props) => {
  return (
    <Root
      center
      gap={0.5}
      className={classNames({
        shiny,
        faded,
      })}
      {...(shiny && !faded ? { role: 'status' } : {})}
    >
      {typeof bonus === 'number' && (
        <>
          <CollectionArrow shiny={shiny} />
          <span>
            <LabelM bold as="span">
              +{bonus * 100}
            </LabelM>
            <LabelS as="span">%</LabelS>
          </span>
        </>
      )}
      {badge?.pictureUrl && (
        <>
          <Icon src={badge.pictureUrl} alt={badge.name} />
          <LabelM bold as="span">
            <FormattedMessage
              id="Collections.ThresholdLable.clubBadge"
              defaultMessage="Badge"
            />
          </LabelM>
        </>
      )}
    </Root>
  );
};
