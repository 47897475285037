import { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';

import { LogosTickerBlock } from '@sorare/core/src/components/landing/LogosTickerBlock';
import { RivalsExperience } from '@sorare/core/src/components/landing/RivalsExperience';
import { Hero } from '@sorare/core/src/components/landing/RivalsHeroBlock';
import { RivalsPlayNow } from '@sorare/core/src/components/landing/RivalsPlayNow';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { useSeoContext } from '@sorare/core/src/contexts/seo';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { AppLayout } from '@sorare/gameplay/src/components/navigation/AppLayout';

import metadataImage from './assets/metadataImage.jpg';

const DarkBackground = styled.div`
  overflow: hidden;
  position: relative;
  background-color: black;
  color: var(--c-white);
`;

const TopTicker = styled.div`
  margin-top: var(--quadruple-unit);
`;

const BottomTicker = styled.div`
  margin-top: var(--quadruple-unit);

  @media ${laptopAndAbove} {
    margin-top: 0;
  }
`;

const messages = defineMessages({
  metadatasTitle: {
    id: 'RivalsLanding.meta.title',
    defaultMessage:
      'Play head-to-head fantasy football, big rewards – Sorare Rivals',
  },
  metadatasDescription: {
    id: 'RivalsLanding.meta.description',
    defaultMessage:
      'Play Sorare Rivals, a free head-to-head fantasy football game with big rewards. Manage daily lineups with players from Premier League, LaLiga, Bundesliga, more',
  },
});

export const RivalsLanding = () => {
  const track = useEvents();
  const { setPageMetadata } = useSeoContext();
  const { formatMessage } = useIntlContext();

  useEffect(
    () =>
      setPageMetadata(formatMessage(messages.metadatasTitle), {
        img: metadataImage,
        description: formatMessage(messages.metadatasDescription),
      }),
    [setPageMetadata, formatMessage]
  );

  useEffect(() => {
    track('View Homepage Disconnected', {
      landing_variant: 'Rivals_landing',
    });
  }, [track]);

  return (
    <AppLayout>
      <DarkBackground>
        <Hero />
        <TopTicker>
          <LogosTickerBlock />
        </TopTicker>
        <RivalsExperience />
        <BottomTicker>
          <LogosTickerBlock />
        </BottomTicker>
        <RivalsPlayNow />
      </DarkBackground>
    </AppLayout>
  );
};
