import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';

import { InGameCurrency, Rarity } from '__generated__/globalTypes';
import { getCardRepresentation } from 'lib/cards';
import { isType } from 'lib/gql';
import { getRewardBoxRepresentation } from 'lib/probabilisticBundle';
import { lazy } from 'lib/retry';

import type { Reward_AnyRewardInterface } from './__generated__/index.graphql';

const Card = lazy(
  async () => import('@sorare/trois/src/atoms/Card/Card'),
  'Card'
);

const RewardBox = lazy(
  async () => import('@sorare/trois/src/components/RewardBox'),
  'RewardBox'
);

const Energy = lazy(
  async () => import('@sorare/trois/src/atoms/Energy'),
  'Energy'
);

const Essence = lazy(
  async () => import('@sorare/trois/src/atoms/Essence'),
  'Essence'
);

const XP = lazy(async () => import('@sorare/trois/src/atoms/XP'), 'XP');

type Props = {
  reward: Reward_AnyRewardInterface;
};

export const Reward = ({ reward }: Props) => {
  if (isType(reward, 'AnyCardReward')) {
    return <Card card={getCardRepresentation(reward.card)} />;
  }
  if (isType(reward, 'InGameCurrencyReward')) {
    return {
      // energy
      [InGameCurrency.LIMITED_ENERGY]: <Energy rarity="limited" />,
      [InGameCurrency.RARE_ENERGY]: <Energy rarity="rare" />,
      [InGameCurrency.SUPER_RARE_ENERGY]: <Energy rarity="super_rare" />,
      [InGameCurrency.UNIQUE_ENERGY]: <Energy rarity="unique" />,

      // xp
      [InGameCurrency.COMMON_XP]: <XP rarity="common" />,
      [InGameCurrency.LIMITED_XP]: <XP rarity="limited" />,
      [InGameCurrency.RARE_XP]: <XP rarity="rare" />,
      [InGameCurrency.SUPER_RARE_XP]: <XP rarity="super_rare" />,
      [InGameCurrency.UNIQUE_XP]: <XP rarity="unique" />,

      // FIXME
      [InGameCurrency.OLD_CLUB_SHOP_COIN]: null,
      [InGameCurrency.COMMON_GEM]: null,
    }[reward.config.currency];
  }

  if (isType(reward, 'ProbabilisticBundleReward')) {
    const { id, ...boxProps } = getRewardBoxRepresentation(
      reward.probabilisticBundle
    );
    return (
      <RewardBox open={false} {...boxProps} scale={0.5} position-y={-0.4} />
    );
  }

  if (isType(reward, 'CardShardsReward')) {
    return (
      <Essence
        rarity={
          (
            {
              [Rarity.common]: 'common',
              [Rarity.custom_series]: 'limited',
              [Rarity.limited]: 'limited',
              [Rarity.rare]: 'rare',
              [Rarity.super_rare]: 'super_rare',
              [Rarity.unique]: 'unique',
            } as const
          )[reward.rarity]
        }
        scale={0.5}
      />
    );
  }

  // eslint-disable-next-line no-console
  console.error('Unknown reward type', reward);
  return null;
};

Reward.fragments = {
  AnyRewardInterface: gql`
    fragment Reward_AnyRewardInterface on AnyRewardInterface {
      id
      ... on AnyCardReward {
        id
        card {
          slug
          ...getCardRepresentation_anyCardInterface
        }
      }
      ... on ProbabilisticBundleReward {
        id
        probabilisticBundle {
          id
          ...getRewardBoxRepresentation_ProbabilisticBundle
        }
      }
      ... on InGameCurrencyReward {
        id
        config {
          id
          amount
          currency
        }
      }
      ... on CardShardsReward {
        id
        rarity
        quantity
      }
    }
    ${getCardRepresentation.fragments.anyCardInterface}
    ${getRewardBoxRepresentation.fragments.ProbabilisticBundle}
  ` as TypedDocumentNode<Reward_AnyRewardInterface>,
};
