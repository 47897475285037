import {
  LoaderFunction,
  RouteObject,
  ScrollRestoration,
  ShouldRevalidateFunction,
} from 'react-router-dom';

import { SilencedError } from '@sorare/error-boundary';
import { ComponentType, Suspense } from 'react';
import styled from 'styled-components';
export const toRoutePath = (path: string) => {
  return path
    .replace('page.tsx', '')
    .replace('layout.tsx', '')
    .replace('_', '')
    .replace('./', '')
    .replaceAll(/((^|.*\/)_?)\$/g, '$1:');
};

type Module = {
  default: React.ComponentType & {
    loader?: LoaderFunction;
    shouldRevalidate?: ShouldRevalidateFunction;
  };
  loader?: LoaderFunction & {
    shouldRevalidate?: ShouldRevalidateFunction;
  };
  Fallback?: ComponentType;
};

const DefaultFallback = styled.div`
  & > * {
    height: calc(var(--100vh) - var(--current-stack-height));
  }
`;
const lazyRouteObjectFn =
  (context: string) =>
  (lazyComponent: (() => Promise<Module>) | undefined, isPage?: boolean) => {
    if (lazyComponent === undefined) {
      return {};
    }
    return {
      lazy: async () => {
        const resolved = await lazyComponent();
        if (!resolved) {
          // fail silently because the error is already caught by ViteErrorHandler
          throw new SilencedError(
            'Lazy component could not be resolved',
            new Error(`Lazy component could not be resolved: ${context}`)
          );
        }

        const { default: Component, loader, Fallback } = resolved;
        if (!Component) {
          throw new Error(
            `Page-level components must have default export: ${context}`
          );
        }
        const finalLoader = loader || Component.loader;
        const FinalFallback = Fallback || DefaultFallback;
        return {
          Component: () => {
            const componentWithScrollRestoration = isPage ? (
              <>
                <Component />
                {/** https://github.com/remix-run/react-router/issues/9472 */}
                <ScrollRestoration />
              </>
            ) : (
              <Component />
            );
            return (
              <Suspense fallback={<FinalFallback />}>
                {componentWithScrollRestoration}
              </Suspense>
            );
          },
          loader: finalLoader,
          shouldRevalidate:
            Component.shouldRevalidate || loader?.shouldRevalidate,
        };
      },
    };
  };

type FolderContent<T> = {
  path: string;
  layout?: T;
  page?: T;
  handle?: any;
  folders: {
    [key: string]: FolderContent<T>;
  };
  parent?: FolderContent<T>;
};

const getType = (filePath: string) =>
  filePath.endsWith('layout.tsx') ? 'layout' : 'page';

const filesArrayTofilesTree = (files: Record<string, any>, prefix: string) =>
  Object.entries(files).reduce(
    (acc, [filePath, module]) => {
      const routePath = filePath
        .replace('page.tsx', '')
        .replace('layout.tsx', '');
      const tokens = routePath.split('/');

      let currentDict = acc;
      tokens.forEach((token, index) => {
        let path = token.replace('_', '').replace('$', ':');
        if (path !== '') {
          if (token.startsWith('_') && currentDict.parent) {
            path = `${currentDict.path}/${path}`;
            currentDict = currentDict.parent;
          }
          if (token.endsWith('_') && currentDict.parent) {
            currentDict = acc;
            path = tokens
              .slice(0, index + 1)
              .map(t => t.replace('_', '').replace('$', ':'))
              // .filter(t => t !== '.')
              .join('/');
          }
          if (currentDict.folders[path] === undefined) {
            currentDict.folders[path] = {
              folders: {},
              path,
              parent: currentDict,
            };
          }
          currentDict = currentDict.folders[path];
        }
      });

      const type = getType(filePath);
      currentDict[type] = module;
      currentDict.handle ??= {};
      currentDict.handle[type] = prefix + filePath.slice(1);
      return acc;
    },
    { folders: {}, path: '' } as FolderContent<any>
  );

const folderTreeToRoutes = (
  path: string,
  content: FolderContent<any>,
  basePath = ''
): RouteObject[] => {
  // clean up top level path
  // eslint-disable-next-line no-param-reassign
  path = path.replace(/^\/\.?\/?/, '');

  const lazyRouteObject = lazyRouteObjectFn(basePath.replace('//', ''));
  // empty
  if (
    !content.layout &&
    !content.page &&
    Object.keys(content.folders).length === 0
  ) {
    return [];
  }

  if (!content.layout && Object.keys(content.folders).length === 0) {
    return [
      {
        path,
        handle: content.handle,
        ...lazyRouteObject(content.page, true),
      },
    ];
  }

  // collapse routes
  if (!content.page && !content.layout) {
    return Object.entries(content.folders)
      .map(([folderName, f]) =>
        folderTreeToRoutes(
          `${path}/${folderName}`,
          f,
          `${basePath}/${path}/${folderName}`
        )
      )
      .flat();
  }

  // subroutes
  const entry: RouteObject = {
    path,
    handle: content.handle,
    ...lazyRouteObject(content.layout),
  };

  entry.children = [
    ...Object.entries(content.folders)
      .map(([folderName, f]) =>
        folderTreeToRoutes(folderName, f, `${basePath}/${folderName}`)
      )
      .flat(),
    ...(content.page
      ? [
          {
            index: true,
            handle: content.handle,
            ...lazyRouteObject(content.page, true),
          },
        ]
      : []),
  ];
  return [entry];
};

export const getRoutesFromFiles = (
  files: Record<string, any>,
  prefix: string = ''
) => {
  return folderTreeToRoutes('', filesArrayTofilesTree(files, prefix));
};
