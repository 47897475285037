import { createContext, useContext } from 'react';

export interface DeviceFingerprintContext {
  deviceFingerprint: () => Promise<string>;
}

export const DeviceFingerprintContext =
  createContext<DeviceFingerprintContext | null>(null);

export const useDeviceFingerprintContext = () =>
  useContext(DeviceFingerprintContext)!;
