import { RefObject, useEffect, useRef, useState } from 'react';

import {
  MarketplaceOnboardingStep,
  useManagerTaskContext,
} from '@sorare/core/src/contexts/managerTask';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { useIsPWA } from '@sorare/core/src/hooks/useIsPWA';

export const useBottomBarScroll = (navigationRef: RefObject<HTMLElement>) => {
  const isPwa = useIsPWA();
  const isLaptopAndAbove = useIsLaptop();
  const { step } = useManagerTaskContext();
  const scrollRef = useRef({ oldScroll: 0 });
  const { isMobileApp } = useIsMobileApp();

  const [hideBottomBar, setHideBottomBar] = useState(false);

  useEffect(() => {
    if (isLaptopAndAbove || isPwa) {
      return () => {};
    }

    const onScroll = () => {
      const { scrollingElement } = document;
      if (
        !scrollingElement ||
        !navigationRef.current ||
        isMobileApp ||
        step === MarketplaceOnboardingStep.menu
      ) {
        return;
      }

      const { scrollTop } = scrollingElement;
      const isScrollingDown = scrollRef.current.oldScroll < scrollTop;
      const hasReachedTop = scrollTop <= 0;
      const hasReachedBottom =
        scrollTop + window.innerHeight >= document.body.offsetHeight;

      scrollRef.current.oldScroll = scrollTop;
      setHideBottomBar(isScrollingDown && !hasReachedBottom && !hasReachedTop);
    };

    window.document.addEventListener('scroll', onScroll);
    return () => {
      window.document.removeEventListener('scroll', onScroll);
    };
  }, [isMobileApp, step, isPwa, navigationRef, isLaptopAndAbove]);

  return hideBottomBar;
};
