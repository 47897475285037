import classNames from 'classnames';
import styled from 'styled-components';

import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

type Props = {
  shiny?: boolean;
  color?: Color;
  size?: keyof typeof unitMapping;
};

const StyledSVG = styled.svg`
  &.shiny {
    filter: drop-shadow(0px 1px 2px #292825);
  }
`;

export const CollectionArrow = ({ shiny, color, size = 1.5 }: Props) => (
  <StyledSVG
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: unitMapping[size], height: unitMapping[size] }}
    className={classNames({ shiny })}
  >
    <path
      d="M3.65217 6.54545L-4.76852e-07 6.54545L-5.96064e-07 5.18182L6 5.24536e-07L12 5.18182L12 6.54545L8.34783 6.54545L8.34783 12L3.65217 12L3.65217 6.54545Z"
      fill={color || 'currentColor'}
    />
  </StyledSVG>
);
