import { ReactNode, useEffect, useState } from 'react';

import { TickerContext } from '.';

interface Props {
  children: ReactNode;
}

// allow mocking in tests, cf mockDate
const getNewDate = () => new Date();

export const TickerProvider = ({ children }: Props) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(getNewDate), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <TickerContext.Provider
      value={{
        now,
      }}
    >
      {children}
    </TickerContext.Provider>
  );
};
