import { ReactNode, useId } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Checkbox } from 'atoms/inputs/Checkbox';
import { SimpleRadio } from 'atoms/inputs/SimpleRadio';
import { SBHorizontal } from 'atoms/layout/flex';
import { BodyS, LabelS } from 'atoms/typography';
import { laptopAndAbove } from 'style/mediaQuery';

type Props = {
  children: NonNullable<ReactNode>;
  count?: number;
  onClick: () => void;
  active?: boolean;
  variant: 'checkbox' | 'radio';
  disabled?: boolean;
  afterLabel?: ReactNode;
};

const Infos = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--intermediate-unit);
  color: var(--c-nd-100);
  cursor: pointer;
  min-width: 55px; /** ~3 digits */
`;

const Root = styled.label`
  width: 100%;
  cursor: pointer;
  height: calc(5 * var(--unit));
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--c-nd-900);
  @media ${laptopAndAbove} {
    height: var(--quadruple-unit);
  }

  &:hover,
  &:focus-within {
    & ${Infos} {
      color: var(--c-nd-200);
    }
  }
`;
const Content = styled(SBHorizontal).attrs({ gap: 2 })`
  width: 100%;
`;
const LabelContainer = styled(BodyS)`
  display: flex;
  align-items: center;
  gap: var(--unit);
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & > img {
    flex-shrink: 0;
  }
`;

const Count = styled(LabelS)`
  color: var(--c-nd-600);
  display: inline-block;
`;

export const Option = ({
  children,
  count,
  onClick,
  active,
  variant,
  afterLabel,
  disabled,
}: Props) => {
  const { formatNumber } = useIntl();

  const optionId = useId();
  return (
    <Root htmlFor={optionId}>
      <Content>
        <LabelContainer>{children}</LabelContainer>
        {afterLabel}
        <Infos>
          {count !== undefined && <Count>{formatNumber(count)}</Count>}
          {variant === 'checkbox' ? (
            <Checkbox
              id={optionId}
              checked={!!active}
              noPadding
              disabled={disabled}
              onChange={onClick}
            />
          ) : (
            <SimpleRadio
              id={optionId}
              checkedColor="var(--c-brand-600)"
              checked={!!active}
              disabled={disabled}
              onChange={onClick}
            />
          )}
        </Infos>
      </Content>
    </Root>
  );
};
