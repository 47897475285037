import { ReactNode, useState } from 'react';

import { RestrictedAccessContext, RestrictedAccessReason } from '.';

type Props = {
  children: ReactNode;
};
export const RestrictedAccessProvider = ({ children }: Props) => {
  const [showRestrictedAccess, setShowRestrictedAccess] =
    useState<RestrictedAccessReason>();

  return (
    <RestrictedAccessContext.Provider
      value={{
        showRestrictedAccess,
        setShowRestrictedAccess,
      }}
    >
      {children}
    </RestrictedAccessContext.Provider>
  );
};
