import { Operation } from '@apollo/client/core';
import { createContext, useContext } from 'react';

export interface GraphQLCall {
  id: string;
  operation: Operation;
  response: any;
  complexity: number;
  depth: number;
  subgraphs: string[];
}

interface DebugContext {
  recordGQLOperation: (call: GraphQLCall) => void;
}

export const DebugContext = createContext<DebugContext | null>(null);

export const useDebugContext = () => useContext(DebugContext)!;
