import { defineMessages } from 'react-intl';

export const bonusMessages = defineMessages({
  captain: {
    id: 'CardBonus.captain',
    defaultMessage: 'Captain bonus',
  },
  levelBonus: {
    id: 'BonusChip.levelBonus',
    defaultMessage: 'XP bonus',
  },
  scarcity: {
    id: 'CardBonus.scarcity',
    defaultMessage: 'Scarcity bonus',
  },
  transferMalus: {
    id: 'CardBonus.transferMalus',
    defaultMessage: 'Non transferrable XP',
  },
  collection: {
    id: 'CardBonus.collection',
    defaultMessage: 'Collection bonus',
  },
  season: {
    id: 'BonusChip.seasonBonus',
    defaultMessage: 'Season bonus',
  },
  club: {
    id: 'BonusChip.clubBonus',
    defaultMessage: 'Club bonus',
  },
  nationality: {
    id: 'BonusChip.nationalityBonus',
    defaultMessage: 'Nationality bonus',
  },
  position: {
    id: 'BonusChip.positionBonus',
    defaultMessage: 'Position bonus',
  },
  specialEdition: {
    id: 'BonusChip.specialEdition',
    defaultMessage: 'Special Edition bonus',
  },
});
