import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  CartItemState,
  So5LeaderboardSeasonality,
} from '@sorare/core/src/__generated__/globalTypes';
import { SeasonalityIcon } from '@sorare/core/src/atoms/icons/SeasonalityIcon';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyL, LabelM } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import { SorareUser } from '@sorare/core/src/components/user/SorareUser';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { cart, glossary } from '@sorare/core/src/lib/glossary';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { anyPositionShortNames } from '@sorare/core/src/lib/players';

import { CardBonus } from 'components/card/CardBonus';
import { CollectionPrediction } from 'components/card/CollectionPrediction';
import { CartItemStateWarning } from 'components/cart/CartItemStateWarning';
import { FlexCard } from 'components/token/FlexCard';
import { useCart } from 'hooks/useCart';

import { CartAction } from '../CartAction';
import { CartItem_cartItem } from './__generated__/index.graphql';
import type { CartItem_anyCard } from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 1 })`
  width: 100%;
  .clear-cart &,
  &.loading {
    filter: blur(10px);
  }
`;

const Metas = styled(Vertical)`
  flex-grow: 1;
  align-items: flex-start;
`;

const CardWrapper = styled.div`
  width: 80px;
  margin-right: var(--half-unit);
  .removed & {
    filter: grayscale(1);
  }
`;
const StyledLabelM = styled(LabelM)`
  > * {
    vertical-align: baseline;
  }
`;

type Props = {
  cartItem: Nullable<CartItem_cartItem>;
  card: Nullable<CartItem_anyCard>;
  onAction?: () => void;
};

export const CartItem = ({ cartItem, card, onAction }: Props) => {
  const { formatMessage } = useIntlContext();
  const { removeFromCart, saveForLater } = useCart();

  if (!cartItem || !card) return null;

  const removed = cartItem.state === CartItemState.REMOVED;

  return (
    <Root
      className={classNames({
        removed,
        loading: removeFromCart.loading || saveForLater.loading,
      })}
    >
      <CartItemStateWarning cartItem={cartItem} />
      <Horizontal>
        <CardWrapper>
          <FlexCard card={card} width={80} />
        </CardWrapper>
        <Metas>
          {removed && (
            <Chip size="small">
              <FormattedMessage {...cart.soldOut} />
            </Chip>
          )}
          <Vertical gap={0.5}>
            <BodyL bold>{card?.anyPlayer?.displayName}</BodyL>
            <StyledLabelM color="var(--c-nd-600)">
              {card.anyPositions
                .map(position => formatMessage(anyPositionShortNames[position]))
                .join(',')}
              {' • '}
              <SeasonalityIcon
                size={1.5}
                seasonality={So5LeaderboardSeasonality.IN_SEASON}
              />
              {' • '}
              {card.serialNumber}/{card?.supply}
              {' • '}
              <SorareUser />
            </StyledLabelM>
          </Vertical>
          {!removed && (
            <>
              <Horizontal>
                <CardBonus card={card} />
                <CollectionPrediction card={card} />
              </Horizontal>
              <Horizontal>
                <CartAction
                  onClick={() => {
                    onAction?.();
                    removeFromCart.mutate([cartItem.id]);
                  }}
                >
                  <FormattedMessage {...glossary.remove} />
                </CartAction>
                <CartAction
                  onClick={() => {
                    onAction?.();
                    saveForLater.mutate([cartItem.id]);
                  }}
                >
                  <FormattedMessage {...cart.saveForLater} />
                </CartAction>
              </Horizontal>
            </>
          )}
        </Metas>
        {!!cartItem.price && (
          <span className="text-right">
            <AmountWithConversion column monetaryAmount={cartItem.price} />
          </span>
        )}
      </Horizontal>
    </Root>
  );
};

CartItem.fragments = {
  anyCard: gql`
    fragment CartItem_anyCard on AnyCardInterface {
      slug
      serialNumber
      supply
      anyPlayer {
        slug
        displayName
      }
      anyPositions
      ...FlexCard_anyCard
      ...CardBonus_anyCard
      ...CollectionPrediction_anyCard
    }
    ${FlexCard.fragments.anyCard}
    ${CardBonus.fragments.anyCard}
    ${CollectionPrediction.fragments.anyCard}
  ` as TypedDocumentNode<CartItem_anyCard>,
  cartItem: gql`
    fragment CartItem_cartItem on CartItem {
      id
      state
      price {
        ...MonetaryAmountFragment_monetaryAmount
      }
      ...CartItemStateWarning_cartItem
    }
    ${CartItemStateWarning.fragments.cartItem}
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<CartItem_cartItem>,
};
