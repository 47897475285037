import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Fragment } from 'react';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { LANDING } from 'constants/__generated__/routes';

const BreadcrumbWrapper = styled(Horizontal).attrs({ gap: 2 })`
  padding: var(--double-unit);
  flex-wrap: wrap;
`;

interface Section {
  to?: string;
  name: string;
}

interface Props {
  rootTo?: string;
  sections?: Section[];
}

export const Breadcrumb = ({ rootTo, sections }: Props) => {
  return (
    <BreadcrumbWrapper>
      <Link to={rootTo || LANDING}>Sorare</Link>
      {sections?.map((section: Section) => (
        <Fragment key={section.name}>
          <FontAwesomeIcon icon={faChevronRight} />
          {section.to ? (
            <Link to={section.to}>{section.name}</Link>
          ) : (
            section.name
          )}
        </Fragment>
      ))}
    </BreadcrumbWrapper>
  );
};
