import { createContext, useContext } from 'react';

interface SearchCardsContext {
  filters: string[];
  competitionEligibilityFilter: string | false;
  setCompetitionEligibilityFilter: (filter: string | false) => void;
  gameweekFilter: string | false;
  setGameweekFilter: (filter: string | false) => void;
  dailyFilter: string | false;
  setDailyFilter: (filter: string | false) => void;
  probablePitchersFilter: string | false;
  setProbablePitchersFilter: (filter: string | false) => void;
  customDecksFilter: string | false;
  setCustomDecksFilter: (filter: string | false) => void;
  favoriteFilter: boolean;
  setFavoriteFilter: (filter: boolean) => void;
  notInLineUpFilter: boolean;
  setNotInLineUpFilter: (filter: boolean) => void;
  promotion: string | false;
  setPromotion: (promo: string | false) => void;
  clearFilters: () => void;
  commonMarket?: boolean;
  editableLists?: boolean;
  galleryOwnerSlug?: string;
  setSelectedIndex?: (index: string) => void;
  selectedIndex?: string;
  promotionalEvent: string | false;
  setPromotionalEvent: (promo: string | false) => void;
  statsMode: string | false;
  setStatsMode: (statsMode: string | false) => void;
  sealed?: boolean;
}

export const SearchCardsContext = createContext<SearchCardsContext | null>(
  null
);

export const useSearchCardsContext = () => useContext(SearchCardsContext)!;
