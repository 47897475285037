import { useIsLoggedIn } from '@sorare/core/src/hooks/auth/useIsLoggedIn';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';

export enum HEADER_MODE {
  iOS = 'iOS',
  LoggedIn = 'LoggedIn',
}

export const useAppHeaderMode = (): HEADER_MODE | undefined => {
  const isLoggedIn = useIsLoggedIn();
  const { isIosApp } = useIsMobileApp();

  if (isIosApp) {
    return HEADER_MODE.iOS;
  }
  if (isLoggedIn) {
    return HEADER_MODE.LoggedIn;
  }
  return undefined;
};
