import { type TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { To } from 'react-router-dom';
import styled, { CSSProperties } from 'styled-components';

import { Link } from '@sorare/routing';

import defaultShield from '@sorare/core/src/assets/club/shield_none.png';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import {
  BodyS,
  HeadlineL,
  LabelL,
  LabelS,
} from '@sorare/core/src/atoms/typography';
import { Avatar } from '@sorare/core/src/components/user/Avatar';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { range } from '@sorare/core/src/lib/arrays';
import { glossary, navLabels } from '@sorare/core/src/lib/glossary';

import { AchievementBadgePlaceholder } from 'components/profile/ManagerIDHeader/AchievementBadgePlaceholder';
import { useDefaultStadiumBackground } from 'hooks/useDefaultStadiumBackground';

import type {
  MyClubNavItemQuery,
  MyClubNavItemQueryVariables,
  MyClubNavItem_UserSportProfileInterface,
} from './__generated__/index.graphql';

const Root = styled(Vertical).attrs({ gap: 3, center: true })`
  position: relative;
  border-radius: var(--double-unit);
  padding: var(--double-unit);
  border: 2px solid transparent;
  background:
    var(--background-url) center / cover no-repeat padding-box,
    linear-gradient(var(--c-black), var(--c-black)) padding-box,
    linear-gradient(
        180deg,
        var(--c-nd-600) 50%,
        var(--c-yellow-300) 70%,
        var(--c-white) 73%,
        var(--c-yellow-300) 76%,
        var(--c-yellow-300) 86%,
        var(--c-white) 89%,
        var(--c-yellow-300)
      )
      border-box;
`;
const ClubBadge = styled.img`
  width: calc(8 * var(--unit));
  height: calc(8 * var(--unit));
`;
const AchievementBadge = styled.img`
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  object-fit: contain;
`;

const userProfileFragment = gql`
  fragment MyClubNavItem_UserSportProfileInterface on UserSportProfileInterface {
    id
    clubName
    background {
      id
      pictureUrl
    }
    achievementBadges {
      id
      name
      pictureUrl
    }
    clubBadge {
      id
      name
      pictureUrl
    }
  }
` as TypedDocumentNode<MyClubNavItem_UserSportProfileInterface>;
const MY_CLUB_NAV_ITEM_QUERY = gql`
  query MyClubNavItemQuery($sport: Sport!) {
    currentUser {
      slug
      nickname
      followersCount
      followingCount
      anyUserSportProfile(sport: $sport) {
        id
        ...MyClubNavItem_UserSportProfileInterface
      }
      ...Avatar_publicUserInfoInterface
    }
  }
  ${userProfileFragment}
  ${Avatar.fragments.publicUserInfoInterface}
` as TypedDocumentNode<MyClubNavItemQuery, MyClubNavItemQueryVariables>;

type Props = { to: To; onClick?: () => void };
export const MyClubNavItem = ({ to, onClick }: Props) => {
  const { sport } = useSportContext();
  const defaultStadiumBackground = useDefaultStadiumBackground(sport);
  const { data } = useQuery(MY_CLUB_NAV_ITEM_QUERY, { variables: { sport } });
  const { currentUser } = data || {};

  const { anyUserSportProfile } = currentUser || {};

  return (
    <Root
      as={Link}
      to={to}
      onClick={onClick}
      style={
        {
          '--background-url': `url(${anyUserSportProfile?.background?.pictureUrl || defaultStadiumBackground})`,
        } as CSSProperties
      }
    >
      <Vertical center>
        <ClubBadge
          src={anyUserSportProfile?.clubBadge?.pictureUrl || defaultShield}
          alt={anyUserSportProfile?.clubBadge?.name || ''}
        />
        <Horizontal>
          {currentUser && <Avatar user={currentUser} size={2} rounded />}
          <LabelS>{currentUser?.nickname}</LabelS>
        </Horizontal>
        <HeadlineL>{anyUserSportProfile?.clubName}</HeadlineL>
        <Horizontal>
          <Horizontal gap={0.5}>
            <BodyS color="var(--c-white)">
              <FormattedNumber
                value={currentUser?.followersCount || 0}
                compactDisplay="short"
                notation="compact"
                maximumFractionDigits={1}
              />
            </BodyS>
            <LabelS color="var(--c-nd-600)">
              <FormattedMessage {...glossary.followers} />
            </LabelS>
          </Horizontal>
          <Horizontal gap={0.5}>
            <BodyS color="var(--c-white)">
              <FormattedNumber
                value={currentUser?.followingCount || 0}
                compactDisplay="short"
                notation="compact"
                maximumFractionDigits={1}
              />
            </BodyS>
            <LabelS color="var(--c-nd-600)">
              <FormattedMessage {...glossary.following} />
            </LabelS>
          </Horizontal>
        </Horizontal>
      </Vertical>
      <Horizontal>
        {range(3).map(i => {
          const badge = anyUserSportProfile?.achievementBadges?.[i];
          // eslint-disable-next-line react/no-array-index-key
          return badge?.pictureUrl ? (
            <AchievementBadge
              key={i}
              src={badge?.pictureUrl}
              alt={badge?.name || ''}
            />
          ) : (
            <AchievementBadgePlaceholder key={i} size={4} />
          );
        })}
      </Horizontal>
      <Button color="quaternary" size="medium">
        <LabelL bold>
          <FormattedMessage {...navLabels.myClub} />
        </LabelL>
      </Button>
    </Root>
  );
};
