import { NotIOsAppFeature } from 'components/BuyableFeature';
import { useConfigContext } from 'contexts/config';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { Balances } from 'routing/MultiSportAppBar/Balances';

import { EnergyBalance } from '../EnergyBalance';
import { InventoryBalance } from '../InventoryBalance';
import { XpBalances } from '../XpBalance';

interface Props {
  compact?: boolean;
  onClick?: () => void;
}

export const CoinsAndBalances = ({ compact, onClick }: Props) => {
  const {
    flags: { useMlbOffseason = false },
  } = useFeatureFlags();
  const {
    so5: { cardXpLevelUpable },
  } = useConfigContext();
  return (
    <>
      {!compact && (
        <>
          {!useMlbOffseason && <EnergyBalance />}
          {cardXpLevelUpable && <XpBalances />}
          <InventoryBalance onClick={onClick} />
        </>
      )}
      <NotIOsAppFeature>
        <Balances compact={compact} onClick={onClick} />
      </NotIOsAppFeature>
    </>
  );
};
