import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import {
  faExclamationCircle,
  faLock,
  faWallet,
} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { IconButton } from 'atoms/buttons/IconButton';
import { FontAwesomeIcon } from 'atoms/icons';
import { ConditionalWrapper } from 'atoms/layout/ConditionalWrapper';
import { BodyM } from 'atoms/typography';
import { Badge } from 'atoms/ui/Badge';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useIntlContext } from 'contexts/intl';
import { useWalletDrawerContext } from 'contexts/walletDrawer';
import { useIsTabletAndAbove } from 'hooks/device/useIsTabletAndAbove';
import { useWalletNeedsRecover } from 'hooks/recovery/useWalletNeedsRecover';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';
import { useOAuthEthWallet } from 'hooks/wallets/useOAuthEthWallet';
import { useShowKycRenewalFlow } from 'hooks/wallets/useShowKycRenewalFlow';
import { minAmountToDeposit } from 'lib/ethereum';
import { RoundingMode } from 'lib/wei';

type Props = {
  compact?: boolean;
  onClick?: () => void;
};

type WalletButtonProps = {
  currentUser: NonNullable<
    ReturnType<typeof useCurrentUserContext>['currentUser']
  >;
  withWarnings?: boolean;
} & Props;

const StyledBadge = styled(Badge)`
  > span {
    /** overlap="circular" only work for icon buttons */
    transform: translate(25%, -25%);
  }
`;

const MobileWalletButton = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &.withWarnings {
    padding: var(--unit) var(--unit) 0 0;
  }
`;

const MobileWalletRow = styled.p`
  display: flex;
  align-items: center;
  font: var(--t-bold) var(--t-label-m);
  &.compact {
    font: var(--t-bold) var(--t-label-s);
  }
`;

const WalletButtons = ({
  currentUser,
  compact,
  onClick,
  withWarnings,
}: WalletButtonProps) => {
  const { formatNumber, formatWei } = useIntlContext();
  const showKycRenewalFlow = useShowKycRenewalFlow();
  const { availableBalance, sorareAddressBalance } = currentUser;
  const {
    walletPreferences: { showFiatWallet, showEthWallet },
  } = useCurrentUserContext();
  const isTabletAndAbove = useIsTabletAndAbove();
  const {
    canListAndTrade,
    availableBalance: availableFiatBalance,
    fiatCurrency,
  } = useFiatBalance();
  const { toggleDrawer } = useWalletDrawerContext();

  const openWallet = useCallback(() => {
    toggleDrawer();
    onClick?.();
  }, [onClick, toggleDrawer]);

  const emptyEthWallet = availableBalance === 0n;
  const emptyFiatWallet = !canListAndTrade || availableFiatBalance === 0;

  const showBalances =
    (showEthWallet && !emptyEthWallet) || (showFiatWallet && !emptyFiatWallet);

  if (!showBalances || currentUser.userSettings?.hideBalance) {
    return (
      <IconButton icon={faWallet} onClick={openWallet} color="transparent" />
    );
  }

  const renderWithLockedWalletBagde = (children: ReactNode) => (
    <ConditionalWrapper
      wrap={!!currentUser?.mangopayUser?.blocked || showKycRenewalFlow}
      Wrapper={StyledBadge}
      props={{ badgeContent: <FontAwesomeIcon icon={faLock} /> }}
    >
      {children}
    </ConditionalWrapper>
  );

  const renderWithBadge = (children: ReactNode) => (
    <ConditionalWrapper
      wrap={sorareAddressBalance > minAmountToDeposit}
      Wrapper={Badge}
      props={{ variant: 'dot' }}
    >
      {children}
    </ConditionalWrapper>
  );

  if (compact) {
    return renderWithLockedWalletBagde(
      <Button onClick={openWallet} color="transparent" size="medium">
        {renderWithBadge(<FontAwesomeIcon icon={faWallet} />)}
      </Button>
    );
  }

  if (isTabletAndAbove) {
    return (
      <>
        {showFiatWallet &&
          renderWithLockedWalletBagde(
            <Button onClick={openWallet} color="transparent" size="medium">
              <BodyM bold>
                {formatNumber(availableFiatBalance, {
                  style: 'currency',
                  currency: fiatCurrency,
                })}
              </BodyM>
            </Button>
          )}
        {showEthWallet &&
          renderWithBadge(
            <Button onClick={openWallet} color="transparent" size="medium">
              <BodyM bold>
                <FontAwesomeIcon icon={faEthereum} />{' '}
                {formatWei(
                  availableBalance,
                  RoundingMode.ROUND_DOWN,
                  { minimumFractionDigits: 3 },
                  { hideSymbol: showEthWallet && showFiatWallet }
                )}
              </BodyM>
            </Button>
          )}
      </>
    );
  }

  const hasTwoRows = showFiatWallet && showEthWallet;
  return (
    <MobileWalletButton
      onClick={openWallet}
      className={classNames({
        withWarnings,
      })}
    >
      {showFiatWallet && (
        <MobileWalletRow
          className={classNames({
            compact: hasTwoRows,
          })}
        >
          {formatNumber(availableFiatBalance, {
            style: 'currency',
            currency: fiatCurrency,
          })}
        </MobileWalletRow>
      )}
      {showEthWallet && (
        <MobileWalletRow
          className={classNames({
            compact: hasTwoRows,
          })}
        >
          <FontAwesomeIcon icon={faEthereum} />{' '}
          {formatWei(
            availableBalance,
            RoundingMode.ROUND_DOWN,
            { minimumFractionDigits: 3 },
            { hideSymbol: showEthWallet && showFiatWallet }
          )}
        </MobileWalletRow>
      )}
    </MobileWalletButton>
  );
};

export const Balances = ({ compact, onClick }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const walletNeedsRecover = useWalletNeedsRecover();
  const { needsCreateEthWallet } = useOAuthEthWallet();

  if (!currentUser) {
    return null;
  }

  if (
    needsCreateEthWallet ||
    walletNeedsRecover ||
    !currentUser.confirmedDevice
  )
    return (
      <StyledBadge
        badgeContent={<FontAwesomeIcon icon={faExclamationCircle} />}
      >
        <WalletButtons
          currentUser={currentUser}
          compact={compact}
          onClick={onClick}
          withWarnings
        />
      </StyledBadge>
    );

  return (
    <WalletButtons
      currentUser={currentUser}
      compact={compact}
      onClick={onClick}
    />
  );
};
