import { CSSProperties, useId } from 'react';
import styled, { keyframes } from 'styled-components';

const dash = keyframes`
  0% {
    stroke-dashoffset: var(--dashoffset);
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: var(--dashoffsetEnd);
  }
  100% {
    stroke-dashoffset: var(--dashoffset);
  }
`;
const Svg = styled.svg`
  position: absolute;
  inset: 0;
  path {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: var(--dashoffset);
    filter: var(--filter);
    animation: ${dash} 1000ms cubic-bezier(0.35, -0.01, 0.3, 0.99) forwards;
  }
`;
const refCallback = (ref: any) => {
  if (typeof ref?.getTotalLength === 'function') {
    const length = ref?.getTotalLength();
    ref?.style.setProperty('--dashoffset', length);
    ref?.style.setProperty('--dashoffsetEnd', length * 2);
  }
};

type Props = {
  fieldUrl: string;
  completion: number;
  paths?: string[];
  offsetY?: number;
};
export const Stadium = ({
  paths,
  offsetY = 0,
  fieldUrl,
  completion = 1,
}: Props) => {
  const filterId = useId();

  return (
    <Svg
      width="100%"
      height="100%"
      viewBox={`0 ${offsetY} 1467 1954`}
      fill="none"
      preserveAspectRatio="xMidYMid slice"
      style={{ '--filter': `url(#${filterId})` } as CSSProperties}
    >
      <defs>
        <filter id={filterId}>
          <feGaussianBlur in="SourceGraphic" stdDeviation="9" result="glow" />
          <feComposite in="SourceGraphic" in2="glow" operator="over" />
        </filter>
      </defs>
      <image
        opacity="1"
        href={fieldUrl!}
        width="100%"
        height="100%"
        style={{
          /** Shouldn't be a default value because the prop is used as a key */
          filter: `grayscale(${100 - completion * 100}%)`,
          opacity: completion,
          transition: '.3s linear opacity 0.3s',
        }}
      />
      {/* Necessary key on g to start the animation on completion change */}
      {paths && (
        <g key={completion}>
          {paths.map(path =>
            [
              { w: 128, o: 0.005 },
              { w: 2, o: 1 },
            ].map(({ w, o }) => (
              <path
                key={w}
                ref={refCallback}
                d={path}
                stroke="var(--c-nd-400)"
                strokeWidth={w}
                strokeOpacity={o}
              />
            ))
          )}
        </g>
      )}
    </Svg>
  );
};
