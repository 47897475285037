import {
  faArrowDownLeft,
  faArrowUpRight,
  faCartShopping,
  faGavel,
  faList,
  faSignOut,
} from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { Dropdown } from '@sorare/core/src/atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { StarBall } from '@sorare/core/src/atoms/icons/StarBall';
import { SBHorizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { CloseButton } from '@sorare/core/src/components/dialog/CloseButton';
import {
  ANY_SPORT_MY_CLUB,
  MY_SORARE_AUCTIONS,
  MY_SORARE_INSTANT_BUYS,
  MY_SORARE_OFFERS_RECEIVED,
  MY_SORARE_OFFERS_SENT,
  MY_SORARE_SALES,
} from '@sorare/core/src/constants/__generated__/routes';
import { MY_SORARE_HOME } from '@sorare/core/src/constants/routes';
import { useWalletContext } from '@sorare/core/src/contexts/wallet';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import { glossary, navLabels } from '@sorare/core/src/lib/glossary';
import { generateSportPath } from '@sorare/core/src/lib/routing/generateSportPath';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';
import { regularMaterial } from '@sorare/core/src/style/utils';

import { MoreNavItems } from 'components/navigation/NewAppNavigation/NewNavItems/MoreNavItems';
import { Footer } from 'components/navigation/NewAppNavigation/NewNavItems/MoreNavItems/Footer';
import { SportSelector } from 'components/navigation/NewAppNavigation/SportSelector';
import { MarketCredits } from 'components/navigation/NewAppNavigation/UserCurrencies/MarketCredits';
import { MoneyBalances } from 'components/navigation/NewAppNavigation/UserCurrencies/MoneyBalances';
import { NewNavItem, Separator } from 'components/navigation/NewNavItem';

import { MyClubNavItem } from './MyClubNavItem';
import { ProfileButtonContent } from './ProfileButtonContent';

const DropdownContent = styled(Vertical).attrs({ gap: 2 })`
  top: 0;
  padding: var(--double-unit);
  ${regularMaterial}

  @media ${laptopAndAbove} {
    width: 375px;
  }
`;

type Props = { sport: Sport };
export const UserNav = ({ sport }: Props) => {
  const { formatMessage } = useIntl();
  const isLaptopAndAbove = useIsLaptop();
  const walletContext = useWalletContext();

  return (
    <Dropdown
      transparent
      forceDesktopBehavior={isLaptopAndAbove}
      label={({ onClick, open }) => (
        <button type="button" onClick={onClick}>
          <ProfileButtonContent sport={sport} active={open} />
        </button>
      )}
      side="bottom"
      gap={16}
    >
      {({ closeDropdown }) => (
        <DropdownContent>
          {!isLaptopAndAbove && (
            <SBHorizontal>
              <SportSelector displaySelectedSportName />
              <CloseButton onClose={closeDropdown} />
            </SBHorizontal>
          )}
          <MyClubNavItem
            to={generateSportPath(ANY_SPORT_MY_CLUB, { sport })}
            onClick={closeDropdown}
          />
          {!isLaptopAndAbove && (
            <>
              <MoneyBalances />
              <MarketCredits sport={sport} />
            </>
          )}
          <Vertical>
            <NewNavItem
              useClassicLink
              to={MY_SORARE_HOME}
              icon={() => <StarBall width={16} height={16} />}
              label={formatMessage(navLabels.mySorare)}
              onClick={closeDropdown}
            />
            <NewNavItem
              useClassicLink
              to={MY_SORARE_AUCTIONS}
              icon={() => <FontAwesomeIcon icon={faGavel} />}
              label={formatMessage(navLabels.myAuctions)}
              onClick={closeDropdown}
            />
            <NewNavItem
              useClassicLink
              to={MY_SORARE_INSTANT_BUYS}
              icon={() => <FontAwesomeIcon icon={faCartShopping} />}
              label={formatMessage(navLabels.myInstantBuys)}
              onClick={closeDropdown}
            />
            <NewNavItem
              useClassicLink
              to={MY_SORARE_SALES}
              icon={() => <FontAwesomeIcon icon={faList} />}
              label={formatMessage(navLabels.myListings)}
              onClick={closeDropdown}
            />
            <NewNavItem
              useClassicLink
              to={MY_SORARE_OFFERS_RECEIVED}
              icon={() => <FontAwesomeIcon icon={faArrowDownLeft} />}
              label={formatMessage(navLabels.myOffersReceived)}
              onClick={closeDropdown}
            />
            <NewNavItem
              useClassicLink
              to={MY_SORARE_OFFERS_SENT}
              icon={() => <FontAwesomeIcon icon={faArrowUpRight} />}
              label={formatMessage(navLabels.myOffersSent)}
              onClick={closeDropdown}
            />
            {!isLaptopAndAbove && (
              <>
                <Separator />
                <MoreNavItems noFooter />
              </>
            )}
            <Separator />
            <NewNavItem
              useClassicLink
              to={MY_SORARE_OFFERS_SENT}
              icon={() => <FontAwesomeIcon icon={faSignOut} />}
              label={formatMessage(glossary.logOut)}
              onClick={() => {
                walletContext.logOut();
                closeDropdown();
              }}
            />
            {!isLaptopAndAbove && (
              <>
                <Separator />
                <Footer />
              </>
            )}
          </Vertical>
        </DropdownContent>
      )}
    </Dropdown>
  );
};
