import styled from 'styled-components';

import { Verified } from 'atoms/icons/Verified';

const Root = styled.span`
  color: var(--current-color);
  font: var(--t-12);
  font-weight: var(--t-bold);

  & svg {
    vertical-align: text-bottom;
    margin-right: var(--half-unit);
  }
`;

export const SorareUser = () => {
  return (
    <Root>
      <Verified />
      Sorare
    </Root>
  );
};
