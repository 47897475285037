import { User } from '@sentry/react';
import { createContext, useContext } from 'react';

interface SentryContext {
  sendSafeError: (error: unknown) => void;
  identifyUser: (user: User | null) => void;
}

export const SentryContext = createContext<SentryContext | null>(null);

export const useSentryContext = () => useContext(SentryContext)!;
