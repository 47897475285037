import styled from 'styled-components';

import { CardCollectionScoringMatrix } from '__generated__/globalTypes';
import { BodyS, LabelS } from 'atoms/typography';
import { Score } from 'components/collections/Score';

import { Image } from './Image';

const Item = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'img label score'
    'img explanation score';
  padding: var(--unit);
  justify-content: start;
  align-items: center;
  column-gap: var(--double-unit);
  &:not(:last-child) {
    border-bottom: 1px solid var(--c-nd-200);
  }
`;
const StyledText14 = styled(BodyS)`
  grid-area: label;
`;
const StyledCaption = styled(LabelS)`
  grid-area: explanation;
`;
const ScoreWrapper = styled.div`
  grid-area: score;
  margin-left: auto;
`;

type Props = {
  detailedScore: CardCollectionScoringMatrix;
};

export const DetailedScoreLine = ({ detailedScore }: Props) => {
  return (
    <Item>
      <Image img={detailedScore.pictureUrl} />
      <StyledText14 bold>
        <span>{detailedScore.label}</span>
      </StyledText14>
      <StyledCaption color="var(--c-nd-700)" as="div">
        {detailedScore.explanation}
      </StyledCaption>
      <ScoreWrapper>
        <Score score={detailedScore.value} />
      </ScoreWrapper>
    </Item>
  );
};
