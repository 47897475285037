import { Navigate } from 'react-router-dom';

import {
  COVERAGE,
  LICENSED_PARTNERS,
} from '@sorare/core/src/constants/__generated__/routes';
import { useQueryString } from '@sorare/core/src/hooks/useQueryString';

const FAQ = () => {
  const selection = useQueryString('selection');
  return (
    <Navigate
      replace
      to={selection === 'Coverage' ? COVERAGE : LICENSED_PARTNERS}
    />
  );
};

export default FAQ;
