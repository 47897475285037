import { Navigate } from 'react-router-dom';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import {
  FOOTBALL_MARKET_COMMON_PACKS,
  FOOTBALL_MARKET_INSTANT_BUY,
} from '@sorare/core/src/constants/__generated__/routes';
import { useSimplifiedNav } from '@sorare/core/src/hooks/commonGame/useSimplifiedNav';

export const Market = () => {
  const { showSimplifiedNav } = useSimplifiedNav(Sport.FOOTBALL);

  if (showSimplifiedNav) {
    return <Navigate to={FOOTBALL_MARKET_COMMON_PACKS} replace />;
  }

  return <Navigate to={FOOTBALL_MARKET_INSTANT_BUY} replace />;
};

export default Market;
