import { SVGProps } from 'react';

interface SVGRProps {
  title?: string;
  titleId?: string;
}

export const MarketCredit = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle cx="6" cy="6" r="6" fill="url(#paint0_linear_6041_46019)" />
    <g style={{ mixBlendMode: 'overlay' }} opacity="0.3">
      <circle
        cx="6.0343"
        cy="6.04876"
        r="4.87805"
        fill="url(#paint1_linear_6041_46019)"
      />
    </g>
    <ellipse
      cx="6.03439"
      cy="6.0488"
      rx="4.78049"
      ry="4.78049"
      fill="url(#paint2_linear_6041_46019)"
    />
    <circle
      cx="6.03449"
      cy="6.04877"
      r="4.68293"
      fill="url(#paint3_linear_6041_46019)"
    />
    <ellipse
      cx="6.03448"
      cy="6.04876"
      rx="4.19512"
      ry="4.19512"
      fill="url(#paint4_linear_6041_46019)"
    />
    <circle
      cx="6.03418"
      cy="6.04877"
      r="4"
      fill="url(#paint5_linear_6041_46019)"
    />
    <mask
      id="mask0_6041_46019"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="6"
      height="6"
    >
      <rect
        x="7.65381"
        y="3.86835"
        width="0.780488"
        height="5.46341"
        transform="rotate(45 7.65381 3.86835)"
        fill="url(#paint6_linear_6041_46019)"
      />
      <ellipse
        cx="4.52223"
        cy="4.60005"
        rx="0.731707"
        ry="0.731707"
        fill="url(#paint7_linear_6041_46019)"
      />
      <ellipse
        cx="7.4197"
        cy="7.49745"
        rx="0.731707"
        ry="0.731708"
        fill="url(#paint8_linear_6041_46019)"
      />
    </mask>
    <g mask="url(#mask0_6041_46019)">
      <circle
        cx="6.03436"
        cy="6.04876"
        r="3.31707"
        fill="url(#paint9_linear_6041_46019)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_6041_46019"
        x1="2.37576"
        y1="2.37576"
        x2="10"
        y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6D09" />
        <stop offset="0.25" stopColor="#FEC034" />
        <stop offset="0.5" stopColor="#DFFEEF" />
        <stop offset="0.75" stopColor="#FEC034" />
        <stop offset="1" stopColor="#FF6D09" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6041_46019"
        x1="9.61817"
        y1="2.46489"
        x2="2.45043"
        y2="9.63264"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.48" stopColor="#FF6A18" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_6041_46019"
        x1="6.03439"
        y1="1.26831"
        x2="6.03439"
        y2="10.8293"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#96400C" />
        <stop offset="0.5" stopColor="#FC6E16" />
        <stop offset="1" stopColor="#96400C" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_6041_46019"
        x1="0.180831"
        y1="6.04877"
        x2="11.8394"
        y2="6.04877"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7F360A" />
        <stop offset="1" stopColor="#FB6B14" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_6041_46019"
        x1="1.83936"
        y1="6.04876"
        x2="10.2296"
        y2="6.04876"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8E3D0C" />
        <stop offset="0.5" stopColor="#F9991D" />
        <stop offset="1" stopColor="#F36D14" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_6041_46019"
        x1="3.00979"
        y1="9.07316"
        x2="9.05857"
        y2="3.02438"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF6309" />
        <stop offset="1" stopColor="#FEF314" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_6041_46019"
        x1="7.65381"
        y1="6.60005"
        x2="8.4343"
        y2="6.60005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#943F0C" />
        <stop offset="1" stopColor="#A8470D" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_6041_46019"
        x1="3.79053"
        y1="4.60005"
        x2="5.25394"
        y2="4.60005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#943F0C" />
        <stop offset="1" stopColor="#A8470D" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_6041_46019"
        x1="6.68799"
        y1="7.49745"
        x2="8.1514"
        y2="7.49745"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#943F0C" />
        <stop offset="1" stopColor="#A8470D" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_6041_46019"
        x1="3.74672"
        y1="3.76113"
        x2="8.43638"
        y2="8.45078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#943F0C" />
        <stop offset="1" stopColor="#EA6312" />
      </linearGradient>
    </defs>
  </svg>
);
