import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { currentSportUnreadNotificationsCountFragment } from 'contexts/currentUser/queries';
import { useSportContext } from 'contexts/sport';
import { idFromObject } from 'gql/idFromObject';
import { useMutation } from 'hooks/graphql/useMutation';
import { allGroups } from 'lib/notifications';

import {
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables,
} from './__generated__/useMarkNotificationsAsRead.graphql';
import { useGetNotificationCategories } from './useGetNotificationCategories';

const MARK_NOTIFICATIONS_AS_READ_MUTATION = gql`
  mutation MarkNotificationsAsReadMutation(
    $input: markNotificationsAsReadInput!
    $sport: Sport!
    $notificationCategories: [NotificationCategoryInput!]
  ) {
    markNotificationsAsRead(input: $input) {
      currentUser {
        slug
        ...CurrentUserProvider_currentSportUnreadNotificationsCount
      }
      notifications {
        id
        read
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${currentSportUnreadNotificationsCountFragment}
` as TypedDocumentNode<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>;

export const useMarkNotificationsAsRead = () => {
  const [markNotificationsAsReadMutation] = useMutation(
    MARK_NOTIFICATIONS_AS_READ_MUTATION
  );
  const getCategories = useGetNotificationCategories();
  const { sport } = useSportContext();

  const markNotificationsAsRead = useCallback(
    async (notifications?: { id: string }[]) => {
      const notificationIds = notifications
        ?.map(({ id }) => idFromObject(id))
        .filter(Boolean);

      await markNotificationsAsReadMutation({
        variables: {
          input: {
            notificationIds,
          },
          sport,
          notificationCategories: getCategories(allGroups),
        },
      });
    },
    [markNotificationsAsReadMutation, getCategories, sport]
  );
  return markNotificationsAsRead;
};
