import { ReactNode, useCallback, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

import { Sport } from '__generated__/globalTypes';
import { FRONTEND_ASSET_HOST } from 'constants/assets';
import { useIsMlbPage } from 'hooks/useIsMlbPage';
import { useIsNBAPage } from 'hooks/useIsNBAPage';
import { useSharedAcrossSportsPage as useSharedAccrossSportsPage } from 'hooks/useSharedAccrossSportsPage';
import { metadatas as baseballMetadatas } from 'lib/seo/baseball';
import { metadatas as commonMetadatas } from 'lib/seo/common';
import { metadatas as footballMetadatas } from 'lib/seo/football';
import { metadatas as nbaMetadatas } from 'lib/seo/nba';
import { ImageVariations } from 'lib/share';

import { Options, SeoContext } from '.';

interface Props {
  children: ReactNode;
}

const sportTitles = {
  [Sport.BASEBALL]: baseballMetadatas.default.title,
  [Sport.NBA]: nbaMetadatas.default.title,
  [Sport.FOOTBALL]: footballMetadatas.default.title,
};

const sportDescriptions = {
  [Sport.BASEBALL]: baseballMetadatas.default.description,
  [Sport.NBA]: nbaMetadatas.default.description,
  [Sport.FOOTBALL]: footballMetadatas.default.description,
};

const defaultImage = `${FRONTEND_ASSET_HOST}/meta/social-picture-2022.png`;
const defaultTwitter = '@sorare';

export const SeoProvider = ({ children }: Props) => {
  const { formatMessage } = useIntl();
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [img, setImg] = useState<
    string | Record<ImageVariations, string | null> | undefined | null
  >(undefined);
  const [twitter, setTwitter] = useState<string | undefined>(undefined);
  const sharedAcrossSportsPage = useSharedAccrossSportsPage();

  const setPageMetadata = useCallback(
    (componentTitle: string, options?: Options) => {
      const titleWithNamespace =
        componentTitle.indexOf('•') >= 0 ||
        componentTitle.indexOf('- Sorare') >= 0 ||
        componentTitle.indexOf('– Sorare') >= 0 ||
        componentTitle.indexOf('Sorare') === 0
          ? componentTitle
          : `${componentTitle} • Sorare`;

      const seoTitle = options?.replaceFullTitle
        ? componentTitle
        : titleWithNamespace;

      setTitle(seoTitle);
      setDescription(options?.description);
      setImg(options?.img);
      setTwitter(options?.twitter);
      return () => {
        setTitle(undefined);
        setImg(undefined);
        setDescription(undefined);
        setTwitter(undefined);
      };
    },
    []
  );

  const isMlbPage = useIsMlbPage();
  const isNBAPage = useIsNBAPage();

  let currentSport = Sport.FOOTBALL;
  if (isMlbPage) currentSport = Sport.BASEBALL;
  if (isNBAPage) currentSport = Sport.NBA;

  const defaultTitle = commonMetadatas.default.title;
  const defaultSportTitle = sportTitles[currentSport];
  const pageTitle =
    title ||
    (sharedAcrossSportsPage
      ? formatMessage(defaultTitle)
      : formatMessage(defaultSportTitle));

  const defaultDescription = commonMetadatas.default.description;
  const defaultSportDescription = sportDescriptions[currentSport];
  const pageDescription =
    description ||
    (sharedAcrossSportsPage
      ? formatMessage(defaultDescription)
      : formatMessage(defaultSportDescription));

  const value = useMemo(
    () => ({
      setPageMetadata,
    }),
    [setPageMetadata]
  );

  return (
    <SeoContext.Provider value={value}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="twitter:site" content={twitter || defaultTwitter} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* eslint-disable-next-line no-restricted-properties */}
        <meta property="og:url" content={window.location.href} />
        {img && typeof img === 'string' && (
          <meta property="og:image" content={img || defaultImage} />
        )}
        {img && typeof img === 'object' && (
          <meta
            property="og:image"
            content={img.post || img.square || img.story || defaultImage}
          />
        )}
      </Helmet>
      {children}
    </SeoContext.Provider>
  );
};
