import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import gift from 'assets/icons/gift.svg';
import trophy from 'assets/icons/trophy.svg';
import { Energy } from 'atoms/icons/Energy';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Skeleton } from 'atoms/loader/Skeleton';
import { BodyM, HeadlineS, LabelS } from 'atoms/typography';
import { Bold } from 'atoms/typography/Bold';
import { useSportContext } from 'contexts/sport';
import { useQuery } from 'hooks/graphql/useQuery';
import { glossary } from 'lib/glossary';

import type {
  EnergyOnboardingInfoModalQuery,
  EnergyOnboardingInfoModalQueryVariables,
} from './__generated__/index.graphql';

const Pils = styled.div`
  width: fit-content;
  border-radius: var(--double-unit);
  padding: var(--half-unit) var(--unit);
  background: linear-gradient(319deg, #1510ff 16.49%, #00fff7 82.68%);
`;

const ImageWrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  padding: var(--unit);
  border-radius: 50%;
  border: 1px solid var(--c-nd-150);
`;

const query = gql`
  query EnergyOnboardingInfoModalQuery($sport: Sport!) {
    config {
      id
      so5 {
        id
        dailyCost: energyCostPerAppearance(eventType: DAILY, sport: $sport)
        classicCost: energyCostPerAppearance(eventType: CLASSIC, sport: $sport)
      }
    }
  }
` as TypedDocumentNode<
  EnergyOnboardingInfoModalQuery,
  EnergyOnboardingInfoModalQueryVariables
>;

const messages = defineMessages({
  title: {
    id: 'EnergyOnboardingInfoModal.title',
    defaultMessage:
      'Energize your Classic Cards and play them in this competition!',
  },
  cost: {
    id: 'EnergyOnboardingInfoModal.cost',
    defaultMessage:
      'Costs <b>{dailyCost} Energy</b> in Daily & <b>{biweeklyCost}</b> in Biweekly',
  },
  entry: {
    id: 'EnergyOnboardingInfoModal.entry',
    defaultMessage: 'Energized for <b>1 competition entry</b>',
  },
  win: {
    id: 'EnergyOnboardingInfoModal.win',
    defaultMessage: 'Win Energy by playing <b>Challenger</b>',
  },
});

type Props = {
  hideTitle?: boolean;
};

export const EnergyOnboardingInfoModal = ({ hideTitle }: Props) => {
  const { sport } = useSportContext();
  const { data, loading } = useQuery(query, {
    variables: { sport },
  });

  return (
    <Vertical>
      {!hideTitle && (
        <>
          <Pils>
            <LabelS bold uppercase>
              <FormattedMessage {...glossary.new} />
            </LabelS>
          </Pils>
          <HeadlineS>
            <FormattedMessage {...messages.title} />
          </HeadlineS>
        </>
      )}
      <Vertical gap={1.5}>
        <Horizontal>
          <ImageWrapper>
            <Energy size={2} />
          </ImageWrapper>
          <BodyM color="var(--c-nd-600)">
            <Skeleton loading={loading}>
              <FormattedMessage
                {...messages.cost}
                values={{
                  dailyCost: data?.config?.so5?.dailyCost,
                  biweeklyCost: data?.config?.so5?.classicCost,
                  b: Bold,
                }}
              />
            </Skeleton>
          </BodyM>
        </Horizontal>
        <Horizontal>
          <ImageWrapper>
            <img height={16} width={16} src={trophy} alt="" />
          </ImageWrapper>
          <BodyM color="var(--c-nd-600)">
            <FormattedMessage {...messages.entry} values={{ b: Bold }} />
          </BodyM>
        </Horizontal>
        <Horizontal>
          <ImageWrapper>
            <img height={16} width={16} src={gift} alt="" />
          </ImageWrapper>
          <BodyM color="var(--c-nd-600)">
            <FormattedMessage {...messages.win} values={{ b: Bold }} />
          </BodyM>
        </Horizontal>
      </Vertical>
    </Vertical>
  );
};
