import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { StarBall } from '@sorare/core/src/atoms/icons/StarBall';
import { FullWidthContainer } from '@sorare/core/src/atoms/layout/FullWidthContainer';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { HeadlineXL, LabelL } from '@sorare/core/src/atoms/typography';
import { OnboardingButton } from '@sorare/core/src/components/onboarding/OnboardingButton';
import { FOOTBALL_PLAY_PRO_ONBOARDING_CHOOSE_YOUR_LEVEL } from '@sorare/core/src/constants/__generated__/routes';
import { useIsTabletAndAbove } from '@sorare/core/src/hooks/device/useIsTabletAndAbove';
import { glossary } from '@sorare/core/src/lib/glossary';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

import playersAvif from './assets/players.avif';
import players from './assets/players.png';

const StyledContainer = styled(FullWidthContainer)`
  background: linear-gradient(to bottom, #2c2b70 0%, rgba(44, 43, 112, 0) 100%);
`;

const Wrapper = styled(Vertical)`
  height: var(--100vh);
  align-items: center;
  padding: var(--quadruple-unit) 0;
  @media ${tabletAndAbove} {
    padding: var(--quadruple-unit) 0;
    gap: calc(6 * (var(--unit)));
  }
`;
const ImgContent = styled(FullWidthContainer.NoPadding)`
  flex: 1;
  position: relative;
  min-width: calc(100% + 2 * var(--container-padding));

  @media ${tabletAndAbove} {
    min-width: 100%;
  }
`;
const Picture = styled.picture`
  position: absolute;
  inset: 0;
  overflow: hidden;
`;
const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;

  transform: scale(1.2);
  @media ${tabletAndAbove} {
    width: 100%;
    transform: none;
  }
`;

const WelcomePage = () => {
  const isTabletAndAbove = useIsTabletAndAbove();
  return (
    <StyledContainer>
      <Wrapper>
        <Vertical center>
          <StarBall />
          <HeadlineXL brand25>
            <FormattedMessage
              id="WelcomePage.title"
              defaultMessage="Welcome to Sorare"
            />
          </HeadlineXL>
          <LabelL color="var(--c-nd-700)">
            <FormattedMessage
              id="WelcomePage.subtitle"
              defaultMessage="Are you ready to build your club?"
            />
          </LabelL>
        </Vertical>
        <ImgContent>
          <Picture>
            <source srcSet={playersAvif} type="image/avif" />
            <Img src={players} alt="" />
          </Picture>
        </ImgContent>
        <OnboardingButton
          color="secondary"
          size="large"
          fullWidth={!isTabletAndAbove}
          to={FOOTBALL_PLAY_PRO_ONBOARDING_CHOOSE_YOUR_LEVEL}
        >
          <FormattedMessage {...glossary.letsGo} />
        </OnboardingButton>
      </Wrapper>
    </StyledContainer>
  );
};
export default WelcomePage;
