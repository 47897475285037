import { PropsWithChildren, useRef } from 'react';
import { Navigate, useLocation, useNavigation } from 'react-router-dom';

import { ORIGIN } from 'config';
import { useDefaultSportPages } from 'constants/routes';
import { useSportContext } from 'contexts/sport';
import { useAfterLoggedInTarget } from 'hooks/useAfterLoggedInTarget';
import { useRedirectUrl } from 'hooks/useRedirectUrl';
import { stripPrefix } from 'i18n/useTranslations';

type Props = {
  currentUser?: {
    userSettings: { [key: string]: any };
    fromPath?: string | null;
  } | null;
};

/** This hook is meant to be used is rare occasions where we need to manually redirect even the user is already logged in (like confirmEmail) */
export const useRedirectToAfterSignIn = () => {
  const { sport } = useSportContext();
  const location = useLocation();
  const redirectUrl = useRedirectUrl();
  const afterLoggedInTarget = useAfterLoggedInTarget();
  const defaultSportPages = useDefaultSportPages(true);

  return (currentUser: Props['currentUser']) => {
    const defaultTo = defaultSportPages[sport];
    const to =
      redirectUrl ||
      currentUser?.fromPath ||
      afterLoggedInTarget ||
      location.pathname;

    // make sure we don't redirect to an external URL as it would be a security issue.
    const { pathname, search } = new URL(to, ORIGIN);
    return stripPrefix(`${pathname.replace(/^\/$/, '') || defaultTo}${search}`);
  };
};

export const RedirectAfterSignIn = ({
  currentUser,
  children,
}: PropsWithChildren<Props>) => {
  const location = useLocation();
  const navigation = useNavigation();
  const isNavigating = !!navigation.location;
  /** We need to keep track of the redirection state between renders to avoid rendering children in case they have conflicting redirections. */
  const shouldRedirectState = useRef<'none' | 'redirect' | 'redirecting'>(
    currentUser ? 'none' : 'redirect'
  );
  const redirectToAfterSignIn = useRedirectToAfterSignIn()(currentUser);

  if (shouldRedirectState.current === 'redirecting') {
    if (isNavigating) {
      return null;
    }
    if (
      stripPrefix(
        `${location.pathname.replace(/^\/$/, '')}${location.search}`
      ) === redirectToAfterSignIn
    ) {
      shouldRedirectState.current = 'none';
    }
  }

  if (shouldRedirectState.current === 'redirect' && currentUser) {
    shouldRedirectState.current = 'redirecting';

    return <Navigate to={redirectToAfterSignIn} replace />;
  }
  return children;
};
