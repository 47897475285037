import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { SmallContainer } from '@sorare/core/src/atoms/layout/Container';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useQuery } from '@sorare/core/src/hooks/graphql/useQuery';
import { useDialogParam } from '@sorare/core/src/hooks/navigation/useDialogParam';

import { Announcement } from '../Announcement';
import {
  GetSpecificNewsQuery,
  GetSpecificNewsQueryVariables,
} from './__generated__/index.graphql';

const Root = styled.div`
  padding-bottom: var(--double-unit);
`;

const GET_SPECIFIC_NEWS_QUERY = gql`
  query GetSpecificNewsQuery($cursor: String, $announcementIds: [String!]) {
    announcements(
      after: $cursor
      announcementIds: $announcementIds
      first: 10
    ) {
      nodes {
        id
        ...Announcement_announcement
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${Announcement.fragments.announcement}
` as TypedDocumentNode<GetSpecificNewsQuery, GetSpecificNewsQueryVariables>;

type Props = {
  inDialog?: boolean;
  id: string;
};

const SpecificNewsComponent = ({ inDialog, id }: Props) => {
  const { data, loading } = useQuery(GET_SPECIFIC_NEWS_QUERY, {
    variables: {
      announcementIds: [id!],
    },
    skip: !id,
  });

  if (!data || loading) {
    return (
      <Root>
        <SmallContainer>
          <LoadingIndicator />
        </SmallContainer>
      </Root>
    );
  }
  const announcement = data?.announcements.nodes[0];

  return (
    <Root>
      <SmallContainer>
        {announcement ? (
          <Announcement announcement={announcement} inDialog={!!inDialog} />
        ) : (
          <FormattedMessage
            id="SpecificNews.notFound"
            defaultMessage="This news does not exist."
          />
        )}
      </SmallContainer>
    </Root>
  );
};

export const SpecificNewsDialogContent = ({
  ...otherProps
}: Omit<Props, 'id'>) => {
  const id = useDialogParam(DialogKey.news);
  if (!id) {
    return null;
  }
  return (
    <SpecificNewsComponent id={idFromObject(id)} {...otherProps} inDialog />
  );
};

export const SpecificNews = ({ ...otherProps }: Omit<Props, 'id'>) => {
  const { id } = useParams();
  if (!id) {
    return null;
  }
  return <SpecificNewsComponent id={idFromObject(id)} {...otherProps} />;
};
