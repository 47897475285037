import { Outlet } from 'react-router-dom';

import { RequireAuth } from '@sorare/core/src/routing/RequireAuth';

const Layout = () => {
  return (
    <RequireAuth>
      <Outlet />
    </RequireAuth>
  );
};

export default Layout;
