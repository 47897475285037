import { createContext, useContext } from 'react';
import { defineMessages } from 'react-intl';

import { glossary } from 'lib/glossary';

export const notifications = {
  copiedToClipboard: glossary.copiedToClipboard,
  ...defineMessages({
    bridgeApproval: {
      id: 'BridgeApproval.notification',
      defaultMessage:
        "We're authorizing deposits to the bridge contract. Please wait for this transaction to complete before doing a deposit",
    },
    cancelOffer: {
      id: 'CancelOffer.notification',
      defaultMessage: 'Sale has been canceled',
    },
    confirmWithdrawal: {
      id: 'WithdrawEthBeta',
      defaultMessage:
        'Please confirm your withdrawal through the confirmation email that has been sent to you.',
    },
    ethereumCard: {
      id: 'EthereumCard.notification',
      defaultMessage:
        'Your Card has been deposited. It might take a few minutes to appear in your gallery.',
    },
    ethereumAccountLinked: {
      id: 'AddEthereumAccountForm.notification',
      defaultMessage: 'Your Ethereum account {address} has been linked',
    },
    composeLineup: {
      id: 'ComposeLineup.successNotification',
      defaultMessage: 'Your team has been saved!',
    },
    errors: {
      id: 'Notification.errors',
      defaultMessage: 'Errors: {errors}',
    },
    threeDsError: {
      id: 'Notification.threeDsError',
      defaultMessage: '3D Secure Failed: {message}.',
    },
    baseballDraftMoreThanFiveOnTheSameTeam: {
      id: 'Baseball.Draft.TooManyOnSameTeam.DescriptionLong',
      defaultMessage:
        'You have {nbDrafted} players from the {teamName}. You have to swap out one player to meet the criteria of maximum {max} players per team.',
    },
    baseballDraftAutofillErrorExceedBudget: {
      id: 'Baseball.Draft.AutofillError.ExceedBudget',
      defaultMessage:
        'You do not have enough budget remaining to auto complete a team',
    },
    baseballTeamRegistered: {
      id: 'ComposeBaseballLineup.teamRegistered',
      defaultMessage: 'Team registered successfully',
    },
    lostInternetConnection: {
      id: 'Notification.lostInternetConnection',
      defaultMessage: 'You appear offline, most actions might not work.',
    },
    serviceUnderMaintenance: {
      id: 'Notification.serviceUnderMaintenance',
      defaultMessage:
        'Maintenance operation in progress from {start} to {end}: {msg}',
    },
    passwordForgottenError: {
      id: 'PasswordForgotten.errorNotification',
      defaultMessage: 'An error has occured. Please try again later.',
    },
    passwordForgottenSuccess: {
      id: 'PasswordForgotten.successNotification',
      defaultMessage: 'Please check your email to reset your password.',
    },
    confirmDeviceRequestSent: {
      id: 'Wallet.confirmDeviceRequestSent',
      defaultMessage:
        'Please check your {delivery, select, email {email} phone {phone} other {email or phone}} to confirm the current device.',
    },
    promoApplied: {
      id: 'Wallet.promopplied',
      defaultMessage:
        'Promotion has been successfully applied and will be available after your signup',
    },
    deviceSuccessfullyConfirmed: {
      id: 'Wallet.deviceSuccessfullyConfirmed',
      defaultMessage: 'Current device successfully confirmed.',
    },
    signUp: {
      id: 'Signup.successNotifications',
      defaultMessage:
        'Welcome {nickname}! Please check your emails to confirm your account',
    },
    directOfferCancelled: {
      id: 'DirectOffer.cancelNotification',
      defaultMessage: 'Offer cancelled',
    },
    directOfferAccepted: {
      id: 'DirectOffer.acceptedNotification',
      defaultMessage: 'Offer accepted',
    },
    directOfferRejected: {
      id: 'DirectOffer.rejectedNotification',
      defaultMessage: 'Offer rejected',
    },
    so5FixtureDelete: {
      id: 'So5Fixture.deleteNotification',
      defaultMessage: 'Your team has been deleted!',
    },
    so5LineupsConfirm: {
      id: 'So5Fixture.confirmLineupsNotification',
      defaultMessage: 'Your teams have been confirmed',
    },
    offerSent: {
      id: 'Offer.successNotification',
      defaultMessage: 'Your offer has been sent',
    },
    '2faEnabled': {
      id: 'Update2FA.2faEnabled',
      defaultMessage:
        'Two-factor authentication has been successfully enabled on your account',
    },
    '2faDisabled': {
      id: 'Update2FA.2faDisabled',
      defaultMessage:
        'Two-factor authentication has been successfully disabled on your account',
    },
    unlockWallet: {
      id: 'Wallet.unlockWallet',
      defaultMessage: 'Please unlock your wallet to be able to proceed',
    },
    txSent: {
      id: 'Tx.sent',
      defaultMessage: 'Transaction has been sent successfully',
    },
    phoneNumberVerified: {
      id: 'VerifyPhoneNumber.verified',
      defaultMessage: 'Your phone number has been successfully verified!',
    },
    paymentMethodDetached: {
      id: 'PaymentMethod.detached',
      defaultMessage: 'Your credit card has been removed',
    },
    secondFactorRecommendation: {
      id: 'CurrentUser.secondFactorRecommendation',
      defaultMessage:
        'To ensure the security of your assets on Sorare, we strongly recommend that you <link>enable a second authentication factor</link>.',
    },
    confirmEmail: {
      id: 'CurrentUser.confirmEmail.remainingHours',
      defaultMessage:
        '<strong>You still need to confirm your email.</strong> Check your email to confirm your account {remainingHours, plural, =0 {within the coming minutes} one {within the hour} other {within the next # hours}} to avoid getting locked out.<link>Resend confirmation email</link>',
    },
    unknownReferrer: {
      id: 'OwnYourGame.unknownReferrer',
      defaultMessage:
        "The username <strong>{referrer}</strong> doesn't exist. Please try another referral link.",
    },
    nullSorarePrivateKeyRecovery: {
      id: 'CurrentUser.nullSorarePrivateKeyRecovery',
      defaultMessage:
        'Your wallet is not pending recovery. If you recently changed your password you will receive a new email within 24 hours.',
    },
    mangopayWalletVerificationSumitted: {
      id: 'Notification.mangopayWalletVerificationSumitted',
      defaultMessage:
        'Thank you for submitting your document. You will be notified once the review is processed.',
    },
    termsAccepted: {
      id: 'CurrentUser.termsAccepted',
      defaultMessage: 'You have accepted the terms of service, please sign in',
    },
    logOutAllDevices: {
      id: 'Snack.logOutAllDevices.success',
      defaultMessage:
        'You’ve been successfully logged out from all other devices besides this one',
    },
    accountingExtractSent: {
      id: 'MySorare.TransactionsHistory.accountingExtractSent',
      defaultMessage:
        'You should receive an email with your accounting extract within the coming minutes.',
    },
    recoveryEmailRegistered: {
      id: 'Settings.security.activateRecoveryEmail',
      defaultMessage:
        '{email} has been successfully added to your recovery methods.',
    },
    walletSuccessfullyRecovered: {
      id: 'Snack.walletSuccessfullyRecovered',
      defaultMessage: 'You’ve recovered your wallet.',
    },
    cashWalletNamesUpdatedDefault: {
      id: 'settings.updateName.success.default',
      defaultMessage: 'Success! Your name has been updated.',
    },
    cashWalletNamesUpdatedAskingKycAgain: {
      id: 'settings.updateName.success.couldDepositAndWithdraw',
      defaultMessage:
        'Success! Your name has been updated. Please upload your ID to resume access to cash deposits, withdrawals, and rewards.',
    },
    uploadError: {
      id: 'SnackNotification.uploadError',
      defaultMessage: 'Oops! Something went wrong during the file upload.',
    },
    oauthMissingEmail: {
      id: 'SnackNotification.oauthMissingEmail',
      defaultMessage: 'You cannot create a Sorare account without an email.',
    },
    oauthLockedAccount: {
      id: 'SnackNotification.oauthLockedAccount',
      defaultMessage: 'Your account is suspended, please use another email.',
    },
    reminderSuccessfullyAddedOnAuction: {
      id: 'SnackNotification.reminderSuccessfullyAddedOnAuction',
      defaultMessage:
        'Notifications are on for this auction! Follow it in <myAuctionsLink>My Auctions</myAuctionsLink>.',
    },
    reminderSuccessfullyRemovedFromAuction: {
      id: 'SnackNotification.reminderSuccessfullyRemovedFromAuction',
      defaultMessage: 'Notifications are off for this auction.',
    },
    competitionSubscribed: {
      id: 'SnackNotification.competitionSubscribed',
      defaultMessage: '{displayName} added to Favorite Competitions',
    },
    competitionUnsubscribed: {
      id: 'SnackNotification.competitionUnsubscribed',
      defaultMessage: '{displayName} removed from Favorite Competitions',
    },
    importLinuepsDeclined: {
      id: 'SnackNotification.importLinuepsDeclined',
      defaultMessage:
        'You have already declined this import for your lineups from {provider}',
    },
    importLinuepsExpired: {
      id: 'SnackNotification.importLinuepsExpired',
      defaultMessage: 'This import of your lineups from {provider} has expired',
    },
    commonCardDropped: {
      id: 'SnackNotification.commonCardDropped',
      defaultMessage: 'Card dropped successfully',
    },
  } as const),
};

export enum Level {
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

type Ids = keyof typeof notifications;
export type SnackNotificationOptions = {
  level?: Level;
  link?: string;
  onClosed?: () => void;
  autoHideDuration?: null | number;
};

export type Notification = {
  id: Ids;
  values?: any;
  options?: {
    level?: Level;
    link?: string;
    onClosed?: () => void;
    ignoreDuplicates?: boolean;
  };
};

export interface SnackNotificationContext {
  showNotification: (
    id: Ids,
    values?: any,
    opts?: SnackNotificationOptions
  ) => void;
  clearNotification: () => void;
  notification: Notification | null;
}

export const SnackNotificationContext =
  createContext<SnackNotificationContext | null>(null);

export const useSnackNotificationContext = () =>
  useContext(SnackNotificationContext)!;
