import { TypedDocumentNode, gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Currency } from '@sorare/core/src/__generated__/globalTypes';
import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Skeleton } from '@sorare/core/src/atoms/loader/Skeleton';
import { LinkOverlay } from '@sorare/core/src/atoms/navigation/Box';
import { LabelL, LabelM, LabelS } from '@sorare/core/src/atoms/typography';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useAmountWithConversion } from '@sorare/core/src/hooks/useAmountWithConversion';
import { useInviteLink } from '@sorare/core/src/hooks/useInviteLink';
import { zeroMonetaryAmount } from '@sorare/core/src/hooks/useMonetaryAmount';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';

import { LobbyTile } from 'components/home/LobbyTile';

import type { Referral_config } from './__generated__/index.graphql';
import bigReferralBg from './assets/bigReferralBg.jpg';
import referralSquare from './assets/referralSquare.png';

const Wrapper = styled.div`
  opacity: 1;
  position: relative;

  transition: opacity 0.05s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledLobbyTile = styled(LobbyTile)`
  display: flex;
  height: 205px;
  align-items: end;

  background: url(${bigReferralBg}) no-repeat center center;
  background-size: cover;
`;

const CTA = styled(Button)`
  margin-left: auto;
`;

type VariantProps = {
  loading: boolean;
  inviteLink: string;
  referralAmount: string;
};

const CompactVariant = ({
  referralAmount,
  loading,
  inviteLink,
}: VariantProps) => {
  return (
    <Wrapper>
      <LinkOverlay as={Link} to={inviteLink}>
        <Horizontal>
          <img width={48} height={48} src={referralSquare} alt="" />
          <Vertical gap={0.5}>
            <Skeleton loading={loading}>
              <LabelM bold>
                <FormattedMessage
                  id="ForYou.Referral.title2"
                  defaultMessage="Invite a friend, earn up to {referralAmount}"
                  values={{ referralAmount }}
                />
              </LabelM>
            </Skeleton>
            <LabelM as="span" color="var(--c-nd-600)">
              <FormattedMessage
                id="ForYou.Referral.subtitle"
                defaultMessage="In referral credits"
              />
            </LabelM>
          </Vertical>
          <CTA size="compact" color="quaternary">
            <FormattedMessage
              id="ForYou.Referral.CTA"
              defaultMessage="Invite friends"
            />
          </CTA>
        </Horizontal>
      </LinkOverlay>
    </Wrapper>
  );
};

const DefaultVariant = ({
  referralAmount,
  loading,
  inviteLink,
}: VariantProps) => {
  return (
    <StyledLobbyTile
      outlineColor="#9C00F6"
      label={
        <LabelS bold color="var(--c-white)">
          <FormattedMessage
            id="ForYou.Referral.label"
            defaultMessage="Referral"
          />
        </LabelS>
      }
    >
      <LinkOverlay as={Link} to={inviteLink}>
        <Skeleton loading={loading}>
          <LabelL bold>
            <FormattedMessage
              id="ForYou.Referral.default.title"
              defaultMessage="Get {referralAmount} for each referred friend!"
              values={{ referralAmount }}
            />
          </LabelL>
        </Skeleton>
      </LinkOverlay>
    </StyledLobbyTile>
  );
};

type Props = {
  config: Nullable<Referral_config>;
  compact?: boolean;
};

export const Referral = ({ config, compact }: Props) => {
  const { sport } = useSportContext();
  const inviteLink = useInviteLink(sport);

  const { main: referralAmount } = useAmountWithConversion({
    monetaryAmount:
      config?.referralProgramConfig?.maxDiscount || zeroMonetaryAmount,
    primaryCurrency: Currency.FIAT,
    hideFractionDigits: true,
  });

  if (compact) {
    return (
      <CompactVariant
        loading={!config}
        inviteLink={inviteLink}
        referralAmount={referralAmount}
      />
    );
  }

  return (
    <DefaultVariant
      loading={!config}
      inviteLink={inviteLink}
      referralAmount={referralAmount}
    />
  );
};

Referral.fragments = {
  config: gql`
    fragment Referral_config on Config {
      id
      referralProgramConfig(sport: $sport) {
        id
        maxDiscount {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
    }
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<Referral_config>,
};
