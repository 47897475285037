import { useState } from 'react';

import { useIsMobileApp } from './useIsMobileApp';
import { useLifecycle } from './useLifecycle';

export const useDisable3D = () => {
  const { isMobileApp } = useIsMobileApp();
  const { lifecycle } = useLifecycle();
  const [disable3D, setDisable3D] = useState(
    lifecycle?.disable3D || isMobileApp
  );
  return { disable3D, setDisable3D };
};
