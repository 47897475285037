import { CLUB_PLACEHOLDER } from 'constants/assets';
import { ClubHit } from 'lib/algolia';

import { Suggestion } from '../Suggestion';

interface Props {
  hit: ClubHit;
  isHighlighted: boolean;
}

export const ClubSuggestion = ({ hit, isHighlighted }: Props) => {
  return (
    <Suggestion
      isHighlighted={isHighlighted}
      renderAvatar={Img => (
        <Img
          src={hit.pictureUrl}
          alt={hit.name}
          isContain
          fallbackUrl={CLUB_PLACEHOLDER}
        />
      )}
      primary={hit.name}
    />
  );
};
