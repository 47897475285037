import {
  AdditionalKycRequestRequestType,
  AdditionalKycRequestState,
} from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export const useShowKycRenewalFlow = () => {
  const { currentUser } = useCurrentUserContext();
  const {
    flags: { enableKycRenewal = false },
  } = useFeatureFlags();
  const hasCompletedKycRenewal = (
    currentUser?.mangopayUser?.additionalKycRequests || []
  ).find(
    request =>
      request.requestType === AdditionalKycRequestRequestType.KYC_RENEWAL &&
      request.aasmState === AdditionalKycRequestState.VALIDATED
  );
  return enableKycRenewal && !hasCompletedKycRenewal;
};
