import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineL } from 'atoms/typography';
import { GraphQLResult, GraphqlForm, TextField } from 'components/form/Form';
import { RECOVERY_EMAIL_VERIFICATION_CODE_LENGTH } from 'constants/verificationCode';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import { useActivateWalletRecoveryEmail } from 'hooks/recovery/useActivateWalletRecoveryEmail';
import { glossary } from 'lib/glossary';

const Wrapper = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--triple-unit);
  form {
    margin-bottom: 0;
  }
`;
const Group = styled(Vertical).attrs({ gap: 2 })``;
const StyledTextField = styled(TextField)`
  width: 100%;
`;

type Props = {
  email: string;
  onSuccess: () => void;
};
export const VerifyRecoveryEmailForm = ({
  email,
  onSuccess: doOnSuccess,
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { showNotification } = useSnackNotificationContext();
  const { activateWalletRecoveryEmail } = useActivateWalletRecoveryEmail();
  const submit = async (values: any, onResult: (r: GraphQLResult) => void) => {
    const results = await activateWalletRecoveryEmail({
      destination: email,
      verificationCode: values.verificationCode,
    });
    onResult(results);
  };

  const onSuccess = () => {
    showNotification('recoveryEmailRegistered', { email });
    doOnSuccess();
  };

  if (!currentUser) return null;

  return (
    <Wrapper>
      <Group>
        <HeadlineL as="h3" bold>
          <FormattedMessage
            id="verifyRecoveryEmail.title"
            defaultMessage="Verify your recovery email"
          />
        </HeadlineL>
        <BodyM>
          <FormattedMessage
            id="verifyRecoveryEmail.desc"
            defaultMessage="Enter the 6-digit code sent to { email }."
            values={{ email }}
          />
        </BodyM>
      </Group>
      <GraphqlForm
        onSubmit={(values, doOnResult) => {
          submit(values, doOnResult);
        }}
        onChange={(values, doSubmit) => {
          const { verifiactionCode } = values;
          if (
            verifiactionCode?.length === RECOVERY_EMAIL_VERIFICATION_CODE_LENGTH
          )
            doSubmit();
        }}
        onSuccess={onSuccess}
        render={(Error, SubmitButton) => (
          <Group>
            <Error code />
            <StyledTextField name="verificationCode" />
            <SubmitButton fullWidth size="large">
              <FormattedMessage {...glossary.verify} />
            </SubmitButton>
          </Group>
        )}
      />
    </Wrapper>
  );
};
