import { ReactNode } from 'react';
import styled from 'styled-components';

import { CardCollectionScoringMatrix } from '__generated__/globalTypes';
import { LabelM, LabelS } from 'atoms/typography';
import { Image } from 'components/collections/DetailedScoreLine/Image';
import { Score } from 'components/collections/Score';

const Root = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'img label score'
    'img explanation score';
  justify-content: start;
  align-items: center;
  column-gap: var(--double-unit);
`;
const Label = styled(LabelM).attrs({ bold: true })`
  grid-area: label;
`;
const Explanation = styled(LabelS).attrs({ color: 'var(--c-nd-600)' })`
  grid-area: explanation;
`;
const ScoreWrapper = styled.div`
  grid-area: score;
  margin-left: auto;
`;

type Props = {
  detailedScore: CardCollectionScoringMatrix;
  explanationGauge?: ReactNode;
  listed?: boolean;
  specificScore?: number;
};
export const DetailedScoreLine = ({
  detailedScore,
  listed,
  explanationGauge,
  specificScore,
}: Props) => {
  return (
    <Root>
      <Image img={detailedScore.pictureUrl} listed={listed} width={32} />
      <Label>{detailedScore.label}</Label>
      <Explanation>
        {detailedScore.explanation}
        {explanationGauge}
      </Explanation>
      <ScoreWrapper>
        <Score score={specificScore ?? detailedScore.value} />
      </ScoreWrapper>
    </Root>
  );
};
