import { ReactNode, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { catchAll } from '@sorare/routing';

import { Horizontal, SBHorizontal } from '@sorare/core/src/atoms/layout/flex';
import {
  ANY_SPORT_MARKET,
  ANY_SPORT_MY_CARDS,
  ANY_SPORT_PLAY,
  ANY_SPORT_SCOUTING_CENTER,
} from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { lazy } from '@sorare/core/src/lib/retry';
import { matchAnySportPath } from '@sorare/core/src/lib/routing/generateSportPath';

import { UserCurrencies } from 'components/navigation/NewAppNavigation/UserCurrencies';
import { InGameCurrencyAgreggated } from 'components/navigation/NewAppNavigation/UserCurrencies/useUserCurrencies';

import { UserNav } from '../UserNav';
import { WalletButton } from '../WalletButton';

const Notifications = lazy(
  async () => import('components/notification/Notifications'),
  'Notifications'
);

const NavigationCart = lazy(
  async () => import('@sorare/marketplace/src/components/cart/NavigationCart'),
  'NavigationCart'
);

const Root = styled(SBHorizontal)`
  position: relative;
  border-bottom: 1px solid var(--c-nd-150);
  padding: var(--intermediate-unit) var(--double-unit);
`;

type Props = { extraHeaderButton?: ReactNode };
export const MobileWebAppHeader = ({ extraHeaderButton }: Props) => {
  const { sport } = useSportContext();
  const { pathname } = useLocation();

  const isPlay = !!matchAnySportPath(catchAll(ANY_SPORT_PLAY), pathname);
  const isScoutingCenter = !!matchAnySportPath(
    catchAll(ANY_SPORT_SCOUTING_CENTER),
    pathname
  );
  const isMarket = !!matchAnySportPath(catchAll(ANY_SPORT_MARKET), pathname);
  const isMyCards = !!matchAnySportPath(catchAll(ANY_SPORT_MY_CARDS), pathname);

  const expandedCurrencies = [];
  if (isPlay || isScoutingCenter || isMarket) {
    expandedCurrencies.push(InGameCurrencyAgreggated.SHARDS);
  } else if (isMyCards) {
    expandedCurrencies.push(
      InGameCurrencyAgreggated.XP,
      InGameCurrencyAgreggated.ENERGY
    );
  }

  return (
    <Root>
      <Horizontal>
        <UserNav sport={sport} />
        <UserCurrencies expandedCurrencies={expandedCurrencies} />
      </Horizontal>
      <Horizontal>
        {extraHeaderButton}
        {isMarket && <WalletButton />}
        <Suspense fallback={null}>
          <Notifications />
          <NavigationCart />
        </Suspense>
      </Horizontal>
    </Root>
  );
};
