import { PropsWithChildren } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { Horizontal, SBHorizontal, Vertical } from 'atoms/layout/flex';
import { LabelL, LabelS } from 'atoms/typography';
import { fantasy } from 'lib/glossary';

import { Cursor } from './Cursor';

const Root = styled(Vertical).attrs({ gap: 1.5 })`
  border-radius: var(--double-unit);
  padding: var(--double-unit);
  background-color: var(--c-nd-50);
  backdrop-filter: blur(32px);
`;
const Bar = styled.div`
  position: relative;
  border-radius: var(--unit);
  background-color: var(--c-nd-200);
`;
const Sections = styled(Horizontal).attrs({ gap: 0 })`
  position: relative;
`;

const Section = styled.div`
  position: relative;
  height: calc(5 * var(--unit));
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: var(--unit);
  color: var(--c-nl-500);

  & > * {
    z-index: 1;
  }

  &::before {
    inset: 0;
    content: '';
    opacity: 0.5;
    position: absolute;
  }

  &:first-of-type::before {
    border-radius: var(--unit) 0 0 var(--unit);
  }
  &:last-of-type::before {
    border-radius: 0 var(--unit) var(--unit) 0;
  }

  &.missed::before {
    background-color: var(--c-nd-100);
  }

  &.target1::before {
    background-color: var(--c-score-medium);
  }

  &.target2::before {
    background-color: var(--c-score-mediumHigh);
  }

  &.target3::before {
    background-color: var(--c-score-high);
  }
`;

const StyledCursor = styled(Cursor)`
  z-index: 1;
  position: absolute;
  top: calc(-1 * var(--intermediate-unit));
  transform: translateX(50%);
`;
type TProps = {
  name: any;
  currentScore: number;
  sections: { score: number | null }[];
  cursorPosition: string;
};
export const ScoreGaugeTarget = ({
  name,
  currentScore,
  sections,
  cursorPosition,
  children,
}: PropsWithChildren<TProps>) => {
  const sectionsWidth = 100 / (sections.length + 1);
  return (
    <Root>
      <SBHorizontal>
        {name}
        <LabelL bold>
          <FormattedMessage
            {...fantasy.pointsWithoutFormat}
            values={{
              score: (
                <FormattedNumber
                  value={currentScore}
                  maximumFractionDigits={2}
                />
              ),
            }}
          />
        </LabelL>
      </SBHorizontal>
      <Bar>
        <Sections>
          <Section className="missed" style={{ width: `${sectionsWidth}%` }}>
            <LabelS as="p" bold>
              <FormattedNumber value={0} maximumFractionDigits={0} />
            </LabelS>
          </Section>
          {sections?.map(({ score }, index) => (
            <Section
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={score ? `target${index + 1}` : 'missed'}
              style={{ width: `${sectionsWidth}%` }}
            >
              {!!score && (
                <LabelS as="p" bold>
                  <FormattedNumber
                    value={score ?? 0}
                    maximumFractionDigits={0}
                  />
                </LabelS>
              )}
            </Section>
          ))}
        </Sections>
        {!!currentScore && (
          <StyledCursor
            stroke={false}
            color="rgb(var(--c-rgb-white))"
            style={{
              right: cursorPosition,
            }}
          />
        )}
      </Bar>
      {children}
    </Root>
  );
};
