import { Navigate, generatePath } from 'react-router-dom';

import { useTypedParams } from '@sorare/routing';

import { NBA_SCOUTING_CENTER_SCORES_GAMEID } from '@sorare/core/src/constants/__generated__/routes';

import { PageParams } from './__generated__/routeParams';

const NBAScoreGameIdPage = () => {
  const { gameId } = useTypedParams<PageParams>();
  return (
    <Navigate
      to={generatePath(NBA_SCOUTING_CENTER_SCORES_GAMEID, {
        gameId,
      })}
    />
  );
};

export default NBAScoreGameIdPage;
