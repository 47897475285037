import { useRef } from 'react';
import styled from 'styled-components';

const ScrollTo = styled.div`
  scroll-margin-top: var(--current-stack-height);
`;

const evaluateNestedCalc = (calcVal: string) => {
  // this is naive implementation, it gets all numbers from the input and sums them
  const numbers = calcVal.match(/\d+/g);
  if (!numbers) return 0;
  return numbers.map(Number).reduce((sum, num) => sum + num, 0);
};

const instantSearchScrollTop = (element: HTMLDivElement | null) => {
  if (!element) return;
  const rect = element.getBoundingClientRect();
  const topOffset = evaluateNestedCalc(
    getComputedStyle(element).getPropertyValue('--current-stack-height')
  );

  if (
    rect.top < topOffset ||
    rect.left < 0 ||
    rect.bottom >
      (window.innerHeight || document.documentElement.clientHeight) ||
    rect.right > (window.innerWidth || document.documentElement.clientWidth)
  ) {
    element.scrollIntoView();
  }
};

export const usePreventScrollReset = () => {
  const scrollTopRef = useRef<HTMLDivElement>(null);
  return {
    instantSearchScrollTop: () => instantSearchScrollTop(scrollTopRef.current),
    scrollToAnchor: <ScrollTo ref={scrollTopRef} />,
  };
};
