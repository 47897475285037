import { TypedDocumentNode, gql } from '@apollo/client';
import classnames from 'classnames';
import styled from 'styled-components';

import { ResponsiveImg } from 'atoms/ui/ResponsiveImg';
import { isType } from 'lib/gql';

import {
  Avatar_anonymousUser,
  Avatar_ethereumAccount,
  Avatar_publicUserInfoInterface,
  Avatar_starkwareAccount,
  Avatar_user,
} from './__generated__/index.graphql';
import placeholders from './assets/placeholders.svg';

type BaseProps = {
  variant?:
    | 'extraSmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'extraLarge'
    | 'responsive';
  rounded?: boolean;
  style?: React.CSSProperties;
};

type DisabledAvatarDisplayProps = BaseProps & {
  invert?: boolean;
};

type PlaceHolderAvatarProps = BaseProps & {
  user?: { nickname?: string };
};

type PictureAvatarProps = BaseProps & {
  invert?: boolean;
  user: { nickname: string };
  pictureUrl: NonNullable<Avatar_publicUserInfoInterface['pictureUrl']>;
};

type AvatarDisplayProps = Omit<BaseProps, 'style'> & {
  invert?: boolean;
  largePictureUrl?: string | null;
  size?: number;
};

type Props = {
  user:
    | Avatar_publicUserInfoInterface
    | Avatar_anonymousUser
    | Avatar_ethereumAccount
    | Avatar_starkwareAccount
    | Avatar_user;
  placeholderUrl?: string;
} & AvatarDisplayProps;

const isAnonymous = (
  user:
    | Avatar_publicUserInfoInterface
    | Avatar_anonymousUser
    | Avatar_ethereumAccount
    | Avatar_starkwareAccount
): user is
  | Avatar_anonymousUser
  | Avatar_ethereumAccount
  | Avatar_starkwareAccount => {
  return (
    isType(user, 'AnonymousUser') ||
    isType(user, 'EthereumAccount') ||
    isType(user, 'StarkwareAccount')
  );
};

const Root = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: calc(4 * var(--unit));
  object-fit: cover;
  background-color: var(--c-nd-800);
  border-radius: var(--unit);
  aspect-ratio: 1;
  color: var(--c-black);
  font: var(--t-16);
  text-transform: uppercase;
  &.extraSmall {
    width: 14px;
    line-height: 100%;
    font-size: 10px;
  }
  &.small {
    width: calc(3 * var(--unit));
    font-size: var(--t-12);
  }
  &.medium {
    width: calc(5 * var(--unit));
  }
  &.large {
    width: calc(8 * var(--unit));
  }
  &.extraLarge {
    width: calc(10 * var(--unit));
  }
  &.responsive {
    width: 100%;
  }
  &.rounded {
    border-radius: 50%;
  }
  &.invert {
    background-color: var(--c-nd-100);
    color: var(--c-nd-600);
  }
`;

const DisabledAvatar = ({
  rounded,
  invert,
  variant,
  style,
}: DisabledAvatarDisplayProps) => {
  return (
    <Root className={classnames(variant, { rounded, invert })} style={style}>
      ?
    </Root>
  );
};

const getLetterIndex = (letter?: string) =>
  Math.abs(((letter?.toLowerCase()?.charCodeAt(0) ?? 0) - 97) % 25) * 100;
const Placeholder = styled.div`
  background: url(${placeholders});
  /* Use 100% height instead of cover to work around chrome zoom issue
   * https://issues.chromium.org/issues/40325175 */
  background-size: auto 100%;
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;

export const PlaceHolderAvatar = ({
  rounded,
  variant,
  user,
  style,
}: PlaceHolderAvatarProps) => {
  return (
    <Root className={classnames(variant, { rounded })} style={style}>
      <Placeholder
        role="img"
        aria-label={user?.nickname}
        style={{
          backgroundPosition: `${getLetterIndex(user?.nickname?.[0])}% 0`,
        }}
      />
    </Root>
  );
};

export const PictureAvatar = ({
  rounded,
  invert,
  variant,
  user,
  pictureUrl,
  style,
}: PictureAvatarProps) => {
  return (
    <Root
      as={ResponsiveImg}
      src={pictureUrl}
      className={classnames(variant, { rounded, invert })}
      cropWidth={variant === 'responsive' ? undefined : 80}
      alt={user.nickname!}
      style={style}
    />
  );
};

export const Avatar = ({
  user,
  placeholderUrl,
  variant,
  largePictureUrl,
  size,
  ...rest
}: Props) => {
  const disabledAvatar = isAnonymous(user) || user.suspended;
  const style = size ? { width: `calc(${size} * var(--unit))` } : {};

  if (disabledAvatar) {
    return <DisabledAvatar variant={variant} {...rest} style={style} />;
  }
  const { pictureUrl } = user;

  const url = largePictureUrl || pictureUrl;
  if (url) {
    return (
      <PictureAvatar
        user={user}
        pictureUrl={url}
        variant={variant}
        {...rest}
        style={style}
      />
    );
  }
  if (placeholderUrl) {
    return (
      <PictureAvatar
        user={user}
        pictureUrl={placeholderUrl}
        variant={variant}
        {...rest}
        style={style}
      />
    );
  }
  return (
    <PlaceHolderAvatar user={user} variant={variant} {...rest} style={style} />
  );
};

Avatar.fragments = {
  user: gql`
    fragment Avatar_user on User {
      slug
      nickname
      suspended
      pictureUrl(derivative: "avatar")
    }
  ` as TypedDocumentNode<Avatar_user>,
  publicUserInfoInterface: gql`
    fragment Avatar_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      nickname
      suspended
      pictureUrl(derivative: "avatar")
    }
  ` as TypedDocumentNode<Avatar_publicUserInfoInterface>,
  anonymousUser: gql`
    fragment Avatar_anonymousUser on AnonymousUser {
      id
    }
  ` as TypedDocumentNode<Avatar_anonymousUser>,
  ethereumAccount: gql`
    fragment Avatar_ethereumAccount on EthereumAccount {
      id
    }
  ` as TypedDocumentNode<Avatar_ethereumAccount>,
  starkwareAccount: gql`
    fragment Avatar_starkwareAccount on StarkwareAccount {
      id
    }
  ` as TypedDocumentNode<Avatar_starkwareAccount>,
};
