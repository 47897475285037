import { useEffect, useState } from 'react';

import { load } from 'lib/http';

export const useFetch = <T extends NonNullable<unknown> = string>(
  url: string
) => {
  const [data, setData] = useState<null | T>(null);

  useEffect(() => {
    const fetchData = async () => {
      setData(await load<T>(url));
    };

    fetchData();
  }, [url]);

  return data;
};
