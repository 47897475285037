import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  InGameCurrency,
  InGameCurrencyBalance,
  Rarity,
  Sport,
} from '__generated__/globalTypes';
import { Button } from 'atoms/buttons/Button';
import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from 'atoms/icons';
import { Energy } from 'atoms/icons/Energy';
import { Horizontal, SBHorizontal, Vertical } from 'atoms/layout/flex';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { LabelS } from 'atoms/typography';
import { useIntlContext } from 'contexts/intl';
import { useSportContext } from 'contexts/sport';
import { useEnergyBalance } from 'hooks/useEnergyBalance';
import { formatScarcity } from 'lib/cards';
import { glossary } from 'lib/glossary';

import { EnergyBalanceHelp } from './EnergyBalanceHelp';

const TooltipWrapper = styled(Vertical)`
  padding: var(--unit);
  min-width: 145px;
`;

const EnergyTooltip = ({
  energyBalance,
  setOpenOnboarding,
}: {
  energyBalance: InGameCurrencyBalance[];
  setOpenOnboarding: (open: boolean) => void;
}) => {
  const scarcities = {
    [InGameCurrency.LIMITED_ENERGY]: Rarity.limited,
    [InGameCurrency.RARE_ENERGY]: Rarity.rare,
    [InGameCurrency.SUPER_RARE_ENERGY]: Rarity.super_rare,
    [InGameCurrency.UNIQUE_ENERGY]: Rarity.unique,
  };

  return (
    <TooltipWrapper>
      <SBHorizontal gap={0.5}>
        <Horizontal gap={0.5}>
          <Energy size={1.5} />
          <LabelS bold uppercase>
            <FormattedMessage {...glossary.energy} />
          </LabelS>
        </Horizontal>
        <ButtonBase onClick={() => setOpenOnboarding(true)}>
          <FontAwesomeIcon icon={faInfoCircle} color="var(--c-nd-400)" />
        </ButtonBase>
      </SBHorizontal>
      <Vertical gap={0.5}>
        {energyBalance.map(balance => {
          const scarcity =
            scarcities[balance.currency as keyof typeof scarcities];

          return (
            <SBHorizontal key={balance.currency}>
              <LabelS color="var(--c-nd-600)">
                {formatScarcity(scarcity)}
              </LabelS>
              <LabelS bold uppercase>
                {balance.amount}
              </LabelS>
            </SBHorizontal>
          );
        })}
      </Vertical>
    </TooltipWrapper>
  );
};

export const EnergyBalance = () => {
  const { sport } = useSportContext();
  const { formatNumber } = useIntlContext();
  const { energyBalance } = useEnergyBalance();
  const [openOnboarding, setOpenOnboarding] = useState(false);

  if (sport !== Sport.BASEBALL) {
    return null;
  }

  const totalEnergy = energyBalance.reduce((acc, curr) => acc + curr.amount, 0);

  return (
    <>
      <Tooltip
        title={
          <EnergyTooltip
            energyBalance={energyBalance}
            setOpenOnboarding={setOpenOnboarding}
          />
        }
      >
        <Button size="medium" color="transparent" onClick={() => {}}>
          <Energy size={2} />
          <LabelS bold>{formatNumber(totalEnergy)}</LabelS>
        </Button>
      </Tooltip>
      <EnergyBalanceHelp
        open={openOnboarding}
        onClose={() => setOpenOnboarding(false)}
      />
    </>
  );
};
