import { Navigate } from '@sorare/routing';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { ANY_SPORT_MY_CARDS_COLLECTIONS_TYPE } from '@sorare/core/src/constants/__generated__/routes';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useSportContext } from '@sorare/core/src/contexts/sport';

export const CollectionsPage = () => {
  const { currentUser } = useCurrentUserContext();
  const hasLimitedCards = !!currentUser?.cardCounts?.limited;
  const { generateSportContextPath } = useSportContext();
  return (
    <Navigate
      to={generateSportContextPath(ANY_SPORT_MY_CARDS_COLLECTIONS_TYPE, {
        params: {
          type: hasLimitedCards ? Rarity.limited : Rarity.common,
        },
      })}
      replace
    />
  );
};
