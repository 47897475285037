import { Sport } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';

export const useSimplifiedNav = (sport: Sport) => {
  const { currentUser } = useCurrentUserContext();

  return {
    showSimplifiedNav:
      sport === Sport.FOOTBALL &&
      !!currentUser &&
      currentUser.footballUserProfile?.proUnlocked === false,
  };
};
