import { faCube } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineXL } from 'atoms/typography';

const Centered = styled(Vertical).attrs({ gap: 4, center: true })`
  height: 100%;
  text-align: center;
`;

type Props = {
  children?: ReactNode;
};
export const No3dSupport = ({ children }: Props) => {
  return (
    <Centered>
      <div>
        <HeadlineXL as="h2">
          <FontAwesomeIcon icon={faCube} />
          <br />

          <FormattedMessage
            id="No3dSupport.title"
            defaultMessage="Could not render 3D content"
          />
        </HeadlineXL>
        <BodyM>
          <FormattedMessage
            id="No3dSupport.explanation"
            defaultMessage="Please allow WebGL on your browser, or try from another device."
          />
        </BodyM>
      </div>
      {children}
    </Centered>
  );
};
