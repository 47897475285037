import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

import { DialogKey } from 'components/navigation/WithDialogs';

export const useCloseDialog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (dialogKey: DialogKey, otherParams?: string[]) => {
    const searchParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    delete searchParams[dialogKey];
    otherParams?.forEach(param => {
      delete searchParams[param];
    });
    navigate(
      `${location.pathname}${qs.stringify(searchParams, {
        addQueryPrefix: true,
      })}`,
      {
        replace: true,
      }
    );
  };
};
