import { Navigate, generatePath } from 'react-router-dom';

import { MLB_PLAY_LOBBY_EVENTTYPE_FIXTURE } from '@sorare/core/src/constants/__generated__/routes';
import { useLastVisitedEventType } from '@sorare/core/src/hooks/useLastVisitedEventType';

const LobbyPage = () => {
  const defaultFixture = 'ongoing';
  const { lastVisitedEventType } = useLastVisitedEventType();

  return (
    <Navigate
      to={generatePath(MLB_PLAY_LOBBY_EVENTTYPE_FIXTURE, {
        eventType: lastVisitedEventType,
        fixture: defaultFixture,
      })}
      replace
    />
  );
};

export default LobbyPage;
