import { IndexUiState, UiState } from 'instantsearch.js';
import { ComponentProps } from 'react';
import { InstantSearch } from 'react-instantsearch';

import { Sport } from '__generated__/globalTypes';
import {
  AlgoliaCardIndexesName,
  AlgoliaIndex,
  GallerySortName,
  PrintablePlayerSortName,
  ShortSortName,
} from 'contexts/config';

export type SearchProps = {
  analyticsTags: AnalyticTag[];
  indexes?: (
    | AlgoliaIndex
    | GallerySortName
    | PrintablePlayerSortName
    | ShortSortName
  )[];
  availableSorts?: (
    | AlgoliaCardIndexesName
    | GallerySortName
    | PrintablePlayerSortName
  )[];
  defaultHitsPerPage?: number;
  sport: Sport;
  defaultFilters?: string[];
  getOptionalFilters?: (index: string) => string[];
  urlState?: boolean;
  initialIndexUIState?: ExtendedIndexUIState;
  searchClient?: ComponentProps<typeof InstantSearch>['searchClient'];
};

export type Props = {
  analyticsTags: AnalyticTag[];
  indexes: (
    | AlgoliaIndex
    | GallerySortName
    | PrintablePlayerSortName
    | ShortSortName
  )[];
  availableSorts?: (
    | AlgoliaCardIndexesName
    | GallerySortName
    | PrintablePlayerSortName
  )[];
  distinct: boolean | number;
  defaultHitsPerPage: number;
  attributesToRetrieve?: string[];
  sport?: Sport;
  defaultFilters?: string[];
  getOptionalFilters?: (index: string) => string[];
  getFilters?: (index: string) => string[];
  urlState?: boolean;
  initialIndexUIState?: ExtendedIndexUIState;
  searchClient?: ComponentProps<typeof InstantSearch>['searchClient'];
};

export enum SEARCH_PARAMS {
  QUERY = 'q',
  SORT = 's',
  PAGE = 'p',
  ACTIVE_LEAGUE = 'al',
  PROMOTION = 'promo',
  TEAM = 'team',
  RARITY = 'rarity',
  POSITION = 'pos',
  MENU_POSITION = 'mPos',
  PLAYER_NAME = 'player',
  NATIONAL_TEAM = 'nt',
  NATIONALITY = 'nationality',
  SEASON = 'season',
  CLUB = 'club',
  ACTIVE_TEAM = 'currentTeam',
  EDITION = 'edition',
  BUNDLED = 'b',
  DECK = 'deck',
  GRADE = 'level',
  AGE = 'age',
  ON_SALE = 'sale',
  SALE_TYPE = 't',
  IN_SEASON = 'is',
  FAVORITE_FILTER = 'ff',
  NON_PLAYABLE_CARDS = 'co',
  LEGEND = 'legend',
  COMPETITION_ELIGIBILITY = 'lf',
  GAMEWEEK_FILTER = 'gw',
  DAILY_FILTER = 'dly',
  PROBABLE_PITCHERS_FILTERS = 'pp',
  PRICE = 'price',
  SERIAL_NUMBER = 'sn',
  MLB_15_AVERAGE = 'mlbL15Avg',
  MLB_SEASON_AVG = 'mlbSAvg',
  SO5_15_AVERAGE = 'so5L15Avg',
  SO5_5_AVERAGE = 'so5L5Avg',
  SO5_15_APPEARANCES = 'so5L15App',
  SO5_5_APPEARANCES = 'so5L5App',
  NBA_10_AVERAGE = 'nbaL10Avg',
  NOT_IN_LINEUP = 'nl',
  JERSEY_SERIAL = 'js',
  PURCHASE_OPTIONS = 'po',
  ELIGIBLE_LEADERBOARDS = 'mlblf',
  PROMOTIONAL_EVENT = 'promotion',
  PLAYING_STATUS = 'ps',
  CREDIT_ELIGIBLE = 'ce',
  FLOOR_PRICES_ALL_SEASONS_LIMITED = 'fp-as-l',
  FLOOR_PRICES_ALL_SEASONS_RARE = 'fp-as-r',
  FLOOR_PRICES_ALL_SEASONS_SUPER_RARE = 'fp-as-sr',
  FLOOR_PRICES_ALL_SEASONS_UNIQUE = 'fp-as-u',
  FLOOR_PRICES_IN_SEASON_LIMITED = 'fp-is-l',
  FLOOR_PRICES_IN_SEASON_RARE = 'fp-is-r',
  FLOOR_PRICES_IN_SEASON_SUPER_RARE = 'fp-is-sr',
  FLOOR_PRICES_IN_SEASON_UNIQUE = 'fp-is-u',
  STATS_MODE = 'sm',
}

type DeprecatedRouteState = {
  refinementList: Record<string, string[]>;
  range: Record<string, { min?: string; max?: string }>;
  page: number;
  sortBy: string;
};

export type RouteState = Record<SEARCH_PARAMS, string> &
  Partial<DeprecatedRouteState>;

// omit `ratingMenu` to work-around a typing issue in `instantsearch.js`, a widget we don't use anyway
export interface ExtendedIndexUIState extends Omit<IndexUiState, 'ratingMenu'> {
  virtualToggle?: Record<string, any>;
  exclusions?: Record<string, boolean>;
  shouldReplace?: boolean;
}

export interface ExtendedUIState extends UiState {
  [indexName: string]: Partial<ExtendedIndexUIState>;
}

export type AnalyticTag =
  | 'TransferMarket'
  | 'TransferMarketStack'
  | 'NewSignings'
  | 'StarterBundles'
  | 'InstantBuy'
  | 'Featured'
  | 'Player'
  | 'Club'
  | 'Team'
  | 'League'
  | 'Country'
  | 'Football'
  | 'NBA'
  | 'MLB'
  | 'Baseball'
  | 'Gallery'
  | 'NewOffer'
  | 'BuyOnAuction'
  | 'CardOnSale'
  | 'LineupToDiscover'
  | 'CardPicker'
  | 'SearchBar'
  | 'AvailableOnPrimary'
  | 'FavoriteCards'
  | 'Inventory'
  | 'Swap'
  | 'Scouting-center'
  | 'Compose-Buy';
