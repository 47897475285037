import { ReactNode, useMemo, useState } from 'react';

import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';

import { AddToCartPopin } from 'components/cart/AddToCartPopin';
import { BuyConfirmationProvider as BuyingConfirmationProvider } from 'contexts/buyingConfirmation/Provider';

import {
  MarketplaceProvider as MarketplaceContextProvider,
  MarketplaceContextType,
} from '.';

interface Props
  extends Omit<
    MarketplaceContextType,
    'hideDetails' | 'setHideDetails' | 'showAddToCartPopin'
  > {
  children: ReactNode;
}

export const MarketplaceProvider = ({ children }: Props) => {
  const {
    flags: { useBulkBuy = false },
  } = useFeatureFlags();
  const [hideDetails, setHideDetails] = useState(false);
  const [addToCartSlug, setAddToCartSlug] = useState<string | null>(null);

  const value = useMemo(
    () => ({
      hideDetails,
      setHideDetails,
      showAddToCartPopin: (s: string | null) => setAddToCartSlug(s),
    }),
    [hideDetails]
  );

  return (
    <MarketplaceContextProvider value={value}>
      {useBulkBuy && (
        <AddToCartPopin
          slug={addToCartSlug}
          onClose={() => {
            setAddToCartSlug(null);
          }}
        />
      )}
      <BuyingConfirmationProvider>{children}</BuyingConfirmationProvider>
    </MarketplaceContextProvider>
  );
};
