import { TypedDocumentNode, gql } from '@apollo/client';
import { createContext, useContext } from 'react';

import { CancelSalePopin } from 'components/offer/CancelSalePopin';

import { SingleSaleOfferContext_anyCard } from './__generated__/index.graphql';

export interface SingleSaleOfferPopinInfo {
  slug: string;
}

interface SingleSaleOfferContext {
  showPopin: (singleSaleOfferPopinInfo: SingleSaleOfferPopinInfo) => void;
}

export const SingleSaleOfferContext =
  createContext<SingleSaleOfferContext | null>(null);

export const useSingleSaleOfferContext = () =>
  useContext(SingleSaleOfferContext)!;

export const singleSaleOfferContextFragments = {
  anyCard: gql`
    fragment SingleSaleOfferContext_anyCard on AnyCardInterface {
      slug
      ...CancelSalePopin_anyCard
    }
    ${/* eslint-disable-next-line sorare/no-detached-fragments */ ''}
    ${CancelSalePopin.fragments.anyCard}
  ` as TypedDocumentNode<SingleSaleOfferContext_anyCard>,
};
