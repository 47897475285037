import { Outlet } from 'react-router-dom';

import { AppLayout } from '@sorare/gameplay/src/components/navigation/AppLayout';

const LicensedPartnersLayout = () => (
  <AppLayout>
    <Outlet />
  </AppLayout>
);

export default LicensedPartnersLayout;
