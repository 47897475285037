import { Rarity, Sport } from '__generated__/globalTypes';
import { ANY_SPORT_MY_CARDS_CARDS_TYPE } from 'constants/__generated__/routes';
import { useSportContext } from 'contexts/sport';

export const useGetMyCardsBoostLink = () => {
  const { generateSportContextPath } = useSportContext();

  return ({ rarity, sport }: { rarity: Rarity; sport: Sport }) =>
    `${generateSportContextPath(ANY_SPORT_MY_CARDS_CARDS_TYPE, {
      params: { type: rarity },
      sport,
    })}?selectionMode=boost`;
};
