import { TypedDocumentNode, gql } from '@apollo/client';

import { useMutation } from 'hooks/graphql/useMutation';

import {
  ResendConfirmationInstructionsMutation,
  ResendConfirmationInstructionsMutationVariables,
} from './__generated__/useResendConfirmationInstructions.graphql';

const RESEND_CONFIRMATION_INSTRUCTIONS = gql`
  mutation ResendConfirmationInstructionsMutation(
    $input: resendConfirmationInstructionsInput!
  ) {
    resendConfirmationInstructions(input: $input) {
      errors {
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  ResendConfirmationInstructionsMutation,
  ResendConfirmationInstructionsMutationVariables
>;

export const useResendConfirmationInstructions = () => {
  const [mutate] = useMutation(RESEND_CONFIRMATION_INSTRUCTIONS, {
    showErrorsWithSnackNotification: true,
  });
  return mutate;
};
