import { defineMessages } from 'react-intl';

export type ImageVariations = 'post' | 'square' | 'story';

export const derivatives = {
  post: {
    height: 630,
    width: 1200,
  },
  square: {
    height: 1080,
    width: 1080,
  },
  story: {
    height: 1920,
    width: 1080,
  },
};

export const socialSharingMessages = defineMessages({
  lineup: {
    id: 'SocialSharing.lineup',
    defaultMessage: 'Check out that lineup',
  },
  myLineup: {
    id: 'SocialSharing.myLineup',
    defaultMessage: 'Check out my lineup',
  },
  card: {
    id: 'SocialSharing.card',
    defaultMessage: 'Check out this card {link}',
  },
});
