import { TypedDocumentNode, gql } from '@apollo/client';

import { useMutation } from 'hooks/graphql/useMutation';

import {
  CreateJWTToken,
  CreateJWTTokenVariables,
} from './__generated__/useCreateJwtToken.graphql';

const CREATE_JWT_TOKEN_MUTATION = gql`
  mutation CreateJWTToken($input: createJwtTokenInput!) {
    createJwtToken(input: $input) {
      jwtToken {
        expiredAt
        token
      }
    }
  }
` as TypedDocumentNode<CreateJWTToken, CreateJWTTokenVariables>;

export const useCreateJwtToken = () => {
  const [mutate] = useMutation(CREATE_JWT_TOKEN_MUTATION);

  return mutate;
};
