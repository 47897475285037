import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

type Props = {
  color?: Color;
  size?: keyof typeof unitMapping;
};

export const AllSeasons = ({ size = 2, color = 'var(--c-nd-600)' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 17"
      fill="none"
      style={{ height: unitMapping[size] }}
    >
      <path
        d="M2.92174 8.98932V1.26758H0V7.9394L2.92174 8.98932Z"
        fill={color}
      />
      <path
        d="M21.0783 8.98932V1.26758H24V7.9394L21.0783 8.98932Z"
        fill={color}
      />
      <g filter="url(#filter0_bd_8213_50793)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0347 1.26758V15.3246L14.2158 16.5024H9.78418L3.96518 15.3246L3.96518 1.26758L20.0347 1.26758ZM16.0062 11.6195L14.6556 10.2689L13.5699 11.3546H11.1601L10.4319 10.6264V6.62772L11.1601 5.89948H13.4772L14.563 6.98522L15.9135 5.63467L14.4041 4.12523H10.22L8.44575 5.89948V11.3546L10.22 13.1289H14.4967L16.0062 11.6195Z"
          fill={color}
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_8213_50793"
          x="3.33535"
          y="0.637745"
          width="17.3292"
          height="16.494"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.314917" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_8213_50793"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.0372931" />
          <feGaussianBlur stdDeviation="0.158496" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_8213_50793"
            result="effect2_dropShadow_8213_50793"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8213_50793"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
