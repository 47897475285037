import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import {
  InstantSearch as AlgoliaInstantSearch,
  Configure,
  Index,
} from 'react-instantsearch';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Filters } from 'components/search/Filter';
import { AlgoliaIndex, useConfigContext } from 'contexts/config';
import { useIntlContext } from 'contexts/intl';
import { useSearchCardsContext } from 'contexts/searchCards';
import { useMediaCardsPerPage } from 'hooks/search/useMediaCardsPerPage';
import { useSearchClient } from 'hooks/search/useSearchClient';
import { useMarketplaceLifecycle } from 'hooks/useMarketplaceLifecycle';
import { sportFilter } from 'lib/algolia';

import { OptionalFilters } from '../OptionalFilters';
import { MAX_SHOW_MORE_LIMIT } from '../RefineList';
import { InitialVirtualToggleHandler } from './InitialVirtualToggleHandler';
import { NavigationHandler } from './NavigationHandler';
import { InstantSearchRefresh } from './Refresh';
import {
  ExtendedUIState,
  Props,
  RouteState,
  SEARCH_PARAMS,
  SearchProps,
} from './types';
import { usePreventScrollReset } from './usePreventScrollReset';
import {
  cleanFixedRangeRefinements,
  cleanInitialIndexUiState,
  routeToState,
  stateToRoute,
} from './utils';

export const InstantSearch: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  indexes,
  defaultHitsPerPage,
  attributesToRetrieve,
  distinct,
  availableSorts,
  urlState = false,
  analyticsTags,
  sport,
  getOptionalFilters = () => [],
  getFilters = () => [],
  defaultFilters,
  initialIndexUIState,
  searchClient,
}) => {
  const { instantSearchScrollTop, scrollToAnchor } = usePreventScrollReset();
  const {
    algoliaIndexes,
    so5: { cardMaxGrade },
  } = useConfigContext();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const defaultSearchClient = useSearchClient();
  const { language } = useIntlContext();

  const { filters: cardContextFilters } = useSearchCardsContext() || {
    // when not in a search cards context, this doesn't exist
    filters: [],
  };

  const actualIndexes = indexes.map(i => {
    return (i in algoliaIndexes && algoliaIndexes[i as AlgoliaIndex]) || i;
  });
  const [mainIndex, ...otherIndexes] = actualIndexes;

  const queryParams = Object.fromEntries(searchParams) as Record<
    SEARCH_PARAMS,
    any
  >;

  const stateFromRoute = urlState
    ? routeToState(queryParams, mainIndex, algoliaIndexes, language)
    : {};

  const { sort: lifecycleMarketplaceSort } = useMarketplaceLifecycle();
  const sortBy = (sort => {
    if (!sort) {
      return undefined;
    }
    if (Array.isArray(sort)) {
      const sorts = sort.filter(s => availableSorts?.includes(s));
      return sorts.length > 0 ? sorts.join(',') : undefined;
    }
    if (availableSorts?.includes(sort)) {
      return sort in algoliaIndexes
        ? algoliaIndexes[sort as AlgoliaIndex]
        : sort;
    }
    return undefined;
  })(lifecycleMarketplaceSort);

  const cleanedInitialIndexUIState = cleanInitialIndexUiState(
    {
      ...initialIndexUIState,
      ...(sortBy ? { sortBy } : {}),
    },
    queryParams
  );

  const initialUiState: ExtendedUIState = {
    [mainIndex]: {
      ...stateFromRoute[mainIndex],
      ...cleanedInitialIndexUIState,
      refinementList: {
        ...stateFromRoute[mainIndex]?.refinementList,
        ...cleanedInitialIndexUIState?.refinementList,
      },
      range: {
        ...stateFromRoute[mainIndex]?.range,
        ...cleanedInitialIndexUIState?.range,
      },
      toggle: {
        ...stateFromRoute[mainIndex]?.toggle,
        ...cleanedInitialIndexUIState?.toggle,
      },
      virtualToggle: {
        ...stateFromRoute[mainIndex]?.virtualToggle,
        ...cleanedInitialIndexUIState?.virtualToggle,
      },
      menu: {
        ...stateFromRoute[mainIndex]?.menu,
        ...cleanedInitialIndexUIState?.menu,
      },
    },
  };

  const initialRouteState = stateToRoute(
    initialUiState,
    mainIndex,
    algoliaIndexes,
    language,
    cardMaxGrade
  );
  const lastRouteState = useRef<RouteState>(initialRouteState);

  const [processed, setProcessed] = useState(false);

  useEffect(() => {
    if (urlState && !processed) {
      const algoliaParamsValues = Object.values(SEARCH_PARAMS) as string[];
      const notAlgoliaSearchParams = Object.fromEntries(
        Array.from(searchParams).filter(
          ([key]) => !algoliaParamsValues.includes(key)
        )
      );

      const queryString = qs.stringify(
        { ...notAlgoliaSearchParams, ...lastRouteState.current },
        {
          format: 'RFC1738',
          arrayFormat: 'comma',
          encodeValuesOnly: true,
          skipNulls: true,
          addQueryPrefix: true,
        }
      );
      // only rewrite search params if they should be changed
      if (queryString !== location.search) {
        setSearchParams(new URLSearchParams(queryString), {
          replace: true,
        });
      }
      setProcessed(true);
    }
  }, [
    lastRouteState,
    processed,
    searchParams,
    setSearchParams,
    urlState,
    location.search,
  ]);

  const actualSearchClient = searchClient || defaultSearchClient;

  return (
    <AlgoliaInstantSearch<ExtendedUIState, RouteState>
      searchClient={actualSearchClient}
      indexName={mainIndex}
      initialUiState={initialUiState}
      onStateChange={({ uiState, setUiState }) => {
        if (urlState) {
          const routeState: RouteState = stateToRoute(
            uiState,
            mainIndex,
            algoliaIndexes,
            language,
            cardMaxGrade
          );

          const algoliaParamsValues = Object.values(SEARCH_PARAMS) as string[];
          const notAlgoliaSearchParams = Object.fromEntries(
            Array.from(searchParams).filter(
              ([key]) => !algoliaParamsValues.includes(key)
            )
          );

          const queryString = routeState
            ? qs.stringify(
                { ...notAlgoliaSearchParams, ...routeState },
                {
                  format: 'RFC1738',
                  arrayFormat: 'comma',
                  encodeValuesOnly: true,
                  skipNulls: true,
                  addQueryPrefix: true,
                }
              )
            : '';

          const strLastRouteState = JSON.stringify(lastRouteState.current);
          const strRouteState = JSON.stringify(routeState);
          if (strRouteState !== strLastRouteState) {
            navigate(
              `${location.pathname}${queryString}${location.hash ? `${location.hash}` : ''}`,
              {
                replace: !!uiState[mainIndex]?.shouldReplace,
                preventScrollReset: true,
              }
            );
            instantSearchScrollTop();
            lastRouteState.current = routeState;
            setUiState(uiState);
          }
        } else {
          setUiState(uiState);
        }
      }}
      // Work-around range bug: forcing the min/max of the connectRange triggers a refinement
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      searchFunction={helper => {
        cleanFixedRangeRefinements(helper, cardMaxGrade);
        helper.search();
      }}
      // End work-around
    >
      {urlState && (
        <NavigationHandler
          sport={sport}
          mainIndex={mainIndex}
          lastRouteState={lastRouteState.current}
          initialUiState={initialUiState}
        />
      )}
      <InitialVirtualToggleHandler
        initialIndexUIState={initialUiState[mainIndex]}
      />
      <Configure
        distinct={distinct}
        hitsPerPage={defaultHitsPerPage}
        attributesToRetrieve={attributesToRetrieve}
        attributesToHighlight={[]}
        analyticsTags={analyticsTags}
        allowTyposOnNumericTokens={false}
        maxValuesPerFacet={MAX_SHOW_MORE_LIMIT}
      />
      <Filters
        filters={[
          ...(defaultFilters || []),
          ...(sport ? [sportFilter(sport)] : []),
          ...cardContextFilters,
        ]}
      />

      <OptionalFilters
        defaultIndex={mainIndex}
        getOptionalFilters={getOptionalFilters}
      />
      <InstantSearchRefresh interval={60_000} />
      {otherIndexes.map(i => (
        <Index indexName={i} key={i} indexId={i}>
          <Configure optionalFilters={getOptionalFilters(i)} />
          <Filters
            filters={[
              ...(defaultFilters || []),
              ...(sport ? [sportFilter(sport)] : []),
              ...getFilters(i),
            ]}
          />
        </Index>
      ))}
      {scrollToAnchor}
      {children}
    </AlgoliaInstantSearch>
  );
};

export const InstantCardSearch: React.FC<
  React.PropsWithChildren<
    SearchProps & {
      attributesToRetrieve?: string[];
      distinct?: number | boolean;
    }
  >
> = ({
  children,
  indexes = ['Ending Soon'],
  defaultHitsPerPage = undefined,
  analyticsTags,
  availableSorts,
  sport,
  defaultFilters,
  getOptionalFilters,
  urlState,
  attributesToRetrieve = ['asset_id', 'sale', 'slug'],
  distinct = false,
  initialIndexUIState,
  searchClient,
}) => {
  const mediaHitsPerPage = useMediaCardsPerPage();

  return (
    <InstantSearch
      indexes={indexes}
      availableSorts={availableSorts}
      defaultHitsPerPage={defaultHitsPerPage ?? mediaHitsPerPage}
      urlState={urlState}
      analyticsTags={analyticsTags}
      sport={sport}
      defaultFilters={defaultFilters}
      getOptionalFilters={getOptionalFilters}
      distinct={distinct}
      attributesToRetrieve={attributesToRetrieve}
      initialIndexUIState={initialIndexUIState}
      searchClient={searchClient}
    >
      {children}
    </InstantSearch>
  );
};
