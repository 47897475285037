import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import { usePortal } from 'hooks/usePortal';

export const Portal: FC<
  PropsWithChildren<{
    id:
      | 'dropdown'
      | 'above-bottom-bar-portal'
      | 'page-header-title'
      | 'page-header-title-breadcrumb'
      | 'sidebar-layout-toolbar'
      | 'page-header-right-button'
      | 'full-screen-animation'
      | 'pro-competitions-filters'
      | 'pro-games-filters'
      | 'snackbar'
      | 'onboarding-overlay'
      | 'loan-lineup-footer';
    style?: Partial<CSSStyleDeclaration>;
  }>
> = ({ id, style, children }) => {
  const target = usePortal(id, {
    replace: id === 'page-header-title',
    style,
  });
  return createPortal(children, target);
};
