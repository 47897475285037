import { useEffect } from 'react';

import { IS_PRERENDER } from '@sorare/core/src/config';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';

const loadScript = async (src: string, id?: string) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById('ze-snippet')) {
      resolve(undefined);
      return;
    }

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = src;
    if (id) script.setAttribute('data-id', id);
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

declare global {
  interface Window {
    zESettings: any;
    zE: any;
    quidget: {
      init: () => void;
      destroy: () => void;
    };
  }
}

export const openWidget = () => {
  if (window.zE) window.zE('webWidget', 'open');
};

const hideWidget = () => {
  if (window.zE) window.zE('webWidget', 'hide');
};

const showWidget = () => {
  if (window.zE) window.zE('webWidget', 'show');
};

const ZDForm = () => {
  const { currentUser } = useCurrentUserContext();

  useEffect(() => {
    if (IS_PRERENDER) return () => {};

    loadScript(
      'https://static.zdassets.com/ekr/snippet.js?key=5f9868fc-4b4d-4ddf-980f-a12935a05f96'
    ).then(() => showWidget());

    return () => hideWidget();
  }, []);

  window.zESettings = {
    webWidget: {
      contactForm: {
        fields: [
          { id: 'email', prefill: { '*': currentUser?.email } },
          { id: '360023889697', prefill: { '*': currentUser?.nickname } },
        ],
        ticketForms: [{ id: '22003730653597' }],
      },
    },
  };

  return null;
};

const QuidgetForm = () => {
  useEffect(() => {
    if (IS_PRERENDER) return () => {};

    if (window.quidget?.init) {
      window.quidget.init();
    } else {
      loadScript(
        'https://w.quidget.ai/quidget.loader.js',
        'c8aa4d39-a732-4196-bb29-8bcd29f0c9fb'
      );
    }

    return () => {
      if (window.quidget?.destroy) {
        window.quidget.destroy();
      }
    };
  }, []);

  return null;
};

export const Form = () => {
  const {
    flags: { useChatBot = false },
  } = useFeatureFlags();

  if (useChatBot) return <QuidgetForm />;

  return <ZDForm />;
};
