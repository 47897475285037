import { createContext, useContext } from 'react';

interface AnalyticsContext {
  identify: (id: string, traits?: { [trait: string]: any }) => void;
  track: (event: string, properties?: { [propery: string]: any }) => void;
}

export const AnalyticsContext = createContext<AnalyticsContext | null>(null);

export const useEventsContext = () => useContext(AnalyticsContext)!;
