import { TypedDocumentNode, gql } from '@apollo/client';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useCallback } from 'react';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from 'react-intl';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LoadingIndicator } from 'atoms/loader/LoadingIndicator';
import { BodyM, HeadlineM, HeadlineS } from 'atoms/typography';
import { Bold } from 'atoms/typography/Bold';
import { Dialog } from 'components/dialog';
import { AuthenticityToken } from 'components/form/AuthenticityToken';
import { LANDING } from 'constants/__generated__/routes';
import { useQuery } from 'hooks/graphql/useQuery';
import { useQueryString } from 'hooks/useQueryString';

import { API_ROOT } from '../../config';
import {
  OAuthApplicationQuery,
  OAuthApplicationQueryVariables,
} from './__generated__/index.graphql';

const messages = defineMessages({
  title: {
    id: 'OAuth.title',
    defaultMessage: 'Authorization required',
  },
  subtitle: {
    id: 'OAuth.subtitle',
    defaultMessage: '<b>{name}</b> wants to access your Sorare account',
  },
  scopes: {
    id: 'OAuth.scopes',
    defaultMessage: 'This will allow {name} to:',
  },
  nickname: {
    id: 'OAuth.nickname',
    defaultMessage: 'Access your username, your avatar and your wallet address',
  },
  cards: {
    id: 'OAuth.cards',
    defaultMessage:
      'Access details about your Cards, your achievements and your favorites',
  },
  auctions: {
    id: 'OAuth.auctions',
    defaultMessage:
      'Access details about your auctions, your offers and your notifications',
  },
  cannot: {
    id: 'OAuth.cannot',
    defaultMessage: 'This will NOT allow {name} to:',
  },
  email: {
    id: 'OAuth.email',
    defaultMessage: 'Access your email address',
  },
  cannot_so5: {
    id: 'OAuth.cannotSo5',
    defaultMessage:
      'List your future lineups, compose your lineups, claim your rewards',
  },
  cannot_market: {
    id: 'OAuth.cannotMarket',
    defaultMessage:
      'Bid on Cards, sell your Cards, make offers, accept offers or initiate a withdrawal',
  },
});

const Body = styled(Vertical).attrs({ gap: 2 })`
  padding: 0 var(--triple-unit);
`;
const Footer = styled(Horizontal)`
  padding: var(--triple-unit);
  form {
    margin-bottom: 0;
    width: 100%;
  }
`;
const Message = styled(Horizontal).attrs({ gap: 0.5 })``;
const Logo = styled.img`
  width: 64px;
`;
const PictureContainer = styled(Horizontal).attrs({ gap: 0, center: true })``;
const Icon = styled(FontAwesomeIcon)`
  margin-right: var(--unit);
`;

const authorizeEndpoint = `${API_ROOT}/oauth/authorize`;
const OAUTH_APPLICATION_QUERY = gql`
  query OAuthApplicationQuery($clientId: String!) {
    oauthApplication(clientId: $clientId) {
      id
      name
      pictureUrl
      scopes
      myAccessToken {
        id
      }
    }
  }
` as TypedDocumentNode<OAuthApplicationQuery, OAuthApplicationQueryVariables>;

export const OAuth = () => {
  const clientId = useQueryString('client_id');
  const state = useQueryString('state');
  const { data, loading } = useQuery(OAUTH_APPLICATION_QUERY, {
    variables: {
      clientId: clientId!,
    },
    skip: !clientId,
  });
  const redirectUri = useQueryString('redirect_uri');

  const authorizeFormIsMounted = useCallback((node: HTMLFormElement | null) => {
    node?.submit();
  }, []);

  if (loading) return <LoadingIndicator fullHeight />;
  if (!data?.oauthApplication) return <Navigate replace to={LANDING} />;

  const { name, pictureUrl, scopes, myAccessToken } = data.oauthApplication!;

  const renderForm = () => {
    return (
      <>
        <AuthenticityToken />
        <input type="hidden" name="client_id" value={clientId} />
        <input type="hidden" name="redirect_uri" value={redirectUri} />
        <input type="hidden" name="state" value={state} />
        <input type="hidden" name="response_type" value="code" />
        <input type="hidden" name="scope" value={scopes || 'public'} />
        <input type="hidden" name="code_challenge" />
        <input type="hidden" name="code_challenge_method" />
      </>
    );
  };

  const renderAllowedMessage = (message: MessageDescriptor) => {
    return (
      <Message key={message.id}>
        <Icon icon={faCheck} />
        <BodyM>
          <FormattedMessage {...message} />
        </BodyM>
      </Message>
    );
  };

  const renderBlockedMessage = (message: MessageDescriptor) => {
    return (
      <Message key={message.id}>
        <Icon icon={faTimes} />
        <BodyM>
          <FormattedMessage {...message} />
        </BodyM>
      </Message>
    );
  };

  if (myAccessToken) {
    return (
      <>
        <LoadingIndicator fullHeight />
        <form
          action={authorizeEndpoint}
          method="post"
          ref={authorizeFormIsMounted}
        >
          {renderForm()}
        </form>
      </>
    );
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open
      title={
        <HeadlineM as="h3" className="text-center">
          <FormattedMessage {...messages.title} />
        </HeadlineM>
      }
      footer={
        <Footer>
          <form action={authorizeEndpoint} method="post">
            {renderForm()}
            <Button color="primary" size="medium" type="submit" fullWidth>
              <FormattedMessage
                id="OAuth.authorize"
                defaultMessage="Authorize"
              />
            </Button>
          </form>
          <form action={authorizeEndpoint} method="post">
            <input type="hidden" name="_method" value="delete" />
            {renderForm()}
            <Button size="medium" color="red" type="submit" fullWidth>
              <FormattedMessage id="OAuth.deny" defaultMessage="Deny" />
            </Button>
          </form>
        </Footer>
      }
    >
      <Body>
        {pictureUrl && (
          <PictureContainer>
            <Logo alt={name} src={pictureUrl} />
          </PictureContainer>
        )}
        <HeadlineS as="h5">
          <FormattedMessage {...messages.subtitle} values={{ name, b: Bold }} />
        </HeadlineS>
        <Vertical>
          <BodyM bold>
            <FormattedMessage {...messages.scopes} values={{ name }} />
          </BodyM>

          {[
            scopes?.includes('email') && messages.email,
            messages.nickname,
            messages.cards,
            messages.auctions,
          ].map(m => m && renderAllowedMessage(m))}
        </Vertical>
        <Vertical>
          <BodyM bold>
            <FormattedMessage {...messages.cannot} values={{ name }} />
          </BodyM>
          {[
            !scopes?.includes('email') && messages.email,
            messages.cannot_so5,
            messages.cannot_market,
          ].map(m => m && renderBlockedMessage(m))}
        </Vertical>
      </Body>
    </Dialog>
  );
};
