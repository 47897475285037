import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { FootballLogo } from '@sorare/core/src/atoms/icons/FootballLogo';
import { MLBLogo } from '@sorare/core/src/atoms/icons/MLBLogo';
import { NBALogo } from '@sorare/core/src/atoms/icons/NBALogo';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { Ellipsis } from '@sorare/core/src/atoms/typography/Ellipsis';
import { Avatar } from '@sorare/core/src/components/user/Avatar';
import { useCurrentUserContext } from '@sorare/core/src/contexts/currentUser';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';

const Root = styled(Horizontal).attrs({ center: true })`
  position: relative;
  padding: var(--half-unit);
  border-radius: var(--triple-unit);
  padding-right: var(--unit);

  &:hover {
    background-color: var(--c-nd-50);
  }
`;
const SportIcon = styled(Horizontal).attrs({ center: true })`
  position: absolute;
  right: 0;
  bottom: 0;

  border: 2px solid var(--c-black);
  border-radius: 50%;
  width: var(--triple-unit);
  height: var(--triple-unit);
  overflow: hidden;
`;

const StyledMLBLogo = styled(MLBLogo)`
  min-width: 50px;
  min-height: 50px;
`;
const StyledNBALogo = styled(NBALogo)`
  position: absolute;
  top: 0;
  min-width: 60px;
  min-height: 50px;
`;

const sportIcons = {
  [Sport.FOOTBALL]: <FootballLogo />,
  [Sport.BASEBALL]: <StyledMLBLogo />,
  [Sport.NBA]: <StyledNBALogo />,
};

type Props = { sport: Sport; active?: boolean };
export const ProfileButtonContent = ({ sport, active }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const isLaptopAndAbove = useIsLaptop();

  if (!currentUser) {
    return null;
  }

  if (!isLaptopAndAbove) {
    return (
      <Root>
        <Avatar user={currentUser} rounded />
        <SportIcon>{sportIcons[sport]}</SportIcon>
      </Root>
    );
  }

  return (
    <Root>
      <Avatar user={currentUser} rounded />
      <Ellipsis>
        <LabelM bold>{currentUser.nickname}</LabelM>
      </Ellipsis>
      <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown} />
    </Root>
  );
};
