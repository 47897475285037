import { faCartShopping } from '@fortawesome/pro-solid-svg-icons';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useGenerateDialogPath } from '@sorare/core/src/hooks/navigation/useGenerateDialogPath';
import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';

import { useCart } from 'hooks/useCart';

export const NavigationCart = () => {
  const {
    flags: { useBulkBuy },
  } = useFeatureFlags();
  const { cartCount, savedForLaterCount } = useCart();
  const { sport } = useSportContext();
  const generateDialogPath = useGenerateDialogPath();
  const dialogRoute = generateDialogPath(DialogKey.checkout, sport);

  if (!useBulkBuy || cartCount + savedForLaterCount === 0) {
    return null;
  }
  return (
    <Button size="medium" color="tertiary" to={dialogRoute}>
      <FontAwesomeIcon icon={faCartShopping} />
      {cartCount || ''}
    </Button>
  );
};
