import { SVGAttributes } from 'react';

type Props = {
  stroke?: boolean;
  className?: string;
  style?: React.CSSProperties;
  color: SVGAttributes<SVGPathElement>['fill'];
};

export const Cursor = ({ color, className, style, stroke = true }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="61"
      viewBox="0 0 12 61"
      fill="none"
      className={className}
      style={style}
    >
      <path
        d="M3.30969 12.2009L3.30969 57.3933C3.30969 58.7822 4.43567 59.9082 5.82463 59.9082C7.2136 59.9082 8.33958 58.7822 8.33958 57.3933L8.33958 12.2462C8.33958 11.489 8.62268 10.7591 9.1333 10.2L9.43531 9.86923C12.2704 6.76457 10.0678 1.77047 5.86346 1.77047C1.72416 1.77047 -0.502867 6.63153 2.20026 9.7663L2.95759 9.11326L2.20027 9.76631L2.55997 10.1834C3.04362 10.7443 3.30969 11.4603 3.30969 12.2009Z"
        fill={color}
        strokeWidth="2"
        stroke={stroke ? 'var(--c-black)' : 'none'}
      />
    </svg>
  );
};
