import { type TypedDocumentNode, gql } from '@apollo/client';
import { SpringValue, easings, useSpring } from 'react-spring';

import type {
  useCardXpProgressBarSprings_cardXpGradeThreshold,
  useCardXpProgressBarSprings_leveledUpCard,
} from './__generated__/useCardXpProgressBarSprings.graphql';

export const DURATION_TO_STEP_3 = 1650;

type Springs = {
  color: SpringValue<string>;
  backgroundColor: SpringValue<string>;
  borderColor: SpringValue<string>;
  level: SpringValue<number>;
  percentage: SpringValue<number>;
};
type Args = {
  cardLeveledUp: useCardXpProgressBarSprings_leveledUpCard;
  gradeThresholds?: useCardXpProgressBarSprings_cardXpGradeThreshold[];
  started?: boolean;
  delay?: number;
};
export const useCardXpProgressBarSprings = ({
  cardLeveledUp,
  gradeThresholds,
  started,
  delay = 0,
}: Args): Springs => {
  const { card, xpGain } = cardLeveledUp;
  const preGainXp = card.xp - xpGain;
  const { grade } = card;

  const currentGradeLowLimit =
    gradeThresholds?.[card.grade - 1]?.threshold || 0;
  const nextGradeLowLimit = gradeThresholds?.[card.grade]?.threshold || 0;
  const previousGradeLowLimit =
    gradeThresholds?.[card.grade - 2]?.threshold || 0;

  const config = {
    mass: 1,
    friction: 80,
    tension: 340,
  };

  // State before the level up :
  //
  // previousGradeLowLimit                                              preGainXp               currentGradeLowLimit
  // |■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■                             |

  // State after the level up :
  //
  // currentGradeLowLimit               card.xp                                                  nextGradeLowLimit
  // |■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■                                                             |

  // This spring is animating the values from the first state to the second one.

  const [springValues] = useSpring(
    () => ({
      from: {
        percentage:
          ((preGainXp - previousGradeLowLimit) /
            (currentGradeLowLimit - previousGradeLowLimit)) *
          100,
        level: Math.max(0, grade - 1),
        backgroundColor: '#101010',
        color: '#ffffff',
        borderColor: '#212121',
      },
      ...(started && {
        delay,
        to: [
          {
            percentage: 100,
            config,
          },
          {
            immediate: true,
            percentage: 0,
            level: grade,
            config,
          },
          {
            backgroundColor: '#00fff7',
            color: '#101010',
            borderColor: '#101010',
            percentage:
              nextGradeLowLimit > 0
                ? ((card.xp - currentGradeLowLimit) /
                    (nextGradeLowLimit - currentGradeLowLimit)) *
                  100
                : 100,
            config,
          },
          {
            backgroundColor: '#101010',
            color: '#ffffff',
            borderColor: '#212121',
            config: {
              duration: 500,
              easing: easings.easeInOutQuart,
            },
          },
        ],
      }),
    }),
    [started]
  );

  return springValues;
};

useCardXpProgressBarSprings.fragments = {
  cardXpGradeThreshold: gql`
    fragment useCardXpProgressBarSprings_cardXpGradeThreshold on CardXPGradeThreshold {
      threshold
    }
  ` as TypedDocumentNode<useCardXpProgressBarSprings_cardXpGradeThreshold>,
  leveledUpCard: gql`
    fragment useCardXpProgressBarSprings_leveledUpCard on LeveledUpCard {
      id
      xpGain
      card {
        slug
        grade
        xp
      }
    }
  ` as TypedDocumentNode<useCardXpProgressBarSprings_leveledUpCard>,
};
