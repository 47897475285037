import { TypedDocumentNode, gql } from '@apollo/client';
import styled from 'styled-components';

import { Button } from '@sorare/core/src/atoms/buttons/Button';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BuyConfirmation } from '@sorare/core/src/components/buyActions/BuyConfirmation';

import { PrimaryBuyConfirmationOptions } from 'contexts/buyingConfirmation';

import {
  BuyPrimaryBuyConfirmation_anyCard,
  BuyPrimaryBuyConfirmation_anyReward,
} from './__generated__/index.graphql';

const Actions = styled(Vertical).attrs({ center: true })`
  width: 100%;
  & > * {
    width: calc(50% - var(--unit));
    flex-shrink: 0;
  }
`;

type Props = {
  card: BuyPrimaryBuyConfirmation_anyCard;
  rewards?: Nullable<BuyPrimaryBuyConfirmation_anyReward[]>;
  options?: PrimaryBuyConfirmationOptions;
  onClose: () => void;
};

export const BuyPrimaryBuyConfirmation = ({
  card,
  rewards,
  options,
  onClose,
}: Props) => {
  const { actions } = options || {};
  const { primary, secondary } = actions || {};
  return (
    <BuyConfirmation
      cards={[card]}
      additionalRewards={rewards}
      actions={
        actions && (
          <Actions>
            {primary && (
              <Button
                size="medium"
                color="secondary"
                fullWidth
                to={primary.to}
                onClick={() => {
                  onClose();
                  primary.onClick?.();
                }}
              >
                {primary.label}
              </Button>
            )}
            {secondary && (
              <Button
                size="medium"
                color="transparent"
                fullWidth
                to={secondary.to}
                onClick={() => {
                  onClose();
                  secondary.onClick?.();
                }}
              >
                {secondary.label}
              </Button>
            )}
          </Actions>
        )
      }
    />
  );
};

BuyPrimaryBuyConfirmation.fragments = {
  anyCard: gql`
    fragment BuyPrimaryBuyConfirmation_anyCard on AnyCardInterface {
      slug
      ...BuyConfirmation_anyCard
    }
    ${BuyConfirmation.fragments.anyCard}
  ` as TypedDocumentNode<BuyPrimaryBuyConfirmation_anyCard>,
  anyReward: gql`
    fragment BuyPrimaryBuyConfirmation_anyReward on AnyRewardInterface {
      id
      ...BuyConfirmation_anyReward
    }
    ${BuyConfirmation.fragments.anyReward}
  ` as TypedDocumentNode<BuyPrimaryBuyConfirmation_anyReward>,
};
