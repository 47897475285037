import { TypedDocumentNode, gql } from '@apollo/client';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { HeadlineM, LabelL } from 'atoms/typography';
import { Bold } from 'atoms/typography/Bold';

import { DetailedScoreLine } from './DetailedScoreLine';
import { CollectionGameHelper_cardCollection } from './__generated__/index.graphql';
import books from './assets/books.png';

const Illustration = styled.img`
  height: 80px;
`;
const WarningWrapper = styled(Horizontal)`
  align-items: flex-start;
  b {
    color: var(--c-yellow-400);
  }
`;
const WarningIconWrapper = styled.div`
  color: var(--c-yellow-400);
`;
type Props = {
  cardCollection: Nullable<CollectionGameHelper_cardCollection>;
};

export const CollectionGameHelper = ({ cardCollection }: Props) => {
  const { rarity, scoringMatrix } = cardCollection || {};
  const isCommonCollection = rarity === Rarity.common;

  return (
    <>
      <Vertical gap={2} center>
        <Illustration src={books} />
        <Vertical gap={0} className="text-center">
          <HeadlineM>
            <FormattedMessage
              id="collections.DetailsDialog.scoringMatrix"
              defaultMessage="Scoring Matrix"
            />
          </HeadlineM>
          <LabelL color="var(--c-nd-600)">
            <FormattedMessage
              id="collections.DetailsDialog.section1.description"
              defaultMessage="Each card has a collection score based on the following"
            />
          </LabelL>
        </Vertical>
      </Vertical>
      <Vertical gap={2}>
        {scoringMatrix?.map(value => {
          return <DetailedScoreLine key={value.name} detailedScore={value} />;
        })}
      </Vertical>
      <WarningWrapper>
        <WarningIconWrapper>
          <FontAwesomeIcon icon={faCircleExclamation} />
        </WarningIconWrapper>
        <span>
          {isCommonCollection ? (
            <FormattedMessage
              id="collections.Warning.message2.common"
              defaultMessage="If a card is <b>dropped</b>, the points do not count in the collection score"
              values={{ b: Bold }}
            />
          ) : (
            <FormattedMessage
              id="collections.Warning.message2"
              defaultMessage="If a card is <b>listed on the market</b> the points do not count in the collection score"
              values={{ b: Bold }}
            />
          )}
        </span>
      </WarningWrapper>
    </>
  );
};

CollectionGameHelper.fragments = {
  cardCollection: gql`
    fragment CollectionGameHelper_cardCollection on CardCollection {
      slug
      rarity
      scoringMatrix {
        name
        pictureUrl
        label
        explanation
        value
      }
    }
  ` as TypedDocumentNode<CollectionGameHelper_cardCollection>,
};
