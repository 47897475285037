import { useConnector } from 'react-instantsearch';

import { connectFilter } from 'components/search/connectors/connectFilter';

type Props = {
  name: string;
  filter: string;
};

const Filter = ({ name, filter }: Props) => {
  useConnector(connectFilter, {
    name,
    filter,
  });
  return null;
};

export const Filters = ({ filters }: { filters: string[] }) => {
  return (
    <>
      {filters.map(f => (
        <Filter key={f} name={`Filter:${f.slice(0, 10)}`} filter={f} />
      ))}
    </>
  );
};
