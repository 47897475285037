import { ApolloQueryResult } from '@apollo/client';
import { createContext, useContext } from 'react';

import { Currency, CurrencyCode } from 'lib/fiat';

import {
  ConfigQuery,
  ConfigQuery_currentLocation,
  ConfigQuery_currentUser,
  ConfigQuery_exchangeRate,
} from './types';

export interface AlgoliaCardIndexes {
  // blockchain cards indices
  New: string;
  'Ending Soon': string;
  'Newly Listed': string;
  'Highest Price': string;
  'Lowest Price': string;
  'Highest Average Score': string;
  'Best Value': string;
  'Popular Player': string;

  // On sale cards indices
  'Cards On Sale Ending Soon': string;
  'Cards On Sale Newly Listed': string;
  'Cards On Sale Highest Price': string;
  'Cards On Sale Lowest Price': string;
  'Cards On Sale Highest Average Score': string;
  'Cards On Sale Best Value': string;
  'Cards On Sale Popular Player': string;
}

export interface AlgoliaIndexes extends AlgoliaCardIndexes {
  User: string;
  Player: string;
  Club: string;
  League: string;
  Country: string;
  'National Team': string;
  Competition: string;
}

export type AlgoliaIndex = keyof AlgoliaIndexes;

export type AlgoliaCardIndexesName = keyof AlgoliaCardIndexes;

type Distribute<T> = T extends string ? `ASC-${T}` | `DESC-${T}` : never;

export type PrintablePlayerSortName = Distribute<
  | 'L5'
  | 'L10'
  | 'L15'
  | 'L15AA'
  | 'L15D'
  | 'season_avg'
  | 'name_for_sorting'
  | 'next_classic_fixture_projected_score'
  | 'next_daily_fixture_projected_score'
  | 'points'
  | 'assists'
  | 'rebounds'
  | 'blocks'
  | 'double_double'
  | 'average_mins_played'
  | 'total_mins_played'
  | 'steals'
  | 'three_points_made'
  | 'triple_double'
  | 'turnovers'
  | 'total_set_piece_taken'
  | 'total_goals'
  | 'total_goal_assist'
  | 'total_assist_penalty_won'
  | 'total_last_man_tackle'
  | 'total_clean_sheet'
  | 'total_yellow_card'
  | 'total_red_card'
  | 'total_error_lead_to_goal'
  | 'total_penalty_conceded'
  | 'total_three_goals_conceded'
  | 'average_set_piece_taken'
  | 'average_goals'
  | 'average_goal_assist'
  | 'average_assist_penalty_won'
  | 'average_last_man_tackle'
  | 'average_clean_sheet'
  | 'average_yellow_card'
  | 'average_red_card'
  | 'average_error_lead_to_goal'
  | 'average_penalty_conceded'
  | 'average_three_goals_conceded'
  | 'average_three_points_made'
  | 'floor_prices_in_season_limited'
  | 'floor_prices_in_season_rare'
  | 'floor_prices_in_season_super_rare'
  | 'floor_prices_in_season_unique'
  | 'floor_prices_all_seasons_limited'
  | 'floor_prices_all_seasons_rare'
  | 'floor_prices_all_seasons_super_rare'
  | 'floor_prices_all_seasons_unique'
  | 'ib_prices_limited'
  | `starter_odds_basis_points`
  | 'last_thirty_days_subscription_count'
  | 'hitting_at_base'
  | 'hitting_at_plate'
  | 'hitting_caught_stealing'
  | 'hitting_doubles'
  | 'hitting_hit_by_pitch'
  | 'hitting_home_runs'
  | 'hitting_runs'
  | 'hitting_runs_batted_in'
  | 'hitting_singles'
  | 'hitting_stolen_bases'
  | 'hitting_strikeouts'
  | 'hitting_triples'
  | 'hitting_walks'
  | 'pitching_earned_runs'
  | 'pitching_hits_allowed'
  | 'pitching_hit_batsmen'
  | 'pitching_holds'
  | 'pitching_innings_pitched'
  | 'pitching_no_hitters'
  | 'pitching_pitch_count'
  | 'pitching_relief_appearance'
  | 'pitching_saves'
  | 'pitching_strikeouts'
  | 'pitching_walks'
  | 'pitching_wins'
  | 'pitching_wins'
>;

export type PrintableI18nPlayerSortName =
  | 'DESC-L10'
  | 'DESC-L15'
  | Distribute<'player_name' | 'name_for_sorting' | 'name_for_sorting'>;

export type GallerySortName =
  | 'Cards New'
  | 'Cards Highest Average Score'
  | 'Cards Highest Price'
  | 'Cards Lowest Price'
  | 'Cards Player Name';

export type GallerySortNames = GallerySortName[];

// TODO(sylvain): Need to figure a way to support dynamic fields
export type ShortSortName = string;

export type AlgoliaCardIndexesNames = AlgoliaCardIndexesName[];

interface ConfigContext {
  algoliaIndexes: AlgoliaIndexes;
  algoliaCardIndexes: AlgoliaCardIndexes;
  algoliaApplicationId: string;
  algoliaSearchApiKey: string;
  ethereumNetworkId: string;
  ethereumEndpoint: string;
  sorareTokensAddress: EthereumAddress;
  sorareEncryptionKey: string;
  sponsorAccountAddress: string;
  bankAddress: string;
  ethQuantum: bigint;
  starkExchangeAddress: EthereumAddress;
  so5: {
    teamsPlayingNextClassicGameWeeks: ConfigQuery['config']['so5']['teamsPlayingNextClassicGameWeeks'];
    teamsPlayingNextDailyGameWeeks: ConfigQuery['config']['so5']['teamsPlayingNextDailyGameWeeks'];
    so5Competitions: ConfigQuery['so5']['so5Competitions'];
    inSeasonName: string;
    allSeasonsName: string;
    cardMaxGrade: number;
    cardXpLevelUpable: boolean;
  };
  currentUser: ConfigQuery_currentUser | null;
  refetch: () => Promise<ApolloQueryResult<ConfigQuery>>;
  updateQuery: (user: ConfigQuery_currentUser) => void;
  exchangeRate: ConfigQuery_exchangeRate & {
    getEthRate: (to: CurrencyCode) => number;
  };
  marketFeeRateBasisPoints: number;
  marketFeePercentagePoints: bigint;
  checkoutFeesBasisPoints: string;
  idealDepositFeesAmountMinor: number;
  mangopayVisaFeesBasisPoints: string;
  mangopayAmexFeesBasisPoints: string;
  paypalDepositFeesBasisPoints: number;
  mangopayApplePayDepositFeesBasisPoints: number;
  stripeCreditCardFeesBasisPoints: string;
  defaultFiatCurrency: Currency;
  minimumReceiveWeiAmount: bigint;
  currentLocation: ConfigQuery_currentLocation;
  marketplacePromotionalEvents: ConfigQuery['config']['marketplacePromotionalEvents'];
  promotionalEvents: ConfigQuery['tokens']['promotionalEvents'];
  counts: ConfigQuery['config']['counts'];
}

export const ConfigContext = createContext<ConfigContext | null>(null);

export const useConfigContext = () => useContext(ConfigContext)!;
