import { FormattedMessage, defineMessages } from 'react-intl';

import {
  Currency,
  SupportedCurrency,
} from '@sorare/core/src/__generated__/globalTypes';
import { BodyM, HeadlineXS, LabelS } from '@sorare/core/src/atoms/typography';
import { AmountWithConversion } from '@sorare/core/src/components/buyActions/AmountWithConversion';
import { MonetaryAmountOutput } from '@sorare/core/src/hooks/useMonetaryAmount';
import { getMonetaryAmountIndex } from '@sorare/core/src/lib/monetaryAmount';

import { Row } from 'components/offer/Row';
import { MarketFeeStatus } from 'hooks/useMarketFeesHelperStatus';

const offerMessages = defineMessages({
  initialOffer: {
    id: 'FeesDetails.total',
    defaultMessage: 'Total',
  },
  marketFees: {
    id: 'FeesDetails.marketFees',
    defaultMessage: 'Market fee',
  },
  partialMarketFees: {
    id: 'FeesDetails.marketFees.partial',
    defaultMessage: 'Market fee (up to)',
  },
  youReceive: {
    id: 'FeesDetails.youReceive',
    defaultMessage: 'You receive',
  },
  partialYouReceive: {
    id: 'FeesDetails.youReceive.partial',
    defaultMessage: 'You receive (at least)',
  },
});

const completedOfferUniqueMessages = defineMessages({
  initialOffer: {
    id: 'FeesDetails.listedPrice',
    defaultMessage: 'Listed price',
  },
  marketFees: {
    id: 'FeesDetails.marketFees',
    defaultMessage: 'Market fee',
  },
  youReceive: {
    id: 'FeesDetails.youReceived',
    defaultMessage: 'You received',
  },
});

const completedOfferMessages = {
  initialOffer: completedOfferUniqueMessages.initialOffer,
  marketFees: completedOfferUniqueMessages.marketFees,
  youReceive: completedOfferUniqueMessages.youReceive,
  partialMarketFees: completedOfferUniqueMessages.marketFees,
  partialYouReceive: completedOfferUniqueMessages.youReceive,
};

interface Props {
  monetaryAmount: MonetaryAmountOutput;
  marketFeeMonetaryAmount: MonetaryAmountOutput;
  referenceCurrency: SupportedCurrency;
  completed?: boolean;
  marketFeeStatus?: MarketFeeStatus.ENABLED | MarketFeeStatus.PARTIALLY_ENABLED;
}

export const FeesTooltipFromProps = ({
  monetaryAmount,
  marketFeeMonetaryAmount,
  referenceCurrency,
  completed,
  marketFeeStatus,
}: Props) => {
  const isFiat = referenceCurrency !== SupportedCurrency.WEI;
  const monetaryAmountIndex = getMonetaryAmountIndex(referenceCurrency);
  const total =
    BigInt(monetaryAmount[monetaryAmountIndex]) -
    BigInt(marketFeeMonetaryAmount[monetaryAmountIndex]);
  const messages = completed ? completedOfferMessages : offerMessages;

  return (
    <>
      <Row
        borderless
        title={
          <BodyM>
            <FormattedMessage {...messages.initialOffer} />
          </BodyM>
        }
      >
        <BodyM>
          <AmountWithConversion
            monetaryAmount={{ ...monetaryAmount, referenceCurrency }}
            primaryCurrency={isFiat ? Currency.FIAT : Currency.ETH}
            hideExponent
          />
        </BodyM>
      </Row>
      <Row
        borderless
        title={
          <BodyM>
            <FormattedMessage
              {...(marketFeeStatus === MarketFeeStatus.PARTIALLY_ENABLED
                ? messages.partialMarketFees
                : messages.marketFees)}
            />
          </BodyM>
        }
      >
        <BodyM>
          <AmountWithConversion
            monetaryAmount={{ ...marketFeeMonetaryAmount, referenceCurrency }}
            primaryCurrency={isFiat ? Currency.FIAT : Currency.ETH}
            hideExponent
          />
        </BodyM>
      </Row>
      <Row borderless>
        <LabelS>
          <FormattedMessage
            id="FeesTooltipFromProps.marketFeeNotAppliedToEveryCard"
            defaultMessage="The market fee may not apply to every card involved in the transaction."
          />
        </LabelS>
      </Row>
      <Row
        borderless
        title={
          <HeadlineXS as="p">
            <FormattedMessage
              {...(marketFeeStatus === MarketFeeStatus.PARTIALLY_ENABLED
                ? messages.partialYouReceive
                : messages.youReceive)}
            />
          </HeadlineXS>
        }
      >
        <HeadlineXS as="p">
          <AmountWithConversion
            monetaryAmount={{ [monetaryAmountIndex]: total, referenceCurrency }}
            primaryCurrency={isFiat ? Currency.FIAT : Currency.ETH}
            hideExponent
          />
        </HeadlineXS>
      </Row>
    </>
  );
};
