import { isExternalDomain } from 'lib/urls';

import { useQueryString } from './useQueryString';

export const useRedirectUrl = () => {
  const redirectUrl = decodeURIComponent(useQueryString('redirectUrl') || '');
  if (!redirectUrl || isExternalDomain(redirectUrl)) {
    return undefined;
  }
  try {
    const { pathname, search } = new URL(redirectUrl);
    return `${pathname}${search}`;
  } catch {
    return redirectUrl;
  }
};
