import { type TypedDocumentNode, gql } from '@apollo/client';
import classnames from 'classnames';
import { ReactNode } from 'react';
import { To } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { Horizontal, SBHorizontal, Vertical } from 'atoms/layout/flex';
import { LabelM, LabelS } from 'atoms/typography';
import { Avatar } from 'components/user/Avatar';
import { UserName } from 'components/user/UserName';
import { useShowBottomBarNav } from 'routing/MultiSportBottomNavBar/useShowBottomBarNav';
import { regularMaterial } from 'style/utils';

import type { LeaderboardRow_publicUserInfoInterface } from './__generated__/index.graphql';

const Root = styled(SBHorizontal).attrs({ gap: 2 })`
  --outline-width: 2px;
  border-radius: var(--double-unit);
  padding: var(--intermediate-unit);
  width: 100%;
  text-align: left;

  &.highlighted {
    position: sticky;
    z-index: 1;
    top: calc(var(--parent-stack-height) + var(--unit) + var(--outline-width));
    bottom: calc(var(--double-unit) + var(--outline-width));
    outline: var(--outline-width) solid var(--c-brand-600);
    ${regularMaterial};

    &.showBottomNavBar {
      bottom: calc(
        var(--navbar-height-mobile) + var(--double-unit) + var(--outline-width)
      );
    }
  }
`;
const Infos = styled(Vertical).attrs({ gap: 0.5 })`
  overflow: hidden;
`;
const Rank = styled(LabelS).attrs({ bold: true })`
  width: calc(6 * var(--unit));
  text-align: center;
`;

type Props = {
  rank?: number | null;
  avatar?: ReactNode;
  user: LeaderboardRow_publicUserInfoInterface;
  caption?: ReactNode;
  children: ReactNode;
  to?: To;
  onClick?: () => void;
  highlighted?: boolean;
};

export const LeaderboardRow = ({
  rank,
  user,
  avatar,
  caption,
  children,
  to,
  onClick,
  highlighted,
}: Props) => {
  const showBottomNavBar = useShowBottomBarNav();

  return (
    <Root
      className={classnames({ showBottomNavBar, highlighted })}
      {...(to && { as: Link, to, replace: true, onClick })}
      {...(onClick && { as: ButtonBase, onClick })}
    >
      <Horizontal>
        <Rank>{rank || '-'}</Rank>
        <div>{avatar || <Avatar size={4} rounded user={user} />}</div>
        <Infos>
          <LabelM bold>
            <UserName user={user} />
          </LabelM>
          {caption}
        </Infos>
      </Horizontal>
      <div>{children}</div>
    </Root>
  );
};

LeaderboardRow.fragments = {
  publicUserInfoInterface: gql`
    fragment LeaderboardRow_publicUserInfoInterface on PublicUserInfoInterface {
      slug
      ...Avatar_publicUserInfoInterface
      ...UserName_publicUserInfoInterface
    }
    ${Avatar.fragments.publicUserInfoInterface}
    ${UserName.fragments.user}
  ` as TypedDocumentNode<LeaderboardRow_publicUserInfoInterface>,
};
