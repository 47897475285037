import { unitMapping } from 'lib/style';

import { IconColor, useIconColor } from './useIconColor';

type Props = {
  size?: keyof typeof unitMapping;
  color?: IconColor;
};

export const Binoculars = ({ size = 3, color }: Props) => {
  const { fill } = useIconColor(color);

  return (
    <svg
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0192 59.5C31.1655 49 49.7011 49 63.3808 59.5H67.7014L56 35H38.5C34.5297 39.9629 26.8375 48.2097 19.1902 56.4086L19.1901 56.4086L19.1856 56.4135L19.1827 56.4166C18.2212 57.4474 17.2605 58.4774 16.308 59.5H17.0192ZM40 112C48.8366 112 56 104.837 56 96C56 87.1634 48.8366 80 40 80C31.1634 80 24 87.1634 24 96C24 104.837 31.1634 112 40 112ZM69.6739 108C64.9268 119.727 53.4295 128 40 128C22.3269 128 8 113.673 8 96C8 78.3269 22.3269 64 40 64C53.4295 64 64.9268 72.2727 69.6739 84H90.3261C95.0732 72.2727 106.57 64 120 64C137.673 64 152 78.3269 152 96C152 113.673 137.673 128 120 128C106.57 128 95.0732 119.727 90.3261 108H69.6739ZM120 112C128.837 112 136 104.837 136 96C136 87.1634 128.837 80 120 80C111.163 80 104 87.1634 104 96C104 104.837 111.163 112 120 112ZM141.981 59.5C127.834 49 109.299 49 95.6192 59.5H91.2986L103 35H120.5C124.47 39.9627 132.162 48.2093 139.809 56.4079L139.809 56.4081L139.81 56.4084L139.81 56.4086L139.856 56.4576C140.804 57.4747 141.752 58.491 142.692 59.5H141.981ZM87 96.5C87 100.09 84.0899 103 80.5 103C76.9101 103 74 100.09 74 96.5C74 92.9101 76.9101 90 80.5 90C84.0899 90 87 92.9101 87 96.5Z"
        fill={fill}
      />
    </svg>
  );
};
