/* eslint-disable no-restricted-properties */
/* eslint-disable no-console */
import {
  faClose,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useKey } from 'react-use';
import styled from 'styled-components';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { LabelXS } from 'atoms/typography';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { sortBy } from 'lib/arrays';
import { Color } from 'style/types';

import { DebugContext, GraphQLCall } from '.';

interface Props {
  children: ReactNode;
}

interface DebugProps {
  graphQLCalls: GraphQLCall[];
}

const MEDIUM_COMPLEXITY = 1000;
const MEDIUM_DEPTH = 3;
const HIGH_COMPLEXITY = 3000;
const HIGH_DEPTH = 7;
const HIGH_GQL_CALLS = 7;

const Root = styled(ButtonBase)`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  padding: var(--half-unit) var(--triple-unit) var(--half-unit) var(--unit);
  background: var(--c-nd-800);
  &.closed {
    cursor: pointer;
    right: 20px;
    left: auto;
    padding-right: var(--unit);
    border-radius: var(--half-unit) var(--half-unit) 0 0;
  }
`;
const Calls = styled(Horizontal)`
  overflow-x: auto;
`;
const Call = styled.div`
  flex-shrink: 0;
`;
const CloseButton = styled(ButtonBase)`
  width: var(--double-unit);
  height: var(--double-unit);
  cursor: pointer;
  position: absolute;
  right: var(--unit);
  top: calc(50% - var(--double-unit) / 2);
`;

const depthColor = (depth: number): Color => {
  if (depth < MEDIUM_DEPTH) return 'var(--c-rivals-win)';
  if (depth < HIGH_DEPTH) return 'var(--c-score-low)';
  return 'var(--c-rivals-loss)';
};

const complexityColor = (complexity: number): Color => {
  if (complexity < MEDIUM_COMPLEXITY) return 'var(--c-rivals-win)';
  if (complexity < HIGH_COMPLEXITY) return 'var(--c-score-low)';
  return 'var(--c-rivals-loss)';
};

const locationEmoji = (subgraphs: string[]) => {
  const backend = subgraphs.includes('BACKEND');
  const go = subgraphs.includes('GO');
  if (backend && go) return '🌎';
  if (backend) return '🇫🇷';
  if (go) return '🇺🇸';
  return '❓';
};

const DebugInfos = (props: DebugProps) => {
  const { graphQLCalls } = props;
  const [open, setOpen] = useLocalStorage<boolean>(
    'gql-debug',
    process.env.NODE_ENV === 'development'
  );

  useKey(
    (event: KeyboardEvent) => {
      return event.key === 'i' && (event.metaKey || event.ctrlKey);
    },
    () => {
      setOpen(!open);
    }
  );

  if (graphQLCalls.length === 0) return null;

  if (!open) {
    const isComplex =
      graphQLCalls.length > HIGH_GQL_CALLS ||
      graphQLCalls.filter(
        call =>
          call.complexity > HIGH_COMPLEXITY ||
          (call.complexity > MEDIUM_COMPLEXITY && call.depth >= HIGH_DEPTH)
      ).length > 0;

    return (
      <Root type="submit" className="closed" onClick={() => setOpen(true)}>
        <LabelXS color="var(--c-black)">
          GQL ({graphQLCalls.length}){' '}
          {isComplex && (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              color="var(--c-red-600)"
            />
          )}
        </LabelXS>
      </Root>
    );
  }

  return (
    <Root as="div">
      <Calls>
        {sortBy(call => call.complexity, graphQLCalls)
          .reverse()
          .map(call => {
            const { operation, complexity, depth } = call;

            return (
              <Call key={call.id}>
                <LabelXS color="var(--c-black)">
                  {locationEmoji(call.subgraphs)} {operation.operationName}(C=
                  <b style={{ color: complexityColor(complexity) }}>
                    {complexity}
                  </b>
                  , D=<b style={{ color: depthColor(depth) }}>{depth}</b>)
                </LabelXS>
              </Call>
            );
          })}
      </Calls>
      <CloseButton type="submit" onClick={() => setOpen(false)}>
        <FontAwesomeIcon icon={faClose} color="var(--c-black)" />
      </CloseButton>
    </Root>
  );
};

const SILENCED_OPERATIONS = ['PingConfigQuery'];

export const DebugProvider = ({ children }: Props) => {
  const [graphQLCalls, setGraphQLCalls] = useState<
    Record<string, GraphQLCall[]>
  >({});

  // We use `window.location` instead of `useLocation()`
  // because we want to avoid depending on `location` and having the
  // `recordGQLOperation` change at each page load.
  // This would result in the `DebugLink` changing at each page load,
  // which is then making the `ApolloClient` change at each page load,
  // which then makes the whole app re-render at each page load.
  const recordGQLOperation = useCallback(
    (call: GraphQLCall) => {
      if (process.env.NODE_ENV !== 'development') {
        return;
      }

      const { operation, complexity, depth, response } = call;

      if (SILENCED_OPERATIONS.includes(operation.operationName)) {
        return;
      }
      setGraphQLCalls(v => {
        const calls = v[window.location.pathname] || [];
        console.log(`🐞 GraphQL Query ${operation.operationName}`, {
          variables: operation.variables,
          response,
          complexity,
          depth,
          source: operation.query.loc?.source?.body
            ?.replace(/\s+/g, ' ')
            ?.trim(),
        });
        return {
          ...v,
          [window.location.pathname]: calls.concat([call]),
        };
      });
    },
    [setGraphQLCalls]
  );

  const value = useMemo(
    () => ({
      recordGQLOperation,
    }),
    [recordGQLOperation]
  );

  return (
    <DebugContext.Provider value={value}>
      {children}
      {process.env.NODE_ENV === 'development' && (
        <DebugInfos
          graphQLCalls={graphQLCalls[window.location.pathname] || []}
        />
      )}
    </DebugContext.Provider>
  );
};
