import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { InGameCurrency } from '__generated__/globalTypes';
import { scarcityNames } from 'lib/cards';
import { isType } from 'lib/gql';
import { isEnergyCurrency, isXPCurrency } from 'lib/rewards';
import { inGameCurrencyToRarity, scarcityMessages } from 'lib/scarcity';

import type { RewardTitle_AnyRewardInterface } from './__generated__/index.graphql';

type Props = { reward: RewardTitle_AnyRewardInterface };

export const RewardTitle = ({ reward }: Props) => {
  if (isType(reward, 'InGameCurrencyReward')) {
    if (reward.config.currency === InGameCurrency.OLD_CLUB_SHOP_COIN) {
      return (
        <FormattedMessage
          id="buyTokenConfirmation.buy.inGameCurrencyReward.subtitle.clubShopCoin"
          defaultMessage="You also received {amount} club shop coin!"
          values={{
            amount: reward.config.amount,
          }}
        />
      );
    }
    const rarity = inGameCurrencyToRarity[reward.config.currency]!;
    if (isEnergyCurrency(reward.config.currency)) {
      return (
        <FormattedMessage
          id="buyTokenConfirmation.buy.inGameCurrencyReward.subtitle.energy"
          defaultMessage="You also received {amount} {rarity} energy!"
          values={{
            amount: reward.config.amount,
            rarity: <FormattedMessage {...scarcityMessages[rarity]} />,
          }}
        />
      );
    }
    if (isXPCurrency(reward.config.currency)) {
      return (
        <FormattedMessage
          id="buyTokenConfirmation.buy.inGameCurrencyReward.subtitle.xp"
          defaultMessage="You also received {amount} {rarity} XP!"
          values={{
            amount: reward.config.amount,
            rarity: <FormattedMessage {...scarcityMessages[rarity]} />,
          }}
        />
      );
    }
  }
  if (isType(reward, 'AnyCardReward')) {
    return (
      <FormattedMessage
        id="buyTokenConfirmation.buy.cardReward.subtitle"
        defaultMessage="2 for 1, {playerDisplayName} is also joining your team!"
        values={{
          playerDisplayName: reward.card.anyPlayer.displayName,
        }}
      />
    );
  }
  if (isType(reward, 'CardShardsReward')) {
    return (
      <FormattedMessage
        id="buyTokenConfirmation.buy.cardShardReward.subtitle2"
        defaultMessage="You also received {quantity} {rarity} essence {quantity, plural, one {capsule} other {capsules}}!"
        values={{
          quantity: reward.quantity,
          rarity: scarcityNames[reward.rarity],
        }}
      />
    );
  }

  if (isType(reward, 'ProbabilisticBundleReward')) {
    return (
      <FormattedMessage
        id="buyTokenConfirmation.buy.probabilisticBundle.subtitle"
        defaultMessage="You also received a {name}!"
        values={{
          name: reward.probabilisticBundle.config.name,
        }}
      />
    );
  }
  return null;
};

RewardTitle.fragments = {
  AnyRewardInterface: gql`
    fragment RewardTitle_AnyRewardInterface on AnyRewardInterface {
      id
      ... on InGameCurrencyReward {
        id
        config {
          id
          amount
          currency
        }
      }
      ... on AnyCardReward {
        id
        card {
          slug
          anyPlayer {
            slug
            displayName
          }
        }
      }
      ... on CardShardsReward {
        id
        quantity
        rarity
      }
      ... on ProbabilisticBundleReward {
        id
        probabilisticBundle {
          id
          config {
            id
            name
          }
        }
      }
    }
  ` as TypedDocumentNode<RewardTitle_AnyRewardInterface>,
};
