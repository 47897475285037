import { matchPath } from 'react-router-dom';

import { catchAll } from '@sorare/routing';

import { Sport } from '__generated__/globalTypes';
import { FOOTBALL_PATH, MLB_PATH, NBA_PATH } from 'constants/routes';

export const sportFromCountry = (
  defaultSport?: Sport | null,
  defaultSportOnlyIfUS?: boolean
) => {
  const { sorareCountry = 'FR' } = document.documentElement.dataset;
  if (defaultSportOnlyIfUS) {
    return sorareCountry.toLowerCase() === 'us'
      ? defaultSport || Sport.NBA
      : Sport.FOOTBALL;
  }
  return (
    defaultSport ||
    (sorareCountry.toLowerCase() === 'us' ? Sport.NBA : Sport.FOOTBALL)
  );
};

export const sportFromPath = (path: string, defaultSport?: Sport | null) => {
  const mainSport = defaultSport || sportFromCountry(defaultSport);

  if (matchPath(catchAll(NBA_PATH), path)) {
    return Sport.NBA;
  }
  if (matchPath(catchAll(MLB_PATH), path)) {
    return Sport.BASEBALL;
  }
  if (matchPath(catchAll(FOOTBALL_PATH), path)) {
    return Sport.FOOTBALL;
  }
  return mainSport;
};
