import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import { GoogleReCAPTCHA, ReCAPTCHA } from 'components/recaptcha';
import { useEvents } from 'lib/events/useEvents';

import { WalletDrawerContext, WalletTab } from '.';
import { DepositProvider } from './Deposit/Provider';
import { useBackButtonDestinations } from './useBackButtonDestinations';

interface Props {
  children: ReactNode;
}

export const WalletDrawerProvider = ({ children }: Props) => {
  const backButtonDestinations = useBackButtonDestinations();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [walletOpened, setWalletOpened] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [currentTab, setCurrentTab] = useState<WalletTab>(WalletTab.HOME);
  const [beforeBackButton, setBeforeBackButton] = useState<() => void>(
    () => {}
  );
  const [navFromMenu, setNavFromMenu] = useState<boolean>(false);
  const [walletIsLocked, setWalletIsLocked] = useState<boolean>(false);
  const track = useEvents();

  const recaptchaRef = useRef<GoogleReCAPTCHA>(null);

  const trackOpenWallet = useCallback(() => {
    track('Wallet Opened', {});
  }, [track]);

  const showDrawer = useCallback(() => {
    setDrawerOpened(true);
    trackOpenWallet();
  }, [trackOpenWallet]);
  const hideDrawer = useCallback(() => {
    setDrawerOpened(false);
  }, []);

  const toggleDrawer = useCallback(() => {
    setDrawerOpened(prevValue => {
      if (!prevValue) {
        trackOpenWallet();
      }
      return !prevValue;
    });
  }, [trackOpenWallet]);

  const showWallet = useCallback(() => {
    showDrawer();
    setWalletOpened(true);
  }, [showDrawer]);
  const hideWallet = useCallback(() => setWalletOpened(false), []);

  const closeWalletAndDrawer = useCallback(() => {
    hideDrawer();
    hideWallet();
    setNavFromMenu(false);
    setWalletIsLocked(false);
    setCurrentTab(WalletTab.HOME);
  }, [hideWallet, hideDrawer]);

  const onBackButton = useCallback(() => {
    if (beforeBackButton) beforeBackButton();
    else {
      hideWallet();
      setNavFromMenu(false);
      setWalletIsLocked(false);
      setCurrentTab(backButtonDestinations[currentTab] || WalletTab.HOME);
      if (!backButtonDestinations[currentTab]) hideDrawer();
    }
  }, [
    beforeBackButton,
    hideWallet,
    backButtonDestinations,
    currentTab,
    hideDrawer,
  ]);

  const value = useMemo(
    () => ({
      drawerOpened,
      hideDrawer,
      showDrawer,
      toggleDrawer,
      showWallet,
      hideWallet,
      walletOpened,
      mounted,
      setMounted,
      currentTab,
      setCurrentTab,
      navFromMenu,
      setNavFromMenu,
      walletIsLocked,
      setWalletIsLocked,
      onBackButton,
      setBeforeBackButton,
      closeWalletAndDrawer,
      recaptchaRef,
    }),
    [
      closeWalletAndDrawer,
      currentTab,
      drawerOpened,
      hideDrawer,
      hideWallet,
      mounted,
      navFromMenu,
      onBackButton,
      showDrawer,
      showWallet,
      toggleDrawer,
      walletIsLocked,
      walletOpened,
    ]
  );

  return (
    <WalletDrawerContext.Provider value={value}>
      {walletOpened && <ReCAPTCHA ref={recaptchaRef} />}
      <DepositProvider>{children}</DepositProvider>
    </WalletDrawerContext.Provider>
  );
};
