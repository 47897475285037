import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { IconButton } from 'atoms/buttons/IconButton';
import { Dropdown } from 'atoms/dropdowns/Dropdown';
import { Vertical } from 'atoms/layout/flex';
import {
  GAME_RULES,
  LEGAL_NOTICE,
  SETTINGS,
  TERMS_AND_CONDITIONS,
} from 'constants/__generated__/routes';
import { ANY_SPORT_BLOG } from 'constants/routes';
import { useSportContext } from 'contexts/sport';
import { copyright } from 'lib/copyright';
import { useEvents } from 'lib/events/useEvents';
import { navLabels } from 'lib/glossary';

const Root = styled.div`
  margin: auto auto var(--double-unit);
`;

const Item = styled(Link)`
  display: block;
  padding: var(--unit) var(--double-unit);
  font: var(--t-16);

  &:hover {
    background-color: var(--c-nd-50);
  }
`;

const LinkWrapper = styled(Vertical).attrs({ gap: 0 })`
  padding: var(--unit) 0;
`;

const Copyright = styled.div`
  border-top: 1px solid var(--c-nd-200);
  padding: var(--unit) var(--double-unit);
  font: var(--t-14);
  color: var(--c-nd-500);
  margin: auto;
`;

const CopyrightLine = styled.div`
  width: 150px;
`;

export const FooterMenu = () => {
  const { sport } = useSportContext();
  const { formatMessage } = useIntl();
  const track = useEvents();

  const trackLink = (source: string) => {
    track('Click Footer link', {
      source,
    });
  };

  return (
    <Root>
      <Dropdown
        label={props => (
          <IconButton icon={faInfoCircle} color="transparent" {...props} />
        )}
      >
        <LinkWrapper>
          <Item
            to={generatePath(ANY_SPORT_BLOG, {
              sport: sport.toLowerCase(),
            })}
            onClick={() =>
              trackLink(
                generatePath(ANY_SPORT_BLOG, {
                  sport: sport.toLowerCase(),
                })
              )
            }
          >
            {formatMessage(navLabels.blog)}
          </Item>
          <Item
            to={TERMS_AND_CONDITIONS}
            onClick={() => trackLink(TERMS_AND_CONDITIONS)}
          >
            {formatMessage(navLabels.terms)}
          </Item>
          <Item to={GAME_RULES} onClick={() => trackLink(GAME_RULES)}>
            {formatMessage(navLabels.gameRules)}
          </Item>
          <Item to={LEGAL_NOTICE} onClick={() => trackLink(LEGAL_NOTICE)}>
            {formatMessage(navLabels.legalNotice)}
          </Item>
          <Item to={SETTINGS} onClick={() => trackLink(SETTINGS)}>
            {formatMessage(navLabels.settings)}
          </Item>
        </LinkWrapper>
        <Copyright>
          <CopyrightLine>{copyright}</CopyrightLine>
        </Copyright>
      </Dropdown>
    </Root>
  );
};
