import { createContext, useContext } from 'react';

import { BrazeAPI, BrazeData } from './types';

type BrazeContextType = {
  data: BrazeData;
  api: BrazeAPI | null;
};

export const BrazeContext = createContext<BrazeContextType | null>(null);

export const useBrazeContext = () => useContext(BrazeContext)!;
