import { flushSync } from 'react-dom';

export const useTransitionApi = (props?: {
  skipTransition?: boolean;
  flushSync?: boolean;
}) => {
  const { skipTransition, flushSync: flushSyncOpt = true } = props || {};
  const updateDOM = (callback: () => void) => {
    if (skipTransition || !(document as any).startViewTransition) {
      const updateCallbackDone = Promise.resolve(callback()).then(() => {});
      const ready = Promise.reject(Error('View transitions unsupported'));
      // Avoid spamming the console with this error unless the promise is used.
      ready.catch(() => {});
      return {
        ready,
        updateCallbackDone,
        finished: updateCallbackDone,
        skipTransition: () => {},
      };
    }

    const fn = () => (flushSyncOpt ? flushSync(callback) : callback());

    const transition = (document as any).startViewTransition(fn);

    return transition as ViewTransition;
  };
  return { updateDOM };
};
