import { PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { Video } from 'atoms/ui/Video';
import { CARD_BORDER_RADIUS } from 'lib/cards';

import mp4VideoLimited from './assets/mp4/energized_limited.mp4';
import mp4VideoRare from './assets/mp4/energized_rare.mp4';
import mp4VideoSuperRare from './assets/mp4/energized_super_rare.mp4';
import mp4VideoUnique from './assets/mp4/energized_unique.mp4';
import webmAV1VideoLimited from './assets/webm/energized_limited.av1.webm';
import webmVP9VideoLimited from './assets/webm/energized_limited.vp9.webm';
import webmAV1VideoRare from './assets/webm/energized_rare.av1.webm';
import webmVP9VideoRare from './assets/webm/energized_rare.vp9.webm';
import webmAV1VideoSuperRare from './assets/webm/energized_super_rare.av1.webm';
import webmVP9VideoSuperRare from './assets/webm/energized_super_rare.vp9.webm';
import webmAV1VideoUnique from './assets/webm/energized_unique.av1.webm';
import webmVP9VideoUnique from './assets/webm/energized_unique.vp9.webm';

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  isolation: isolate;
`;

const EnergizedVideo = styled(Video)`
  inset: 0;
  overflow: hidden;
  position: absolute;
  border-radius: ${CARD_BORDER_RADIUS};

  z-index: 1;
  width: auto;
  height: auto;
  pointer-events: none;
  mix-blend-mode: screen;

  > * {
    overflow: visible;
  }
`;

const GlowFilter = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  filter: blur(10px) saturate(2);

  & > * {
    max-width: 100%;
  }

  animation: 2500ms ${fadeInOut} infinite ease-in-out;
`;

const mp4ByRarity = {
  [Rarity.limited]: mp4VideoLimited,
  [Rarity.rare]: mp4VideoRare,
  [Rarity.super_rare]: mp4VideoSuperRare,
  [Rarity.unique]: mp4VideoUnique,
} as Record<Rarity, string>;

const vp9ByRarity = {
  [Rarity.limited]: webmVP9VideoLimited,
  [Rarity.rare]: webmVP9VideoRare,
  [Rarity.super_rare]: webmVP9VideoSuperRare,
  [Rarity.unique]: webmVP9VideoUnique,
} as Record<Rarity, string>;

const av1ByRarity = {
  [Rarity.limited]: webmAV1VideoLimited,
  [Rarity.rare]: webmAV1VideoRare,
  [Rarity.super_rare]: webmAV1VideoSuperRare,
  [Rarity.unique]: webmAV1VideoUnique,
} as Record<Rarity, string>;

type Props = {
  variant?: Rarity;
};

export const Energized = ({
  children,
  variant = Rarity.limited,
}: PropsWithChildren<Props>) => {
  if (!children) return null;

  return (
    <Wrapper>
      <GlowFilter>{children}</GlowFilter>
      {children}
      <EnergizedVideo
        loop
        autoPlay
        muted
        playsInline
        hideMutedIcon
        sources={[
          {
            src: av1ByRarity[variant],
            type: 'video/webm; codecs="av01.0.31M.08,opus"',
          },
          { src: vp9ByRarity[variant], type: 'video/webm; codecs="vp9"' },
          { src: mp4ByRarity[variant], type: 'video/mp4' },
        ]}
      />
    </Wrapper>
  );
};
