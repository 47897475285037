import { TypedDocumentNode, gql } from '@apollo/client';

import { validateFiatWalletInput } from '__generated__/globalTypes';
import { fiatAccounts } from 'contexts/currentUser/queries';
import { useMutation } from 'hooks/graphql/useMutation';

import {
  ValidateFiatWalletMutation,
  ValidateFiatWalletMutationVariables,
} from './__generated__/useValidateFiatWallet.graphql';

const VALIDATE_FIAT_WALLET_MUTATION = gql`
  mutation ValidateFiatWalletMutation($input: validateFiatWalletInput!) {
    validateFiatWallet(input: $input) {
      currentUser {
        slug
        ...CurrentUseProvider_fiatAccounts
      }
      errors {
        message
        code
      }
    }
  }
  ${fiatAccounts}
` as TypedDocumentNode<
  ValidateFiatWalletMutation,
  ValidateFiatWalletMutationVariables
>;

export const useValidateFiatWallet = () => {
  const [mutate, { loading }] = useMutation(VALIDATE_FIAT_WALLET_MUTATION, {
    showErrorsInForm: true,
    showErrorsWithSnackNotification: false,
  });
  const validateFiatWallet = async (input: validateFiatWalletInput) => {
    const { data } = await mutate({
      variables: {
        input,
      },
    });
    return data?.validateFiatWallet || {};
  };
  return {
    validateFiatWallet,
    loading,
  };
};
