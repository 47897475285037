import { GraphQLResult, ValueOfTypename } from './gql';
import { LiteralToPrimitive, Primitive } from './typescript';

export const sameArrays = (array1: any[], array2: any[]) =>
  array1.length === array2.length &&
  [...array1]
    .sort()
    .every((value, index) => value === [...array2].sort()[index]);

export function groupBy<T extends GraphQLResult>(
  list: T[],
  key: (obj: T) => ValueOfTypename<T>
): Partial<{ [key in ValueOfTypename<T>]: Extract<T, { __typename: key }>[] }>;

export function groupBy<T, K extends PropertyKey>(
  list: T[],
  key: (obj: T) => K
): Partial<Record<K, T[]>>;

export function groupBy<T, K extends PropertyKey>(
  list: T[],
  key: (obj: T) => K
) {
  return list.reduce<Partial<Record<K, T[]>>>((res, obj) => {
    const v = key(obj);
    (res[v] = res[v] || []).push(obj);
    return res;
  }, {});
}

export function arrayToObject<T, S = T>({
  key,
  value,
  list,
}: {
  key: (obj: T) => string;
  value: (obj: T) => S;
  list: T[];
}) {
  return list.reduce(
    (acc, curr) =>
      key(curr)
        ? {
            ...acc,
            [key(curr)]: value(curr),
          }
        : acc,
    {}
  );
}

export function sortBy<T>(key: (obj: T) => Date | string | number, list: T[]) {
  return list.sort((a, b) => {
    if (key(a) < key(b)) {
      return -1;
    }
    if (key(a) > key(b)) {
      return 1;
    }
    return 0;
  });
}

export function uniqueBy<T>(key: (obj: T) => string, list: T[]) {
  const keys = list.map(key);
  return list.filter((item, pos) => keys.indexOf(key(item)) === pos);
}

// So that Object.keys returns the correct type: cf https://github.com/microsoft/TypeScript/pull/12253
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

export function randomElement(array: []): undefined;
export function randomElement<T>(array: Readonly<T[]>): T;
export function randomElement<T>(array: Readonly<T[]>): T | undefined {
  if (!array.length) return undefined;
  return array[Math.floor(Math.random() * array.length)];
}

export function shuffle<T>(array: T[], options?: { nElements?: number }): T[] {
  const shuffled = [...array].sort(() => 0.5 - Math.random());
  return typeof options?.nElements === 'number'
    ? shuffled.slice(0, options.nElements)
    : shuffled;
}

export enum LenientPolicy {
  UNKNOWN_AT_TAIL = 1,
  UNKNOWN_AT_HEAD = -1,
}

export function sortByArrayIndex<T>(array: readonly T[], a: T, b: T): number {
  return array.indexOf(a) - array.indexOf(b);
}

export function partition<T>(
  ary: T[],
  predicate: (value: T, index: number) => boolean
) {
  return ary.reduce<T[][]>(
    (res, obj) => {
      res[predicate(obj, ary.indexOf(obj)) ? 0 : 1].push(obj);
      return res;
    },
    [[], []]
  );
}

/**
 * expected an array sorted by importance and sort it where
 * the most important items are in the middle
 * [0,1,2,3,4,5,6] =>[6,4,2,0,1,3,5]
 */
export function sortByTheMiddle<T>(arr: T[]) {
  return arr.reduce<T[]>((prev, curr, index) => {
    if (index % 2 === 0) {
      prev.unshift(curr);
    } else {
      prev.push(curr);
    }
    return prev;
  }, []);
}

export function range(size: number) {
  return Array.from(Array(size).keys());
}

export function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  let l = array.length;
  while (l) {
    l -= 1;
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}

export function findIndexStartingFrom<T>(
  array: Array<T>,
  startingIndex: number,
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  for (let i = startingIndex; i < array.length; i += 1) {
    if (predicate(array[i], i, array)) return i;
  }
  return -1;
}

type StrictLookup<T, K extends keyof T> = <P extends T[K]>(
  v: P
) => T & Readonly<Record<K, P>>;

type Fallback<T, K extends keyof T> = <P extends LiteralToPrimitive<T[K]>>(
  v: P
) => (T & Readonly<Record<K, P>>) | undefined;

type StrictFinder<T, K extends keyof T> = StrictLookup<T, K> & Fallback<T, K>;

type _FindByKey<E, K extends keyof E> = E[K] extends Primitive
  ? `findBy${Capitalize<K & string>}`
  : never;

type FindBy<T extends readonly unknown[]> = {
  [K in keyof T[number] as _FindByKey<T[number], K>]: Readonly<T> extends T
    ? StrictFinder<T[number], K> // Handle makeSearchable([{…}] as const);
    : (v: LiteralToPrimitive<T[number][K]>) => T[number] | undefined; // Handle non const cases
};

export const makeSearchable = <T extends readonly Record<string, unknown>[]>(
  data: T
) => {
  return new Proxy(data, {
    get(array: T, prop: string, receiver: any) {
      const prefix = 'findBy';
      if (typeof prop === 'string' && prop.startsWith(prefix)) {
        let key = prop.replace(prefix, '');
        key = key[0].toLowerCase() + key.slice(1);
        return (value: string) => array.find(x => x[key] === value);
      }
      return Reflect.get(array, prop, receiver);
    },
  }) as T & FindBy<T>;
};
