import { useEffect, useRef, useState } from 'react';

import cardPlaceholderURL from '@sorare/core/src/assets/cards/placeholder.svg';
import playerPlaceholderURL from '@sorare/core/src/assets/players/placeholder.png';
import {
  Preloader,
  ScriptPreload,
} from '@sorare/core/src/atoms/loader/Preloader';
import { REVISION } from '@sorare/core/src/config';
import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';

const LAZY_CHUNKS = /(Lazy|add-all-tz|GLTFLoader)/;
const IMAGES_TO_PRELOAD = [cardPlaceholderURL, playerPlaceholderURL];

export const MobileAppPreloader = () => {
  const { isAndroidApp, postMessage } = useIsMobileApp();
  const [chunks, setChunks] = useState<string[]>([]);
  const loaded = useRef(0);
  const {
    flags: { preloadMobileAppChunks = true },
  } = useFeatureFlags();

  const isProdAndroidApp = import.meta.env.PROD && isAndroidApp;

  useEffect(() => {
    if (!isProdAndroidApp || !preloadMobileAppChunks) {
      setChunks([]);
      if (isProdAndroidApp) {
        postMessage('loaded', {
          page: undefined,
          iframe: undefined,
          app: {
            progress: 100,
          },
        });
      }
      return;
    }

    fetch(`/build-manifest/chunks.${REVISION}.txt`)
      .then(async response => response.text())
      .then(text => {
        const jsUrls = text
          .trim()
          .split('\n')
          .filter(url => !url.match(LAZY_CHUNKS))
          .map(url => `/${url}`);
        setChunks(jsUrls);
      })
      .catch(() => {});
  }, [isProdAndroidApp, postMessage, preloadMobileAppChunks]);

  if (!isProdAndroidApp) return null;

  const onLoad = () => {
    loaded.current += 1;
    if (isAndroidApp && preloadMobileAppChunks) {
      postMessage('loaded', {
        page: undefined,
        iframe: undefined,
        app: {
          progress: Math.min(
            100,
            Math.round((loaded.current * 100) / chunks.length)
          ),
        },
      });
    }
  };

  return (
    <>
      <Preloader imageUrls={IMAGES_TO_PRELOAD} />
      <ScriptPreload scriptUrls={chunks} onLoad={onLoad} />
    </>
  );
};
