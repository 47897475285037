import { RequireAuth } from '@sorare/core/src/routing/RequireAuth';

import { AppLayout } from '@sorare/gameplay/src/components/navigation/AppLayout';

import { ClaimStrk as ClaimStrkContent } from 'ClaimStrk';

const ClaimStrk = () => (
  <RequireAuth>
    <AppLayout>
      <ClaimStrkContent />
    </AppLayout>
  </RequireAuth>
);

export default ClaimStrk;
