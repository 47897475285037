import { defineMessages } from 'react-intl';

import { Checkbox } from 'atoms/inputs/Checkbox';
import { useIntlContext } from 'contexts/intl';

const messages = defineMessages({
  partnersLabel: {
    id: 'PromptTermsDialog.partnersLabel',
    defaultMessage:
      'I agree to share my information with Sorare’s trusted partners for marketing and promotional purposes',
  },
});

export const AgreeToReceiveOffersFromPartners = (props: {
  checked: boolean;
  onChange: () => void;
  name: string;
}) => {
  const { formatMessage } = useIntlContext();

  return <Checkbox {...props} label={formatMessage(messages.partnersLabel)} />;
};
