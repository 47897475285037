import { ReactNode } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon, IconDefinition } from 'atoms/icons';
import { BodyM, LabelM } from 'atoms/typography';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: var(--double-unit);
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  border-radius: var(--triple-unit);
  background-color: var(--c-nd-200);
  padding: var(--unit);
  aspect-ratio: 1 / 1;
`;

type Props = {
  icon: IconDefinition;
  title?: ReactNode;
  desc?: ReactNode;
};

export const ReinsuranceItem = ({ title, desc, icon }: Props) => {
  return (
    <Wrapper>
      <StyledFontAwesomeIcon icon={icon} size="sm" color="var(--c-white)" />
      <div>
        {title && (
          <BodyM bold color="var(--c-white)">
            {title}
          </BodyM>
        )}
        {desc && <LabelM color="var(--c-nd-600)">{desc}</LabelM>}
      </div>
    </Wrapper>
  );
};
