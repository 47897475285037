import { createContext, useContext } from 'react';

import { LIFECYCLE, LifecycleGetter, LifecycleValue } from 'hooks/useLifecycle';

interface OneTimeDialogContext {
  sawDialog: (
    dialogId: LIFECYCLE,
    getter?: (lifecycle: LifecycleValue) => LifecycleGetter
  ) => boolean;
  activeDialog?: LIFECYCLE;
  onMount: (dialogId: LIFECYCLE) => void;
  onUnmount: (dialogId: LIFECYCLE) => void;
}

export const OneTimeDialogContext = createContext<OneTimeDialogContext | null>(
  null
);

export const useOneTimeDialogContext = () => useContext(OneTimeDialogContext)!;
