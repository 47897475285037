import { useContext } from 'react';

import {
  FeatureFlagsContext,
  FeatureFlagsContextType,
  FlagSet,
} from 'contexts/featureFlags';

const noFlags: FeatureFlagsContextType = {
  flags: {},
  monitoredFlags: {},
  identify: undefined,
};

export const useFeatureFlags = <
  T extends FlagSet = Record<string, any>,
>(): FeatureFlagsContextType<T> => {
  return useContext(FeatureFlagsContext) || noFlags;
};

export { useLDClient } from 'launchdarkly-react-client-sdk';
