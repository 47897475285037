import { Card } from 'components/card/Card';
import { CardHit, convertCardHitToCard } from 'lib/algolia';
import { getHumanReadableScarcity } from 'lib/cards';

import { Suggestion } from '../Suggestion';

interface Props {
  hit: CardHit;
  isHighlighted: boolean;
}

export const CardSuggestion = ({ hit, isHighlighted }: Props) => {
  return (
    <Suggestion
      isHighlighted={isHighlighted}
      renderAvatar={() => (
        <div className="text-center">
          <Card card={convertCardHitToCard(hit)} width={40} />
        </div>
      )}
      primary={hit.player.display_name}
      secondary={getHumanReadableScarcity({
        rarity: hit.rarity,
        serialNumber: hit.serial_number,
      })}
    />
  );
};
