import { useEffect, useRef } from 'react';

export const usePortal = (
  id: string,
  options?: { replace: boolean; style?: Partial<CSSStyleDeclaration> }
) => {
  const { replace = false, style } = options || {};
  const rootElemRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    let parentElem = document.querySelector(`#${id}`);
    if (!parentElem) {
      parentElem = document.createElement('div');
      parentElem.id = id;
      document.body.appendChild(parentElem);
    }
    if (!rootElemRef.current) {
      return undefined;
    }

    if (style) {
      Object.assign(rootElemRef.current.style, style);
    }

    if (replace && parentElem.firstChild) {
      parentElem.replaceChild(rootElemRef.current, parentElem.firstChild);
    } else {
      parentElem.appendChild(rootElemRef.current);
    }
    return function removeElement() {
      if (rootElemRef.current) {
        rootElemRef.current.remove();
      }
    };
  }, [id, replace, style]);

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
      if (style) {
        Object.assign(rootElemRef.current.style, style);
      }
    }
    return rootElemRef.current;
  }

  return getRootElem();
};
