import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

type Props = {
  color?: Color;
  size?: keyof typeof unitMapping;
};

export const InSeason = ({ size = 2, color }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 17"
      fill="none"
      style={{ height: unitMapping[size] }}
    >
      <g filter="url(#filter0_bd_8253_53194)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5348 15.0571V1L4.46522 1L4.46522 15.0571L10.2842 16.2348H14.7158L20.5348 15.0571ZM9.95804 5.61663L9.65903 5.91563V6.46989H12.2115V7.41796H8.59427V5.5364L9.33085 4.79982L10.8478 4.45706L11.1468 4.15805V3.64755L10.9061 3.40689H9.81948L9.31627 3.91009L8.59427 3.1881L9.32356 2.45881H11.4093L12.2115 3.26103V4.53728L11.475 5.27386L9.95804 5.61663ZM16.5475 5.41243V6.3605H16.0225V7.41796H14.9577V6.3605H12.6677V5.20822L14.7462 2.45881H16.0225V5.41243H16.5475ZM13.6085 5.41243H14.9577V3.5965L13.6085 5.41243ZM9.65904 12.2809L9.95805 11.9819L11.475 11.6392L12.2115 10.9026V9.62632L11.4093 8.82411H9.32357L8.59428 9.5534L9.31628 10.2754L9.81949 9.77218H10.9061L11.1468 10.0128V10.5233L10.8478 10.8224L9.33086 11.1651L8.59428 11.9017V13.7833H12.2115V12.8352H9.65904V12.2809ZM16.46 11.4933L15.6651 10.6984H13.9148V9.77218H16.2631V8.82411H12.923V11.6173H15.1109L15.388 11.8944V12.5872L15.14 12.8352H13.9878L13.4116 12.2518L12.675 12.9883L13.4773 13.7833H15.6651L16.46 12.9883V11.4933Z"
          fill={color || 'url(#paint0_linear_8253_53194)'}
        />
      </g>
      <path
        d="M21.5782 8.72174V1H24.4999V7.67182L21.5782 8.72174Z"
        fill={color || '#609DFD'}
      />
      <path
        d="M3.42174 8.72174V1H0.5V7.67182L3.42174 8.72174Z"
        fill={color || '#E4C7E2'}
      />
      <defs>
        <filter
          id="filter0_bd_8253_53194"
          x="3.83538"
          y="0.370167"
          width="17.3292"
          height="16.494"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.314917" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_8253_53194"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.0372931" />
          <feGaussianBlur stdDeviation="0.158496" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.45 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_8253_53194"
            result="effect2_dropShadow_8253_53194"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_8253_53194"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8253_53194"
          x1="4.46522"
          y1="16.2348"
          x2="21.9878"
          y2="14.3238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8D3DA" />
          <stop offset="0.283178" stopColor="#B3A9F4" />
          <stop offset="0.5401" stopColor="#FBE9FB" />
          <stop offset="1" stopColor="#4F94FD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
