import { CLUB_PLACEHOLDER } from 'constants/assets';
import { useIntlContext } from 'contexts/intl';
import { CompetitionHit } from 'lib/algolia';

import { Suggestion } from '../Suggestion';

interface Props {
  hit: CompetitionHit;
  isHighlighted: boolean;
}

export const CompetitionSuggestion = ({ hit, isHighlighted }: Props) => {
  const { language } = useIntlContext();

  const name = hit[`display_name_${language}`] || hit.display_name;

  return (
    <Suggestion
      isHighlighted={isHighlighted}
      renderAvatar={Img => (
        <Img
          src={hit.pictureUrl}
          alt={name}
          isContain
          fallbackUrl={CLUB_PLACEHOLDER}
        />
      )}
      primary={name}
    />
  );
};
