import { useSearchParams } from 'react-router-dom';

import { SEARCH_PARAMS } from '@sorare/core/src/components/search/InstantSearch/types';
import { buildFilterQuery } from '@sorare/core/src/components/search/InstantSearch/utils';

const EXCLUDED_SEARCH_PARAMS = [
  SEARCH_PARAMS.IN_SEASON,
  SEARCH_PARAMS.BUNDLED,
  SEARCH_PARAMS.PURCHASE_OPTIONS,
  SEARCH_PARAMS.SORT,
  SEARCH_PARAMS.PAGE,
];

export const buildMarketTabRoute = (url: string, qs: string) => {
  return `${url}${qs ? `?${qs}` : ''}`;
};

export const useNavigableMarketTabParams = () => {
  const [params] = useSearchParams();
  const algoliaParamsValue = Object.entries(SEARCH_PARAMS);

  const cleanedParams = algoliaParamsValue
    .map(([enumKey, enumValue]) => {
      const values = params.getAll(enumValue);
      if (!EXCLUDED_SEARCH_PARAMS.includes(enumValue) && values.length)
        return {
          enumKey,
          enumValue,
          values,
        };
      return null;
    })
    .filter(Boolean);

  const buildFilterQueryArgs = cleanedParams.reduce((acc, cleanedParam) => {
    return {
      ...acc,
      [cleanedParam.enumValue]: cleanedParam.values,
    };
  }, {});

  const filterQuery = buildFilterQuery(buildFilterQueryArgs);
  const filterQueryWithInSeason = buildFilterQuery({
    ...buildFilterQueryArgs,
    [SEARCH_PARAMS.IN_SEASON]: true,
  });
  return {
    filterQuery,
    filterQueryWithInSeason,
  };
};
