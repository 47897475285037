import {
  ElementProps,
  FloatingArrow,
  FloatingContext,
  FloatingPortal,
  Placement,
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'style/theme';
import { regularMaterial } from 'style/utils';

import { TooltipContentWrapper } from './TooltipContentWrapper';

type Props = {
  arrow?: false;
  children?: ReactNode;
  title: NonNullable<ReactNode>;
  className?: string;
  placement?: Placement;
  disableFocusListener?: boolean;
  onClick?: React.MouseEventHandler;
  open?: boolean;
  role?: 'status' | 'tooltip';
  skip?: boolean;
  // Touch delays
  disableClick?: boolean;
  leaveTouchDelay?: number;
  // Hover delays
  hoverOpenDelay?: number;
  // Long press
  withLongPress?: boolean;
  longPressDelay?: number;
  onLongPress?: (open: boolean) => void;
  zIndex?: number;
  variant?: 'default' | 'brand' | 'red';
  style?: React.CSSProperties;
};

const StyledTooltip = styled.div`
  padding: var(--unit);
  box-shadow: var(--shadow-300);
  max-width: min(calc(100vw - var(--double-unit)), 600px);
  color: var(--c-white);
  border-radius: var(--unit);
  font: var(--t-12);
  ${regularMaterial}
  /* Match Floating Arrow border color */
  background-clip: padding-box;
  --tooltip-border: var(--c-nd-100);
  border: 1px solid var(--tooltip-border);

  &.brand {
    --tooltip-border: transparent;
    --arrow-color: var(--c-brand-600);
    background-color: var(--c-brand-600);
  }
  &.red {
    --tooltip-border: transparent;
    --arrow-color: var(--c-red-600);
    background-color: var(--c-red-600);
    border: none;
  }
`;

const ARROW_HEIGHT = 4;
const ARROW_WIDTH = 8;

const LONG_PRESS_DELAY = 500;
const HOVER_DELAY = 0;

const useLongPress = (
  context: FloatingContext,
  { enabled, longPressDelay }: { enabled?: boolean; longPressDelay?: number }
): ElementProps => {
  const touchStartRef = useRef<ReturnType<typeof setTimeout>>();

  const onTouchStart = useCallback(
    (e: React.TouchEvent) => {
      if (enabled === false) return;

      e.preventDefault();

      clearTimeout(touchStartRef.current);
      touchStartRef.current = setTimeout(() => {
        context.onOpenChange(true, e.nativeEvent);
      }, longPressDelay);
    },
    [context, enabled, longPressDelay]
  );

  const onTouchEnd = useCallback(() => {
    clearTimeout(touchStartRef.current);
  }, []);

  return useMemo(
    (): ElementProps => ({
      reference: {
        onTouchStart,
        onTouchEnd,
      },
      floating: {},
      item: {},
    }),
    [onTouchEnd, onTouchStart]
  );
};

export const Tooltip = ({
  children,
  title,
  placement = 'bottom',
  disableFocusListener,
  disableClick,
  className,
  leaveTouchDelay,
  hoverOpenDelay = HOVER_DELAY,
  withLongPress,
  longPressDelay = LONG_PRESS_DELAY,
  onLongPress,
  open: openProp,
  skip,
  zIndex,
  variant = 'default',
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);
  const timeoutRef = useRef<number>();
  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement,
    middleware: [
      offset(2 * ARROW_HEIGHT),
      flip({ padding: 24 }),
      shift(),
      arrow({ element: arrowRef }),
    ],
    open: isOpen || openProp,
    onOpenChange(newOpen, event) {
      if (disableFocusListener && newOpen) {
        return;
      }
      clearTimeout(timeoutRef.current);
      if (
        event &&
        'type' in event &&
        event.type === 'touchstart' &&
        onLongPress
      ) {
        onLongPress(newOpen);
        return;
      }
      setIsOpen(newOpen);
      if (event) {
        if (
          // HACK work around missing PointerEvent in JSDOM
          // https://github.com/jsdom/jsdom/issues/2527
          // event instanceof PointerEvent &&
          'pointerType' in event &&
          event.pointerType === 'touch' &&
          leaveTouchDelay
        ) {
          timeoutRef.current = window.setTimeout(
            () => setIsOpen(false),
            leaveTouchDelay
          );
        }
      }
    },
    whileElementsMounted: autoUpdate,
  });

  const arrowX = middlewareData.arrow?.x ?? 0;
  const arrowY = middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + ARROW_WIDTH / 2;
  const transformY = arrowY + ARROW_HEIGHT;

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: 200,
    initial: {
      opacity: 0,
      transform: 'scale(0.8)',
    },
    open: {
      opacity: 1,
      transform: 'scale(1)',
    },
    common: ({ side }) => ({
      transformOrigin: (
        {
          top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
          bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
          left: `calc(100% + ${ARROW_WIDTH}px) ${transformY}px`,
          right: `${-ARROW_WIDTH}px ${transformY}px`,
        } as const
      )[side],
    }),
  });

  const hover = useHover(context, {
    handleClose: safePolygon({ requireIntent: false }),
    mouseOnly: true,
    delay: {
      open: hoverOpenDelay,
    },
  });
  const role = useRole(context, { role: 'tooltip' });
  const focus = useFocus(context, { enabled: !disableFocusListener });
  const click = useClick(context, {
    ignoreMouse: true,
    enabled: !disableClick,
  });
  const dismiss = useDismiss(context);
  const longPress = useLongPress(context, {
    enabled: !!withLongPress,
    longPressDelay,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    click,
    dismiss,
    focus,
    role,
    longPress,
  ]);

  if (skip) {
    return <>{children}</>;
  }

  return (
    <>
      <TooltipContentWrapper
        ref={refs.setReference}
        {...getReferenceProps({
          title:
            typeof title === 'string' && title && !context.open
              ? title
              : undefined,
          className,
          ...rest,
        })}
      >
        {children}
      </TooltipContentWrapper>
      {isMounted && title && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            {...getFloatingProps({
              style: {
                zIndex: theme.zIndex.modal,
                ...floatingStyles,
              },
            })}
          >
            <StyledTooltip
              style={transitionStyles}
              className={classNames(variant)}
            >
              {title}
              <FloatingArrow
                ref={arrowRef}
                context={context}
                height={ARROW_HEIGHT}
                width={ARROW_WIDTH}
                strokeWidth={1}
                stroke="var(--tooltip-border)"
                fill="var(--arrow-color)"
              />
            </StyledTooltip>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
