import { faWallet } from '@fortawesome/pro-solid-svg-icons';

import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { useWalletDrawerContext } from '@sorare/core/src/contexts/walletDrawer';

export const WalletButton = () => {
  const { toggleDrawer } = useWalletDrawerContext();

  return (
    <IconButton color="transparent" icon={faWallet} onClick={toggleDrawer} />
  );
};
