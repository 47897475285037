import { PropsWithChildren, ReactNode, Suspense } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { catchAll } from '@sorare/routing';

import { ContentWithStickyHeader } from '@sorare/core/src/atoms/layout/ContentWithStickyHeader';
import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { AppNavigation } from '@sorare/core/src/components/navigation/AppNavigation';
import { UserFlags } from '@sorare/core/src/components/user/UserFlags';
import {
  ACTIVITY,
  ANY_SPORT_MY_CARDS_BOXES_TYPE,
  ANY_SPORT_MY_CARDS_ESSENCE_TYPE,
  FOOTBALL_CLUBS_SLUG_CARDS,
  FOOTBALL_COUNTRIES_COUNTRYCODE_CARDS,
  FOOTBALL_LEAGUES_SLUG_CARDS,
  FOOTBALL_MARKET,
  FOOTBALL_MY_CLUB_SLUG_CARDS,
  FOOTBALL_PLAYERS_SLUG_CARDS,
  FOOTBALL_PLAY_RIVALS,
  MLB_MY_CLUB_SLUG_CARDS,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_TEAMS_SLUG_CARDS,
  NBA_MY_CLUB_SLUG_CARDS,
  NBA_PLAYERS_SLUG_CARDS,
  NBA_TEAMS_SLUG_CARDS,
} from '@sorare/core/src/constants/__generated__/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { useIsTabletAndAbove } from '@sorare/core/src/hooks/device/useIsTabletAndAbove';
import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { MultiSportFooter } from '@sorare/core/src/routing/MultiSportFooter';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';

import { AppHeader } from 'components/navigation/AppHeader';
import { NewAppHeader } from 'components/navigation/NewAppHeader';
import { NewAppNavigation } from 'components/navigation/NewAppNavigation';

const Wrapper = styled.div`
  display: flex;
  min-height: var(--100vh);
  flex-direction: column-reverse;
  @media ${laptopAndAbove} {
    flex-direction: row;
  }
`;

// min-width: 0; used to override default min-width: content; Prevents content from overflowing window container (Firefox)
const MainWrapper = styled.div`
  flex: 1;
  overflow: clip;
  min-width: 0;
`;

const Content = styled.main`
  isolation: isolate;
  &.displayFooter {
    min-height: calc(100vh - var(--current-stack-height));
  }
`;

const MOBILE_PAGES_WITHOUT_FOOTER = [
  ANY_SPORT_MY_CARDS_ESSENCE_TYPE,
  ANY_SPORT_MY_CARDS_BOXES_TYPE,
];

const PAGES_WITHOUT_FOOTER = [
  FOOTBALL_MY_CLUB_SLUG_CARDS,
  FOOTBALL_PLAYERS_SLUG_CARDS,
  FOOTBALL_CLUBS_SLUG_CARDS,
  FOOTBALL_LEAGUES_SLUG_CARDS,
  FOOTBALL_COUNTRIES_COUNTRYCODE_CARDS,
  NBA_MY_CLUB_SLUG_CARDS,
  NBA_PLAYERS_SLUG_CARDS,
  NBA_TEAMS_SLUG_CARDS,
  MLB_MY_CLUB_SLUG_CARDS,
  MLB_PLAYERS_SLUG_CARDS,
  MLB_TEAMS_SLUG_CARDS,
  ACTIVITY,
  (pathname: string) =>
    matchPath(catchAll(FOOTBALL_MARKET), pathname) &&
    !matchPath(FOOTBALL_MARKET, pathname),
  (pathname: string) => matchPath(catchAll(FOOTBALL_PLAY_RIVALS), pathname),
];

const useDisplayFooter = () => {
  const { pathname } = useLocation();
  const { isIosApp } = useIsMobileApp();
  const isTabletAndAbove = useIsTabletAndAbove();
  const { matchAnySportPath } = useSportContext();

  return (
    !isIosApp &&
    !PAGES_WITHOUT_FOOTER.some(path =>
      typeof path === 'string' ? matchPath(path, pathname) : path(pathname)
    ) &&
    (isTabletAndAbove ||
      MOBILE_PAGES_WITHOUT_FOOTER.every(
        path => !matchAnySportPath(path, pathname)
      ))
  );
};

export const AppLayout = ({
  children,
  extraHeaderButton,
}: PropsWithChildren<{ extraHeaderButton?: ReactNode }>) => {
  const {
    flags: { useNewNav = false },
  } = useFeatureFlags();

  const displayFooter = useDisplayFooter();

  const Navigation = useNewNav ? NewAppNavigation : AppNavigation;
  const header = useNewNav ? (
    <NewAppHeader extraHeaderButton={extraHeaderButton} />
  ) : (
    <AppHeader extraHeaderButton={extraHeaderButton} />
  );

  return (
    <Wrapper>
      <Navigation />
      <MainWrapper>
        <ContentWithStickyHeader header={header}>
          <Content>
            <UserFlags />
            <Suspense fallback={<LoadingIndicator fullHeight />}>
              {children}
            </Suspense>
          </Content>
          {displayFooter && <MultiSportFooter />}
        </ContentWithStickyHeader>
      </MainWrapper>
    </Wrapper>
  );
};
