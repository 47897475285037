import classNames from 'classnames';
import { ComponentProps } from 'react';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components';

import { NavLink } from '@sorare/routing';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { STORAGE, useLocalStorage } from 'hooks/useLocalStorage';
import { laptopAndAbove } from 'style/mediaQuery';

const StyledLink = styled(NavLink)`
  flex: 1;
  color: var(--c-nd-600);
  position: relative;
  &:hover,
  &:focus {
    color: var(--c-white);
    & img {
      opacity: 1;
    }
  }
  &.active {
    color: var(--c-white);
    & img {
      opacity: 1;
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  padding: var(--unit);
  font: var(--t-12);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${laptopAndAbove} {
    flex: unset;
    gap: var(--half-unit);
    padding: var(--double-unit) var(--half-unit);
  }

  & img {
    opacity: 0.7;
  }
`;

export const NavItem = ({
  to,
  onClick,
  discardHistory,
  children,
  matches,
  keepSearchParams,
  disabled,
}: {
  to: string; // don't allow Partial on purpose. It should be a string
  onClick?: () => void;
  discardHistory?: boolean;
  children: ComponentProps<typeof NavLink>['children'];
  matches?: ComponentProps<typeof NavLink>['matches'];
  keepSearchParams?: boolean;
  disabled?: boolean;
}) => {
  const {
    flags: { useContextModeNavigation = false },
  } = useFeatureFlags();
  const [savedHistory, setSavedHistory] = useLocalStorage(
    STORAGE.savedHistory,
    {} as Record<string, string>
  );
  const match = useMatch(`${to}/*`);
  const returnToHome = match && match.pathname !== match.pathnameBase;

  if (!useContextModeNavigation || discardHistory) {
    return (
      <StyledLink
        to={to}
        state={{
          fromNavItem: true,
        }}
        matches={matches}
        keepSearchParams={keepSearchParams}
        onClick={onClick}
        className={classNames({ disabled })}
      >
        {children}
      </StyledLink>
    );
  }

  if (match && match.pathname !== savedHistory?.[to]) {
    setSavedHistory(saved => ({ ...saved, [to]: match.pathname }));
  }

  return (
    <StyledLink
      to={returnToHome || !savedHistory?.[to] ? to : savedHistory?.[to]}
      state={{
        fromNavItem: true,
      }}
      matches={matches}
      keepSearchParams={keepSearchParams}
      onClick={onClick}
      className={classNames({ disabled })}
    >
      {children}
    </StyledLink>
  );
};
