import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Lightning } from '@sorare/core/src/atoms/icons/Lightning';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { Tooltip } from '@sorare/core/src/atoms/tooltip/Tooltip';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { Chip } from '@sorare/core/src/atoms/ui/Chip';
import { DetailedScoreLine } from '@sorare/core/src/components/collections/DetailedScoreLine';
import { toCamelCase } from '@sorare/core/src/lib/toCamelCase';

import type { CollectionPrediction_anyCard } from './__generated__/index.graphql';

const TooltipTitle = styled(LabelS)`
  background-color: var(--c-nd-50);
  padding: var(--half-unit) var(--unit);
  border-radius: var(--unit);
`;
const Icon = styled.img`
  width: var(--double-unit);
  height: var(--double-unit);
  &:not(:first-child) {
    margin-left: calc(-1 * var(--unit));
  }
`;

const StyledLightning = styled(Lightning)`
  width: var(--unit);
  height: var(--unit);
`;

const StyledLabelS = styled(LabelS)`
  & > * {
    vertical-align: middle;
  }
`;

type Props = {
  card: CollectionPrediction_anyCard;
};

export const CollectionPrediction = ({ card }: Props) => {
  const {
    collectionIncreaseScoreBreakdownAfterTransfer,
    collectionScoringMatrix,
  } = card;

  if (!collectionIncreaseScoreBreakdownAfterTransfer) return null;

  const { total } = collectionIncreaseScoreBreakdownAfterTransfer;

  const afterTransferScores = collectionScoringMatrix.filter(
    detailScore =>
      !!collectionIncreaseScoreBreakdownAfterTransfer[
        toCamelCase(
          detailScore.name
        ) as keyof typeof collectionIncreaseScoreBreakdownAfterTransfer
      ]
  );

  return (
    <Tooltip
      title={
        <Vertical gap={0}>
          {afterTransferScores.length > 0 ? (
            <>
              <TooltipTitle className="text-center">
                <FormattedMessage
                  id="collectionPrediction.tooltip.title"
                  defaultMessage="Collection bonus"
                />
              </TooltipTitle>

              {afterTransferScores.map(detailedScore => {
                return (
                  <DetailedScoreLine
                    key={detailedScore.name}
                    detailedScore={detailedScore}
                  />
                );
              })}
            </>
          ) : (
            <FormattedMessage
              id="collectionPrediction.tooltip.noCollectionImprovement"
              defaultMessage="Bonus is 0 as you already own the same card edition"
            />
          )}
        </Vertical>
      }
    >
      <Chip color="yellow">
        <Horizontal gap={0}>
          {afterTransferScores.map(detailedScore => {
            return (
              <Icon
                src={detailedScore?.pictureUrl}
                alt=""
                key={detailedScore.name}
              />
            );
          })}
        </Horizontal>
        <Horizontal gap={0}>
          <StyledLabelS bold>
            <span>+{total}</span> <StyledLightning />
          </StyledLabelS>
        </Horizontal>
      </Chip>
    </Tooltip>
  );
};

CollectionPrediction.fragments = {
  anyCard: gql`
    fragment CollectionPrediction_anyCard on AnyCardInterface {
      slug
      collectionIncreaseScoreBreakdownAfterTransfer {
        firstOwner
        firstSerialNumber
        firstTimeOwnedPlayerCard
        holding
        owner
        shirtMatchingSerialNumber
        specialEdition
        rookie
        total
      }
      collectionScoringMatrix {
        name
        pictureUrl
        label
        explanation
        value
      }
    }
  ` as TypedDocumentNode<CollectionPrediction_anyCard>,
};
