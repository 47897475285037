import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineL } from 'atoms/typography';
import { glossary } from 'lib/glossary';

import { PhoneNumber } from './PhoneNumber';
import { RecoveryEmail } from './RecoveryEmail';
import { TwoFA } from './TwoFA';

const Content = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--triple-unit);
`;

const Blocks = styled.div`
  border: solid 1px var(--c-nd-200);
  border-radius: var(--double-unit);
`;

const Block = styled.div`
  padding: var(--double-unit);
  &:not(:last-child) {
    border-bottom: solid 1px var(--c-nd-200);
  }
`;

type Props = {
  onClose: () => void;
};
export const Home = ({ onClose }: Props) => {
  return (
    <Content>
      <Vertical>
        <div className="text-center">
          <HeadlineL as="h3" color="var(--c-white)">
            <FormattedMessage
              id="accountSecurityCheck.title"
              defaultMessage="Account Security Overview"
            />
          </HeadlineL>
          <BodyM color="var(--c-white)">
            <FormattedMessage
              id="accountSecurityCheck.subtitle"
              defaultMessage="Please review and confirm your account security preferences below. Consider adding missing information to ensure you keep your account secure and avoid getting locked out."
            />
          </BodyM>
        </div>
      </Vertical>
      <Blocks>
        <Block>
          <RecoveryEmail />
        </Block>
        <Block>
          <TwoFA />
        </Block>
        <Block>
          <PhoneNumber />
        </Block>
      </Blocks>
      <Button size="medium" onClick={onClose} color="primary">
        <FormattedMessage {...glossary.confirm} />
      </Button>
    </Content>
  );
};
