import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { IconButton } from 'atoms/buttons/IconButton';
import { Vertical } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';
import { Chip } from 'atoms/ui/Chip';
import { Dialog } from 'components/dialog';
import { EnergyOnboardingInfoModal } from 'components/onboarding/EnergyOnboardingInfoModal';
import { glossary } from 'lib/glossary';

const StyledDialog = styled(Dialog)`
  background: var(--c-black);
  border-radius: var(--double-unit);
  border: 1px solid var(--c-nd-50);
  box-shadow: 0px 0px 10px 0px var(--c-nd-200);
`;

const Wrapper = styled(Vertical)`
  padding: var(--triple-unit);
`;

const IconButtonWrapper = styled.div`
  position: absolute;
  top: var(--unit);
  right: var(--unit);
`;

const NewChip = styled(Chip)`
  background: linear-gradient(319deg, #1510ff 16.49%, #00fff7 82.68%);
`;

type Props = {
  open: boolean;
  onClose: () => void;
};

export const EnergyBalanceHelp = ({ open, onClose }: Props) => {
  return (
    <StyledDialog
      hideHeader
      open={open}
      maxWidth="xs"
      onClose={onClose}
      title={
        <NewChip>
          <LabelS bold uppercase>
            <FormattedMessage {...glossary.new} />
          </LabelS>
        </NewChip>
      }
    >
      <Wrapper gap={1.5}>
        <IconButtonWrapper>
          <IconButton icon={faXmark} onClick={onClose} color="transparent" />
        </IconButtonWrapper>
        <EnergyOnboardingInfoModal />
      </Wrapper>
    </StyledDialog>
  );
};
