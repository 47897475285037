import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const linearGradientProps = {
  x1: 1,
  x2: 0.6,
  y1: 1,
  y2: 0,
};
const gradients = [
  {
    id: 'svg-limited-gradient',
    rotation: 0,
    colors: [
      { color: '#ae5e13', offset: 0 },
      { color: '#dfad46', offset: 1 },
    ],
  },
  {
    id: 'svg-mix-gradient',
    rotation: 0,
    colors: [
      { color: '#391885', offset: 0 },
      { color: '#c43ba9', offset: 1 },
    ],
  },
  {
    id: 'svg-rare-gradient',
    rotation: 0,
    colors: [
      { color: '#c22342', offset: 0 },
      { color: '#f2412d', offset: 1 },
    ],
  },
  {
    id: 'svg-super_rare-gradient',
    rotation: 0,
    colors: [
      { color: '#366bf2', offset: 0 },
      { color: '#6b49db', offset: 1 },
    ],
  },
  {
    id: 'svg-unique-gradient',
    rotation: 0,
    colors: [
      { color: '#171717', offset: 0 },
      { color: '#4D4B49', offset: 1 },
    ],
  },
  {
    id: 'svg-rookie-gradient',
    rotation: 0,
    colors: [{ color: '#101010', offset: 0 }],
  },
  {
    id: 'svg-academy-gradient',
    rotation: 0,
    colors: [
      { color: '#0840d1', offset: 0 },
      { color: '#00a3ff', offset: 1 },
    ],
  },
  {
    id: 'svg-rewards-1st-gradient',
    rotation: 0,
    colors: [
      { color: '#FFC931', offset: 0 },
      { color: '#FC7D22', offset: 1 },
    ],
  },
  {
    id: 'svg-rewards-2nd-gradient',
    rotation: 0,
    colors: [
      { color: '#FFFFFF', offset: 0 },
      { color: '#C0C0C0', offset: 1 },
    ],
  },
  {
    id: 'svg-rewards-3rd-gradient',
    rotation: 0,
    colors: [
      { color: '#EBA278', offset: 0 },
      { color: '#DCA883', offset: 1 },
    ],
  },
  {
    id: 'svg-rivals-rep-boost-gradient',
    rotation: 0,
    colors: [
      { color: 'var(--c-rivals-reputation)', offset: 0 },
      { color: 'var(--c-rivals-arena)', offset: 0.7 },
    ],
  },
  {
    id: 'svg-special-reward-gradient',
    rotation: 100,
    colors: [
      { color: 'var(--c-static-reward-200)', offset: 0 },
      { color: 'var(--c-static-reward-900)', offset: 1 },
    ],
  },
];

export const SvgProvider = ({ children }: Props) => {
  return (
    <>
      <svg
        aria-hidden="true"
        style={{ position: 'absolute', display: 'block', height: 0, width: 0 }}
      >
        {gradients.map(({ id, colors, rotation }) => {
          return (
            <linearGradient
              key={id}
              id={id}
              gradientTransform={`rotate(${rotation})`}
              {...linearGradientProps}
            >
              {colors.map(({ color, offset }) => (
                <stop key={color} offset={offset} stopColor={color} />
              ))}
            </linearGradient>
          );
        })}
      </svg>
      {children}
    </>
  );
};
