import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { Sport } from '__generated__/globalTypes';
import {
  AcceptedCurrenciesValue,
  MARKETPLACE_PREFERENCES_ACCEPTED_CURRENCIES,
  useAcceptedCurrencies,
} from 'hooks/useAcceptedCurrencies';

import {
  CurrentUserAcceptedCurrenciesQuery,
  CurrentUserAcceptedCurrenciesQueryVariables,
} from './__generated__/useCurrentUserAcceptedCurrencies.graphql';
import { useQuery } from './graphql/useQuery';
import { useUpdateUserProfile } from './useUpdateUserProfile';

const CURRENT_USER_ACCEPTED_CURRENCIES_QUERY = gql`
  query CurrentUserAcceptedCurrenciesQuery {
    currentUser {
      slug
      ...useAcceptedCurrencies_publicUserInfoInterface
    }
  }
  ${useAcceptedCurrencies.fragments.publicUserInfoInterface}
` as TypedDocumentNode<
  CurrentUserAcceptedCurrenciesQuery,
  CurrentUserAcceptedCurrenciesQueryVariables
>;

export const useCurrentUserAcceptedCurrencies = () => {
  const updateUserProfile = useUpdateUserProfile();
  const { data } = useQuery(CURRENT_USER_ACCEPTED_CURRENCIES_QUERY);
  const { acceptedCurrencies, storedAcceptedCurrencies } =
    useAcceptedCurrencies({
      user: data?.currentUser,
    });

  const updateAcceptedCurrencies = useCallback(
    async (value: AcceptedCurrenciesValue) => {
      return updateUserProfile({
        marketplacePreferences: [
          {
            sports: [Sport.BASEBALL, Sport.FOOTBALL, Sport.NBA],
            name: MARKETPLACE_PREFERENCES_ACCEPTED_CURRENCIES,
            value,
          },
        ],
      });
    },
    [updateUserProfile]
  );

  return {
    acceptedCurrencies,
    updateAcceptedCurrencies,
    acceptedCurrenciesHaveBeenSet: !!storedAcceptedCurrencies,
  };
};
