import { FunctionComponent, useEffect, useState } from 'react';

import { useFormContext } from '../context';

export type RenderProps<T> = {
  name: string;
  error: string | null;
  handleChange: (
    value: T,
    target?: {
      setCustomValidity?: (error: string) => void;
      reportValidity?: () => boolean;
    }
  ) => void;
  value: T | undefined;
  disabled?: boolean;
};

export type Props<T> = {
  defaultValue?: T;
  name: string;
  render: FunctionComponent<React.PropsWithChildren<RenderProps<T>>>;
  validate?: (value: T) => string | null;
};

export const Field = <T,>({
  defaultValue,
  name,
  render,
  validate,
}: Props<T>) => {
  const { registerField, handleChange, fields } = useFormContext();

  const [value, setValue] = useState<T | undefined>(defaultValue);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    registerField(name, {
      onChange: (val: T) => setValue(val),
      onError: (err: string | null) => setError(err),
      validate,
      defaultValue,
    });
  }, [defaultValue, name, validate, registerField]);

  return render({
    name,
    // disable the field until it's registered
    // fixes https://sorare-inc.sentry.io/issues/4908770894/
    disabled: !fields[name],
    error,
    handleChange: handleChange(name),
    value,
  });
};
