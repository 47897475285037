import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { secondsToHours } from 'date-fns';
import { useCallback } from 'react';

import { Bold } from 'atoms/typography/Bold';
import { useConfigContext } from 'contexts/config';
import { useDeviceFingerprintContext } from 'contexts/deviceFingerprint';
import { useRestrictedAccessContext } from 'contexts/restrictedAccess';
import { createResendEmailConfirmationButton } from 'contexts/restrictedAccess/ResendEmailConfirmationLink';
import { useSnackNotificationContext } from 'contexts/snackNotification';
import { useSportContext } from 'contexts/sport';
import { useEvents } from 'lib/events/useEvents';
import { getClientId } from 'lib/ga';

import {
  SignInMutation,
  SignInMutationVariables,
} from './__generated__/useSignIn.graphql';
import { currentUser as currentUserFragment } from './queries';

const SIGN_IN_MUTATION = gql`
  mutation SignInMutation($input: signInInput!, $sport: Sport!) {
    signIn(input: $input) {
      currentUser {
        slug
        timeLeftForConfirmation
        ...CurrentUserProvider_currentUser
      }
      otpSessionChallenge
      errors {
        path
        message
        code
      }
      tcuToken
    }
  }
  ${currentUserFragment}
` as TypedDocumentNode<SignInMutation, SignInMutationVariables>;

export const useSignInMutation = () => {
  const { sport } = useSportContext();
  const { showNotification } = useSnackNotificationContext();
  const track = useEvents();
  const { setShowRestrictedAccess } = useRestrictedAccessContext();
  const [mutate] = useMutation(SIGN_IN_MUTATION);
  const { deviceFingerprint: getDeviceFingerPrint } =
    useDeviceFingerprintContext();
  const { updateQuery } = useConfigContext();

  /** necessary callback to avoid infinite loop because signIn is used on dependency arrays */
  return useCallback(
    async (props: SignInMutationVariables['input']) => {
      const deviceFingerprint = await getDeviceFingerPrint();
      const { data } = await mutate({
        variables: {
          sport,
          input: {
            ...props,
            gaClientId: getClientId(),
            deviceFingerprint,
          },
        },
      });

      const { currentUser } = data?.signIn || {};

      if (currentUser) {
        if (!currentUser.confirmed) {
          const remainingHours = secondsToHours(
            Math.max(currentUser.timeLeftForConfirmation || 0, 0)
          );
          showNotification(
            'confirmEmail',
            {
              strong: Bold,
              remainingHours,
              link: createResendEmailConfirmationButton({
                onClick: () => setShowRestrictedAccess('email'),
              }),
            },
            { autoHideDuration: 30000 }
          );
          track('View Email Confirmation Reminder Toast');
        }
        updateQuery(currentUser);
        /** Redirection happens on RedirectAfterSignin */
      }
      return data?.signIn;
    },
    [
      getDeviceFingerPrint,
      mutate,
      setShowRestrictedAccess,
      showNotification,
      sport,
      track,
      updateQuery,
    ]
  );
};
