import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { DialogKey } from '@sorare/core/src/components/navigation/WithDialogs';
import {
  FOOTBALL_MARKET_NEW_SIGNINGS,
  LANDING,
  MLB_MARKET_PRIMARY,
} from '@sorare/core/src/constants/__generated__/routes';
import { NBA_PRIMARY_MARKET } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';
import { idFromObject } from '@sorare/core/src/gql/idFromObject';
import { useMutation } from '@sorare/core/src/hooks/graphql/useMutation';
import { useGenerateDialogPath } from '@sorare/core/src/hooks/navigation/useGenerateDialogPath';
import { currentUserConversionCredits } from '@sorare/core/src/hooks/useConversionCredits';

import {
  ClaimConversionCreditMutation,
  ClaimConversionCreditMutationVariables,
} from './__generated__/page.graphql';

const CLAIM_CONVERSION_CREDIT_MUTATION = gql`
  mutation ClaimConversionCreditMutation(
    $input: claimConversionCreditInput!
    $sport: Sport
  ) {
    claimConversionCredit(input: $input) {
      conversionCredit {
        id
        sport
      }
      currentUser {
        slug
        ...CurrentUser_conversionCredits
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${currentUserConversionCredits}
` as TypedDocumentNode<
  ClaimConversionCreditMutation,
  ClaimConversionCreditMutationVariables
>;

const PromoClaimPage = () => {
  const generateDialogPath = useGenerateDialogPath();
  const [mutate] = useMutation(CLAIM_CONVERSION_CREDIT_MUTATION, {
    showErrorsWithSnackNotification: true,
  });
  const { sport } = useSportContext();
  const { code } = useParams();
  const navigate = useNavigate();

  const claimConversionCredit = useCallback(async () => {
    const { data } = await mutate({
      variables: {
        input: {
          promoCode: code,
        },
        sport,
      },
    });

    const conversionCredit = data?.claimConversionCredit?.conversionCredit;
    const ccSport = conversionCredit?.sport || sport;
    const primaryMarketsPerSport = {
      [Sport.FOOTBALL]: FOOTBALL_MARKET_NEW_SIGNINGS,
      [Sport.NBA]: NBA_PRIMARY_MARKET,
      [Sport.BASEBALL]: MLB_MARKET_PRIMARY,
    };

    const basePath = primaryMarketsPerSport[ccSport] || LANDING;
    return navigate(
      data?.claimConversionCredit?.conversionCredit?.id
        ? generateDialogPath(
            DialogKey.claimedConversionCredit,
            idFromObject(data?.claimConversionCredit?.conversionCredit.id),
            {
              basePath,
            }
          )
        : basePath
    );
  }, [code, mutate, navigate, generateDialogPath, sport]);

  useEffect(() => {
    if (code) {
      claimConversionCredit();
    }
  }, [claimConversionCredit, code]);

  if (!code) {
    return <Navigate to={LANDING} replace />;
  }

  return null;
};

export default PromoClaimPage;
