import classNames from 'classnames';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { DragToScroll } from 'atoms/navigation/DragToScroll';
import { sortByDate } from 'lib/dates';
import { getScrollParent } from 'lib/getScrollParent';

import { FixtureScoreBar } from '../FixtureScoreBar';

const Wrapper = styled.div`
  display: flex;
  --border-width: 1px;
  --figure-height: 24px;
  --max-bar-height: var(--override-max-bar-height, 260px);
  gap: var(--border-width);
  /* prevent scroll from triggering back navigation */
  html:has(&:hover) {
    overscroll-behavior: contain;
  }
`;
const Fixture = styled(Vertical).attrs({ gap: 0, center: true })`
  /* unfortunate hack to add appropriate padding on the edge when
   * chart is in a scrolling container
   */
  &:last-child {
    padding-right: var(--intermediate-unit);
  }
  justify-content: flex-start;
`;
const FixtureName = styled.div`
  min-height: var(--double-unit);
  font: var(--t-12);
  color: var(--c-nd-400);
  text-align: left;
  white-space: nowrap;
  align-self: flex-start;
  font-weight: var(--t-bold);
  margin-left: var(--half-unit);
`;

const Games = styled.div`
  display: flex;
  flex: 1;
  direction: initial;
  position: relative;
  padding: 0 var(--unit);
  gap: var(--unit);

  &.showBorder::before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: var(--border-width);
    background: var(--border-color, var(--c-nd-100));
  }
`;

const Slide = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: var(--unit);
  overflow-x: auto;
  overflow-y: hidden;
  /* Not enough sadly to prevent back navigation on safari */
  overscroll-behavior: contain;
  padding-bottom: var(--unit);
  flex-direction: row-reverse;
`;

export const SCORE_LIMIT = 50;

type Props = {
  InfiniteScrollLoader?: ReactNode;
  yMaxValue?: number;
  fixturesStats: {
    key?: string;
    label?: string;
    games: {
      id: string;
      playerScore?: number;
      upcoming?: boolean;
      gameLabel?: ReactNode;
      startDate: Date;
      color?: string;
      barLabel?: MessageDescriptor;
      dnpLabel?: ReactNode;
      onClick?: () => void;
    }[];
  }[];
  showBorder?: boolean;
};

export const FixtureChart = ({
  fixturesStats,
  InfiniteScrollLoader,
  yMaxValue,
  showBorder = true,
}: Props) => {
  const { formatMessage } = useIntl();
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // ensure the rightmost part is visible
    const scrollableContainer = getScrollParent(elementRef.current);
    if (scrollableContainer) {
      scrollableContainer.scrollTo(scrollableContainer.scrollWidth, 0);
    }
  }, []);

  const maxScore = useMemo(
    () =>
      yMaxValue ||
      Math.max(
        ...fixturesStats.map(fixtureStats =>
          Math.max(
            ...fixtureStats.games.map(game =>
              game.playerScore ? Math.abs(game.playerScore) : 0
            )
          )
        ),
        1 // if we receive all zeros from the score, keeping 1 here to make zeros stick to the x-axis.
      ),
    [fixturesStats, yMaxValue]
  );

  return (
    <DragToScroll>
      <Slide>
        <Wrapper
          ref={elementRef}
          aria-label={formatMessage({
            id: 'player.FixtureChart.fixtureChart',
            defaultMessage: 'Fixture chart',
          })}
          style={
            {
              '--max-score': maxScore,
            } as React.CSSProperties
          }
        >
          {InfiniteScrollLoader}
          {fixturesStats.map(fixtureStats => {
            return (
              <Fixture key={fixtureStats.key || fixtureStats.label}>
                <FixtureName>{fixtureStats.label}</FixtureName>
                <Games className={classNames({ showBorder })}>
                  {!fixtureStats.games.length && (
                    <FixtureScoreBar
                      game={{
                        id: 'empty',
                        playerScore: undefined,
                        upcoming: false,
                        startDate: new Date(),
                      }}
                    />
                  )}
                  {sortByDate(fixtureStats.games, g => g.startDate).map(
                    game => (
                      <FixtureScoreBar key={game.id} game={game} />
                    )
                  )}
                </Games>
              </Fixture>
            );
          })}
        </Wrapper>
      </Slide>
    </DragToScroll>
  );
};
