import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type RestrictedAccessReason = 'email' | 'phone';

interface RestrictedAccessContext {
  setShowRestrictedAccess: Dispatch<
    SetStateAction<RestrictedAccessReason | undefined>
  >;
  showRestrictedAccess: RestrictedAccessReason | undefined;
}

export const RestrictedAccessContext =
  createContext<RestrictedAccessContext | null>(null);

export const useRestrictedAccessContext = () =>
  useContext(RestrictedAccessContext)!;
