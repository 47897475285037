import { ReactNode } from 'react';

import { ButtonBase } from 'atoms/buttons/ButtonBase';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import * as nativeMessages from 'protos/webview/channel/native_messages';
import { StringKeysOf } from 'types';

import { Item } from '../types';

type messages = StringKeysOf<nativeMessages.ChannelMessage>;

const getItemPostMessage = (
  item: Item
): {
  message: messages;
  params: nativeMessages.ChannelMessage[messages];
} | null => {
  switch (item.__typename) {
    case 'BaseballCard':
    case 'NBACard':
    case 'Card': {
      return {
        message: 'showCardScreen',
        params: {
          cardSlug: item.slug,
        },
      };
    }
    default: {
      return null;
    }
  }
};

type Props = {
  item: Item;
  children?: ReactNode;
};

export const IosOpenItemDialog = ({ item, children }: Props) => {
  const { postMessage } = useIsMobileApp();
  const itemPostMessage = getItemPostMessage(item);

  if (!itemPostMessage) return children;

  const { message, params } = itemPostMessage;

  return (
    <ButtonBase
      onClick={() => {
        postMessage(message, params);
      }}
    >
      {children}
    </ButtonBase>
  );
};
