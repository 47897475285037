import classnames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import cardPlaceholder from 'assets/cards/placeholder.svg';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM, BodyS } from 'atoms/typography';

const Container = styled(Horizontal)`
  border-radius: var(--unit);
  padding: var(--half-unit) 0;
  cursor: pointer;
  &:hover,
  &.highlighted {
    background: var(--c-nd-100);
  }
`;
const Avatar = styled.div`
  width: calc(5 * var(--unit));
  aspect-ratio: 1;
  border-radius: var(--unit);
  overflow: hidden;
  flex-shrink: 0;
`;

const Text = styled(Vertical)`
  max-width: calc(100% - (6 * var(--unit)));
`;

const Img = styled.img<{ isContain?: boolean; fallbackUrl?: string }>`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: ${({ isContain }) => (isContain ? 'contain' : 'cover')};
  &::before {
    position: absolute;
    inset: 0;
    font-size: 0;
    content: '';
    background: var(--c-nd-100)
      url(${({ fallbackUrl }) => fallbackUrl || cardPlaceholder}) no-repeat top;
    background-size: cover;
  }
`;

interface Props {
  avatar?: ReactElement;
  renderAvatar?: (props: typeof Img) => ReactNode;
  primary: string | ReactElement;
  secondary?: string;
  isHighlighted: boolean;
}

export const Suggestion = ({
  avatar,
  renderAvatar,
  primary,
  secondary = '',
  isHighlighted,
}: Props) => {
  return (
    <Container className={classnames({ highlighted: isHighlighted })}>
      <Avatar>{renderAvatar?.(Img) || avatar}</Avatar>
      <Text gap={0}>
        <BodyM bold>{primary}</BodyM>
        <BodyS color="var(--c-nd-600)">{secondary}</BodyS>
      </Text>
    </Container>
  );
};
