import { Rarity } from '__generated__/globalTypes';
import { AnimatedNumber } from 'atoms/animations/AnimatedNumber';
import { Button } from 'atoms/buttons/Button';
import { Box } from 'atoms/icons/Box';
import { Cards } from 'atoms/icons/Cards';
import { Shards } from 'atoms/icons/Shards';
import { Badge } from 'atoms/ui/Badge';
import { RaffleTicketBalanceButton } from 'components/RaffleTicketBalanceButton';
import {
  ANY_SPORT_MY_CARDS_BOXES_TYPE,
  ANY_SPORT_MY_CARDS_ESSENCE_TYPE,
  ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
} from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';

type Props = {
  onClick?: () => void;
};

export const InventoryBalance = ({ onClick }: Props) => {
  const { currentUser } = useCurrentUserContext();

  const { generateSportContextPath } = useSportContext();

  if (!currentUser) {
    return null;
  }
  const { anyUserSportProfile, shardsChests, unopenedBoxes } = currentUser;

  const { blueprintCardsCount = 0 } = anyUserSportProfile || {};
  const totalShards = shardsChests.reduce(
    (acc, chest) => acc + chest.cardShardsCount,
    0
  );

  const chestWithThresholdUnlocked = shardsChests.find(
    chest => chest.newThresholdUnlocked
  );

  return (
    <>
      {blueprintCardsCount > 0 && (
        <Button
          size="medium"
          color="transparent"
          to={generateSportContextPath(
            ANY_SPORT_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE,
            {
              params: {
                type: Rarity.limited,
              },
            }
          )}
          onClick={onClick}
        >
          <Cards />
          <AnimatedNumber
            value={blueprintCardsCount}
            disableAnimationOnFirstRender
          />
        </Button>
      )}
      <RaffleTicketBalanceButton />
      <Button
        size="medium"
        color="transparent"
        to={generateSportContextPath(ANY_SPORT_MY_CARDS_BOXES_TYPE, {
          params: {
            type: Rarity.limited,
          },
        })}
        onClick={onClick}
      >
        <Box />
        <AnimatedNumber
          value={unopenedBoxes.totalCount}
          disableAnimationOnFirstRender
        />
      </Button>
      <Button
        size="medium"
        color="transparent"
        to={generateSportContextPath(ANY_SPORT_MY_CARDS_ESSENCE_TYPE, {
          params: {
            type: chestWithThresholdUnlocked?.rarity || Rarity.limited,
          },
        })}
        onClick={onClick}
      >
        <Badge variant="dot" invisible={!chestWithThresholdUnlocked}>
          <Shards />
        </Badge>
        <AnimatedNumber value={totalShards} disableAnimationOnFirstRender />
      </Button>
    </>
  );
};
