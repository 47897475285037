import {
  faDollar,
  faEuroSign,
  faSterlingSign,
} from '@fortawesome/pro-solid-svg-icons';

import { FiatCurrency } from '__generated__/globalTypes';

export type CurrencyCode = `${FiatCurrency}`;

export interface Currency {
  code: CurrencyCode;
  symbol: string;
}

export const currencies: { [locale: string]: Currency } = {
  usd: {
    code: 'USD',
    symbol: '$',
  },
  gbp: {
    code: 'GBP',
    symbol: '£',
  },
  eur: {
    code: 'EUR',
    symbol: '€',
  },
};

export const currencySymbol = (code: string) =>
  currencies[code.toLowerCase()].symbol;

export const currency = (c: FiatCurrency | undefined): Currency => {
  if (!c) return currencies.eur;
  switch (c) {
    case FiatCurrency.EUR:
      return currencies.eur;
    case FiatCurrency.GBP:
      return currencies.gbp;
    default:
      return currencies.usd;
  }
};

export const getFaCurrencySymbol = (fiatCurrency?: FiatCurrency) => {
  switch (fiatCurrency) {
    case FiatCurrency.EUR:
      return faEuroSign;
    case FiatCurrency.USD:
      return faDollar;
    case FiatCurrency.GBP:
      return faSterlingSign;
    default:
      return faDollar;
  }
};
