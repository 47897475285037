import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

import { Sport } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { FootballLogo } from '@sorare/core/src/atoms/icons/FootballLogo';
import { MLBLogo } from '@sorare/core/src/atoms/icons/MLBLogo';
import { NBALogo } from '@sorare/core/src/atoms/icons/NBALogo';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { useSportContext } from '@sorare/core/src/contexts/sport';

const Root = styled(Horizontal).attrs({ center: true })`
  gap: var(--unit);
  padding: 0 var(--unit);
  border-radius: var(--triple-unit);
  border: 1px solid var(--c-nd-150);
  height: 40px;

  &:hover {
    background-color: var(--c-nd-50);
  }

  transition:
    background-color 100ms ease-in-out,
    border-color 100ms ease-in-out;
`;
const SportIconWrapper = styled(Horizontal).attrs({ center: true })`
  &.football {
    width: var(--double-and-a-half-unit);
    height: var(--double-and-a-half-unit);
  }
  &.nba {
    padding-left: var(--unit);
    width: var(--double-and-a-half-unit);
  }
  &.mlb {
    height: var(--double-and-a-half-unit);
  }
  & > svg {
    width: 100%;
  }
`;

const sportIcons = {
  [Sport.FOOTBALL]: <FootballLogo />,
  [Sport.BASEBALL]: <MLBLogo />,
  [Sport.NBA]: <NBALogo />,
};

type Props = {
  displaySelectedSportName?: boolean;
  withoutChevronIcon?: boolean;
  active?: boolean;
};
export const SportButtonContent = ({
  displaySelectedSportName,
  withoutChevronIcon,
  active,
}: Props) => {
  const { sport } = useSportContext();

  return (
    <Root>
      <SportIconWrapper className={sport.toLowerCase()}>
        {sportIcons[sport]}
      </SportIconWrapper>
      {displaySelectedSportName && (
        <LabelM bold uppercase>
          {sport}
        </LabelM>
      )}
      {!withoutChevronIcon && (
        <FontAwesomeIcon
          icon={active ? faChevronUp : faChevronDown}
          color="var(--c-nd-600)"
        />
      )}
    </Root>
  );
};
