import { useCallback, useEffect, useRef } from 'react';

export const usePrevious = <T>(
  value: T,
  shouldUpdate?: (prev: T | undefined, next: T) => boolean
) => {
  const ref = useRef<T>();
  // Ignore upstream shouldUpdate change as this should only be base on prev & next value
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shouldUpdateCb = useCallback(shouldUpdate || (() => true), []);

  useEffect(() => {
    if (shouldUpdateCb(ref.current, value)) {
      ref.current = value;
    }
  }, [value, shouldUpdateCb]);

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};
