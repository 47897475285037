import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';

import { Sport } from '__generated__/globalTypes';
import { Button } from 'atoms/buttons/Button';
import { StarBall } from 'atoms/icons/StarBall';
import { Vertical } from 'atoms/layout/flex';
import { DisplayM, HeadlineL, HeadlineXL } from 'atoms/typography';
import { GraphqlForm, TextField } from 'components/form/Form';
import { useIntlContext } from 'contexts/intl';
import { useWalletContext } from 'contexts/wallet';
import { useEvents } from 'lib/events/useEvents';
import { glossary } from 'lib/glossary';
import { laptopAndAbove } from 'style/mediaQuery';

import {
  CheckNicknameAvailability,
  CheckNicknameAvailabilityVariables,
} from './__generated__/SportContainer.graphql';

const Wrapper = styled(animated.div)`
  display: flex;
  align-items: flex-end;
  position: relative;
  isolation: isolate;
  justify-content: center;
  overflow: hidden;
  transition: 300ms;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-inline: var(--double-unit);

  & > * {
    z-index: 1;
  }

  &:not(.active) {
    cursor: pointer;
  }

  &.step1 {
    align-items: center;
  }

  &.step2 {
    align-items: start;
  }

  &.step2::after {
    content: '';
    inset: 0;
    pointer-events: none;
    position: absolute;
    backdrop-filter: blur(15px);
  }

  @media ${laptopAndAbove} {
    padding-inline: 0;
  }

  &.active::before {
    content: '';
    inset: 0;
    opacity: 0.35;
    position: absolute;
    pointer-events: none;
    background: linear-gradient(0deg, #101010 0%, rgba(16, 16, 16, 0) 143.7%);
  }
`;

const StartButton = styled(Button)`
  transition: 250ms;
  margin-bottom: 3vh;
  backdrop-filter: blur(33px);
  background-color: rgba(255, 255, 255, 0.11);

  @media ${laptopAndAbove} {
    margin-bottom: calc(var(--unit) * 18);
  }

  &:hover {
    background-color: white;
    color: var(--c-black);
  }
`;

const Title = styled(DisplayM)`
  font-weight: 400;
  letter-spacing: -1px;

  text-align: center;
  font-size: 32px;

  @media ${laptopAndAbove} {
    display: flex;
    font-size: 48px;
    white-space: nowrap;
    align-items: center;
    text-align: start;
  }
`;

const Subtitle = styled(HeadlineL)`
  opacity: 0.75;
  line-height: 1;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.5px;

  @media ${laptopAndAbove} {
    font-size: 24px;
  }
`;

const SmallStarBall = styled(StarBall)`
  height: 20px;
  margin-bottom: var(--intermediate-unit);

  @media ${laptopAndAbove} {
    height: 26px;
    margin-bottom: 0;
  }
`;

const StyledInput = styled(TextField)`
  height: 55px;
  backdrop-filter: blur(10px);
  width: min(calc(100vw - 32px), 520px);
  border: 1px solid var(--c-white);
  background-color: rgba(255, 255, 255, 0.11);

  input {
    font-size: 20px;
    text-align: center;
    font-family: 'Helvetica';
    color: var(--c-white);
    background-color: transparent;
  }

  input::placeholder {
    opacity: 0.75;
  }
`;

const FinalStepWrapper = styled(Vertical)`
  margin-top: 10vh;
  gap: 0;
  overflow: hidden;
  @media ${laptopAndAbove} {
    gap: calc(var(--unit) * 8);
  }
`;

const FinalStepTitle = styled(HeadlineXL)`
  z-index: 1;
  line-height: 1;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;

  span {
    font-size: 18px;
  }

  @media ${laptopAndAbove} {
    font-size: 32px;
    span {
      font-size: 32px;
    }
  }
`;

const ErrorWrapper = styled.div`
  border-radius: 8px;
  background-color: var(--c-red-600);
  padding: var(--intermediate-unit) var(--double-unit);

  span {
    color: white;
  }
`;

const messages = defineMessages({
  lastStepTitle: {
    id: 'Landing.chooseYourSportModal.lastStepTitle',
    defaultMessage:
      'Welcome to Sorare, {username}.{br}<span>One final step before you start your Sorare journey</span>',
  },
  placeholder: {
    id: 'Landing.chooseYourSportModal.placeholder',
    defaultMessage: 'Enter your manager name',
  },
});

const CHECK_NICKNAME_AVAILABILITY_QUERY = gql`
  query CheckNicknameAvailability($nickname: String!) {
    isNicknameAvailable(nickname: $nickname)
  }
` as TypedDocumentNode<
  CheckNicknameAvailability,
  CheckNicknameAvailabilityVariables
>;

type UsernameFormProps = {
  sport: Sport;
  onSuccess: () => void;
  setUsername: (username: string) => void;
  error: string | undefined;
};

const UsernameForm = ({
  sport,
  onSuccess,
  setUsername,
  error: parentError,
}: UsernameFormProps) => {
  const [error, setError] = useState(false);
  const { signUp } = useWalletContext();
  const { formatMessage } = useIntlContext();
  const [search] = useLazyQuery(CHECK_NICKNAME_AVAILABILITY_QUERY);
  const track = useEvents();

  return (
    <GraphqlForm
      onSubmit={async ({ username }, onResult, onCancel) => {
        const { data } = await search({ variables: { nickname: username } });

        if (data?.isNicknameAvailable) {
          onResult({});
          setUsername(username);
          signUp(username, sport);
          track('Choose Signup Manager Name', {});
        } else {
          track('Fail Signup Manager Name', {});
          onResult({
            error: 'Username is already taken',
          });
          onCancel();
        }
      }}
      onSuccess={() => {
        onSuccess();
        setError(false);
      }}
      onError={() => {
        setError(true);
      }}
      render={(
        Error: React.ComponentType<React.PropsWithChildren<unknown>>,
        SubmitButton: any,
        _,
        values
      ) => (
        <Vertical center gap={3}>
          <StyledInput
            type="text"
            name="username"
            placeholder={formatMessage(messages.placeholder)}
          />
          {error && (
            <ErrorWrapper>
              <Error />
            </ErrorWrapper>
          )}
          {parentError && <ErrorWrapper>{parentError}</ErrorWrapper>}
          <SubmitButton
            medium
            type="submit"
            color="secondary"
            disabled={!values.username}
          >
            {formatMessage(glossary.continue)}
          </SubmitButton>
        </Vertical>
      )}
    />
  );
};

type Props = {
  step: 0 | 1 | 2;
  active?: boolean;
  sport: Sport;
  onClick: () => void;
  className?: string;
  background: string;
  WalletPortal: ReactNode;
  startButtonText: string;
  goToNextStep: () => void;
  secondaryStepTitle: string;
  secondaryStepSubtitle: string;
  usernameError: string | undefined;
};

export const SportContainer = ({
  step,
  active,
  sport,
  onClick,
  className,
  background,
  goToNextStep,
  WalletPortal,
  startButtonText,
  secondaryStepTitle,
  secondaryStepSubtitle,
  usernameError,
}: Props) => {
  const [username, setUsername] = useState<string | undefined>();

  const secondTransition = useTransition(step === 1 && active, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: { duration: 300 },
  });

  return (
    <Wrapper
      onClick={() => !active && onClick()}
      className={classNames(className, {
        active,
        step1: step > 0,
        step2: step === 2,
      })}
      style={{ backgroundImage: `url(${background})` }}
    >
      {step === 0 && (
        <StartButton size="large" color="quaternary">
          {startButtonText}
        </StartButton>
      )}
      {secondTransition(
        (styles, item) =>
          item && (
            <animated.div style={styles}>
              <Vertical gap={5} center>
                <Vertical gap={0} center>
                  <Title as="h1">
                    {secondaryStepTitle}
                    <SmallStarBall />
                  </Title>
                  <Subtitle as="h3">{secondaryStepSubtitle}</Subtitle>
                </Vertical>
                <UsernameForm
                  sport={sport}
                  onSuccess={goToNextStep}
                  setUsername={setUsername}
                  error={usernameError}
                />
              </Vertical>
            </animated.div>
          )
      )}
      {step === 2 && active && (
        <FinalStepWrapper center>
          <FinalStepTitle as="h2">
            <FormattedMessage
              {...messages.lastStepTitle}
              values={{
                username,
                br: <br />,
                span: (...chunks) => <span>{chunks}</span>,
              }}
            />
          </FinalStepTitle>
          {WalletPortal}
        </FinalStepWrapper>
      )}
    </Wrapper>
  );
};
