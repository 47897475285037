import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { InGameCurrency, Rarity, Sport } from '__generated__/globalTypes';
import { AnimatedNumber } from 'atoms/animations/AnimatedNumber';
import { Button } from 'atoms/buttons/Button';
import { Dropdown } from 'atoms/dropdowns/Dropdown';
import { FontAwesomeIcon } from 'atoms/icons';
import { Boost } from 'atoms/icons/Boost';
import { Horizontal, SBHorizontal, Vertical } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import { useGetMyCardsBoostLink } from 'hooks/my-cards/useGetMyCardsBoostLink';
import { formatScarcity } from 'lib/cards';
import { useEvents } from 'lib/events/useEvents';
import { inGameCurrencyToRarity } from 'lib/scarcity';

const DropdownContent = styled(Vertical)`
  padding: var(--unit);
`;

export const XpBalances = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUserContext();
  const track = useEvents();

  const { sport } = useSportContext();
  const getMyCardsBoostLink = useGetMyCardsBoostLink();

  if (!currentUser) return null;

  const { xpBalances } = currentUser;
  const filteredXpBalances =
    currentUser?.betaTesterFootballCommonCardsGame || sport !== Sport.FOOTBALL
      ? xpBalances
      : xpBalances.filter(
          balance => balance.currency !== InGameCurrency.COMMON_XP
        );

  const total = filteredXpBalances.reduce((acc, { amount }) => acc + amount, 0);

  const firstNonZeroBalance = filteredXpBalances.find(
    ({ amount }) => amount > 0
  );

  const onClick = () => {
    const rarity = firstNonZeroBalance?.currency
      ? inGameCurrencyToRarity[firstNonZeroBalance.currency] || Rarity.limited
      : Rarity.limited;

    track('Click Xp Balance', {
      rarity,
    });

    navigate(
      getMyCardsBoostLink({
        rarity,
        sport,
      })
    );
  };

  return (
    <Dropdown
      triggerOnHover
      label={props => (
        <Button size="medium" color="transparent" {...props} onClick={onClick}>
          <Boost size={2} />
          <AnimatedNumber value={total} disableAnimationOnFirstRender />
        </Button>
      )}
    >
      <DropdownContent>
        <Horizontal>
          <Boost size={1.5} />
          <LabelS bold>
            <FormattedMessage
              id="XpBalances.xpReserve"
              defaultMessage="XP Reserve"
            />
          </LabelS>
        </Horizontal>
        {filteredXpBalances.map((xpBalance, i) => {
          const rarity = inGameCurrencyToRarity[xpBalance.currency];
          if (!rarity) return null;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <SBHorizontal gap={4} key={i}>
              <LabelS color="var(--c-nd-600)">{formatScarcity(rarity)}</LabelS>
              <Horizontal gap={0.5}>
                <LabelS bold>
                  <FormattedNumber value={xpBalance.amount} />
                </LabelS>
                {xpBalance.capAlmostReached && (
                  <FontAwesomeIcon
                    size="xs"
                    color="var(--c-yellow-400)"
                    icon={faTriangleExclamation}
                  />
                )}
              </Horizontal>
            </SBHorizontal>
          );
        })}
      </DropdownContent>
    </Dropdown>
  );
};
