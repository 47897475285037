import { createContext, useContext } from 'react';

// tm stands for telemetry, but has been named this way to avoid being blocked by ad blockers

export interface Operation {
  name: string;
  timeMs: number;
  path: string;
}

interface TMContext {
  logOperation: (operation: Operation) => void;
  flushOperations: () => Operation[];
}

export const TMContext = createContext<TMContext | null>(null);

export const useTMContext = () => useContext(TMContext)!;
