import { createContext, useContext } from 'react';

export enum MarketplaceOnboardingStep {
  menu = 'menu',
  managerSalesLink = 'managerSalesLink',
  search = 'search',
  marketplaceItem = 'marketplaceItem',
  buy = 'buy',
}
export enum LearnCompetitionsOnboardingStep {
  menu = 'learning_competition_menu',
}
export type OnboardingStep =
  | MarketplaceOnboardingStep
  | LearnCompetitionsOnboardingStep;

export type Task = {
  id: string;
};

type ManagerTaskContext = {
  isOpen: boolean;
  step: OnboardingStep | undefined;
  setStep: (step?: OnboardingStep) => void;
  task?: Task;
  setTask: (task?: Task) => void;
  onSuccessCallback?: () => void;
  setOnSuccessCallback: (callback: () => void) => void;
};

export const ManagerTaskContext = createContext<ManagerTaskContext | null>(
  null
);

export const useManagerTaskContext = () => useContext(ManagerTaskContext)!;
