export const DISCORD_INVITATION_URL = 'https://discord.gg/sorare';
export const TWITTER_URL = 'https://twitter.com/sorare';
export const SUPPORT_TWITTER_URL = 'https://twitter.com/SorareSupport';
export const INSTAGRAM_URL = 'https://www.instagram.com/sorare';
export const YOUTUBE_URL = 'https://www.youtube.com/@Sorare';
export const TIKTOK_URL = 'https://www.tiktok.com/@sorare';
export const REDDIT_URL = 'https://www.reddit.com/r/Sorare';
export const FACEBOOK_URL = 'https://www.facebook.com/SorareHQ/';

export const MLB_INSTAGRAM_URL = 'https://www.instagram.com/soraremlb';
export const MLB_TWITTER_URL = 'https://twitter.com/SorareMLB';
export const MLB_DISCORD_INVITATION_URL = 'https://discord.gg/99BNrkTXZG';
export const MLB_FACEBOOK_URL = 'https://www.facebook.com/SorareMLB/';

export const NBA_INSTAGRAM_URL = 'https://www.instagram.com/sorarenba';
export const NBA_TWITTER_URL = 'https://twitter.com/SorareNBA';
export const NBA_FACEBOOK_URL = 'https://facebook.com/SorareNBA';
export const NBA_DISCORD_INVITATION_URL = 'https://discord.gg/STdWv5Dr79';

// this is the AppsFlyer link either opening the app or redirecting to the app store, allowing us to forward
// some query parameters to the native apps
export const STORE_URL = 'https://sorare.onelink.me/Rk91/3bxn978p';

export const DELAYED_REVEAL_HELP_LINK =
  'https://sorare.com/help/a/20047651185821/what-happens-if-sorare-doesn-t-have-the-official-photos-of-my-player-before-the-reveal';

export const PROBATION_UNTIL_HELP_LINK =
  'https://sorare.com/help/a/22012899481245/my-account-is-on-probation-what-should-i-do';
