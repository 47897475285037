import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { FontAwesomeIcon } from 'atoms/icons';
import { Vertical } from 'atoms/layout/flex';
import { HeadlineL, LabelM } from 'atoms/typography';
import { glossary } from 'lib/glossary';

const Content = styled(Vertical).attrs({ gap: 2 })`
  align-items: flex-start;
  height: 100%;
`;

const Actions = styled(Vertical).attrs({ gap: 0 })`
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;
`;
export const Review = ({ onClick }: { onClick: () => void }) => {
  return (
    <Content>
      <FontAwesomeIcon size="2xl" icon={faClock} />
      <HeadlineL as="h3">
        <FormattedMessage
          id="Review.title"
          defaultMessage="We’re reviewing your ID"
        />
      </HeadlineL>
      <LabelM>
        <FormattedMessage
          id="Review.description"
          defaultMessage="It usually takes 5 - 10 minutes but sometimes can take up to 24 hours."
        />
      </LabelM>
      <LabelM>
        <FormattedMessage
          id="Review.descriptionNotify"
          defaultMessage="We’ll notify you once we validate your ID. After that, you will have access to cash deposits, cash withdrawals, and cash rewards."
        />
      </LabelM>
      <LabelM>
        <FormattedMessage
          id="Review.meantime"
          defaultMessage="In the meantime, you can pick up right where you left off."
        />
      </LabelM>
      <Actions>
        <Button fullWidth size="medium" color="primary" onClick={onClick}>
          <FormattedMessage {...glossary.done} />
        </Button>
      </Actions>
    </Content>
  );
};
