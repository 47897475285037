import { TypedDocumentNode, gql } from '@apollo/client';
import { useCallback } from 'react';

import { PrivateKeyRecoveryOptionMethodEnum } from '__generated__/globalTypes';
import { walletRecovery } from 'contexts/currentUser/queries';
import { useMutation } from 'hooks/graphql/useMutation';

import {
  ActivateWalletRecoveryEmailMutation,
  ActivateWalletRecoveryEmailMutationVariables,
} from './__generated__/useActivateWalletRecoveryEmail.graphql';

const ACTIVATE_WALLET_RECOVERY_EMAIL = gql`
  mutation ActivateWalletRecoveryEmailMutation(
    $input: activateWalletRecoveryInput!
  ) {
    activateWalletRecovery(input: $input) {
      currentUser {
        slug
        ...CurrentUserProvider_walletRecovery
      }
      errors {
        path
        message
        code
      }
    }
  }
  ${walletRecovery}
` as TypedDocumentNode<
  ActivateWalletRecoveryEmailMutation,
  ActivateWalletRecoveryEmailMutationVariables
>;

export const useActivateWalletRecoveryEmail = () => {
  const [mutate, { loading }] = useMutation(ACTIVATE_WALLET_RECOVERY_EMAIL, {
    showErrorsInForm: true,
  });

  const activateWalletRecoveryEmail = useCallback(
    async ({
      verificationCode,
      destination,
    }: {
      verificationCode: string;
      destination: string;
    }) => {
      const input = {
        verificationCode,
        destination,
        method: PrivateKeyRecoveryOptionMethodEnum.EMAIL,
      };
      const result = await mutate({
        variables: {
          input,
        },
      });
      return result;
    },
    [mutate]
  );

  return {
    activateWalletRecoveryEmail,
    loading,
  };
};
