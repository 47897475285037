import { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { Vertical } from 'atoms/layout/flex';
import { HeadlineM } from 'atoms/typography';
import { TooltipFooter } from 'components/onboarding/managerTask/TooltipFooter';
import { glossary } from 'lib/glossary';

export const ManagerTaskContainer: FC<
  React.PropsWithChildren<{
    labels: {
      title: MessageDescriptor;
      button?: MessageDescriptor;
    };
    current: number;
    total: number;
    onClick: () => void;
  }>
> = ({ labels, children, current, total, onClick }) => {
  return (
    <Vertical>
      <HeadlineM as="h4">
        <FormattedMessage {...labels.title} />
      </HeadlineM>
      {children}
      <TooltipFooter
        value={current}
        total={total}
        onClick={onClick}
        buttonLabel={labels.button || glossary.continue}
      />
    </Vertical>
  );
};
