import { ReactNode } from 'react';

import { LandingAppHeader } from '@sorare/core/src/components/navigation/AppHeader/LandingAppHeader';

import { IOSAppHeader } from './IOSAppHeader';
import { WebAppHeader } from './WebAppHeader';
import { HEADER_MODE, useAppHeaderMode } from './useAppHeaderMode';

type Props = { extraHeaderButton?: ReactNode };
export const NewAppHeader = ({ extraHeaderButton }: Props) => {
  const headerMode = useAppHeaderMode();

  if (headerMode === HEADER_MODE.iOS) {
    return <IOSAppHeader />;
  }

  if (headerMode === HEADER_MODE.LoggedIn) {
    return <WebAppHeader extraHeaderButton={extraHeaderButton} />;
  }

  return <LandingAppHeader />;
};
