import { defineMessages } from 'react-intl';

import {
  BaseballAverageStatType,
  BaseballPosition,
  Position,
  Rarity,
  So5LeaderboardType,
} from '__generated__/globalTypes';

type Scarcity = 'common' | 'limited' | 'rare' | 'super_rare' | 'unique';

export const getScarcityName = (scarcity: Scarcity | Rarity): string =>
  scarcity.replace('_', ' ');

export const battingThrowingHandMessage = defineMessages({
  header: {
    id: 'MLB.playerStats.battingAndThrowingHands.header',
    defaultMessage: 'Batting / Throwing',
  },
});

export const positionNames = defineMessages({
  [Position.baseball_starting_pitcher]: {
    id: 'PositionFilter.startingPitcher',
    defaultMessage: 'Starting Pitcher',
  },
  [Position.baseball_relief_pitcher]: {
    id: 'PositionFilter.reliefPitcher',
    defaultMessage: 'Relief Pitcher',
  },
  [Position.baseball_catcher]: {
    id: 'PositionFilter.baseball_catcher',
    defaultMessage: 'Catcher',
  },
  [Position.baseball_designated_hitter]: {
    id: 'PositionFilter.baseball_designated_hitter',
    defaultMessage: 'Designated hitter',
  },
  [Position.baseball_first_base]: {
    id: 'PositionFilter.baseball_first_base',
    defaultMessage: 'First base',
  },
  [Position.baseball_outfield]: {
    id: 'PositionFilter.baseball_outfield',
    defaultMessage: 'Outfield',
  },
  [Position.baseball_second_base]: {
    id: 'PositionFilter.baseball_second_base',
    defaultMessage: 'Second base',
  },
  [Position.baseball_shortstop]: {
    id: 'PositionFilter.baseball_shortstop',
    defaultMessage: 'Shortstop',
  },
  [Position.baseball_third_base]: {
    id: 'PositionFilter.baseball_third_base',
    defaultMessage: 'Third base',
  },
});

export const baseballPositionNames = defineMessages<BaseballPosition>({
  [BaseballPosition.STARTING_PITCHER]: {
    id: 'PositionFilter.startingPitcher',
    defaultMessage: 'Starting Pitcher',
  },
  [BaseballPosition.RELIEF_PITCHER]: {
    id: 'PositionFilter.reliefPitcher',
    defaultMessage: 'Relief Pitcher',
  },
  [BaseballPosition.FIRST_BASE]: {
    defaultMessage: 'First base',
    id: 'PositionFilter.firstBase',
  },
  [BaseballPosition.THIRD_BASE]: {
    defaultMessage: 'Third base',
    id: 'PositionFilter.thirdBase',
  },
  [BaseballPosition.DESIGNATED_HITTER]: {
    defaultMessage: 'Designated hitter',
    id: 'PositionFilter.DesignatedHitter',
  },
  [BaseballPosition.CATCHER]: {
    defaultMessage: 'Catcher',
    id: 'PositionFilter.Catcher',
  },
  [BaseballPosition.SECOND_BASE]: {
    defaultMessage: 'Second base',
    id: 'PositionFilter.SecondBase',
  },
  [BaseballPosition.SHORTSTOP]: {
    defaultMessage: 'Shortstop',
    id: 'PositionFilter.ShortStop',
  },
  [BaseballPosition.OUTFIELD]: {
    defaultMessage: 'Outfield',
    id: 'PositionFilter.Outfield',
  },
  [BaseballPosition.UNKNOWN]: {
    defaultMessage: 'Unknown',
    id: 'PositionFilter.Unknown',
  },
});

type PlayerInjuryStatus =
  | 'DayToDay'
  | '60DayIL'
  | '15DayIL'
  | '10DayIL'
  | '7DayIL'
  | 'Out'
  | 'Suspension';

const shortMessages = defineMessages<PlayerInjuryStatus>({
  DayToDay: {
    id: 'Player.InjuryStatusMessage.Short.DayToDay',
    defaultMessage: 'DTD',
  },
  '60DayIL': {
    id: 'Player.InjuryStatusMessage.Short.60DayIL',
    defaultMessage: 'IL60',
  },
  '15DayIL': {
    id: 'Player.InjuryStatusMessage.Short.15DayIL',
    defaultMessage: 'IL15',
  },
  '10DayIL': {
    id: 'Player.InjuryStatusMessage.Short.10DayIL',
    defaultMessage: 'IL10',
  },
  '7DayIL': {
    id: 'Player.InjuryStatusMessage.Short.7DayIL',
    defaultMessage: 'IL7',
  },
  Out: {
    id: 'Player.InjuryStatusMessage.Short.Out',
    defaultMessage: 'OUT',
  },
  Suspension: {
    id: 'Player.InjuryStatusMessage.Short.Suspension',
    defaultMessage: 'SUSP',
  },
});

const longMessages = defineMessages<PlayerInjuryStatus>({
  DayToDay: {
    id: 'Player.InjuryStatusMessage.Long.DayToDay',
    defaultMessage: 'Day-To-Day',
  },
  '60DayIL': {
    id: 'Player.InjuryStatusMessage.Long.60DayIL',
    defaultMessage: '60-Day IL',
  },
  '15DayIL': {
    id: 'Player.InjuryStatusMessage.Long.15DayIL',
    defaultMessage: '15-Day IL',
  },
  '10DayIL': {
    id: 'Player.InjuryStatusMessage.Long.10DayIL',
    defaultMessage: '10-Day IL',
  },
  '7DayIL': {
    id: 'Player.InjuryStatusMessage.Long.7DayIL',
    defaultMessage: '7-Day IL',
  },
  Out: {
    id: 'Player.InjuryStatusMessage.Long.Out',
    defaultMessage: 'Out',
  },
  Suspension: {
    id: 'Player.InjuryStatusMessage.Long.Suspension',
    defaultMessage: 'Suspension',
  },
});

export function getPlayerInjuryMessage(
  status: string | null,
  { long }: { long?: boolean } = {}
) {
  const messages = long ? longMessages : shortMessages;

  switch (status) {
    case 'Day-To-Day':
      return messages.DayToDay;
    case '60-Day IL':
      return messages['60DayIL'];
    case '15-Day IL':
      return messages['15DayIL'];
    case '10-Day IL':
      return messages['10DayIL'];
    case '7-Day IL':
      return messages['7DayIL'];
    case 'Suspension':
      return messages.Suspension;
    default:
    case 'Out':
      return messages.Out;
  }
}

type PlayerInjuryColor = 'yellow' | 'red';

export function getPlayerInjuryColor(status: string | null): PlayerInjuryColor {
  switch (status) {
    case 'Day-To-Day':
      return 'yellow';
    default:
      return 'red';
  }
}

const anyPitcherPositions = [
  Position.baseball_starting_pitcher,
  Position.baseball_relief_pitcher,
];

export const anyHitterPositions = [
  Position.baseball_first_base,
  Position.baseball_third_base,
  Position.baseball_designated_hitter,
  Position.baseball_second_base,
  Position.baseball_catcher,
  Position.baseball_shortstop,
  Position.baseball_outfield,
];

export const anyBaseballPositions = [
  ...anyPitcherPositions,
  ...anyHitterPositions,
];

export const isPitcher = (positions: Position[]) =>
  positions.some(p => anyPitcherPositions.includes(p));

export const isHitter = (positions: Position[]) =>
  positions.some(p => anyHitterPositions.includes(p));

export const randomMlbPositions = () => {
  return anyBaseballPositions[
    Math.floor(Math.random() * anyBaseballPositions.length)
  ];
};

export const shortBaseballStats = {
  [BaseballAverageStatType.HITTING_AT_BASE]: 'AB',
  [BaseballAverageStatType.HITTING_AT_PLATE]: 'PA',
  [BaseballAverageStatType.HITTING_CAUGHT_STEALING]: 'CS',
  [BaseballAverageStatType.HITTING_DOUBLES]: '2B',
  [BaseballAverageStatType.HITTING_HIT_BY_PITCH]: 'HBP',
  [BaseballAverageStatType.HITTING_HOME_RUNS]: 'HR',
  [BaseballAverageStatType.HITTING_RUNS]: 'R',
  [BaseballAverageStatType.HITTING_RUNS_BATTED_IN]: 'RBI',
  [BaseballAverageStatType.HITTING_SINGLES]: '1B',
  [BaseballAverageStatType.HITTING_STOLEN_BASES]: 'SB',
  [BaseballAverageStatType.HITTING_STRIKEOUTS]: 'K',
  [BaseballAverageStatType.HITTING_TRIPLES]: '3B',
  [BaseballAverageStatType.HITTING_WALKS]: 'BB',

  [BaseballAverageStatType.PITCHING_EARNED_RUNS]: 'ER',
  [BaseballAverageStatType.PITCHING_HITS_ALLOWED]: 'H',
  [BaseballAverageStatType.PITCHING_HIT_BATSMEN]: 'HBP',
  [BaseballAverageStatType.PITCHING_HOLDS]: 'HLD',
  [BaseballAverageStatType.PITCHING_INNINGS_PITCHED]: 'IP',
  [BaseballAverageStatType.PITCHING_NO_HITTERS]: 'NH',
  [BaseballAverageStatType.PITCHING_PITCH_COUNT]: 'P',
  [BaseballAverageStatType.PITCHING_RELIEF_APPEARANCE]: 'G',
  [BaseballAverageStatType.PITCHING_SAVES]: 'SV',
  [BaseballAverageStatType.PITCHING_STRIKEOUTS]: 'K',
  [BaseballAverageStatType.PITCHING_WALKS]: 'BB',
  [BaseballAverageStatType.PITCHING_WINS]: 'W',
};

export const dailySwingTournamentTypes = [
  So5LeaderboardType.MLB_COMMON_DAILY_SWING,
  So5LeaderboardType.MLB_LIMITED_DAILY_SWING,
  So5LeaderboardType.MLB_RARE_DAILY_SWING,
  So5LeaderboardType.MLB_SUPER_RARE_DAILY_SWING,
  So5LeaderboardType.MLB_UNIQUE_DAILY_SWING,
];
