import { useEffect, useId } from 'react';
import { useScrollbarWidth } from 'react-use';

function addStyle(id: string, css: string) {
  const style = document.createElement('style');
  style.id = id;
  style.textContent = css;
  document.head.append(style);
}

export const useBodyLock = (locked = false) => {
  const uniqueId = useId();
  const scrollBarWidth = useScrollbarWidth();
  useEffect(() => {
    if (!locked) {
      return undefined;
    }
    addStyle(
      uniqueId,
      `body {
        overflow: hidden;
        padding-right: ${scrollBarWidth ? `${scrollBarWidth}px` : 'unset'};
      }`
    );
    return () => {
      document.getElementById(uniqueId)?.remove();
    };
  }, [locked, uniqueId, scrollBarWidth]);
};
