import { css } from 'styled-components';

import { derivatives } from 'lib/share';

export const breakpoints = {
  se: 375,
  mobile: 480,
  tablet: 720,
  laptop: 960,
  desktop: 1200,
  mediumDesktop: 1320,
  largeDesktop: 1800,
  post: derivatives.post.width,
  square: derivatives.square.width,
  story: derivatives.story.width,
} as const;

const heightBreakpoints = {
  smallMobile: 670,
} as const;

export type Breakpoint = keyof typeof breakpoints;

export const mediumMobileHeightAndAbove =
  `(min-height: ${heightBreakpoints.smallMobile}px)` as const;

export const mobileAndAbove = `(min-width: ${breakpoints.mobile}px)` as const;
export const tabletAndAbove = `(min-width: ${breakpoints.tablet}px)` as const;
export const laptopAndAbove = `(min-width: ${breakpoints.laptop}px)` as const;
export const laptopAndBelow = `(max-width: ${
  breakpoints.laptop - 1
}px)` as const;
export const desktopAndAbove = `(min-width: ${breakpoints.desktop}px)` as const;
export const mediumDesktopAndAbove =
  `(min-width: ${breakpoints.mediumDesktop}px)` as const;
export const largeDesktopAndAbove =
  `(min-width: ${breakpoints.largeDesktop}px)` as const;

export const socialPost =
  `(width: ${derivatives.post.width}px) and (height: ${derivatives.post.height}px)` as const;
export const socialSquare =
  `(width: ${derivatives.square.width}px) and (height: ${derivatives.square.height}px)` as const;
export const socialStory =
  `(width: ${derivatives.story.width}px) and (height: ${derivatives.story.height}px)` as const;

const socialStoryOrSquare =
  `(max-width: ${derivatives.story.width}px)` as const;

const breakpointMQ =
  (mq: string) =>
  (strings: TemplateStringsArray, ...expr: string[]) => css`
    @media ${mq} {
      ${css(strings, ...expr)};
    }
  `;

export const socialStoryOrSquareMQ = breakpointMQ(socialStoryOrSquare);
export const socialSquareMQ = breakpointMQ(socialSquare);
