import { generatePath } from 'react-router-dom';

import { Navigate } from '@sorare/routing';

import { ANY_SPORT_BLOG } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';

const BlogPage = () => {
  const { sport } = useSportContext();

  return (
    <Navigate
      to={generatePath(ANY_SPORT_BLOG, {
        sport: sport.toLowerCase(),
      })}
      replace
    />
  );
};

export default BlogPage;
