import { addDays, isBefore, isPast } from 'date-fns';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FiatWalletAccountState } from '__generated__/globalTypes';
import { CreateFiatWallet } from 'components/fiatWallet/CreateFiatWallet';
import { CreateFiatWalletSteps } from 'components/fiatWallet/CreateFiatWallet/type';
import {
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
} from 'constants/__generated__/routes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useDeeplinkFromNativeApp } from 'hooks/useDeeplinkFromNativeApp';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { LIFECYCLE, useLifecycle } from 'hooks/useLifecycle';
import { useQueryString } from 'hooks/useQueryString';
import { useCashWalletKycEvents } from 'hooks/wallets/useCashWalletKycEvents';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';
import {
  EnterCashWalletKYCFlow_Source,
  EnterCashWalletKYCFlow_Target,
} from 'protos/events/platform/web/events';

const cashWalletLaunchDate = new Date('2023-08-01T00:00:00.000Z');

export const WalletSetup = () => {
  const { trackEnter } = useCashWalletKycEvents();
  const {
    currentUser,
    walletPreferences: { hasMigratedAndSetupWallets },
  } = useCurrentUserContext();
  const { isWebviewFromNativeApp } = useDeeplinkFromNativeApp();

  const location = useLocation();
  const navigate = useNavigate();
  const { update, loading, lifecycle } = useLifecycle();

  const [shouldSetupWalletNow, setShouldSetupWalletNow] =
    useState<boolean>(false);

  const { fiatBalanceStatus } = useFiatBalance();
  const action = useQueryString('action');
  const needsToKycBecauseRefused = action === 'kyc';

  const { isMobileApp } = useIsMobileApp();

  if (!currentUser) {
    return null;
  }

  const userCreatedAt = currentUser.createdAt;

  const userHasBeenCreatedBeforeCashWalletLaunch = isBefore(
    userCreatedAt,
    cashWalletLaunchDate
  );

  const userHasSetupWallet = !!lifecycle?.userHasSetupWallet;

  const nextTimeUserShouldSetupWallet =
    lifecycle?.nextTimeUserShouldSetupWallet;

  const onClose = () => {
    if (!userHasSetupWallet) {
      update(LIFECYCLE.userHasSetupWallet, true);
    }

    if (
      !nextTimeUserShouldSetupWallet ||
      isPast(new Date(nextTimeUserShouldSetupWallet))
    ) {
      update(
        LIFECYCLE.nextTimeUserShouldSetupWallet,
        addDays(new Date(), 7).toISOString()
      );
    }

    setShouldSetupWalletNow(false);
    if (needsToKycBecauseRefused) {
      // remove the ?action=
      navigate(location.pathname);
    }
  };

  if (
    !isWebviewFromNativeApp &&
    !userHasSetupWallet &&
    !loading &&
    (!hasMigratedAndSetupWallets || userHasBeenCreatedBeforeCashWalletLaunch) &&
    !shouldSetupWalletNow
  ) {
    setShouldSetupWalletNow(true);
    trackEnter({
      source: EnterCashWalletKYCFlow_Source.MIGRATION_MODAL,
      target: EnterCashWalletKYCFlow_Target.OPTIONAL_VALIDATED_OWNER,
    });
  }

  if (!loading && !hasMigratedAndSetupWallets && !shouldSetupWalletNow) {
    if (!nextTimeUserShouldSetupWallet) {
      update(
        LIFECYCLE.nextTimeUserShouldSetupWallet,
        addDays(new Date(), 2).toISOString()
      );
    } else if (
      isPast(new Date(nextTimeUserShouldSetupWallet)) &&
      !isWebviewFromNativeApp
    ) {
      setShouldSetupWalletNow(true);
      trackEnter({
        source: EnterCashWalletKYCFlow_Source.MIGRATION_MODAL,
        target: EnterCashWalletKYCFlow_Target.OPTIONAL_VALIDATED_OWNER,
      });
    }
  }

  if (
    !isWebviewFromNativeApp &&
    needsToKycBecauseRefused &&
    fiatBalanceStatus !== FiatWalletAccountState.VALIDATED_OWNER &&
    !shouldSetupWalletNow
  ) {
    setShouldSetupWalletNow(true);
    trackEnter({
      source: EnterCashWalletKYCFlow_Source.REJECTED_KYC_EMAIL,
      target: EnterCashWalletKYCFlow_Target.OPTIONAL_VALIDATED_OWNER,
    });
  }

  const isAuthorizedRoute =
    !location.pathname.match(TERMS_AND_CONDITIONS) ||
    !location.pathname.match(PRIVACY_POLICY);

  if (!shouldSetupWalletNow || !isAuthorizedRoute || isMobileApp) {
    return null;
  }

  const initialStep = () => {
    if (needsToKycBecauseRefused) return undefined;
    if (userHasSetupWallet)
      return CreateFiatWalletSteps.ACTIVATE_YOUR_CASH_WALLET;
    return CreateFiatWalletSteps.WHATS_NEW;
  };
  return (
    <CreateFiatWallet
      initialStep={initialStep()}
      statusTarget={FiatWalletAccountState.VALIDATED_OWNER}
      canDismissAfterActivation={!needsToKycBecauseRefused}
      onClose={onClose}
      onDismissActivationSuccess={onClose}
      onDismissBeforeActivation={onClose}
    />
  );
};
