import { FormattedMessage, defineMessages } from 'react-intl';

import { Sport } from '__generated__/globalTypes';
import { Button } from 'atoms/buttons/Button';
import { BodyM } from 'atoms/typography';

const messages = defineMessages({
  [Sport.FOOTBALL]: {
    id: 'EmptyResult.FOOTBALL',
    defaultMessage: 'No result in Football',
  },
  [Sport.BASEBALL]: {
    id: 'EmptyResult.BASEBALL',
    defaultMessage: 'No result in MLB',
  },
  [Sport.NBA]: {
    id: 'EmptyResult.NBA',
    defaultMessage: 'No result in NBA',
  },
});

export const EmptyResult = ({ sport }: { sport: Sport }) => {
  return (
    <div>
      <Button disabled size="medium" color="quaternary">
        <BodyM bold>
          <FormattedMessage {...messages[sport]} />
        </BodyM>
      </Button>
    </div>
  );
};
