import { useState } from 'react';

import { NewNavItem } from 'components/navigation/NewNavItem';

import { SearchBarDialog } from '../../SearchBar/SearchBarDialog';

type Props = React.ComponentProps<typeof NewNavItem>;
export const SearchNavItem = (props: Props) => {
  const [searchDialogOpened, setSearchDialogOpened] = useState(false);

  return (
    <>
      <NewNavItem {...props} onClick={() => setSearchDialogOpened(true)} />
      {searchDialogOpened && (
        <SearchBarDialog onClose={() => setSearchDialogOpened(false)} />
      )}
    </>
  );
};
