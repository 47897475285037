import { createGlobalStyle, css } from 'styled-components';

const APP_BAR_MOBILE_HEIGHT = 57;

export const GlobalStyles = createGlobalStyle<{
  noHeight: boolean;
  minSafeAreaInsetBottom?: number;
}>`
  :root {
    ${({ minSafeAreaInsetBottom = 0 }) => css`
      --safe-area-inset-bottom: max(
        ${minSafeAreaInsetBottom}px,
        env(safe-area-inset-bottom, 0px)
      );
    `}
    --bottom-bar-height-mobile: calc(${APP_BAR_MOBILE_HEIGHT}px + var(--safe-area-inset-bottom));
    ${({ noHeight }) =>
      noHeight &&
      css`
        --bottom-bar-height-mobile: 0px;
      `}
  }
`;
