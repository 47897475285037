import { Dispatch, SetStateAction, createContext, useContext } from 'react';

interface ResetPrivateKeyContext {
  resetPrivateKey: boolean;
  setResetPrivateKey: Dispatch<SetStateAction<boolean>>;
}

export const ResetPrivateKeyContext =
  createContext<ResetPrivateKeyContext | null>(null);

export const useResetPrivateKeyContext = () =>
  useContext(ResetPrivateKeyContext)!;
