import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { MonetaryAmount } from '__generated__/globalTypes';
import { Vertical } from 'atoms/layout/flex';
import { Snackbar } from 'atoms/snackbar/Snackbar';
import { LabelL } from 'atoms/typography';
import { SpentProgress } from 'components/wallet/BankEthAccounting/AddFundsToFiatWallet/promo/SpentProgress';
import {
  CreditCardPaymentMethod,
  PaymentMethod as DepositMethod,
} from 'components/wallet/BankEthAccounting/AddFundsToFiatWallet/types';
import { useCurrentUserContext } from 'contexts/currentUser';
import { Lifecycle } from 'hooks/useLifecycle';

import { DepositContext } from '.';

interface Props {
  children: ReactNode;
}

const isEqual = (a: MonetaryAmount, b: MonetaryAmount) => {
  return a.eur === b.eur && a.usd === b.usd && a.gbp === b.gbp;
};

const DepositActivePromotionalSpentNotification = () => {
  const { currentUser } = useCurrentUserContext();

  const [previousActivePromotionalSpent, setPreviousActivePromotionalSpent] =
    useState<MonetaryAmount | undefined>();
  const [open, setOpen] = useState(false);

  if (
    !previousActivePromotionalSpent &&
    currentUser?.completablePromotionalEvent?.promotionalEventCompletion
  ) {
    setPreviousActivePromotionalSpent(
      currentUser.completablePromotionalEvent.promotionalEventCompletion
        .actualAmounts
    );
  }

  if (
    previousActivePromotionalSpent &&
    currentUser?.completablePromotionalEvent?.promotionalEventCompletion &&
    !open &&
    !isEqual(
      previousActivePromotionalSpent,
      currentUser.completablePromotionalEvent.promotionalEventCompletion
        .actualAmounts
    )
  ) {
    setPreviousActivePromotionalSpent(
      currentUser.completablePromotionalEvent.promotionalEventCompletion
        .actualAmounts
    );
    setOpen(true);
  }

  if (!open) return null;

  return (
    <Snackbar
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      autoHideDuration={4000}
      message={
        <Vertical style={{ minWidth: '300px' }}>
          <LabelL bold>
            <FormattedMessage
              id="DepositActivePromotionalSpentNotification.winYourBox"
              defaultMessage="Win your reward box"
            />
          </LabelL>
          <SpentProgress />
        </Vertical>
      }
    />
  );
};

export const DepositProvider = ({ children }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const preferredDepositMethod = (
    currentUser?.userSettings.lifecycle as Lifecycle
  )?.preferredDepositMethod;
  const [defaultDepositMethod, setDefaultDepositMethod] = useState<
    DepositMethod | undefined
  >(
    preferredDepositMethod !== CreditCardPaymentMethod.CREDIT_CARD
      ? preferredDepositMethod
      : undefined
  );

  return (
    <DepositContext.Provider
      value={{ defaultDepositMethod, setDefaultDepositMethod }}
    >
      {children}
      <DepositActivePromotionalSpentNotification />
    </DepositContext.Provider>
  );
};
