import { ReactNode, Suspense, useState } from 'react';

import { CancelSalePopin } from 'components/offer/CancelSalePopin';

import { SingleSaleOfferContext, SingleSaleOfferPopinInfo } from '.';

interface Props {
  children: ReactNode;
}

export const SingleSaleOfferProvider = ({ children }: Props) => {
  const [popinInfo, setPopinInfo] = useState<SingleSaleOfferPopinInfo | null>(
    null
  );

  return (
    <SingleSaleOfferContext.Provider
      value={{
        showPopin: setPopinInfo,
      }}
    >
      {popinInfo && (
        <Suspense fallback={null}>
          <CancelSalePopin
            slug={popinInfo.slug}
            onClose={() => setPopinInfo(null)}
          />
        </Suspense>
      )}
      {children}
    </SingleSaleOfferContext.Provider>
  );
};
