import { MutableRefObject, createContext, useContext } from 'react';

interface GraphqlContext {
  refreshWsCable: () => void;
  setApiKey: (apiKey: string | undefined) => void;
  activeQueries: MutableRefObject<number>;
}

export const GraphqlContext = createContext<GraphqlContext | null>(null);

export const useGraphqlContext = () => useContext(GraphqlContext)!;
