import type { Connector } from 'instantsearch.js';
import { noop } from 'instantsearch.js/es/lib/utils';

import { joinFiltersWithAnd, parseFilters } from 'lib/algolia';

type ConnectorState = {
  name: string;
  filter: string;
};

export const connectFilter: Connector<
  {
    $$type: 'sorare.filters';
    renderState: ConnectorState;
    indexRenderState: Record<string, unknown>;
    indexUiState: Record<string, unknown>;
  },
  { name: string; filter: string }
> = (renderFn: any, unmountFn = noop) => {
  return widgetParams => {
    const { name, filter } = widgetParams;

    return {
      $$type: 'sorare.filters',

      init(initOptions) {
        const { instantSearchInstance } = initOptions;

        renderFn(
          {
            ...this.getWidgetRenderState(initOptions),
            instantSearchInstance,
          },
          true
        );
      },

      render(renderOptions) {
        const { instantSearchInstance } = renderOptions;

        renderFn(
          {
            ...this.getWidgetRenderState(renderOptions),
            instantSearchInstance,
          },
          false
        );
      },

      dispose({ state }) {
        unmountFn();

        const filters = parseFilters(state.filters);

        // Some filters are made of multiple filters, we need to remove them all
        const parsedFilter = parseFilters(filter);
        parsedFilter.forEach(f => {
          const index = filters.indexOf(f);
          if (index !== -1) {
            filters.splice(index, 1);
          }
        });

        filters.sort();

        state.filters = joinFiltersWithAnd(filters);
        return state;
      },

      getRenderState(renderState) {
        return renderState;
      },

      getWidgetRenderState() {
        return {
          name,
          filter,
          widgetParams,
        };
      },

      // route to state
      getWidgetUiState(uiState) {
        return uiState;
      },

      // state to route
      getWidgetSearchParameters(searchParameters) {
        const filters = parseFilters(searchParameters.filters);

        // Some filters are made of multiple filters, we need to add them all
        const parsedFilter = parseFilters(filter);

        if (parsedFilter.some(f => !filters.includes(f))) {
          filters.push(...parsedFilter);
        }
        filters.sort();

        searchParameters.filters = joinFiltersWithAnd(filters);

        return searchParameters;
      },
    };
  };
};
