import { useId } from 'react';
import styled from 'styled-components';

import { Rarity } from '__generated__/globalTypes';
import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

const ScarcityGradient = styled.linearGradient`
  stop {
    stop-color: var(--c-white);
  }

  &.limited stop {
    &:nth-child(1) {
      stop-color: #ae5e13;
    }
    &:nth-child(2) {
      stop-color: #dfad46;
    }
  }

  &.rare stop {
    &:nth-child(1) {
      stop-color: #c22342;
    }
    &:nth-child(2) {
      stop-color: #f2412d;
    }
  }

  &.super_rare stop {
    &:nth-child(1) {
      stop-color: #366bf2;
    }
    &:nth-child(2) {
      stop-color: #6b49db;
    }
  }

  &.unique stop {
    &:nth-child(1) {
      stop-color: #391885;
    }
    &:nth-child(2) {
      stop-color: #eb00ff;
    }
  }
`;

type Props = {
  variant?: Rarity;
  className?: string;
  size?: keyof typeof unitMapping;
  color?: Color;
};
export const Energy = ({
  variant,
  size = 2,
  className,
  color = 'var(--c-white)',
}: Props) => {
  const filterId = useId();

  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      className={className}
    >
      <g filter={`url(#${filterId})`}>
        <path
          d="M9.26 7.26L10.154 1H8.366L3 7.26v1.788h4.471l-.894 6.26h1.789l5.365-6.26V7.26H9.26z"
          fill={variant ? `url(#scarcity_gradient_${variant})` : color}
        />
      </g>
      <defs>
        {variant && (
          <ScarcityGradient
            id={`scarcity_gradient_${variant}`}
            x1="11.595"
            y1="13.868"
            x2="2.637"
            y2="4.952"
            gradientUnits="userSpaceOnUse"
            className={variant}
          >
            <stop />
            <stop offset="1" />
          </ScarcityGradient>
        )}
        <filter
          id={filterId}
          x="3"
          y="1"
          width="11.382"
          height="14.308"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx=".65" />
          <feGaussianBlur stdDeviation=".325" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_2023_85306" />
        </filter>
      </defs>
    </svg>
  );
};
