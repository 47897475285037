import styled, { css } from 'styled-components';

type StyleProps = {
  color?:
    | 'transparent'
    | 'grey'
    | 'white'
    | 'black'
    | 'blue'
    | 'green'
    | 'yellow'
    | 'red'
    | 'brand';
  size?: 'small' | 'medium' | 'large';
};

// https://www.figma.com/design/SFAfhEiMKvudU66FU9MB8G/Components?node-id=1983-5582&node-type=frame&m=dev
export const Chip = styled.span<StyleProps>`
  flex-shrink: 0;
  display: inline-flex;
  gap: var(--half-unit);
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-align: center;
  border-radius: 16px;

  ${({ color = 'transparent' }) => {
    switch (color) {
      case 'grey':
        return css`
          background: var(--c-nl-200);
          color: var(--c-black);
        `;
      case 'black':
        return css`
          background: var(--c-black);
          color: var(--c-white);
        `;
      case 'white':
        return css`
          background: var(--c-white);
          color: var(--c-black);
        `;
      case 'blue':
        return css`
          background: rgba(var(--c-rgb-brand-300), 0.16);
          color: var(--c-brand-300);
        `;
      case 'green':
        return css`
          background: rgba(var(--c-rgb-rivals-win), 0.16);
          color: var(--c-rivals-win);
        `;
      case 'yellow':
        return css`
          background: var(--c-surface-caution);
          color: var(--c-yellow-400);
        `;
      case 'red':
        return css`
          background: rgba(var(--c-rgb-red-800), 0.16);
          color: var(--c-red-600);
        `;
      case 'brand':
        return css`
          background: var(--c-gradient-mix);
          color: var(--c-white);
        `;
      case 'transparent':
      default:
        return css`
          background: var(--c-nd-50);
          color: var(--c-white);
          backdrop-filter: blur(32px);
        `;
    }
  }}
  ${({ size = 'small' }) => {
    switch (size) {
      case 'large':
        return css`
          min-height: 32px;
          padding: 6px var(--intermediate-unit);
          font: var(--t-label-l);
          font-weight: var(--t-bold);
        `;
      case 'medium':
        return css`
          min-height: 24px;
          padding: var(--half-unit) var(--unit);
          font: var(--t-label-m);
          font-weight: var(--t-bold);
        `;
      case 'small':
      default:
        return css`
          min-height: var(--chip-small-height);
          padding: 2px var(--unit);
          font: var(--t-label-s);
          font-weight: var(--t-bold);
        `;
    }
  }}
`;
