import { useLocation } from 'react-router-dom';

import { usePrevious } from './usePrevious';

// This hooks uses a comparison on location.key as location object no longer has
// stability when <Routes> is used since React Router 6.4.0
// https://github.com/remix-run/react-router/commit/e766ab5a286376bc26356a322e7ad95a11aa3d41
export const useLocationChanged = () => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  return !!(prevLocation && location.key !== prevLocation.key);
};
