import isMobileWeb from 'is-mobile';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { SignupPlatform, Sport } from '__generated__/globalTypes';
import { getValue } from 'components/PersistsQueryStringParameters/storage';
import { useAfterLoggedInTarget } from 'hooks/useAfterLoggedInTarget';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { useQueryString } from 'hooks/useQueryString';

interface Props {
  sport?: Sport;
}

export const useOAuthParams = ({ sport }: Props) => {
  const action = useQueryString('action');
  const location = useLocation();
  const afterLoggedInTarget = useAfterLoggedInTarget();
  const { isMobileApp, isAndroidApp } = useIsMobileApp();

  const signupPlatform = (() => {
    if (isMobileApp && isAndroidApp) return SignupPlatform.ANDROID;
    if (isMobileWeb()) return SignupPlatform.MOBILE_WEB;
    return SignupPlatform.WEB;
  })();

  return {
    referrer: getValue('referrer'),
    signup_platform: signupPlatform,
    signup_sport: sport,
    impact_click_id: getValue('irclickid'),
    google_click_id: getValue('gclid'),
    facebook_click_id: getValue('fbclid'),
    twitter_click_id: getValue('twclid'),
    tik_tok_click_id: getValue('ttclid'),
    reddit_click_id: getValue('rdt_cid'),
    invitation_token: getValue('invitation_token'),
    after_logged_in_target:
      afterLoggedInTarget ||
      (action
        ? qs.stringify({ action }, { skipNulls: true, addQueryPrefix: true })
        : location.pathname),
  };
};
