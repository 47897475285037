import { SVGProps } from 'react';

import { Rarity } from '__generated__/globalTypes';
import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

type Props = {
  size?: keyof typeof unitMapping;
  color?: Color;
  rarity?: Rarity;
  className?: string;
};

export const Boost = ({ size = 1, color, rarity, className }: Props) => {
  let fill: SVGProps<SVGPathElement>['fill'] = color || 'currentColor';
  if (rarity) {
    fill = `url(#boost-gradient-${rarity?.toLowerCase()})`;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 10"
      fill="none"
      style={{ height: unitMapping[size] }}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70721 0L0 2.85176L0.914578 4.04069L3.70723 1.89245L6.50001 4.0407L7.41456 2.85175L3.70721 0Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7072 3.01886L0.516135 5.33968L1.39841 6.55278L3.70722 4.8736L6.01615 6.55279L6.89839 5.33968L3.7072 3.01886Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70719 6.04485L1.04122 7.8222L1.87328 9.07027L3.70721 7.84763L5.54123 9.07028L6.37327 7.8222L3.70719 6.04485Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="boost-gradient-common"
          x1="12.3945"
          y1="12.8732"
          x2="2.33764"
          y2="4.65217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1DCDF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="boost-gradient-limited"
          x1="5.93838"
          y1="8.15706"
          x2="0.278531"
          y2="2.01725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE5E13" />
          <stop offset="1" stopColor="#DFAD46" />
        </linearGradient>
        <linearGradient
          id="boost-gradient-rare"
          x1="5.93838"
          y1="8.15706"
          x2="0.278531"
          y2="2.01725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C22342" />
          <stop offset="1" stopColor="#F2412D" />
        </linearGradient>
        <linearGradient
          id="boost-gradient-super_rare"
          x1="5.93838"
          y1="8.15706"
          x2="0.278531"
          y2="2.01725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#366BF2" />
          <stop offset="1" stopColor="#6B49DB" />
        </linearGradient>
        <linearGradient
          id="boost-gradient-unique"
          x1="5.93838"
          y1="8.15706"
          x2="0.278531"
          y2="2.01725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#171717" />
          <stop offset="1" stopColor="#4D4B49" />
        </linearGradient>
      </defs>
    </svg>
  );
};
