import styled from 'styled-components';

import {
  LoadingButton,
  Props as LoadingButtonProps,
} from 'atoms/buttons/LoadingButton';
import { useIsTabletAndAbove } from 'hooks/device/useIsTabletAndAbove';

const StyledButton = styled(LoadingButton)`
  &.medium {
    min-width: 240px;
  }
`;

export const OnboardingButton = ({
  loading,
  ...otherProps
}: Omit<LoadingButtonProps, 'loading'> & {
  loading?: boolean;
}) => {
  const isTabletAndAbove = useIsTabletAndAbove();
  return (
    <StyledButton
      {...otherProps}
      fullWidth={!isTabletAndAbove}
      loading={!!loading}
    />
  );
};
