import { createContext, useContext } from 'react';

import {
  ApproveMigrator,
  Deal,
  MessagingContext,
  Password,
  PrivateKeyRecoveryPayload,
  Prompt,
  RequestOAuth2,
  SettleDealSignatureType,
  SignTransfer,
  Sport as WalletSport,
} from '@sorare/wallet-shared';
import {
  AuthorizationApproval,
  AuthorizationRequest,
} from '@sorare/wallet-shared/src/contexts/messaging/authorizations';
import { Sport } from '__generated__/globalTypes';
import { UpdateUserAttributes, UpdateUserEmailAttributes } from 'contexts/auth';
import { RecoveryOption } from 'hooks/recovery/useRecoveryOptions';
import { GraphQLError } from 'lib/gql';

export type Transfer = SignTransfer['request']['args']['transfer'];

type OAuth2Platform = RequestOAuth2['request']['args']['platform'];

export type WalletPlaceHolderRequestedSize = {
  height: number;
  width: number;
};

export type WalletPlaceHolderResizeHandler = (
  dimensions: WalletPlaceHolderRequestedSize
) => void;

export type OAuth2RequestHandler = (
  platform: OAuth2Platform,
  {
    nickname,
    sport,
    acceptTerms,
    acceptAgeLimit,
    acceptToShareAll,
    acceptToShareSpecific,
  }: {
    nickname?: string;
    sport?: Sport;
    acceptTerms?: boolean;
    acceptAgeLimit?: boolean;
    acceptToShareAll?: boolean;
    acceptToShareSpecific?: string[];
  }
) => Promise<void>;

interface WalletContext {
  setWindow: (window: Window | undefined) => void;
  signIn: () => Promise<void>;
  signUp: (username: string, sport: WalletSport) => Promise<void>;
  signUpMobileView: () => Promise<void>;
  passwordForgotten: () => Promise<void>;
  promptResetPassword: () => Promise<void>;
  logOut: () => Promise<void>;
  prompt: (type: Prompt['request']['args']['type']) => Promise<void>;
  promptDeposit: (starkRegistrationSignature?: string) => Promise<void>;
  promptRestoreWallet: (
    privateKeyRecoveryPayloads: PrivateKeyRecoveryPayload[],
    recoveryOption?: RecoveryOption
  ) => Promise<void>;
  selectedRecoveryOption: RecoveryOption | null;
  handleWalletSuccessullyRecovered: () => Promise<void>;
  getPassword: (
    args?: Password['request']['args']
  ) => Promise<string | undefined>;
  checkUserPhoneNumberVerificationCodeWithRecovery: (
    code: string
  ) => Promise<{ message: string }[] | null>;
  loadWallet: () => Promise<{ errors?: { message: string }[] }>;
  updateUserEmailWithPassword: (
    attributes: UpdateUserEmailAttributes
  ) => Promise<{
    errors: GraphQLError[];
  } | null>;
  updateUserWithPassword: (attributes: UpdateUserAttributes) => Promise<any>;
  signSettleDeal: (
    deal: Deal,
    action: SettleDealSignatureType
  ) => Promise<string | undefined>;
  approveMigrator: (
    nonce: number
  ) => Promise<ApproveMigrator['response']['result'] | undefined>;
  signMigration: (
    cardsIds: string[],
    expirationBlock: number | string
  ) => Promise<string | undefined>;
  signEthMigration: (
    nonce: string,
    amount: bigint
  ) => Promise<string | undefined>;
  signTransfer: (transfer: Transfer) => Promise<string | null>;
  approveAuthorizationRequests: (
    authorizationRequests: AuthorizationRequest[]
  ) => Promise<{
    authorizationApprovals?: AuthorizationApproval[];
    starkKey?: string;
  }>;
  depositId?: string;
  signPaymentIntent: (id: string, amount: string) => Promise<string | null>;
  signWalletChallenge: (
    challenge: string
  ) => Promise<{ r: string; s: string } | null>;
  signMany: (values: string[]) => Promise<{ r: string; s: string } | null>;
  walletNode: Element | null;
  setWalletNode: (elt: Element | null) => void;
  setOnWalletResizeRequest: (
    callback: WalletPlaceHolderResizeHandler
  ) => () => void;
  registerOAuthHandler: (callback: OAuth2RequestHandler) => () => void;
}

export const WalletContext = createContext<WalletContext | null>(null);

export const useWalletContext = () => useContext(WalletContext)!;
export const useMessagingContext = () => useContext(MessagingContext)!;
