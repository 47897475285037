import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Button, Props as ButtonProps } from 'atoms/buttons/Button';
import { LoadingButton } from 'atoms/buttons/LoadingButton';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { BodyM } from 'atoms/typography';
import { Dialog } from 'components/dialog';
import { CloseButton } from 'components/dialog/CloseButton';
import { glossary } from 'lib/glossary';
import { regularMaterial } from 'style/utils';

const StyledDialog = styled(Dialog)`
  padding: var(--double-unit);
  border-radius: var(--triple-unit);
  border: 1px solid var(--c-nd-150);
  gap: var(--double-unit);
  ${regularMaterial}
`;

const StyledCloseButton = styled(CloseButton)`
  align-self: flex-end;
`;

const Message = styled(BodyM)`
  text-align: center;
  font-weight: normal;
`;

type Props = {
  onConfirm: (() => void) | (() => Promise<void>);
  title?: ReactNode;
  subtitle?: ReactNode;
  message?: ReactNode;
  cancelCta?: ReactNode;
  cta?: ReactNode;
  ctaProps?: Omit<ButtonProps, 'size'> & { size?: never };
  open: boolean;
  onClose: () => void;
};
export const ConfirmDialog = ({
  title,
  subtitle,
  message,
  onConfirm,
  cancelCta,
  cta,
  ctaProps,
  open,
  onClose,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const confirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
    onClose();
  };

  return (
    <StyledDialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      hideHeader
      transparent
      footer={
        <Horizontal gap={3}>
          <Button
            disabled={loading}
            size="medium"
            onClick={onClose}
            color="quaternary"
            fullWidth
          >
            {cancelCta || <FormattedMessage {...glossary.close} />}
          </Button>
          <LoadingButton
            loading={loading}
            size="medium"
            color="primary"
            onClick={confirm}
            fullWidth
            {...ctaProps}
          >
            {cta || (
              <FormattedMessage
                id="ConfirmDialog.confirm"
                defaultMessage="Confirm"
              />
            )}
          </LoadingButton>
        </Horizontal>
      }
    >
      <Vertical gap={2}>
        <StyledCloseButton onClose={onClose} />
        {title && <div className="text-center">{title}</div>}
        <Vertical>
          {subtitle}
          {message || (
            <Message>
              <FormattedMessage
                id="ConfirmDialog.message"
                defaultMessage="Are you sure?"
              />
            </Message>
          )}
        </Vertical>
      </Vertical>
    </StyledDialog>
  );
};
