export const theme = {
  zIndex: {
    modal: 1300,
    backdrop: 1299,
    snackbar: 1400,
    tooltip: 1500,
  },
  colors: {
    scarcity: {
      common: `var(--c-gradient-common)`,
      limited: `var(--c-gradient-limited)`,
      rare: `var(--c-gradient-rare)`,
      superRare: `var(--c-gradient-superRare)`,
      superRareMlb: `var(--c-gradient-superRareMlb)`,
      unique: `var(--c-gradient-unique)`,
      mix: `var(--c-gradient-mix)`,
      worldCup: `var(--c-gradient-worldCup)`,
      customSeries: `var(--c-gradient-customSeries)`,
    },
  },
};
