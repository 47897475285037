import { TypedDocumentNode, gql } from '@apollo/client';

// eslint-disable-next-line no-restricted-imports
import type { RewardBox } from '@sorare/trois/src/components/ClaimRewardBoxes/type';
import {
  ProbabilisticBundleIntensity,
  ProbabilisticBundleQuality,
} from '__generated__/globalTypes';
import { withFragments } from 'lib/gql';

import type { getRewardBoxRepresentation_ProbabilisticBundle } from './__generated__/probabilisticBundle.graphql';

export const BOX_COLOR: { [k in ProbabilisticBundleQuality]: string } = {
  [ProbabilisticBundleQuality.LITE]: '#999999',
  [ProbabilisticBundleQuality.STANDARD]: '#444444',
  [ProbabilisticBundleQuality.PREMIUM]: '#090909',
};
export const FRAME_COLOR: { [k in ProbabilisticBundleQuality]: string } = {
  [ProbabilisticBundleQuality.LITE]: '#999999',
  [ProbabilisticBundleQuality.STANDARD]: '#444444',
  [ProbabilisticBundleQuality.PREMIUM]: '#000000',
};
export const BOX_LIGHT_INTENSITY: {
  [k in ProbabilisticBundleIntensity]: number;
} = {
  [ProbabilisticBundleIntensity.DEFAULT]: 1,
  [ProbabilisticBundleIntensity.MODERATE]: 2,
  [ProbabilisticBundleIntensity.BRIGHT]: 4,
  [ProbabilisticBundleIntensity.HIGH]: 6,
  [ProbabilisticBundleIntensity.INTENSE]: 10,
};

export const getRewardBoxRepresentation = withFragments(
  (box: getRewardBoxRepresentation_ProbabilisticBundle): RewardBox => {
    return {
      id: box.id,
      type: box.config.design.toString(),
      boxColor: BOX_COLOR[box.config.quality],
      frameColor: FRAME_COLOR[box.config.quality],
      neonColor: box.config.neonColor,
      rewardColor: box.items.at(0)?.neonColor || '',
      intensity: BOX_LIGHT_INTENSITY[box.items.at(0)?.intensity || 'DEFAULT'],
      division: box.config.division ?? undefined,
      stars: box.config.stars ?? undefined,
      opened: box.opened,
      isOpenable: box.isOpenable,
      openableAt: box.openableAt,
      children: null,
      onClaim: async () => {},
      fileName: 'Reward',
    };
  },
  {
    ProbabilisticBundle: gql`
      fragment getRewardBoxRepresentation_ProbabilisticBundle on ProbabilisticBundle {
        id
        opened
        config {
          id
          name
          pictureUrl
          division
          design
          neonColor
          quality
          stars
        }
        isOpenable
        openableAt
        items {
          id
          neonColor
          intensity
        }
      }
    ` as TypedDocumentNode<getRewardBoxRepresentation_ProbabilisticBundle>,
  }
);
