import { useFeatureFlags } from '@sorare/core/src/hooks/useFeatureFlags';

export const useRivalsOnboarding = () => {
  const {
    flags: { enableNewRivalsOnboarding = false },
  } = useFeatureFlags();

  return {
    onboardingEnabled: enableNewRivalsOnboarding,
  };
};
