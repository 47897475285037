import classNames from 'classnames';
import { ReactNode, useCallback, useRef } from 'react';
import styled from 'styled-components';

import { useCurrentUserContext } from 'contexts/currentUser';
import {
  OnboardingHighlightConfig,
  OnboardingHighlightName,
  useOnboardingHighLightContext,
} from 'contexts/onboardingHighlight';
import { useResizeObserver } from 'hooks/ui/useResizeObserver';

const StyledDiv = styled.div`
  &.hidden {
    visibility: hidden;
  }
`;

type WrapperProps = {
  highlightName: OnboardingHighlightName;
  children: ReactNode;
  config?: OnboardingHighlightConfig;
  className?: string;
};
const Wrapper = ({
  highlightName,
  children,
  config,
  className,
}: WrapperProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { registerHighlight } = useOnboardingHighLightContext();

  const registerHighlightCb = useCallback(() => {
    registerHighlight({
      name: highlightName,
      measureCallback: () => {
        if (ref.current) {
          return ref.current.getBoundingClientRect();
        }
        return null;
      },
      config,
      children,
      refToScroll: config?.scrollIntoView ? ref : undefined,
    });
  }, [children, config, highlightName, registerHighlight]);

  useResizeObserver(ref, () => {
    registerHighlightCb();
  });

  return (
    <StyledDiv ref={ref} className={classNames(className, { hidden: true })}>
      {children}
    </StyledDiv>
  );
};

type Props = {
  highlightName: OnboardingHighlightName;
  children: ReactNode;
  config?: OnboardingHighlightConfig;
  wrap?: boolean;
  className?: string;
};
export const OnboardingHighlightWrapper = ({
  children,
  highlightName,
  config,
  wrap = true,
  className,
}: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { activeHighlights } = useOnboardingHighLightContext();
  const active = activeHighlights.includes(highlightName);

  if (currentUser && active && wrap) {
    return (
      <Wrapper
        highlightName={highlightName}
        config={config}
        className={className}
      >
        {children}
      </Wrapper>
    );
  }

  return children;
};
