import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useIsMobileApp } from 'hooks/useIsMobileApp';
import {
  notificationsQueue,
  useNotificationsQueue,
} from 'hooks/useNotificationsQueue';
import { snackNotificationLevelFromJSON } from 'protos/webview/channel/native_messages';

import {
  SnackNotificationContext,
  SnackNotificationOptions,
  notifications,
  useSnackNotificationContext,
} from '.';
import { SnackNotification as Notification } from './SnackNotification';

type Props = {
  children: ReactNode;
};

const formatValues = (values: any): { [key: string]: string } => {
  if (typeof values !== 'object') return {};

  return Object.keys(values).reduce<{ [key: string]: string }>(
    (formattedVal, key) => {
      formattedVal[key] = Array.isArray(values[key])
        ? values[key].join('\n')
        : values[key];
      return formattedVal;
    },
    {}
  );
};

export const SnackNotificationProvider = ({ children }: Props) => {
  const notificationQueue = useNotificationsQueue();
  const { formatMessage } = useIntl();
  const [notification, setNotification] =
    useState<SnackNotificationContext['notification']>(null);
  const { isAndroidApp, postMessage } = useIsMobileApp();

  useEffect(() => {
    // Need to do that in a useEffect because useExternalSyncStore can't be called in the render function
    if (notificationQueue.length && !notification) {
      setNotification(notificationsQueue.popNotification());
    }
  }, [notificationQueue, notification]);

  const showNotification = useCallback(
    (
      id: keyof typeof notifications,
      values = {},
      opts?: SnackNotificationOptions
    ) => {
      if (isAndroidApp) {
        const message = formatMessage(notifications[id], formatValues(values));
        // formatMessage is not always returning a string even if typed as it.
        if (typeof message === 'string') {
          postMessage('displaySnackNotification', {
            message,
            level: snackNotificationLevelFromJSON(opts?.level || 'INFO'),
            url: opts?.link,
          });
          return;
        }
      }

      setNotification({
        id,
        values,
        options: opts,
      });
    },
    [formatMessage, isAndroidApp, postMessage]
  );

  const value = useMemo(
    () => ({
      showNotification,
      notification,
      clearNotification: () => {
        setNotification(null);
      },
    }),
    [showNotification, notification, setNotification]
  );

  return (
    <SnackNotificationContext.Provider value={value}>
      {children}
    </SnackNotificationContext.Provider>
  );
};

export const SnackNotification = () => {
  const { formatMessage } = useIntl();
  const { notification, clearNotification } = useSnackNotificationContext();
  const { onClosed, ...options } = notification?.options || {};
  const onClose = useCallback(() => {
    clearNotification();
    if (onClosed) {
      onClosed();
    }
  }, [onClosed, clearNotification]);

  return (
    <Notification
      notification={
        notification
          ? formatMessage(
              notifications[notification.id],
              formatValues(notification.values)
            )
          : null
      }
      opts={options}
      onClose={onClose}
    />
  );
};
