import { PropsWithChildren } from 'react';
import { animated, easings, useSpring } from 'react-spring';
import styled from 'styled-components';

import { usePrevious } from 'hooks/usePrevious';

const Root = styled(animated.div)`
  opacity: 0;
`;

export const FadeInOut = ({ children }: PropsWithChildren) => {
  const previousChildren = usePrevious(children);
  const [style] = useSpring(
    () => ({
      from: { opacity: children ? 0 : 1 },
      to: { opacity: children ? 1 : 0 },
      config: {
        duration: 300,
        easing: easings.easeInOutCubic,
      },
    }),
    [children]
  );

  return <Root style={style}>{children || previousChildren}</Root>;
};
