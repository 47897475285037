import { ReactNode, useState } from 'react';
import { useDebounce } from 'react-use';
import styled from 'styled-components';

import { SearchInput } from '@sorare/core/src/atoms/inputs/SearchInput';
import { useIsTabletAndAbove } from '@sorare/core/src/hooks/device/useIsTabletAndAbove';
import { useTransitionApi } from '@sorare/core/src/hooks/ui/useTransitionApi';
import { tabletAndAbove } from '@sorare/core/src/style/mediaQuery';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: var(--quadruple-unit);
  background: var(--c-black);
  flex-direction: column;
  gap: var(--double-unit);
  @media ${tabletAndAbove} {
    flex-direction: row;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  @media ${tabletAndAbove} {
    min-width: 200px;
  }
`;

type Props = {
  onSearchInputChange: (value: string) => void;
  searchPlaceholder: string;
  children?: ReactNode;
};

export const Header = ({
  onSearchInputChange,
  searchPlaceholder,
  children,
}: Props) => {
  const isTabletAndAbove = useIsTabletAndAbove();
  const { updateDOM } = useTransitionApi();

  const [searchValue, setSearchValue] = useState('');
  useDebounce(
    () => {
      updateDOM(() => {
        onSearchInputChange(searchValue);
      });
    },
    500,
    [searchValue]
  );

  return (
    <Wrapper>
      {children}
      <StyledSearchInput
        placeholder={searchPlaceholder}
        fullWidth={!isTabletAndAbove}
        withIcon
        onChange={e => setSearchValue(e.target.value)}
      />
    </Wrapper>
  );
};
