import styled, { css } from 'styled-components';

import { tabletAndAbove } from 'style/mediaQuery';
import { Color } from 'style/types';

type BoldProps = { bold?: boolean };
type BrandProps = { brand?: boolean; brand25?: boolean };
type ColorProps = { color?: Color };
type UppercaseProps = { uppercase?: boolean };

export type Props = BoldProps & BrandProps & ColorProps & UppercaseProps;

const brandCSS = css`
  font-family: var(--brand-font);
  text-transform: uppercase;
`;
const brand25CSS = css`
  font-family: var(--rigid-square);
  text-transform: uppercase;
`;
const uppercaseCSS = css`
  text-transform: uppercase;
`;
const boldCSS = css`
  font-weight: var(--t-bold);
`;
const variant = ({ bold, uppercase, brand, brand25 }: Props) => css`
  ${brand && brandCSS}
  ${brand25 && brand25CSS}
  ${uppercase && uppercaseCSS}
  ${bold && boldCSS}
`;

const getColorsStyle = ({ color }: ColorProps) => {
  return {
    style: { color },
  };
};

export type TypographyVariant =
  | typeof DisplayM
  | typeof HeadlineXL
  | typeof HeadlineL
  | typeof HeadlineM
  | typeof HeadlineS
  | typeof HeadlineXS
  | typeof BodyL
  | typeof BodyM
  | typeof BodyS
  | typeof LabelM
  | typeof LabelS;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NullComponent = () => null;
export type NullableTypographyVariant =
  | TypographyVariant
  | typeof NullComponent;

const Root = styled.div.attrs<Props>(getColorsStyle)<Props>`
  font: var(--typography-font);
  ${variant};
`;
export const HeadlineXL = styled(Root)`
  --typography-font: var(--t-headline-l);
  @media ${tabletAndAbove} {
    --typography-font: var(--t-headline-xl);
  }
`;
export const HeadlineL = styled(Root)`
  --typography-font: var(--t-headline-m);
  @media ${tabletAndAbove} {
    --typography-font: var(--t-headline-l);
  }
`;
export const HeadlineM = styled(Root)`
  --typography-font: var(--t-headline-s);
  @media ${tabletAndAbove} {
    --typography-font: var(--t-headline-m);
  }
`;
export const HeadlineS = styled(Root)`
  --typography-font: var(--t-headline-xs);
  @media ${tabletAndAbove} {
    --typography-font: var(--t-headline-s);
  }
`;
export const HeadlineXS = styled(Root)`
  --typography-font: var(--t-headline-xs);
`;

export const BodyL = styled(Root)`
  --typography-font: var(--t-body-l);
`;
export const BodyM = styled(Root)`
  --typography-font: var(--t-body-m);
`;
export const BodyS = styled(Root)`
  --typography-font: var(--t-body-s);
`;

export const LabelL = styled(Root)`
  --typography-font: var(--t-label-l);
`;
export const LabelM = styled(Root)`
  --typography-font: var(--t-label-m);
`;
export const LabelS = styled(Root)`
  --typography-font: var(--t-label-s);
`;
export const LabelXS = styled(Root)`
  --typography-font: var(--t-label-xs);
`;

export const DisplayL = styled(Root)`
  --typography-font: var(--t-display-l);
  letter-spacing: -2%;
`;
export const DisplayM = styled(Root)`
  --typography-font: var(--t-display-m);
  letter-spacing: -2%;
`;
export const DisplayS = styled(Root)`
  --typography-font: var(--t-display-s);
`;
