import { useEffect, useState } from 'react';

import { Deferred } from '@sorare/wallet-shared';

import { useIsMobileApp } from './useIsMobileApp';

type Handler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

export const useClickHandler = (
  handler?: Handler,
  disable?: boolean
): Handler | undefined => {
  const [deferred, setDeferred] = useState(new Deferred<boolean>());
  const { isAndroidApp, postMessage } = useIsMobileApp();

  useEffect(() => {
    // if the component unmount after trigger the handler
    // timeout will always be undefined. Let's use a double check
    // to avoid no-op react errors.
    let timeout: NodeJS.Timeout | undefined;
    let isSubscribed = true;
    const reschedule = async () => {
      await deferred.promise;
      timeout = setTimeout(() => {
        if (isSubscribed) {
          setDeferred(new Deferred<boolean>());
        }
      }, 1000);
    };
    reschedule();
    return () => {
      isSubscribed = false;
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [deferred]);

  if (!handler) {
    return isAndroidApp ? () => postMessage('clickButton', {}) : undefined;
  }

  if (disable) {
    return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (isAndroidApp) postMessage('clickButton', {});
      handler(event);
    };
  }

  return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (!deferred.pending) return;

    deferred.resolve(true);
    if (isAndroidApp) postMessage('clickButton', {});
    handler(event);
  };
};
