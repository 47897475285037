import styled from 'styled-components';

import { ResponsiveImg } from 'atoms/ui/ResponsiveImg';
import { PlayerHit } from 'lib/algolia';

import { Suggestion } from '../Suggestion';

interface Props {
  hit: PlayerHit;
  isHighlighted: boolean;
}

const StyledResponsiveImg = styled(ResponsiveImg)`
  height: 40px;
`;

export const PlayerSuggestion = ({ hit, isHighlighted }: Props) => {
  return (
    <Suggestion
      isHighlighted={isHighlighted}
      renderAvatar={Img =>
        hit.avatarUrl ? (
          <div className="text-center">
            <StyledResponsiveImg
              cropWidth={40}
              src={hit.avatarUrl}
              alt={hit.display_name}
            />
          </div>
        ) : (
          <Img src={hit.avatarUrl} alt={hit.display_name} isContain />
        )
      }
      primary={hit.display_name}
      secondary={hit.activeClub ? hit.activeClub.name : ''}
    />
  );
};
