import { FunctionComponent, useState } from 'react';

import { Sport } from '__generated__/globalTypes';
import { AlgoliaIndex, useConfigContext } from 'contexts/config';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useSportContext } from 'contexts/sport';
import { idFromObject } from 'gql/idFromObject';
import { useLifecycle } from 'hooks/useLifecycle';

import { InstantSearch } from '../InstantSearch';
import { MAX_RESULTS, SearchBar } from '../SearchBar';

const makeSearchBar = (sport: Sport, indexes: AlgoliaIndex[]) =>
  function InstantSearchSearchBar(
    props: React.ComponentProps<typeof SearchBar> & {
      getOptionalFilters: (index: string) => string[];
      getFilters: (index: string) => string[];
    }
  ) {
    const { getOptionalFilters, getFilters } = props;
    const [displayLatestSearchItem, setDisplayLatestSearchItem] =
      useState(true);

    return (
      <InstantSearch
        indexes={indexes}
        defaultHitsPerPage={MAX_RESULTS}
        analyticsTags={['SearchBar']}
        sport={sport}
        distinct={false}
        {...(displayLatestSearchItem ? { getOptionalFilters } : {})}
        getFilters={getFilters}
      >
        <SearchBar
          {...props}
          setDisplayLatestSearchItem={setDisplayLatestSearchItem}
        />
      </InstantSearch>
    );
  };

interface Props {
  onExit: boolean;
  onSelect: () => void;
}

const SPORTS = Object.keys(Sport) as Sport[];

type SearchBarComponent = FunctionComponent<
  React.PropsWithChildren<
    React.ComponentProps<typeof SearchBar> & {
      getOptionalFilters: (index: string) => string[];
      getFilters: (index: string) => string[];
    }
  >
>;

const LoggedInSearchBarComponents: {
  [key in Sport]: SearchBarComponent;
} = {
  [Sport.BASEBALL]: makeSearchBar(Sport.BASEBALL, [
    'Player',
    'New',
    'Club',
    'User',
  ]),
  [Sport.FOOTBALL]: makeSearchBar(Sport.FOOTBALL, [
    'Player',
    'New',
    'Club',
    'National Team',
    'Competition',
    'User',
  ]),
  [Sport.NBA]: makeSearchBar(Sport.NBA, ['Player', 'New', 'Club', 'User']),
};

const SearchBarComponents: {
  [key in Sport]: SearchBarComponent;
} = {
  [Sport.BASEBALL]: makeSearchBar(Sport.BASEBALL, ['Player', 'Club', 'User']),
  [Sport.FOOTBALL]: makeSearchBar(Sport.FOOTBALL, [
    'Player',
    'Club',
    'National Team',
    'Competition',
    'User',
  ]),
  [Sport.NBA]: makeSearchBar(Sport.NBA, ['Player', 'Club', 'User']),
};

export const MultiSportSearchBar = ({ onExit, onSelect }: Props) => {
  const { sport: currentSport } = useSportContext();
  const { currentUser } = useCurrentUserContext();
  const [presetSearch, setPresetSearch] = useState<string>('');
  const [selectedSport, setSelectedSport] = useState(currentSport);
  const selectSport = (sportInput: Sport, search: string) => {
    setSelectedSport(sportInput);
    setPresetSearch(search);
  };
  const { algoliaIndexes } = useConfigContext();
  const { lifecycle } = useLifecycle();

  const latestSearchedItems = lifecycle?.latestSearchedItems;

  const sortedSports = [
    currentSport,
    ...SPORTS.filter(s => s !== currentSport),
  ];

  const SearchBarComponent = currentUser
    ? LoggedInSearchBarComponents[selectedSport]
    : SearchBarComponents[selectedSport];

  const getOptionalFilters = (index: string) =>
    latestSearchedItems
      ? (latestSearchedItems[selectedSport] || [])
          .filter(item => item.index === index)
          .map((item, i) => `objectID:${item.objectID}<score=${1000 - i}>`)
      : [];

  const getFilters = (index: string) => {
    return index === algoliaIndexes.New && currentUser
      ? [`user.id:${idFromObject(currentUser?.id)}`]
      : [];
  };

  return (
    <SearchBarComponent
      onExit={onExit}
      onSelect={onSelect}
      selectSport={selectSport}
      selectedSport={selectedSport}
      sortedSports={sortedSports}
      getOptionalFilters={getOptionalFilters}
      getFilters={getFilters}
      {...(presetSearch && { presetSearch })}
    />
  );
};
