import { createContext, useContext } from 'react';

export interface MarketplaceContextType {
  setHideDetails: (newValue: boolean) => void;
  hideDetails: boolean;
  showAddToCartPopin: (slug: string | null) => void;
}

const MarketplaceContext = createContext<MarketplaceContextType | null>(null);

export const useMarketplaceContext = () => useContext(MarketplaceContext)!;

export const MarketplaceProvider = MarketplaceContext.Provider;
