import { ReactNode } from 'react';
import { defineMessages } from 'react-intl';

import { Checkbox } from 'atoms/inputs/Checkbox';
import {
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
} from 'constants/__generated__/routes';
import { useIntlContext } from 'contexts/intl';

const messages = defineMessages({
  termsAndPrivacyPolicyLabel: {
    id: 'PromptTermsDialog.termsAndPrivacyPolicyLabel',
    defaultMessage:
      'I agree to Sorare’s <LinkToTerms>Terms and Conditions</LinkToTerms> and that my personal data will be processed pursuant to the <LinkToPrivacyPolicy>Privacy Policy</LinkToPrivacyPolicy>',
  },
});

const LinkToTerms = (s: ReactNode) => (
  <a href={TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer">
    {s}
  </a>
);

const LinkToPrivacyPolicy = (s: ReactNode) => (
  <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
    {s}
  </a>
);

export const AcceptTermsAndPrivacyPolicy = (props: {
  checked: boolean;
  onChange: () => void;
  name: string;
}) => {
  const { formatMessage } = useIntlContext();

  return (
    <Checkbox
      {...props}
      label={formatMessage(messages.termsAndPrivacyPolicyLabel, {
        LinkToTerms,
        LinkToPrivacyPolicy,
      })}
    />
  );
};
