import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import { Button } from 'atoms/buttons/Button';
import { Vertical } from 'atoms/layout/flex';
import { BodyM, HeadlineS } from 'atoms/typography';
import { glossary } from 'lib/glossary';

type Props = {
  onClose: () => void;
  codes: string[];
};

const messages = defineMessages({
  subtitle: {
    id: 'Settings.recoveryCodesDialog.subtitle',
    defaultMessage: 'Please save those backup codes!',
  },
  description: {
    id: 'Settings.recoveryCodesDialog.description',
    defaultMessage:
      'Those codes will allow you to enter your account if you lose your auth app. Each code can only be used once! Any previously generated codes no longer work.',
  },
});

const Wrapper = styled(Vertical).attrs({ gap: 2 })`
  padding: var(--triple-unit);
  width: 100%;
  max-width: 480px;
`;

const CodeBox = styled.div`
  width: 100%;
  background-color: var(--c-nd-100);
  padding: 0 var(--double-unit);
`;
const CodeList = styled.ul`
  columns: 2;
  padding-left: 0;
  list-style-type: none;
`;

export const RecoveryCodesDialogContent = ({ codes, onClose }: Props) => {
  return (
    <Wrapper>
      <HeadlineS as="h5" color="var(--c-white)">
        <FormattedMessage {...messages.subtitle} />
      </HeadlineS>
      <BodyM color="var(--c-nd-600)">
        <FormattedMessage {...messages.description} />
      </BodyM>
      <CodeBox>
        <CodeList>
          {codes.map(code => (
            <li key={code}>
              <code>{code}</code>
            </li>
          ))}
        </CodeList>
      </CodeBox>
      <Button color="primary" size="small" onClick={() => onClose()}>
        <FormattedMessage {...glossary.done} />
      </Button>
    </Wrapper>
  );
};
