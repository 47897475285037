import { TypedDocumentNode, gql } from '@apollo/client';

import { OwnerTransfer } from '__generated__/globalTypes';
import { sendSafeError } from 'lib/error';
import { withFragments } from 'lib/gql';
import { Font, getTextSize } from 'lib/text';

import type { drawObtainedInfos_AnyCardInterface } from './__generated__/drawObtainedInfos.graphql';
import auctionUrl from './assets/obtained/auction.svg';
import instantBuyUrl from './assets/obtained/instant_buy.svg';
import rewardUrl from './assets/obtained/reward.svg';
import shardsUrl from './assets/obtained/shards.svg';
import transfertUrl from './assets/obtained/transfert.svg';

const getObtainedInfos = (type: OwnerTransfer) => {
  switch (type) {
    case OwnerTransfer.ENGLISH_AUCTION:
    case OwnerTransfer.BUNDLED_ENGLISH_AUCTION:
    case OwnerTransfer.PACK:
      return { icon: auctionUrl, label: 'BOUGHT BY' };

    case OwnerTransfer.INSTANT_BUY:
      return { icon: instantBuyUrl, label: 'COLLECTED BY' };

    case OwnerTransfer.MINT:
    case OwnerTransfer.DEPOSIT:
    case OwnerTransfer.TRANSFER:
    case OwnerTransfer.WITHDRAWAL:
    case OwnerTransfer.SINGLE_SALE_OFFER:
    case OwnerTransfer.SINGLE_BUY_OFFER:
    case OwnerTransfer.DIRECT_OFFER:
      return { icon: transfertUrl, label: 'OBTAINED BY' };

    case OwnerTransfer.SHARDS:
      return { icon: shardsUrl, label: 'CRAFTED BY' };

    case OwnerTransfer.REFERRAL:
    case OwnerTransfer.REWARD:
      return { icon: rewardUrl, label: 'WON BY' };

    default:
      sendSafeError(`Unhandled HighlightedStatType ${type}`);
      return { icon: transfertUrl, label: 'OWNED BY' };
  }
};

export const drawObtainedInfos = withFragments(
  async ({
    ctx,
    card,
    y,
    font,
  }: {
    ctx: CanvasRenderingContext2D;
    card: drawObtainedInfos_AnyCardInterface;
    y: number;
    font: Font;
  }) => {
    if (!card.tokenOwner) {
      return;
    }
    const { icon, label } = getObtainedInfos(card.tokenOwner.transferType);
    const obtainedIcon = new Image();
    obtainedIcon.src = icon;
    ctx.fillStyle = '#fff';
    ctx.textAlign = 'center';

    const { height: textHeight } = getTextSize(label, font);
    // display icon
    await obtainedIcon.decode();
    ctx.save();
    ctx.shadowColor = 'transparent';
    ctx.globalCompositeOperation = 'soft-light';
    const { width } = ctx.canvas;
    const scale = 771 / width;
    const iconSize = 80 * scale;
    ctx.translate(width / 2 - iconSize / 2, y - textHeight / 2);
    ctx.rotate(Math.PI / -10);
    ctx.translate(-1 * (width / 2 - iconSize / 2), -1 * (y - textHeight / 2));
    ctx.drawImage(
      obtainedIcon,
      width / 2 - iconSize / 2,
      y - textHeight / 2 - iconSize / 2,
      iconSize,
      iconSize
    );
    ctx.restore();

    ctx.font = font;
    ctx.fillText(label, width / 2, y);
  },

  {
    AnyCardInterface: gql`
      fragment drawObtainedInfos_AnyCardInterface on AnyCardInterface {
        slug

        tokenOwner {
          id
          from
          transferType
        }
      }
    ` as TypedDocumentNode<drawObtainedInfos_AnyCardInterface>,
  }
);
