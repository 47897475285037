import { So5LeaderboardSeasonality } from '__generated__/globalTypes';
import { unitMapping } from 'lib/style';
import { Color } from 'style/types';

import { AllSeasons } from './AllSeasons';
import { InSeason } from './InSeason';

const IconBySeasonality = {
  [So5LeaderboardSeasonality.ALL_SEASONS]: AllSeasons,
  [So5LeaderboardSeasonality.IN_SEASON]: InSeason,
};

type Props = {
  seasonality: So5LeaderboardSeasonality;
  color?: Color;
  size?: keyof typeof unitMapping;
};

export const SeasonalityIcon = ({ seasonality, size = 2, color }: Props) => {
  const Component = IconBySeasonality[seasonality];

  return <Component size={size} color={color} />;
};
