import { PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components';

const cardFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
const Root = styled.div`
  position: relative;
  isolation: isolate;
  opacity: 0;
  animation: 200ms ${cardFadeIn} ease-in-out forwards;
`;
const GlowEffect = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
  filter: saturate(10) blur(25px);
  animation: 1000ms ${fadeInOut} ease-in-out forwards;
`;

type Props = { id: string };
export const MountingAnimation = ({
  id,
  children,
}: PropsWithChildren<Props>) => (
  <Root key={id}>
    {children}
    <GlowEffect>{children}</GlowEffect>
  </Root>
);
