import type { TypedDocumentNode } from '@apollo/client';
import { gql } from '@apollo/client';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { CSSProperties } from 'styled-components';

import { Tooltip } from 'atoms/tooltip/Tooltip';
import { useCurrentUserContext } from 'contexts/currentUser';
import { CARD_BORDER_RADIUS_2024 } from 'lib/cards';

import type { LoanWrapper_anyCard } from './__generated__/index.graphql';
import noGwLeft from './noGwLeft.svg';
import oneGwLeft from './oneGwLeft.svg';
import threeGwLeft from './threeGwLeft.svg';
import twoGwLeft from './twoGwLeft.svg';

const BadgeWrapper = styled(Tooltip)`
  position: absolute;
  top: 0;
  right: 8%;
  width: 20%;
  z-index: 1;
  & > img {
    max-width: 100%;
  }
`;

const getBorderColor = (gameWeekLeft: number) => {
  if (gameWeekLeft === 0) return 'var(--c-white)';
  if (gameWeekLeft === 1) return 'var(--c-score-veryLow)';
  if (gameWeekLeft === 2) return 'var(--c-score-mediumLow)';
  if (gameWeekLeft === 3) return '#01FFD0';

  return null;
};

const getIcon = (gameWeekLeft: number) => {
  if (gameWeekLeft === 0) return noGwLeft;
  if (gameWeekLeft === 1) return oneGwLeft;
  if (gameWeekLeft === 2) return twoGwLeft;
  if (gameWeekLeft === 3) return threeGwLeft;

  return null;
};

const LoanRoot = styled.div`
  position: relative;
  padding: 2px;
  border-radius: ${CARD_BORDER_RADIUS_2024};
  background: linear-gradient(
    200.94deg,
    var(--bgColor) 0.02%,
    rgba(255, 255, 255, 0) 100.01%
  );
`;

export const DumbLoanWrapper = ({
  gameWeekLeft,
  endDate,
  children,
  visible = true,
}: {
  gameWeekLeft: number;
  endDate: Date;
  children?: ReactNode;
  visible?: boolean;
}) => (
  <LoanRoot
    style={
      {
        '--bgColor': visible ? getBorderColor(gameWeekLeft) : 'transparent',
      } as CSSProperties
    }
  >
    <BadgeWrapper
      placement="top"
      style={{
        opacity: visible ? 1 : 0,
      }}
      title={
        <FormattedMessage
          id="loan.tooltip3"
          defaultMessage="{gwLeft, plural, one {# lineup submission} other {# lineup submissions}} left before {endDate, date, short}"
          values={{
            gwLeft: gameWeekLeft,
            endDate,
          }}
        />
      }
    >
      <img alt="" src={getIcon(gameWeekLeft)} />
    </BadgeWrapper>
    {children}
  </LoanRoot>
);

const MyLoanWrapper = ({
  children,
  card,
}: {
  children?: ReactNode;
  card: LoanWrapper_anyCard;
}) => {
  const { isMe } = useCurrentUserContext() || {};
  const { loanedToken } = card;
  if (!loanedToken || !isMe?.(card.user)) return children;
  return (
    <DumbLoanWrapper
      gameWeekLeft={loanedToken.gameWeekLeft}
      endDate={loanedToken.loan.endDate}
    >
      {children}
    </DumbLoanWrapper>
  );
};

export const LoanWrapper = ({
  children,
  card,
}: {
  children?: ReactNode;
  card?: LoanWrapper_anyCard | null;
}) => {
  if (!card) return children;
  const { loanedToken } = card;
  if (!loanedToken) return children;

  return <MyLoanWrapper card={card}>{children}</MyLoanWrapper>;
};

LoanWrapper.fragments = {
  anyCard: gql`
    fragment LoanWrapper_anyCard on AnyCardInterface {
      slug
      user {
        slug
      }
      loanedToken {
        id
        gameWeekLeft
        loan {
          id
          endDate
        }
      }
    }
  ` as TypedDocumentNode<LoanWrapper_anyCard>,
};
