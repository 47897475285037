import { useContext, useEffect } from 'react';

import { MessagingContext, PrepareEthDeposit } from '@sorare/wallet-shared';
import { usePrepareMutation } from 'contexts/blockchain/usePrepareEthDeposit';

export const usePrepareEthDeposit = () => {
  const { registerHandler } = useContext(MessagingContext)!;
  const prepareEthDeposit = usePrepareMutation();

  useEffect(() => {
    registerHandler<PrepareEthDeposit>(
      'prepareEthDeposit',
      async ({ weiAmount }) => {
        const result = await prepareEthDeposit(weiAmount);

        return { result: result || undefined };
      }
    );
  }, [registerHandler, prepareEthDeposit]);
};
