import { unitMapping } from 'lib/style';

type Props = {
  active?: boolean;
  size?: keyof typeof unitMapping;
};

export const PlayNBA = ({ active, size }: Props) => {
  const activeFill = active ? 'currentColor' : 'none';
  const inactiveFill = active ? 'none' : 'currentColor';
  return (
    <svg
      width="24"
      height="24"
      style={{
        width: size ? unitMapping[size] : undefined,
        height: size ? unitMapping[size] : undefined,
      }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.9C10.8244 18.9 9.82429 19.6513 9.45363 20.7H7.59001C8.00696 18.646 9.82293 17.1 12 17.1C14.177 17.1 15.993 18.646 16.41 20.7H14.5463C14.1757 19.6513 13.1756 18.9 12 18.9ZM18.3 12.9V11.1H15.1758C14.784 9.71492 13.5105 8.70002 12 8.70002C10.4895 8.70002 9.21596 9.71492 8.82421 11.1H5.69999V12.9H8.82421C9.21596 14.2851 10.4895 15.3 12 15.3C13.5105 15.3 14.784 14.2851 15.1758 12.9H18.3ZM7.59 3.3H16.41C15.993 5.35403 14.1771 6.90002 12 6.90002C9.82292 6.90002 8.00693 5.35403 7.59 3.3ZM12 5.10002C10.8244 5.10002 9.82428 4.3487 9.45362 3.30002H14.5464C14.1757 4.3487 13.1756 5.10002 12 5.10002ZM10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12C13.5 12.8285 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8285 10.5 12ZM20.1 1.5H3.89999V22.5H20.1V1.5Z"
        fill={activeFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.89999 1.5H20.1V22.5H3.89999V1.5ZM5.69999 3.3V20.7H7.59001H16.41H18.3V3.3H16.41H7.59001H5.69999Z"
        fill={inactiveFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.45363 3.3C9.82428 4.34867 10.8244 5.1 12 5.1C13.1756 5.1 14.1757 4.34867 14.5464 3.3H9.45363ZM16.41 3.3H7.59001C8.00695 5.35402 9.82293 6.9 12 6.9C14.1771 6.9 15.993 5.35402 16.41 3.3Z"
        fill={inactiveFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.45363 20.7C9.82428 19.6513 10.8244 18.9 12 18.9C13.1756 18.9 14.1757 19.6513 14.5464 20.7H9.45363ZM16.41 20.7H7.59001C8.00695 18.646 9.82293 17.1 12 17.1C14.1771 17.1 15.993 18.646 16.41 20.7C16.41 20.3918 16.469 20.9908 16.41 20.7Z"
        fill={inactiveFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.69998 12C8.69998 11.688 8.74328 11.3861 8.8242 11.1C9.21596 9.71491 10.4894 8.70001 12 8.70001C13.5105 8.70001 14.784 9.71491 15.1758 11.1C15.2567 11.3861 15.3 11.688 15.3 12C15.3 12.312 15.2567 12.6139 15.1758 12.9C14.784 14.2851 13.5105 15.3 12 15.3C10.4894 15.3 9.21596 14.2851 8.8242 12.9C8.74328 12.6139 8.69998 12.312 8.69998 12ZM12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5Z"
        fill={inactiveFill}
      />
      <path
        d="M8.8242 11.1C8.74328 11.3861 8.69998 11.688 8.69998 12C8.69998 12.312 8.74328 12.6139 8.8242 12.9L5.69999 12.9V11.1L8.8242 11.1Z"
        fill={inactiveFill}
      />
      <path
        d="M15.1758 12.9L18.3 12.9V11.1L15.1758 11.1C15.2567 11.3861 15.3 11.688 15.3 12C15.3 12.312 15.2567 12.6139 15.1758 12.9Z"
        fill={inactiveFill}
      />
    </svg>
  );
};
