import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { LabelS } from '@sorare/core/src/atoms/typography';
import { laptopAndAbove } from '@sorare/core/src/style/mediaQuery';
import { HexColor } from '@sorare/core/src/style/types';

const Label = styled(LabelS)`
  position: absolute;
  top: 0;
  left: 0;
  padding: var(--half-unit) var(--unit);
  color: var(--c-black);
  border-radius: var(--double-unit) 0 var(--unit) 0;
`;

const Wrapper = styled.div`
  --wrapper-border-radius: var(--double-unit);

  width: 100%;
  padding: var(--double-unit);
  border-radius: var(--wrapper-border-radius);
  position: relative;
  background-size: cover;
  background-position: center;
  margin: 2px;
  outline: 2px solid var(--outline-color);

  &.neon {
    box-shadow: 0px 0px 18.77px 0px var(--outline-color);
  }

  ${Label} {
    max-width: calc(100% - var(--wrapper-border-radius));
    background-color: var(--label-color, var(--outline-color));
  }

  @media ${laptopAndAbove} {
    width: min(440px, 100%);
  }
`;

type Props = {
  neon?: boolean;
  label: string | ReactNode;
  className?: string;
  children: ReactNode;
  labelColor?: HexColor;
  outlineColor?: HexColor;
};

export const LobbyTile = ({
  neon,
  label,
  children,
  className,
  labelColor,
  outlineColor,
}: Props) => {
  return (
    <Wrapper
      className={classNames(className, { neon })}
      style={
        {
          '--label-color': labelColor,
          '--outline-color': outlineColor,
        } as CSSProperties
      }
    >
      {children}
      <Label uppercase bold>
        {label}
      </Label>
    </Wrapper>
  );
};
