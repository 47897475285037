import { ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';

import { SEARCH_PARAMS } from 'components/search/InstantSearch/types';
import { AvailableLanguage } from 'i18n/useTranslations';
import {
  APPEARANCES_15_MAX,
  APPEARANCES_5_MAX,
  APPEARANCES_MIN,
} from 'lib/cards';
import { cardAttributes, filters, glossary } from 'lib/glossary';
import { attributes as playerAttributes } from 'lib/players';

type RealFilterWidget = {
  attribute: string;
  type: 'list' | 'range' | 'toggle';
};

type SortFilterWidget = {
  type: 'sort';
};

type VirtualToggleFilterWidget = {
  filter: VirtualToggleFilter;
  type: 'virtualToggle' | 'raw';
};

export type FilterSeparator = {
  type: 'separator';
};

export type FilterWidget = (
  | RealFilterWidget
  | SortFilterWidget
  | VirtualToggleFilterWidget
) & {
  key: string;
  title?: ReactNode;
  shortTitle?: ReactNode;
  Component: React.FC<{ visible: boolean }>;
  accordionOptions?: {
    startsOpen: boolean;
  };
};

export type Filter = {
  attribute: string;
  i18nAttribute?: (language: AvailableLanguage) => string;
  title: MessageDescriptor;
  shortTitle?: MessageDescriptor;
  label?: MessageDescriptor;
  alias?: string;
  min?: number;
  max?: number;
};

export type VirtualToggleFilter = {
  name: string;
  trackingName: string;
  title?: MessageDescriptor;
  label?: MessageDescriptor;
  defaultValue?: boolean | string[];
  skipClear?: boolean;
  min?: number;
  max?: number;
};

export type ToggleFilter = {
  attribute: string;
  label: MessageDescriptor | string;
};

export type FiltersGroup = {
  title?: ReactNode;
  filters: (FilterWidget | FilterSeparator)[];
  // allow showing a single filter without a border
  filter?: FilterWidget;
  slug: string;
};

export const FLOOR_PRICES_FILTERS = {
  inSeasonLimited: {
    attribute: 'floor_prices.in_season.limited',
    title: filters.floorPrices,
  },
  inSeasonRare: {
    attribute: 'floor_prices.in_season.rare',
    title: filters.floorPrices,
  },
  inSeasonSuperRare: {
    attribute: 'floor_prices.in_season.super_rare',
    title: filters.floorPrices,
  },
  inSeasonUnique: {
    attribute: 'floor_prices.in_season.unique',
    title: filters.floorPrices,
  },
  allSeasonsLimited: {
    attribute: 'floor_prices.all_seasons.limited',
    title: filters.floorPrices,
  },
  allSeasonsRare: {
    attribute: 'floor_prices.all_seasons.rare',
    title: filters.floorPrices,
  },
  allSeasonsSuperRare: {
    attribute: 'floor_prices.all_seasons.super_rare',
    title: filters.floorPrices,
  },
  allSeasonsUnique: {
    attribute: 'floor_prices.all_seasons.unique',
    title: filters.floorPrices,
  },
} satisfies { [key: string]: Filter };

export const FILTERS = {
  rarity: {
    attribute: 'rarity',
    title: cardAttributes.scarcity,
  },
  settlementWallets: {
    attribute: 'sale.settlement_wallets',
    title: filters.settlementWallets,
  },
  position: {
    attribute: 'position',
    title: cardAttributes.position,
  },
  team: {
    attribute: 'team.long_name',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    i18nAttribute: language => {
      return `team.long_name_with_slug`;
    },
    title: cardAttributes.team,
  },
  activeClub: {
    attribute: 'active_club.long_name',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    i18nAttribute: language => {
      return `active_club.long_name_with_slug`;
    },
    title: filters.activeClub,
  },
  activeTeam: {
    attribute: 'active_team.long_name',
    title: filters.activeTeam,
  },
  activeNationalTeam: {
    attribute: 'active_national_team.long_name',
    i18nAttribute: (language: AvailableLanguage) =>
      `active_national_team.name_${language}`,
    title: filters.activeNationalTeam,
  },
  nationality: {
    attribute: 'country.code',
    i18nAttribute: (language: AvailableLanguage) =>
      `country.name_with_code_${language}`,
    title: filters.nationality,
  },
  birthplace: {
    attribute: 'country.code',
    i18nAttribute: (language: AvailableLanguage) =>
      `country.name_with_code_${language}`,
    title: filters.birthplace,
  },
  player: {
    attribute: 'player.display_name',
    title: glossary.name,
  },
  season: {
    attribute: 'season',
    title: cardAttributes.season,
  },
  activeLeague: {
    attribute: 'active_leagues.display_name',
    i18nAttribute: (language: AvailableLanguage) =>
      `active_leagues.name_with_slug_${language}`,
    title: filters.tournament,
  },
  cardEdition: {
    attribute: 'card_edition.display_name',
    title: cardAttributes.cardEdition,
  },
  bundledSale: {
    attribute: 'sale.bundled',
    title: filters.bundledSale,
  },
  saleType: {
    attribute: 'sale.type',
    title: filters.saleType,
  },
  collectible: {
    attribute: 'rarity',
    title: filters.nonPlayableCardsFilterLabel,
    alias: 'co',
  },
  customDeck: {
    attribute: 'custom_decks.name',
    title: filters.customDeck,
  },
  customList: {
    attribute: 'custom_decks.name',
    title: filters.customList,
  },
  cardLevel: {
    attribute: 'grade',
    title: filters.cardLevel,
  },
  averageScore: {
    attribute: 'average_score',
    title: filters.averageScore,
  },
  lastFiveAverageScore: {
    attribute: 'so5.last_five_so5_average_score',
    title: filters.lastFiveAverageScore,
    alias: 'L5',
  },
  lastFifteenAverageScore: {
    attribute: 'so5.last_fifteen_so5_average_score',
    title: filters.lastFifteenAverageScore,
    alias: 'L15',
  },
  appearances: {
    attribute: 'appearances',
    title: filters.appearances,
  },
  lastFiveAppearances: {
    attribute: 'so5.last_five_so5_appearances',
    title: filters.lastFiveAppearances,
    min: APPEARANCES_MIN,
    max: APPEARANCES_5_MAX,
  },
  lastFifteenAppearances: {
    attribute: 'so5.last_fifteen_so5_appearances',
    title: filters.lastFifteenAppearances,
    min: APPEARANCES_MIN,
    max: APPEARANCES_15_MAX,
  },
  price: {
    attribute: 'sale.price',
    title: filters.price,
  },
  serialNumber: {
    attribute: 'serial_number',
    title: cardAttributes.serialNumber,
  },
  age: {
    attribute: 'player.birth_date_i',
    title: playerAttributes.age,
  },
  nbaTenGameAverageScore: {
    attribute: 'so5.last_ten_played_so5_average_score',
    title: filters.nbaTenGameAverageScore,
    shortTitle: filters.nbaShortTenGameAverageScore,
  },
  mlbLastFifteenAverageScore: {
    attribute: 'baseball_stats.last_fifteen_average_score',
    title: filters.mlbLastFifteenAverageScore,
  },
  mlbSeasonAverageScore: {
    attribute: 'baseball_stats.season_average_score',
    title: filters.mlbSeasonAverageScore,
  },
  mlbEligibleLeaderboards: {
    attribute: 'eligible_leaderboards',
    title: filters.tournament,
  },
  playingStatus: {
    attribute: 'player.playing_status',
    title: filters.playingStatusFilterLabel,
  },
  ...FLOOR_PRICES_FILTERS,
} satisfies { [key: string]: Filter };

export const VIRTUAL_TOGGLE_FILTERS = {
  gameweekFilter: {
    name: 'gw',
    trackingName: 'teamHasGame',
    title: filters.gameweekFilterLabel,
    defaultValue: false,
  },
  dailyFilter: {
    name: 'dly',
    trackingName: 'dailyFilter',
    title: filters.dailyFilterLabel,
    shortTitle: filters.dailyFilterShortLabel,
    defaultValue: false,
  },
  probablePitchersFilter: {
    name: 'pp',
    trackingName: 'probablePitchers',
    title: filters.probablePitchersFilterLabel,
    defaultValue: false,
  },
  promotedCardsFilter: {
    name: 'promo',
    trackingName: 'promotion',
    title: filters.promotedCardsFilterTitle,
    defaultValue: false,
  },
  favoriteFilter: {
    name: 'ff',
    trackingName: 'favorite',
    label: filters.favoriteFilterLabel,
    defaultValue: false,
  },
  competitionEligibilityFilter: {
    name: 'lf',
    trackingName: 'league',
    title: filters.competitionEligibilityFilterTitle,
    defaultValue: false,
  },
  customDecksFilter: {
    name: 'deck',
    trackingName: 'customDecksFilter',
    title: filters.customList,
    defaultValue: false,
  },
  notInLineupFilter: {
    name: 'nl',
    trackingName: 'notInLineup',
    label: filters.notInLineupFilterLabel,
    defaultValue: false,
  },
  promotionalEventFilter: {
    name: SEARCH_PARAMS.PROMOTIONAL_EVENT,
    trackingName: 'promotionalEvent',
    title: filters.promotionalEvent,
    defaultValue: false,
  },
  statsModeFilter: {
    name: SEARCH_PARAMS.STATS_MODE,
    trackingName: 'statsMode',
    label: cardAttributes.position,
    defaultValue: false,
  },
} as const;

export type VirtualToggleName =
  (typeof VIRTUAL_TOGGLE_FILTERS)[keyof typeof VIRTUAL_TOGGLE_FILTERS]['name'];

export const TOGGLE_FILTERS = {
  showDetails: {
    attribute: 'showDetails',
    label: filters.showDetailsLabel,
  },
  onSaleFilter: {
    attribute: 'on_sale',
    label: filters.onSaleFilterLabel,
  },
  creditEligibleFilter: {
    attribute: 'sale.type',
    label: filters.creditEligibleFilter,
  },
  inSeasonFilter: {
    attribute: 'in_season_eligible',
    label: 'In Season',
  },
  jerseySerialFilter: {
    attribute: 'jersey_serial',
    label: filters.jerseySerialLabel,
  },
} satisfies { [key: string]: ToggleFilter };

export const MENU = {
  position: {
    attribute: 'position',
    title: cardAttributes.position,
  },
};
