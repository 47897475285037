import { ReactNode } from 'react';

import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';

import { DesktopWebAppHeader } from './DesktopWebAppHeader';
import { MobileWebAppHeader } from './MobileWebAppHeader';

type Props = { extraHeaderButton?: ReactNode };
export const WebAppHeader = ({ extraHeaderButton }: Props) => {
  const isLaptopAndAbove = useIsLaptop();

  if (!isLaptopAndAbove) {
    return <MobileWebAppHeader extraHeaderButton={extraHeaderButton} />;
  }

  return <DesktopWebAppHeader extraHeaderButton={extraHeaderButton} />;
};
