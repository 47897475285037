import { ReactNode } from 'react';
import styled from 'styled-components';

import { Switch } from 'atoms/inputs/Switch';

const Wrapper = styled.div`
  padding: var(--unit) var(--container-padding);
  background: var(--c-black);
`;

const Filter = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--double-unit);
`;

type Props = {
  label: string | ReactNode;
  value: boolean;
  onChange: () => void;
};

export const ToggleFilter = ({ label, value, onChange }: Props) => {
  return (
    <Wrapper>
      <Filter>
        {label}
        <Switch checked={value} onChange={onChange} />
      </Filter>
    </Wrapper>
  );
};
