// TODO: remove duplicate cookie library from bundle
import cookies from 'js-cookie';

import { CategoryPreferences, Preferences, WindowWithAJS } from 'types';

const DEFAULT_COOKIE_NAME = 'tracking-preferences';
const COOKIE_DEFAULT_EXPIRES = 365;

function topDomain(url: string): string {
  // Remove protocol and path
  const domain = url.replace(/^https?:\/\//, '').split('/')[0];

  // Remove port if exists
  const withoutPort = domain.split(':')[0];

  // Handle IP addresses, localhost, and dev domains
  if (/^(\d+\.?){4}$/.test(withoutPort) || !withoutPort.includes('.')) {
    return '';
  }

  const parts = withoutPort.split('.');

  // Handle special cases like co.uk, co.il
  if (parts.length > 2 && parts[parts.length - 2] === 'co') {
    return parts.slice(-3).join('.');
  }

  // Return last two parts of domain
  return parts.slice(-2).join('.');
}

export function loadPreferences(cookieName?: string): Preferences {
  const preferences = JSON.parse(
    cookies.get(cookieName || DEFAULT_COOKIE_NAME) || '{}'
  );

  if (!preferences) {
    return {};
  }

  return {
    destinationPreferences: preferences.destinations as CategoryPreferences,
    customPreferences: preferences.custom as CategoryPreferences,
  };
}

type SavePreferences = Preferences & {
  cookieExpires?: number;
};

export function savePreferences({
  destinationPreferences,
  cookieExpires,
}: SavePreferences) {
  const wd = window as WindowWithAJS;
  if (wd.analytics) {
    wd.analytics.identify({
      destinationTrackingPreferences: destinationPreferences,
    });
  }

  // eslint-disable-next-line no-restricted-properties
  const domain = topDomain(window.location.href);
  const expires = cookieExpires || COOKIE_DEFAULT_EXPIRES;
  const value = {
    version: 1,
    destinations: destinationPreferences,
  };
  cookies.set(DEFAULT_COOKIE_NAME, JSON.stringify(value), {
    expires,
    domain,
  });
}
