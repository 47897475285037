import { defineMessages } from 'react-intl';

import { InGameCurrency, Rarity } from '__generated__/globalTypes';

import { rarities } from './cards';

export type ScarcityType = (typeof rarities)[number] | 'mix';

export const scarcityMessages = defineMessages({
  common: {
    id: 'ScarcityBall.common',
    defaultMessage: 'Common',
  },
  custom_series: {
    id: 'ScarcityBall.custom_series',
    defaultMessage: 'Custom Series',
  },
  limited: {
    id: 'ScarcityBall.limited',
    defaultMessage: 'Limited',
  },
  mix: {
    id: 'ScarcityBall.mix',
    defaultMessage: 'Mix',
  },
  rare: {
    id: 'ScarcityBall.rare',
    defaultMessage: 'Rare',
  },
  rare_pro: {
    id: 'ScarcityBall.rare_pro',
    defaultMessage: 'Rare Pro',
  },
  super_rare: {
    id: 'ScarcityBall.super_rare',
    defaultMessage: 'Super Rare',
  },
  unique: {
    id: 'ScarcityBall.unique',
    defaultMessage: 'Unique',
  },
});

export const RANKED_SCARCITY = {
  global_cup: 0,
  custom_series: 1,
  academy1: 2,
  academy2: 3,
  academy3: 4,
  academy4: 5,
  academy5: 6,
  common: 7,
  limited: 8,
  rare: 9,
  rare_pro: 10,
  super_rare: 11,
  unique: 12,
  mix: 13,
  training: 14,
};

type RankedScarcity = keyof typeof RANKED_SCARCITY;

export function sortByRarity<T extends RankedScarcity>(items: T[]): T[];
export function sortByRarity<T>(
  items: T[],
  accessor: (it: T) => RankedScarcity
): T[];
export function sortByRarity<T>(
  items: T[],
  accessor?: (it: T) => RankedScarcity
): T[] {
  return [...(items ?? [])].sort(
    (a, b) =>
      RANKED_SCARCITY[accessor?.(b) ?? (b as RankedScarcity)] -
      RANKED_SCARCITY[accessor?.(a) ?? (a as RankedScarcity)]
  );
}

export const inGameCurrencyToRarity: Record<InGameCurrency, Rarity | null> = {
  [InGameCurrency.COMMON_XP]: Rarity.common,
  [InGameCurrency.LIMITED_XP]: Rarity.limited,
  [InGameCurrency.RARE_XP]: Rarity.rare,
  [InGameCurrency.SUPER_RARE_XP]: Rarity.super_rare,
  [InGameCurrency.UNIQUE_XP]: Rarity.unique,
  [InGameCurrency.LIMITED_ENERGY]: Rarity.limited,
  [InGameCurrency.RARE_ENERGY]: Rarity.rare,
  [InGameCurrency.SUPER_RARE_ENERGY]: Rarity.super_rare,
  [InGameCurrency.UNIQUE_ENERGY]: Rarity.unique,
  [InGameCurrency.OLD_CLUB_SHOP_COIN]: null,
  [InGameCurrency.COMMON_GEM]: Rarity.common,
};
