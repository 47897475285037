import { ReactNode, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { LoadingIndicator } from '@sorare/core/src/atoms/loader/LoadingIndicator';
import { GoogleOneTap } from '@sorare/core/src/components/GoogleOneTap';
import { PersistsQueryStringParameters } from '@sorare/core/src/components/PersistsQueryStringParameters';
import { ReportCampaignVisit } from '@sorare/core/src/components/ReportCampaignVisit';
import { ResolveImpactClickId } from '@sorare/core/src/components/ResolveImpactClickId';
import { DiscoverablePrompt } from '@sorare/core/src/components/discoverablePrompt';
import { MobileAppHelmet } from '@sorare/core/src/components/mobileApp/MobileAppHelmet';
import { NetworkInfo } from '@sorare/core/src/components/navigation/NetworkInfo';
import { RestrictedAccessModals } from '@sorare/core/src/components/restrictedAccess/RestrictedAccessModals';
import { CookieConsentBanner } from '@sorare/core/src/components/user/CookieConsentBanner';
import { WalletSetup } from '@sorare/core/src/components/wallet/WalletSetup';
import { IS_PRERENDER } from '@sorare/core/src/config';
import { AccountSecurityCheckProvider } from '@sorare/core/src/contexts/accountSecurityCheck/Provider';
import { AuthProvider } from '@sorare/core/src/contexts/auth/Provider';
import { BrazeProvider } from '@sorare/core/src/contexts/braze/Provider';
import { ConfigProvider } from '@sorare/core/src/contexts/config/Provider';
import { ConnectionProvider } from '@sorare/core/src/contexts/connection/Provider';
import { CurrentUserProvider } from '@sorare/core/src/contexts/currentUser/Provider';
import { EventsProvider } from '@sorare/core/src/contexts/events/Provider';
import { HighlightProvider } from '@sorare/core/src/contexts/highlight/Provider';
import { HighlightProvider as ManagerTaskProvider } from '@sorare/core/src/contexts/managerTask/Provider';
import { OnboardingHighlightProvider } from '@sorare/core/src/contexts/onboardingHighlight/Provider';
import { OneTimeDialogProvider } from '@sorare/core/src/contexts/oneTimeDialog/Provider';
import { ResetPrivateKeyProvider } from '@sorare/core/src/contexts/resetPrivateKey/Provider';
import { RestrictedAccessProvider } from '@sorare/core/src/contexts/restrictedAccess/Provider';
import { SeoProvider } from '@sorare/core/src/contexts/seo/Provider';
import { SnackNotification } from '@sorare/core/src/contexts/snackNotification/Provider';
import { SportProvider } from '@sorare/core/src/contexts/sport/Provider';
import { SvgProvider } from '@sorare/core/src/contexts/svg/Provider';
import { TickerProvider } from '@sorare/core/src/contexts/ticker/Provider';
import { Frame as WalletFrame } from '@sorare/core/src/contexts/wallet/Frame';
import { WalletProvider } from '@sorare/core/src/contexts/wallet/Provider';
import { WalletDrawerProvider } from '@sorare/core/src/contexts/walletDrawer/Provider';
import { useSyncAuthStateAcrossTabs } from '@sorare/core/src/hooks/auth/useSyncAuthStateAcrossTabs';
import { useStyledScrollbar } from '@sorare/core/src/hooks/useStyledScrollbar';
import { useVh } from '@sorare/core/src/hooks/useVh';
import { logVersion } from '@sorare/core/src/lib/logVersion';
import { EnsureLatestAppVersion } from '@sorare/core/src/routing/EnsureLatestAppVersion';

import { MarketplaceProvider } from '@sorare/marketplace/src/contexts/Marketplace/Provider';
import { SingleSaleOfferProvider } from '@sorare/marketplace/src/contexts/singleSaleOffer/Provider';

import { MobileAppPreloader } from 'components/MobileAppPreloader';

const LocationTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page(undefined);
    }
  }, [location.pathname]);

  return null;
};

export const AppProviders = ({ children }: { children: ReactNode }) => {
  useVh();
  useStyledScrollbar();
  useSyncAuthStateAcrossTabs();
  useEffect(() => {
    logVersion();
  }, []);

  return (
    <>
      <LocationTracker />
      <PersistsQueryStringParameters />
      <MobileAppPreloader />
      <MobileAppHelmet />
      <EnsureLatestAppVersion />
      <ReportCampaignVisit />
      <SnackNotification />
      <EventsProvider>
        <ResetPrivateKeyProvider>
          <CookieConsentBanner />
          <HighlightProvider>
            <ManagerTaskProvider>
              <OnboardingHighlightProvider>
                <SportProvider>
                  <Suspense fallback={<LoadingIndicator fullScreen />}>
                    <ConfigProvider>
                      <SeoProvider>
                        <RestrictedAccessProvider>
                          <CurrentUserProvider>
                            <ResolveImpactClickId />
                            <DiscoverablePrompt />
                            <AuthProvider>
                              <WalletDrawerProvider>
                                <WalletProvider>
                                  <ConnectionProvider>
                                    <GoogleOneTap />
                                    <AccountSecurityCheckProvider>
                                      <WalletSetup />
                                      <SingleSaleOfferProvider>
                                        <TickerProvider>
                                          <SvgProvider>
                                            <NetworkInfo />
                                            <RestrictedAccessModals />
                                            <OneTimeDialogProvider>
                                              <MarketplaceProvider>
                                                <BrazeProvider>
                                                  <Suspense fallback={null}>
                                                    {children}
                                                  </Suspense>
                                                  {
                                                    /* Do not load the Wallet Frame in prerender (google crawler, wayback machine, etc…) */
                                                    !IS_PRERENDER && (
                                                      <Suspense fallback={null}>
                                                        <WalletFrame />
                                                      </Suspense>
                                                    )
                                                  }
                                                </BrazeProvider>
                                              </MarketplaceProvider>
                                            </OneTimeDialogProvider>
                                          </SvgProvider>
                                        </TickerProvider>
                                      </SingleSaleOfferProvider>
                                    </AccountSecurityCheckProvider>
                                  </ConnectionProvider>
                                </WalletProvider>
                              </WalletDrawerProvider>
                            </AuthProvider>
                          </CurrentUserProvider>
                        </RestrictedAccessProvider>
                      </SeoProvider>
                    </ConfigProvider>
                  </Suspense>
                </SportProvider>
              </OnboardingHighlightProvider>
            </ManagerTaskProvider>
          </HighlightProvider>
        </ResetPrivateKeyProvider>
      </EventsProvider>
    </>
  );
};
