import { OAuth as OAuthContent } from '@sorare/core/src/components/OAuth';
import { RequireAuth } from '@sorare/core/src/routing/RequireAuth';

import { AppLayout } from '@sorare/gameplay/src/components/navigation/AppLayout';

const OAuth = () => {
  return (
    <RequireAuth>
      <AppLayout>
        <OAuthContent />
      </AppLayout>
    </RequireAuth>
  );
};

export default OAuth;
