import { useState } from 'react';

import { Button } from 'atoms/buttons/Button';
import { RaffleTicket } from 'atoms/icons/RaffleTicket';
import { Dialog } from 'components/dialog';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useIsDesktopAndAbove } from 'hooks/device/useIsDesktopAndAbove';

import { RaffleTicketDialogContent } from './RaffleTicketDialogContent';

export const RaffleTicketBalanceButton = () => {
  const { currentUser } = useCurrentUserContext();
  const [raffleTicketDialogOpen, setRaffleTicketDialogOpen] = useState(false);
  const isDesktopAndAbove = useIsDesktopAndAbove();
  if (!currentUser || !currentUser?.raffleTicketsCount) {
    return null;
  }
  const { raffleTicketsCount } = currentUser;

  return (
    <>
      <Button
        onClick={() => setRaffleTicketDialogOpen(true)}
        color={isDesktopAndAbove ? 'transparent' : 'tertiary'}
        size={isDesktopAndAbove ? 'medium' : 'compact'}
      >
        <RaffleTicket width={12} />
        {raffleTicketsCount}
      </Button>
      <Dialog
        maxWidth="xs"
        fullWidth
        hideHeader
        open={raffleTicketDialogOpen}
        onClose={() => setRaffleTicketDialogOpen(false)}
      >
        <RaffleTicketDialogContent
          onClose={() => setRaffleTicketDialogOpen(false)}
        />
      </Dialog>
    </>
  );
};
