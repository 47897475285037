import { TypedDocumentNode, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { useCurrentUserContext } from 'contexts/currentUser';

import {
  UpdateUserSettingsLocaleMutation,
  UpdateUserSettingsLocaleMutationVariables,
} from './__generated__/useSetUserSettingsLocale.graphql';

const UPDATE_USER_SETTINGS_LOCALE_MUTATION = gql`
  mutation UpdateUserSettingsLocaleMutation($input: updateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      userSettings {
        id
        locale
      }
      errors {
        path
        message
        code
      }
    }
  }
` as TypedDocumentNode<
  UpdateUserSettingsLocaleMutation,
  UpdateUserSettingsLocaleMutationVariables
>;

export const useSetUserSettingsLocale = () => {
  const { currentUser } = useCurrentUserContext();
  const [mutate] = useMutation(UPDATE_USER_SETTINGS_LOCALE_MUTATION);

  const setUserSettingsLocale = useCallback(
    (locale: string) => {
      if (currentUser) {
        mutate({ variables: { input: { locale } } });
      }
    },
    [currentUser, mutate]
  );

  return setUserSettingsLocale;
};
