export const drawStat = async ({
  ctx,
  value,
  label,
  iconUrl,
  x,
  y,
  fontName,
  scale,
}: {
  ctx: CanvasRenderingContext2D;
  value: number;
  label: string;
  iconUrl?: string;
  x: number;
  y: number;
  fontName: string;
  scale: number;
}) => {
  if (iconUrl) {
    const icon = new Image();
    icon.src = iconUrl;

    await icon.decode();
    ctx.save();
    ctx.globalCompositeOperation = 'overlay';
    ctx.globalAlpha = 0.5;
    ctx.drawImage(
      icon,
      x + 19 * scale,
      y + 1.5 * scale,
      88 * scale,
      88 * scale
    );
    ctx.restore();
  }

  ctx.fillStyle = '#fff';
  ctx.textAlign = 'center';
  ctx.font = `bold ${85 * scale}px ${fontName}`;

  ctx.fillText(value.toString(), x + 100 * scale, y + 58 * scale);

  ctx.font = `${23 * scale}px ${fontName}`;
  ctx.fillText(label.toLocaleUpperCase(), x + 100 * scale, y + 92 * scale);
};
