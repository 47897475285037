import { Navigate } from '@sorare/routing';

import { INVITE } from '@sorare/core/src/constants/routes';
import { useSportContext } from '@sorare/core/src/contexts/sport';

const ReferralProgram = () => {
  const { sport } = useSportContext();
  const { generateSportContextPath } = useSportContext();
  return <Navigate to={generateSportContextPath(INVITE, { sport })} replace />;
};

export default ReferralProgram;
