import { TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { To } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Link } from '@sorare/routing';

import { SportOrShared } from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon, IconDefinition } from '@sorare/core/src/atoms/icons';
import { SorareLogo } from '@sorare/core/src/atoms/icons/SorareLogo';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { LabelM, LabelS } from '@sorare/core/src/atoms/typography';
import { Card } from '@sorare/core/src/components/card/Card';
import { Avatar } from '@sorare/core/src/components/user/Avatar';
import { useIntlContext } from '@sorare/core/src/contexts/intl';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import {
  getSportFromSportOrShared,
  useFormattedSport,
} from '@sorare/core/src/lib/sports';

import {
  DumbNotification_cardPicture,
  DumbNotification_userAvatar,
} from './__generated__/index.graphql';

type BaseProps = {
  createdAt: Date;
  sport: SportOrShared | null;
  name: string;
  title: string | ReactNode;
  cardPicture?: DumbNotification_cardPicture | null;
  link?: To;
  read?: boolean;
  onClick?: () => void;
  inModale?: boolean;
  children?: ReactNode;
};

type DefaultIconProps = {
  userAvatar?: never;
  avatarUrl?: never;
  icon?: never;
};

type UserAvatarProps = {
  userAvatar: DumbNotification_userAvatar | null | undefined;
  avatarUrl?: never;
  icon?: never;
};

type AvatarUrlProps = {
  userAvatar?: never;
  avatarUrl: Nullable<string>;
  icon?: never;
};

type FontawesomeIconProps = {
  userAvatar?: never;
  avatarUrl?: never;
  icon: IconDefinition;
};

type IconProps =
  | UserAvatarProps
  | AvatarUrlProps
  | FontawesomeIconProps
  | DefaultIconProps;

type Props = BaseProps & IconProps;

const Root = styled.span`
  width: 100%;
  display: flex;
  text-align: left;
  gap: var(--double-unit);
  padding: var(--double-unit) var(--double-unit);
  border-bottom: 1px solid var(--c-nd-50);
  color: var(--c-white);
  cursor: pointer;

  &.read {
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }

  &.inModale {
    border-color: var(--c-nd-100);
    &:hover {
      background-color: var(--c-nd-150);
    }
  }

  &:hover {
    background-color: var(--c-nd-100);
    color: var(--c-white);
    opacity: 1;
  }
`;

const AvatarContainer = styled(Horizontal).attrs({ gap: 0, center: true })`
  width: calc(5 * var(--unit));
  height: calc(5 * var(--unit));
`;

const SorareLogoContainer = styled(AvatarContainer)`
  background-color: var(--c-brand-800);
  border-radius: var(--unit);
`;

const avatarStyle = css`
  width: calc(4 * var(--unit));
`;
const StyledAvatar = styled.img`
  ${avatarStyle}
`;
const StyledSorareLogo = styled(SorareLogo)`
  ${avatarStyle}
`;
const Infos = styled(Vertical).attrs({ gap: 0.5 })`
  flex: 1;
`;
const ImgContainer = styled.div`
  width: calc(5 * var(--unit));
`;

const NotificationAvatar = ({ userAvatar, avatarUrl, icon }: IconProps) => {
  if (userAvatar) {
    return (
      <div>
        <Avatar variant="medium" user={userAvatar} />
      </div>
    );
  }
  if (avatarUrl) {
    return (
      <AvatarContainer>
        <StyledAvatar src={avatarUrl} alt="" />
      </AvatarContainer>
    );
  }
  if (icon) {
    return (
      <AvatarContainer>
        <FontAwesomeIcon size="xl" icon={icon} />
      </AvatarContainer>
    );
  }
  return (
    <SorareLogoContainer>
      <StyledSorareLogo />
    </SorareLogoContainer>
  );
};

export const DumbNotification = ({
  createdAt,
  name,
  title,
  cardPicture,
  link,
  sport,
  read,
  onClick,
  inModale,
  children,
  ...avatarProps
}: Props) => {
  const { formatDistanceToNow } = useIntlContext();

  const isAbsoluteLink =
    link && typeof link === 'string' && !link.startsWith('/');
  const linkProps = link
    ? {
        to: link,
        target: isAbsoluteLink ? '_blank' : undefined,
        rel: isAbsoluteLink ? 'noopener noreferrer' : undefined,
      }
    : {};

  const formatSport = useFormattedSport();
  const track = useEvents();
  const trackOnClick = () => {
    track('Click Notification', { name });
    if (onClick) {
      onClick();
    }
  };

  return (
    <Root
      as={link ? Link : 'div'}
      role={link ? 'link' : undefined}
      {...linkProps}
      onClick={trackOnClick}
      className={classNames({ inModale, read })}
    >
      {cardPicture ? (
        <ImgContainer>
          <Card card={cardPicture} />
        </ImgContainer>
      ) : (
        <NotificationAvatar {...avatarProps} />
      )}
      <Infos>
        <LabelM as="div">{title}</LabelM>
        {children}
        <LabelS color="var(--c-nd-600)">
          {formatSport(getSportFromSportOrShared(sport))}
          {' • '}
          {formatDistanceToNow(createdAt)}
        </LabelS>
      </Infos>
    </Root>
  );
};

DumbNotification.fragments = {
  avatarUser: gql`
    fragment DumbNotification_userAvatar on PublicUserInfoInterface {
      slug
      ...Avatar_publicUserInfoInterface
    }
    ${Avatar.fragments.publicUserInfoInterface}
  ` as TypedDocumentNode<DumbNotification_userAvatar>,
  cardPicture: gql`
    fragment DumbNotification_cardPicture on AnyCardInterface {
      slug
      ...Card_anyCard
    }
    ${Card.fragments.anyCard}
  ` as TypedDocumentNode<DumbNotification_cardPicture>,
};
